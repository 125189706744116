import React from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import PropTypes from 'prop-types';
import { RolesGrid, contextMenuItems } from '../../data/RolesTableDefinition';

const EmployeesRole = ({ items }) => {
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };

  return (
    <div className="m-2 mt-3 p-2 md:p-5 bg-white rounded-3xl">
      <GridComponent
        dataSource={items}
        width="auto"
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        pageSettings={{ pageCount: 5, pageSize: 10 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {RolesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar]} />
      </GridComponent>
    </div>
  );
};

EmployeesRole.propTypes = {
  items: PropTypes.object.isRequired,
};
export default EmployeesRole;
