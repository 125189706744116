import { Line } from 'rc-progress';
import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { ShopContext } from '../../contexts/ShopContext';
import { Header, Modal } from '../../components';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const ShopExport = () => {
  const token = useSelector(selectCurrentToken);
  const [shops] = useContext(ShopContext);
  const shopsForExport = shops.filter((shop) => shop.is_imported);
  const [formData, setFormData] = useState({ export: null, import: null });
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');
  const [actions, setActions] = useState('');
  const [errorMsg, setErrorMsg] = useState(['', '', '']);
  const [modalTitle, setModalTitle] = useState('Check Data Integrity...');
  const [modalInfo, setModalInfo] = useState('');
  const [modalSuccess, setModalSuccess] = useState('');
  const [modalWarning, setModalWarning] = useState('');
  const [modalError, setModalError] = useState('');
  const [progress, setProgress] = useState(0);
  const STEPS = [
    {
      name: 'dataCheck',
      title: 'Check for existing data ...',
      progress: 10,
    },
    {
      name: 'dataPreparing',
      title: 'data check',
      progress: 10,
    },
    {
      name: 'CustomerExport',
      title: 'exportCustomer',
      progress: 10,
    },
    {
      name: 'categoryExport',
      title: 'exportCategory',
      progress: 10,
    },
    {
      name: 'groupAttributeExport',
      title: 'exportGroupAttribute',
      progress: 10,
    },
    {
      name: 'AttributeExport',
      title: 'exportAttribute',
      progress: 10,
    },
    {
      name: 'productExport',
      title: 'exportProduct',
      progress: 40,
    },
    {
      name: 'success',
      title: 'success',
      progress: 30,
    },
  ];

  const handleModalClose = () => {
    setMessages([]);
    setModalTitle('Check Data Integrity...');
    setModalSuccess('');
    setModalError('');
    setModalWarning('');
    setModalInfo('');
    setShowModal(false);
    setProgress(0);
  };

  const checkExistingData = () =>
    new Promise((resolve, reject) => {
      setIsLoading(true);
      const decoded = jwtDecode(token);
      const data = JSON.stringify(formData);
      const config = {
        method: 'post',
        url: `${apiURL}/exports/check-shop-data`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      api
        .request(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });

  const prepareData = async () => {
    const decoded = jwtDecode(token);
    setActions(
      <button
        type="button"
        className="text-white bg-red-600 float-right focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500"
        onClick={() => handleModalClose()}
      >
        Stop exporting
      </button>
    );
    // tempModal.isLoading = true;
    setMessages([]);
    setText('Preparing data for export... ');
    const data = JSON.stringify(formData);
    const config = {
      method: 'post',
      url: `${apiURL}/exports/prepare-data`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    await api
      .request(config)
      .then(async (dataaa) => {
        console.log(dataaa, 'Dammn');
        setProgress((prevState) => prevState + STEPS[1].progress);
        try {
          await exportCustomer(0, 100);
          await exportCategory(0, 100);
          await exportGroupAttribute(0, 100);
          await exportAttribute(0, 100);
          await exportProduct(0, 100);
          finishExport();
        } catch (error) {
          // Handle any potential errors here
        }
      })
      .catch((err) => console.log(err, 'EFFFF'));
  };

  const exportCustomer = async (start, end) => {
    setText('Exporting customers ...');
    const decoded = jwtDecode(token);
    const tempFormData = formData;
    tempFormData.start = start;
    tempFormData.end = end;
    const data = JSON.stringify(tempFormData);
    const config = {
      method: 'post',
      url: `${apiURL}/exports/export-customer-to-woo`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    try {
      const response = await api.request(config);
      if (!response.data.finished) {
        const newStart = start + 100;
        const newEnd = end + 100;
        await exportCustomer(newStart, newEnd);
      } else {
        setProgress((prevState) => prevState + STEPS[2].progress);
        return 'success';
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const exportCategory = async (start, end) => {
    setText('Exporting categories ...');
    const decoded = jwtDecode(token);
    const tempFormData = formData;
    tempFormData.start = start;
    tempFormData.end = end;
    const data = JSON.stringify(tempFormData);
    const config = {
      method: 'post',
      url: `${apiURL}/exports/export-category-to-woo`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    try {
      const response = await api.request(config);
      if (!response.data.finished) {
        const newStart = start + 100;
        const newEnd = end + 100;
        await exportCategory(newStart, newEnd);
      } else {
        setProgress((prevState) => prevState + STEPS[3].progress);
        return 'success';
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const exportGroupAttribute = async (start, end) => {
    setText('Exporting group attributes ...');
    const decoded = jwtDecode(token);
    const tempFormData = formData;
    tempFormData.start = start;
    tempFormData.end = end;
    const data = JSON.stringify(tempFormData);
    const config = {
      method: 'post',
      url: `${apiURL}/exports/export-group-attribute-to-woo`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    try {
      const response = await api.request(config);
      if (!response.data.finished) {
        const newStart = start + 100;
        const newEnd = end + 100;
        await exportGroupAttribute(newStart, newEnd);
      } else {
        setProgress((prevState) => prevState + STEPS[4].progress);
        return 'success';
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const exportAttribute = async (start, end) => {
    setText('Exporting attributes ...');
    const decoded = jwtDecode(token);
    const tempFormData = formData;
    tempFormData.start = start;
    tempFormData.end = end;
    const data = JSON.stringify(tempFormData);
    const config = {
      method: 'post',
      url: `${apiURL}/exports/export-attribute-to-woo`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    try {
      const response = await api.request(config);
      if (!response.data.finished) {
        const newStart = start + 100;
        const newEnd = end + 100;
        await exportAttribute(newStart, newEnd);
      } else {
        setProgress((prevState) => prevState + STEPS[5].progress);
        return 'success';
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const exportProduct = async (start, end) => {
    try {
      setText('Exporting products ...');
      const decoded = jwtDecode(token);
      const tempFormData = formData;
      tempFormData.start = start;
      tempFormData.end = end;
      const data = JSON.stringify(tempFormData);
      const config = {
        method: 'post',
        url: `${apiURL}/exports/export-product-to-woo`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data,
      };

      const response = await api.request(config);
      if (!response.data.finished) {
        const newStart = start + 100;
        const newEnd = end + 100;
        await exportProduct(newStart, newEnd);
      } else {
        setProgress((prevState) => prevState + STEPS[6].progress);
        setText('Data exported successfully ...');
        return 'success';
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  function finishExport() {
    setActions(
      <button
        type="button"
        className="text-black float-right focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500"
        onClick={() => handleModalClose()}
      >
        Continue
      </button>
    );
  }

  const handleExport = async (e) => {
    e.preventDefault();

    if (formData.export === formData.import) {
      alert('Import and export shops must be different');
      return;
    }

    const temp = [...errorMsg];

    if (formData.export === null) {
      temp[0] = 'Required';
    } else {
      temp[0] = '';
    }

    if (formData.import === null) {
      temp[1] = 'Required';
    } else {
      temp[1] = '';
    }

    setErrorMsg(temp);

    if (formData.export === null || formData.import === null) {
      return;
    }

    setShowModal(true);

    try {
      const data = await checkExistingData();

      setIsLoading(false);

      if (data.length) {
        setMessages(data);
        setText(
          'The following data is presented in your shop. Are you sure you want to continue?'
        );
        setProgress(STEPS[0].progress);
        setActions(
          <button
            type="button"
            className="text-black float-right focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500"
            onClick={() => prepareData()}
          >
            Continue
          </button>
        );
      }
    } catch (error) {
      temp[2] = true;
      setErrorMsg(temp);
    }
  };

  const handleInputChange = (name, value) => {
    const temp = {
      ...formData,
      [name]: value,
    };
    setFormData({ ...temp });
  };

  const progressBar = () => (
    <div className="pb-2">
      <div className="flex justify-between mb-1">
        <span className="text-sm font-medium text-blue-700 dark:text-white">
          {isLoading ? spinner() : ''}
        </span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <Line percent={progress} strokeColor="#4BB543" />
        <div className="flex justify-between font-light text-shifti-blue">
          <span className="float-right ml-auto">{progress}%</span>
        </div>
      </div>
    </div>
  );

  function isImported(shop) {
    if (shop.is_imported) {
      return (
        <span className="inline-block">
          <FontAwesomeIcon
            icon={faCheck}
            className="text-green-700 checked-1 pl-2"
          />
          <span className="font-bold text-xs text-gray-900"> imported </span>
        </span>
      );
    }
  }

  function spinner() {
    return (
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
    );
  }

  useEffect(() => {}, [progress]);

  return (
    <div className="md:mt-2 h-screen mt-24 md:ml-5 p-2 md:p-5 rounded-3xl relative">
      <Header category="Page" title="Data transfer" />
      <form onSubmit={(e) => handleExport(e)} className="w-2/3 m-auto">
        <div className="flex justify-center gap-20">
          <div>
            <h3 className="text-xl font-bold text-center w-80 mb-4">
              Export From
            </h3>
            <div className="bg-white shadow-xl rounded-xl py-12 flex flex-col items-center">
              <div className="w-auto text-left">
                {shopsForExport.map((shop, i) => (
                  <Link onClick={() => handleInputChange('export', shop.id)}>
                    <label
                      htmlFor={`export-${i}`}
                      className="block cursor-pointer mb-2 relative"
                    >
                      <div className="relative inline-block">
                        <input
                          type="radio"
                          className="mr-1 appearance-none before:w-5 before:h-5 before:border-1 before:border-solid before:rounded-full before:border-[#181143] before:inline-block before:content-[''] before:checked:bg-shifti-main-bg cursor-pointer"
                          name="export"
                          id={`${shop.id}-export`}
                          value={shop.id}
                          checked={formData.export === shop.id}
                        />
                        {formData.export === shop.id ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{ left: '2', top: '3' }}
                            className="text-[#EC1577] absolute checked-1"
                          />
                        ) : null}
                      </div>
                      {shop.name}
                    </label>
                  </Link>
                ))}
              </div>
              <div style={{ color: '#FF0000', textAlign: 'center' }}>
                {errorMsg[0]}
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold text-center w-80 mb-4">
              Export To
            </h3>
            <div className="bg-white shadow-xl rounded-xl py-12 flex flex-col items-center">
              <div className="w-auto text-left">
                {shops.map((shop, i) => (
                  <Link onClick={() => handleInputChange('import', shop.id)}>
                    <label
                      htmlFor={`import-${i}`}
                      className="block cursor-pointer mb-2 relative"
                    >
                      <div className="relative inline-block">
                        <input
                          type="radio"
                          className="mr-1 appearance-none before:w-5 before:h-5 before:border-1 before:border-solid before:rounded-full before:border-[#181143] before:inline-block before:content-[''] before:checked:bg-shifti-main-bg cursor-pointer"
                          name="import"
                          id={`${shop.id}-import`}
                          value={shop.id}
                          checked={formData.import === shop.id}
                        />
                        {formData.import === shop.id ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{ left: '2', top: '3' }}
                            className="text-[#EC1577] absolute checked-1"
                          />
                        ) : null}
                      </div>
                      {shop.name}
                      {isImported(shop)}
                    </label>
                  </Link>
                ))}
              </div>
              <div style={{ color: '#FF0000', textAlign: 'center' }}>
                {errorMsg[1]}
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-shifti-pink-bg py-1 px-12 text-white font-semibold rounded-full float-right shadow-xl mt-12"
        >
          Export
        </button>
      </form>

      <Modal
        showModal={showModal}
        handleModalClose={handleModalClose}
        title={modalTitle}
        info={modalInfo}
        warning={modalWarning}
        error={modalError}
        success={modalSuccess}
      >
        <div>
          {progressBar()}
          <div className="pb-6 flex justify-between items-center">{text}</div>
          <ul>
            {messages.length
              ? messages.map((item) => (
                  <li className="list-disc ml-4" key={item}>
                    {item}
                  </li>
                ))
              : ''}
          </ul>
        </div>
        <div className="flex justify-end items-center py-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
          {/* <button
            type="button"
            className="text-white bg-red-600 float-right focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500"
            onClick={() => handleModalClose()}
          >
            Stop exporting
          </button> */}
          {actions}
        </div>
      </Modal>
    </div>
  );
};

export default ShopExport;
