import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BsFiles, BsArchive } from 'react-icons/bs';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Creatable from 'react-select/creatable';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header, Toggle } from '../../components';
import { ShopContext } from '../../contexts/ShopContext';
import { selectCurrentToken } from '../../features/auth/authSlice';

import ShopNotImported from '../../components/ShopNotImported';

const apiURL = process.env.REACT_APP_API_URL;

const CreateCategory = () => {
  const token = useSelector(selectCurrentToken);
  const [
    shops,
    setShops,
    allShopTypes,
    allShopIds,
    selectedShop,
    setSelectedShop,
  ] = useContext(ShopContext);

  const [toggle, setToggle] = useState(false);
  const [isShopImported, setIsShopImported] = useState();
  const [shopType, setShopType] = useState('');
  const [url, setUrl] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    active: toggle,
    parent_id: 0,
    description: '',
    coverImage: null,
    thumbnailImage: null,
    menuImage: null,
    metaTitle: '',
    metaDescription: '',
    metaKeyWords: [],
    friendlyUrl: '',
    validation: {
      error: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      errorMsg: ['Required', '', '', '', '', '', '', '', '', '', ''],
    },
  });
  const [formDataError, setformDataError] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [categories, setCategories] = useState({
    categories: [],
    categoryHierarchy: [],
    toggledItems: [],
    selected: [],
  });

  const navigate = useNavigate();
  const [stores, setStores] = useState([]);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '1.5rem',
      height: '4rem',
      border: '0',
      width: '100%',
    }),
  };
  const quillRef = useRef(null);
  const handelFormDataChange = (value, key, index) => {
    const aux = { ...formData };
    if (key === 'name') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    } else if (key === 'toggle') {
      const temp = { ...categories };
      if (categories.toggledItems.includes(value)) {
        temp.toggledItems = categories.toggledItems.filter(
          (item) => item !== value
        );
      } else {
        temp.toggledItems.push(value);
      }
      setCategories(temp);
      return;
    } else if (key === 'metaKeyWords') {
      if (typeof value === 'object') {
        aux.metaKeyWords = value;
      } else {
        const temp = { value, label: value };
        const tempArr = aux.metaKeyWords
          .filter((item) => item.value !== value)
          .concat(temp);
        aux.metaKeyWords = tempArr;
      }
      setFormData(aux);
      return;
    } else if (key === 'friendlyUrl') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    } else if (key === 'shops') {
      if (value.checked === false && aux.shops.length < 2) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      const itemIndex = aux[key].indexOf(value.value);
      if (value.checked === true && itemIndex === -1) {
        aux[key].push(value.value);
      } else if (itemIndex > -1) {
        aux[key].splice(itemIndex, 1);
      }
      setFormData(aux);
      return;
    }
    aux[key] = value;
    setFormData(aux);
  };

  const handleKeyDown = (e) => {
    if (
      e.key === '<' ||
      e.key === '>' ||
      e.key === '#' ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '=' ||
      e.key === ';'
    ) {
      e.preventDefault();
    }
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const decoded = jwtDecode(token);
    const ERROR = [...formData.validation.error];
    const ERROR_MSG = [...formData.validation.errorMsg];
    setformDataError(ERROR_MSG);
    if (ERROR.includes(true)) return;
    const idToast = toast.loading('Please wait...');
    const data = JSON.stringify({
      name: formData.name,
      active: formData.active,
      parent_id: formData.parent_id.foreign_id,
      description: formData.description,
      cover: formData.cover,
      thumbnail: formData.thumbnail,
      menuThumbnail: formData.menuThumbnail,
      meta_title: formData.metaTitle,
      meta_description: formData.metaDescription,
      meta_keywords: formData.metaKeyWords,
      link_rewrite: formData.friendlyUrl,
      shops: [selectedShop],
    });
    const config = {
      method: 'post',
      url: `${apiURL}/categories`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    api
      .request(config)
      .then(async () => {
        toast.update(idToast, {
          render: 'Attribute group created succesfully !',
          type: 'success',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        });
        setTimeout(() => {
          navigate('/categories');
        }, 1500);
      })
      .catch((err) => {
        toast.update(idToast, {
          render: 'Failed to create group attribute !',
          type: 'error',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };
  const getStores = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-group-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };

    await api
      .request(config)
      .then((response) => {
        setStores(response.data);
      })
      .catch((err) => {});
  };
  const getCategories = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/categories/shops/${selectedShop}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .request(config)
      .then((response) => {
        const { data } = response;
        const tempCategories = data.map((item) => ({
          label: item.CategoryLangs[0].name,
          value: item.id,
          parent_id: item.parent_id,
          foreign_id: item.CategoryShops[0].foreign_id,
        }));
        const categoryMap = {}; // empty object to store categories

        // loop through categories and add to categoryMap
        for (const category of tempCategories) {
          categoryMap[category.foreign_id] = category;
          category.children = []; // add empty children array
          category.active = false;
        }

        const categoryHierarchy = []; // array to store top-level categories
        // loop through categories again and add to parent categories' children arrays
        for (const category of tempCategories) {
          if (category.parent_id) {
            // find parent category in categoryMap and add current category to its children array
            categoryMap[category.parent_id].children.push(category);
          } else {
            // no parent category, so add to top-level categories
            categoryHierarchy.push(category);
          }
        }

        const temp = { ...categories };
        temp.categories = tempCategories;
        temp.categoryHierarchy = categoryHierarchy;
        setCategories(temp);
      })
      .catch((error) => {
        JSON.stringify(error);
      });
  };
  const generateCheckboxes = (e) =>
    e.map((category) => (
      <div key={category.value} className="relative">
        <span className="text-[#EC1577]">
          <MdKeyboardArrowRight
            onClick={() => handelFormDataChange(category.value, 'toggle', 2)}
            className={`inline text-2xl	text-[#181143] cursor-pointer ${
              categories.toggledItems.includes(category.value)
                ? 'rotate-90'
                : ''
            }`}
          />
          <label>
            <input
              className="mr-2"
              type="radio"
              onChange={() => handelFormDataChange(category, 'parent_id', 2)}
              name="parentCategory"
              value={category.value}
            />
            {category.label}
          </label>
        </span>
        {category.children && category.children.length > 0 && (
          <div
            className={`ml-3 ${
              !categories.toggledItems.includes(category.value)
                ? 'absolute invisible '
                : ''
            }`}
          >
            {generateCheckboxes(category.children)}
          </div>
        )}
      </div>
    ));
  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      quill.on('keydown', handleKeyDown);

      return () => {
        quill.off('keydown', handleKeyDown);
      };
    }
  }, [quillRef]);

  useEffect(() => {
    getStores();
    getCategories();
    for (let i = 0; i < shops.length; i += 1)
      if (shops[i].id === selectedShop) setUrl(shops[i].url);
    if (shopType === '') {
      setIsShopImported(shops[0].is_imported);
      setShopType(shops[0].type);
    }
  }, [selectedShop, formData]);

  const handleShop = (e) => {
    setSelectedShop(e.target.value);
    e.target.selectedOptions[0].getAttribute('isImported') === 'true'
      ? setIsShopImported(true)
      : setIsShopImported(false);
    setShopType(e.target.selectedOptions[0].getAttribute('type'));
  };

  return (
    <>
      <div className="w-[95%] sticky top-[5.5rem] md:ml-[3%] ml-[3.5%] z-40">
        <div className="filter-by-shop mb-5 relative md:w-full w-[22rem]">
          <select
            name="shops"
            id=""
            className="shopSelect text-center"
            onChange={handleShop}
          >
            {shops.map((shop) => (
              <option
                type={shop.type}
                value={shop.id}
                className="text-[#EC1577] text-center"
                key={shop.id}
              >
                {shop.name}
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            icon={faShop}
            className="h-[1.5rem] w-[1.5rem] scale-100 md:left-[42%] md:top-[19%] top-[19%] left-[30%]  absolute  text-[#AAA6C3] text-opacity-70 checked-1 transition "
          />
        </div>
      </div>
      {isShopImported === true ? (
        <div className="p-2 md:p-5 ml-3 rounded-3xl mb-3 md:mt-8 mt-20 flex justify-center">
          <div className="flex justify-center flex-col w-[90%]">
            <Header category="Page" title="Create Category" />
            <form
              action=""
              className="flex flex-col gap-8"
              onSubmit={(e) => handelSubmit(e)}
            >
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="name"
                  className="text-center text-[#181143] sm:text-2xl"
                >
                  Name
                </label>
                <input
                  id="name"
                  onChange={(e) =>
                    handelFormDataChange(e.target.value, 'name', 0)
                  }
                  type="text"
                  placeholder="Name"
                  className="rounded-3xl block h-16 border-none hover:outline-none focus:outline-none p-4  w-full"
                />
                <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
                <p className="text-gray-400	mt-4">
                  {'Invalid characters: <>;=#{}'}
                </p>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="active"
                  className="flex text-center text-[#181143] sm:text-2xl gap-16"
                >
                  Displayed
                  <Toggle
                    className="inline"
                    onClick={() =>
                      handelFormDataChange(!formData.active, 'active', 1)
                    }
                    toggle={formData.active}
                  />
                </label>
                <div style={{ color: '#FF9494' }}>{formDataError[1]}</div>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="active"
                  className="flex text-center text-[#181143] sm:text-2xl gap-16"
                >
                  Parent category
                </label>
                {generateCheckboxes(categories.categoryHierarchy)}
                <div style={{ color: '#FF9494' }}>{formDataError[2]}</div>
              </div>

              <div className="mt-3 w-3/4">
                <label
                  htmlFor="description"
                  className="text-center  text-[#181143] sm:text-2xl "
                >
                  Description
                </label>
                <ReactQuill
                  ref={quillRef}
                  placeholder={
                    'The summary is a sentence that briefly describes your product.It appears at the top of your store\'s product page, in product listings, and in search engine results (hence its importance for SEO). To provide more information about your product, use the "Description" tab.'
                  }
                  onChange={(e) => handelFormDataChange(e, 'description', 3)}
                  className="bg-white rounded-2xl px-4 pb-2"
                />
                <div style={{ color: '#FF9494' }}>{formDataError[3]}</div>
                <p className="text-gray-400	mt-4">
                  {'Invalid characters: <>;=#{}'}
                </p>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="cover"
                  className="flex text-center text-[#181143] sm:text-2xl gap-16"
                >
                  Category cover image
                </label>
                <div className="relative bg-white rounded-2xl w-full h-16">
                  <div className="absolute left-0 items-center top-0 bottom-0 m-auto">
                    <BsFiles className="absolute text-gray-400 text-xl w-16 top-0 bottom-0 my-auto border-grey-900 border-r-2" />
                  </div>
                  <input
                    type="file"
                    name="cover"
                    id="cover"
                    onChange={(e) =>
                      handelFormDataChange(e.target.value, 'cover', 4)
                    }
                    className="z-50	absolute right-0 left-0 top-0 bottom-0 m-auto cursor-pointer opacity-0 w-full h-16"
                  />
                  <div className="absolute right-0 items-center top-0 bottom-0 m-auto w-30 flex items-center pr-2">
                    <BsArchive className=" text-gray-400 text-xl top-0 bottom-0 w-16 my-auto border-grey-900 border-l-2" />
                    <span className="text-xl text-shifti-blue"> Browse</span>
                  </div>
                </div>
                <div style={{ color: '#FF9494' }}>{formDataError[4]}</div>
                <p className="text-gray-400	mt-4">
                  This is the main image for your category, displayed in the
                  category page. The category description will overlap this
                  image and appear in its top-left corner.
                </p>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="thumbnail"
                  className="flex text-center text-[#181143] sm:text-2xl gap-16"
                >
                  Category thumbnail
                </label>
                <div className="relative bg-white rounded-2xl w-full h-16">
                  <div className="absolute left-0 items-center top-0 bottom-0 m-auto">
                    <BsFiles className="absolute text-gray-400 text-xl w-16 top-0 bottom-0 my-auto border-grey-900 border-r-2" />
                  </div>
                  <input
                    type="file"
                    name="thumbnail"
                    id="thumbnail"
                    onChange={(e) =>
                      handelFormDataChange(e.target.value, 'thumbnail', 5)
                    }
                    className="z-50	absolute right-0 left-0 top-0 bottom-0 m-auto cursor-pointer opacity-0 w-full h-16"
                  />
                  <div className="absolute right-0 items-center top-0 bottom-0 m-auto w-30 flex items-center pr-2">
                    <BsArchive className=" text-gray-400 text-xl top-0 bottom-0 w-16 my-auto border-grey-900 border-l-2" />
                    <span className="text-xl text-shifti-blue"> Browse</span>
                  </div>
                </div>
                <div style={{ color: '#FF9494' }}>{formDataError[5]}</div>
                <p className="text-gray-400	mt-4">
                  Displays a small image in the parent category's page, if the
                  theme allows it.
                </p>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="menuThumbnail"
                  className="flex text-center text-[#181143] sm:text-2xl gap-16"
                >
                  Menu thumbnails
                </label>
                <div className="relative bg-white rounded-2xl w-full h-16">
                  <div className="absolute left-0 items-center top-0 bottom-0 m-auto">
                    <BsFiles className="absolute text-gray-400 text-xl w-16 top-0 bottom-0 my-auto border-grey-900 border-r-2" />
                  </div>
                  <input
                    type="file"
                    name="menuThumbnail"
                    id="menuThumbnail"
                    onChange={(e) =>
                      handelFormDataChange(e.target.value, 'menuThumbnail', 6)
                    }
                    className="z-50	absolute right-0 left-0 top-0 bottom-0 m-auto cursor-pointer opacity-0 w-full h-16"
                  />
                  <div className="absolute right-0 items-center top-0 bottom-0 m-auto w-30 flex items-center pr-2">
                    <BsArchive className=" text-gray-400 text-xl top-0 bottom-0 w-16 my-auto border-grey-900 border-l-2" />
                    <span className="text-xl text-shifti-blue"> Browse</span>
                  </div>
                </div>
                <div style={{ color: '#FF9494' }}>{formDataError[6]}</div>
                <p className="text-gray-400	mt-4">
                  The category thumbnail appears in the menu as a small image
                  representing the category, if the theme allows it.
                </p>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="seoPreview"
                  className="flex text-center text-[#181143] sm:text-2xl gap-16"
                >
                  SEO preview
                </label>
                <div
                  className="rounded-2xl w-full p-7"
                  style={{
                    boxShadow:
                      '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                  }}
                >
                  <p className="text-[#1A0DAB] text-lg cursor-pointer">
                    {formData.metaTitle}
                  </p>
                  <p className="text-[#006621] text-sm">{`${url}-${formData.friendlyUrl}`}</p>
                  <p className="text-[#545454] text-sm">
                    {formData.metaDescription}
                  </p>
                </div>
                <p className="text-gray-400	mt-4">
                  Here is a preview of how your page will appear in search
                  engine results.
                </p>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="metaTitle"
                  className="text-center text-[#181143] sm:text-2xl"
                >
                  Meta title
                </label>
                <input
                  id="metaTitle"
                  onChange={(e) =>
                    handelFormDataChange(e.target.value, 'metaTitle', 7)
                  }
                  type="text"
                  maxLength="70"
                  placeholder="to have a different title from the category name, enter it here."
                  className="rounded-3xl block h-16 border-none hover:outline-none focus:outline-none p-4  w-full"
                />
                <div style={{ color: '#FF9494' }}>{formDataError[7]}</div>
                <p className="text-gray-400	mt-4 float-right">
                  {formData.metaTitle.length} of 70 characters used
                  (recommended)
                </p>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="metaTitle"
                  className="text-center text-[#181143] sm:text-2xl"
                >
                  Meta description
                </label>
                <textarea
                  id="metaDescription"
                  rows="3"
                  onChange={(e) =>
                    handelFormDataChange(e.target.value, 'metaDescription', 8)
                  }
                  maxLength="160"
                  type="text"
                  placeholder="to have a different description than your category summmary in search results page, write it here."
                  className="rounded-3xl block border-none hover:outline-none focus:outline-none p-4  w-full"
                />
                <div style={{ color: '#FF9494' }}>{formDataError[8]}</div>
                <p className="text-gray-400	mt-4 float-right">
                  {formData.metaDescription.length} of 160 characters used
                  (recommended)
                </p>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="metaKeyWords"
                  className="text-center text-[#181143] sm:text-2xl"
                >
                  Meta keywords
                </label>
                <Creatable
                  isMulti
                  value={formData.metaKeyWords}
                  id="metaKeyWords"
                  onCreateOption={(e) =>
                    handelFormDataChange(e, 'metaKeyWords', 9)
                  }
                  onChange={(e) => handelFormDataChange(e, 'metaKeyWords', 9)}
                  placeholder="add tag"
                  styles={customStyles}
                />
                <div style={{ color: '#FF9494' }}>{formDataError[9]}</div>
                <p className="text-gray-400	mt-4">
                  {
                    'To add tags, click in the field, write something, and then press the "Enter" key. Invalid characters: <>;=#{}'
                  }
                </p>
              </div>
              <div className="mt-3 w-3/4">
                <label
                  htmlFor="friendlyUrl"
                  className="text-center text-[#181143] sm:text-2xl"
                >
                  Friendly url
                </label>
                <input
                  id="friendlyUrl"
                  onChange={(e) =>
                    handelFormDataChange(e.target.value, 'friendlyUrl', 10)
                  }
                  type="text"
                  placeholder="Friendly URL"
                  className="rounded-3xl block h-16 border-none hover:outline-none focus:outline-none p-4  w-full"
                />
                <div style={{ color: '#FF9494' }}>{formDataError[10]}</div>
                <p className="text-gray-400	mt-4">
                  Only letters, numbers, underscore (_) and the minus (-)
                  character are allowed.
                </p>
              </div>
              <div className="mt-10">
                <button
                  type="submit"
                  className="bg-shifti-pink-bg rounded-2xl text-white h-9 w-40 float-right"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <ShopNotImported />
      )}
    </>
  );
};

export default CreateCategory;
