import React, { useContext, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import { productContext } from '../pages/products/CreateProduct';
import DropDown from './DropDown';

const RichTextEditor = ({ className = '', name, label, placeholder = '' }) => {
  const [
    shops,
    setShops,
    allShopTypes,
    allShopIds,
    selectedShop,
    setSelectedShop,
    selectedShops,
    setSelectedShops,
    langs,
    setLangs,
    selectedLangs,
    setSelectedLangs,
    formData,
    setFormData,
  ] = useContext(productContext);
  const [currentShopId, setCurrentShopId] = useState(
    selectedShop || selectedShops[0].id
  );
  const [langsByShop, setLangsByShop] = useState(
    shops.find((shop) => shop.id === currentShopId).Langs
  );
  const [currentLang, setCurrentLang] = useState(langsByShop[0].iso_code);

  const handelTextChange = async (e) => {
    const temp = { ...formData };
    temp.sites[currentShopId].languages[currentLang][name] = e.target.innerHTML;
    setFormData(temp);
  };

  const handleLangs = (shopId) => {
    setCurrentShopId(shopId);
    const temp = shops.find((shop) => shop.id === shopId).Langs;
    setLangsByShop(temp);
    setCurrentLang(temp[0].iso_code);
  };

  useEffect(() => {
    if (selectedShop && currentShopId !== selectedShop) {
      setCurrentShopId(selectedShop);
      handleLangs(selectedShop);
    }
  });

  return (
    <div className={className}>
      <div className="flex justify-between">
        <label className="text-shifti-blue text-lg font-medium mr-2 mt-2 mb-2 block">
          {label}
        </label>
      </div>
      {selectedShop ? null : (
        <div className="bg-white h-12 rounded-t-xl divide-x -mb-4 inline-block px-4 pt-1 font-medium text-shifti-blue">
          {selectedShops.map((shop) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              className={`px-4 inline-block cursor-pointer divide-x ${
                currentShopId === shop.id
                  ? 'text-shifti-pink font-semibold'
                  : ''
              }`}
              onClick={() => handleLangs(shop.id)}
              onKeyDown={() => handleLangs(shop.id)}
            >
              {shop.name}
            </div>
          ))}
        </div>
      )}
      <DropDown
        className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl pt-2 px-2 w-14 float-right -mt-6"
        id={name}
        currentMode="white"
        data={langsByShop}
        defaultValue={currentLang}
        value="iso_code"
        text="iso_code"
        onChange={(e) => setCurrentLang(e)}
      />
      <div className="pl-4 p-2 md:p-8 pt-2 rounded-3xl flex flex-col bg-white w-full">
        <ReactQuill
          theme="snow"
          name={name}
          placeholder={placeholder}
          value={
            formData.sites[currentShopId] &&
            formData.sites[currentShopId].languages[currentLang]
              ? formData.sites[currentShopId].languages[currentLang][name]
              : ''
          }
          onKeyDown={(e) => handelTextChange(e)}
        />
      </div>
    </div>
  );
};

RichTextEditor.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default RichTextEditor;
