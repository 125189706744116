import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

function CarrierSettings({
  setErrors,
  setFormData,
  formData,
  setValidatedTabs,
  currentTab,
  validatedTabs,
  setCurrentTab,
}) {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (Object.entries(errors).length === 0) {
      setErrors(0);
    } else {
      setErrors(1);
    }
  }, [errors]);

  const handleOnChangeName = (e) => {
    const numberRegex = /^[a-zA-Z0-9]{3,64}$/;
    const { value } = e.target;
    const isValid = numberRegex.test(value);
    if (isValid) {
      clearErrors('name', null);
    } else {
      setError('name', {
        type: 'manual',
        message: 'Please enter a valid name',
      });
    }
    setFormData({
      ...formData,
      name: value,
    });
  };
  const handleOnChangeGrade = (e) => {
    const numberRegex = /^[0-9]?$/;
    const { value } = e.target;
    const isValid = numberRegex.test(value);
    if (isValid) {
      clearErrors('grade', null);
    } else {
      setError('grade', {
        type: 'manual',
        message: 'Please enter a valid grade',
      });
    }
    setFormData({
      ...formData,
      grade: value,
    });
  };
  const handleOnChangeDelay = (e) => {
    const numberRegex = /^[a-zA-Z0-9]{3,512}$/;
    const { value } = e.target;
    const isValid = numberRegex.test(value);
    if (isValid) {
      clearErrors('delay', null);
    } else {
      setError('delay', {
        type: 'manual',
        message: 'Please enter a valid delay value',
      });
    }

    setFormData({
      ...formData,
      delay: value,
    });
  };
  const handleOnChangeUrl = (e) => {
    const numberRegex =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$|^[0-9]?$/;
    const { value } = e.target;
    const isValid = numberRegex.test(value);
    if (isValid) {
      clearErrors('url', null);
    } else {
      setError('url', {
        type: 'manual',
        message: 'Please enter a valid url ',
      });
    }
    setFormData({
      ...formData,
      url: value,
    });
  };

  const onSubmit = (data) => {
    const currentTabId = parseInt(currentTab);
    setValidatedTabs([...validatedTabs, currentTabId]);

    if (Object.entries(errors).length === 0) {
      setCurrentTab('2');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-5">
        {' '}
        <div className="input-box flex flex-col space-y-3 w-[90%]">
          <div className="label-container">
            <label htmlFor="name" className="form-label">
              Carrier name <p className="text-[#EC1577] ml-2">*</p>
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="text"
                id="name"
                className="form-Input-Shifti-white"
                placeholder="carrier name"
                {...register('name')}
                onChange={handleOnChangeName}
                value={formData.name}
              />
              {errors.name && (
                <div className="error-box relative">
                  <div className="error-message-box">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.name?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="input-box flex flex-col space-y-3 w-[90%]">
          <div className="label-container">
            <label htmlFor="transit-time" className="form-label">
              Transit time <p className="text-[#EC1577] ml-2">*</p>
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="text"
                id="transit-time"
                className="form-Input-Shifti-white"
                placeholder="Transit time"
                {...register('delay', {
                  pattern: {
                    value: /^[A-Za-z0-9\- ]+$/,
                    message: 'Please enter a valid Transit time',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },

                  maxLength: {
                    value: 512,
                    message: 'max characters are 512 !',
                  },
                })}
                onChange={handleOnChangeDelay}
                value={formData.delay}
              />
              {errors.delay && (
                <div className="error-box relative">
                  <div className="error-message-box">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.delay?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
          <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
            The delivery time will be displayed during checkout
          </p>
        </div>
        <div className="input-box flex flex-col space-y-3 w-[90%]">
          <div className="label-container">
            <label htmlFor="speed-grade" className="form-label">
              Speed grade
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="text"
                id="speed-grade"
                className="form-Input-Shifti-white"
                placeholder="speed grade"
                {...register('grade', {
                  pattern: {
                    value: /^[1-9]$/,
                    message: 'Please enter a valid speed grade',
                  },
                  maxLength: {
                    value: 1,
                    message: 'max characters are 1 !',
                  },
                })}
                onChange={handleOnChangeGrade}
                value={formData.grade}
              />
              {errors.grade && (
                <div className="error-box relative">
                  <div className="error-message-box">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.grade?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
          <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
            Enter "0" for longest shipping delay , or "9" for shortest shipping
            delay
          </p>
        </div>
        <div className="input-box flex flex-col space-y-3 w-[90%]">
          <div className="label-container">
            <label htmlFor="tracking-url" className="form-label">
              Tracking url
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="url"
                id="tracking-url"
                className="form-Input-Shifti-white"
                placeholder="tracking url"
                {...register('url', {
                  pattern: {
                    value:
                      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
                    message: 'Please enter a valid url',
                  },
                })}
                onChange={handleOnChangeUrl}
                value={formData.url}
              />
              {errors.url && (
                <div className="error-box relative">
                  <div className="error-message-box">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.url?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
          <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
            For example: 'http://example.com/track.php?num=@' with '@' where the
            tracking number should appear
          </p>
        </div>
        <div className="container-save-submit flex justify-end md:mr-10 mt-5">
          <button
            type="submit"
            className="pl-[0.7rem] bg-[#F99E1F] rounded-3xl w-[10rem] text-white h-[37px]"
            disabled={Object.entries(errors).length === 0 ? 0 : 1}
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
}

CarrierSettings.propTypes = {
  setErrors: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setValidatedTabs: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
  validatedTabs: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
};
export default CarrierSettings;
