import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';
import { Header } from '../../components';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

function EditAddress() {
  const token = useSelector(selectCurrentToken);
  const [firstCountryId, setFirstCountryId] = useState(null); // Initialize state variable to null
  const [countries, setCountries] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [address, setAddress] = useState([]);
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const [phonePrefix, setPhonePrefix] = useState('93');
  const { id } = useParams();

  const getAddress = async (addressId) => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/addresses/${addressId}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getCountries = async (shopId) => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/countries/shop/${shopId}`, // STATIC waiting for navbar
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setCountries(response.data);
        return response.data;
      })
      .catch((error) => JSON.stringify(error));
  };

  const getCustomers = async (shopId) => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/customers/shop/${shopId}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };
  useEffect(() => {
    const prepareData = async () => {
      await getAddress(id)
        .then(async (addressResponse) => {
          await getCountries(addressResponse.Shop.id)
            .then(async () => {
              if (countries === []) {
                if (countries.length > 0) {
                  setFirstCountryId(countries[0].id);
                }
              }
              await setAddress(addressResponse);
              await getCustomers(addressResponse.Shop.id);
              if (addressResponse.Shop.type === 'prestashop')
                if (addressResponse.Country.States) {
                  const stateNames = addressResponse.Country.States.map(
                    (state) => ({
                      id: state.id,
                      name: state.name,
                    })
                  );
                  setStates(stateNames);
                } else {
                  setStates([]);
                  setValue('state', '');
                }

              setLoading(false);
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    };
    prepareData();
  }, [id]);

  const handleStates = (event) => {
    countries.forEach((country) => {
      if (JSON.stringify(country.id) === event.target.value) {
        setPhonePrefix(country.call_prefix);
        if (country.States.length > 0) {
          const stateNames = country.States.map((state) => ({
            id: state.id,
            name: state.name,
          }));
          setStates(stateNames);
        } else {
          setStates([]);
          setValue('state', '');
        }
      }
    });
  };

  const showCountries = () => {
    if (address.Shop.type === 'prestashop') {
      return (
        <div className="input-box flex flex-col space-y-3 w-[60%]">
          <div className="label-container">
            <label htmlFor="Country" className="form-label">
              Country <p className="text-[#EC1577] ml-2">*</p>
            </label>
          </div>
          <div className="input-container">
            <select
              id="Country"
              className="essentialSelect"
              {...register('country', {
                required: {
                  value: true,
                  message: 'This field is required !',
                },
              })}
              defaultValue={address.Country.id}
              onChange={handleStates}
            >
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.CountryLangs[0].name}
                </option>
              ))}
            </select>
          </div>
          {errors.country && (
            <span
              className="text-[#EC1577]"
              ref={(el) =>
                el && el.scrollIntoView({ behavior: 'smooth', block: 'center' })
              }
            >
              {errors.country?.message}
            </span>
          )}
        </div>
      );
    }
    if (address.Shop.type === 'woocommerce') {
      return (
        <div className="input-box flex flex-col space-y-3 w-[69%]">
          <div className="label-container">
            <label htmlFor="country" className="form-label">
              Country
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="text"
                id="country"
                className="form-Input-Shifti-white"
                {...register('woo_country')}
                defaultValue={address.woo_country}
              />
              {errors.woo_country && (
                <div className="error-box relative">
                  <div className="error-message-box  ">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.woo_country?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  const showStates = () => {
    if (address.Shop.type === 'prestashop') {
      if (address.Country.States.length > 0 && states.length > 0) {
        return (
          <div className="input-box flex flex-col space-y-3 w-[60%]">
            <div className="label-container">
              <label htmlFor="State" className="form-label">
                State <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container">
              <select
                id="State"
                className="essentialSelect"
                {...register('state', {
                  required: {
                    value: true,
                    message: 'this field is required',
                  },
                })}
                defaultValue={address.State.id}
              >
                <option value={address.State.id}>{address.State.name}</option>
                {address.Country.States.filter(
                  (item) => item.name !== address.State.name
                ).map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            {errors.state && (
              <span className="text-[#EC1577]">{errors.state?.message}</span>
            )}
          </div>
        );
      }
      if (states.length > 0) {
        return (
          <div className="input-box flex flex-col space-y-3 w-[60%]">
            <div className="label-container">
              <label htmlFor="State" className="form-label">
                State <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container">
              <select
                id="State"
                className="essentialSelect"
                {...register('state', {
                  required: {
                    value: true,
                    message: 'this field is required',
                  },
                })}
              >
                {' '}
                <option value="">select a state</option>
                {states.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            {errors.state && (
              <span className="text-[#EC1577]">{errors.state?.message}</span>
            )}
          </div>
        );
      }
    } else if (address.Shop.type === 'woocommerce') {
      return (
        <div className="input-box flex flex-col space-y-3 w-[69%]">
          <div className="label-container">
            <label htmlFor="id-number" className="form-label">
              State
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="text"
                id="id-number"
                className="form-Input-Shifti-white"
                {...register('woo_state')}
                defaultValue={address.woo_state}
              />
              {errors.woo_state && (
                <div className="error-box relative">
                  <div className="error-message-box  ">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.woo_state?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  const onSubmit = (data) => {
    const decoded = jwtDecode(token);
    let customer;
    customer = customers.find((item) => item.email === data.customer_email);

    if (address.Shop.type === 'woocommerce') {
      customer = address.Customer;
    }

    if (!customer) {
      // Email not found, show error message
      setError('customer_email', {
        type: 'manual',
        message: 'This email does not exist!',
      });
      return;
    }
    if (customer) {
      if (address.Shop.type === 'prestashop') {
        const addressData = JSON.stringify({
          shops: [address.Shop.id],
          customer_id: customer.id,
          manufacturer_id: 1, // static
          supplier_id: 0, // static
          warehouse_id: 0, // static
          country_id: parseInt(data.country),
          state_id: data.state !== '' ? parseInt(data.state) : null,
          alias: data.alias,
          company: data.company,
          last_name: data.last_name,
          first_name: data.first_name,
          vat_number: data.vat_number,
          address1: data.address1,
          address2: data.address2,
          postcode: data.postcode,
          city: data.city,
          other: data.other,
          phone: data.phone,
          phone_mobile: data.phone_mobile,
          dni: data.dni,
          woo_country: null,
          woo_state: null,
        });

        const idToast = toast.loading('Please wait...');
        const config = {
          method: 'put',
          url: `${apiURL}/addresses/${address.id}`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: addressData,
        };
        api
          .request(config)
          .then((response) => {
            toast.update(idToast, {
              render: 'Address Is Updated !',
              type: response.data === 'success',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              isLoading: false,
            });
            setTimeout(() => {
              window.location.assign('/addresses');
            }, 1500);
          })
          .catch((error) => {});
      }
    }
    if (address.Shop.type === 'woocommerce') {
      const addressData = JSON.stringify({
        shops: [address.Shop.id], // static
        customer_id: customer.id,
        manufacturer_id: 1, // static
        supplier_id: 0, // static
        warehouse_id: 0, // static
        company: data.company,
        last_name: data.last_name,
        first_name: data.first_name,
        address1: data.address1,
        address2: data.address2,
        postcode: data.postcode,
        city: data.city,
        phone: data.phone,
        dni: data.dni,
        woo_billing: address.woo_billing,
        woo_shipping: address.woo_shipping,
        woo_country: data.woo_country,
        woo_state: data.woo_state,
      });
      const idToast = toast.loading('Please wait...');
      const config = {
        method: 'put',
        url: `${apiURL}/addresses/${address.id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: addressData,
      };
      api
        .request(config)
        .then((response) => {
          toast.update(idToast, {
            render: 'Address Is Updated !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.assign('/addresses');
          }, 1500);
        })
        .catch((error) => {});
    }

    // Handle form submission here
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="md:mt-2 mt-24 md:ml-16 p-2 md:p-5 rounded-3xl flex flex-col">
      <Header category="Page" title="Edit Address" />
      {address.Shop.type === 'woocommerce' && (
        <div className="text-[24px] text-[#EC1577] font-semibold mb-5">
          This is a {address.woo_billing === true ? 'Billing' : 'Shipping'}{' '}
          address for user {address.Customer.first_name}{' '}
          {address.Customer.last_name}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-container flex flex-col space-y-5">
          {address.Shop.type === 'prestashop' && (
            <div className="input-box flex flex-col space-y-3 w-[69%]">
              <div className="label-container">
                <label htmlFor="Customer-email" className="form-label">
                  Customer email <p className="text-[#EC1577] ml-2">*</p>
                </label>
              </div>
              <div className="input-container">
                <div className="text_input_box">
                  <input
                    type="email"
                    id="Customer-email"
                    className="form-Input-Shifti-white"
                    {...register('customer_email', {
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: 'Please enter a valid email address',
                      },
                      required: {
                        value: true,
                        message: 'This field is required !',
                      },
                      minLength: {
                        value: 3,
                        message: 'minimum characters are 3 !',
                      },
                      maxLength: {
                        value: 254,
                        message: 'max characters are 255 !',
                      },
                    })}
                    defaultValue={address.Customer.email}
                  />
                  {errors.customer_email && (
                    <div className="error-box relative">
                      <div className="error-message-box">
                        <p
                          className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                          ref={(el) =>
                            el &&
                            el.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            })
                          }
                        >
                          {errors.customer_email?.message}
                        </p>
                        <div className="cone" />
                      </div>
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {address.Shop.type === 'prestashop' && (
            <div className="input-box flex flex-col space-y-3 w-[69%]">
              <div className="label-container">
                <label htmlFor="id-number" className="form-label">
                  Identification number
                </label>
              </div>
              <div className="input-container">
                <div className="text_input_box">
                  <input
                    type="text"
                    id="id-number"
                    className="form-Input-Shifti-white"
                    {...register('dni', {
                      pattern: {
                        value: /^[a-zA-Z0-9]+$/,
                        message: 'Please enter a valid identification number',
                      },
                      maxLength: {
                        value: 16,
                        message: 'max characters are 16 !',
                      },
                    })}
                    defaultValue={address.dni}
                  />
                  {errors.dni && (
                    <div className="error-box relative">
                      <div className="error-message-box  ">
                        <p
                          className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                          ref={(el) =>
                            el &&
                            el.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            })
                          }
                        >
                          {errors.dni?.message}
                        </p>
                        <div className="cone" />
                      </div>
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                      />
                    </div>
                  )}
                </div>
              </div>

              <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
                The national ID card number of this person, or a unique tax
                identification number.
              </p>
            </div>
          )}
          {address.Shop.type === 'prestashop' && (
            <div className="input-box flex flex-col space-y-3 w-[69%]">
              <div className="label-container">
                <label htmlFor="alias" className="form-label">
                  Address alias <p className="text-[#EC1577] ml-2">*</p>
                </label>
              </div>
              <div className="input-container">
                <div className="text_input_box">
                  <input
                    type="text"
                    id="alias"
                    className="form-Input-Shifti-white"
                    {...register('alias', {
                      pattern: {
                        value: /^[^<>;=#{}]*$/,
                        message: 'Please avoid these characters <>;=#{}',
                      },
                      required: {
                        value: true,
                        message: 'This field is required !',
                      },
                      minLength: {
                        value: 3,
                        message: 'minimum characters are 3 !',
                      },
                      maxLength: {
                        value: 32,
                        message: 'max characters are 32 !',
                      },
                    })}
                    defaultValue={address.alias}
                  />
                  {errors.alias && (
                    <div className="error-box relative">
                      <div className="error-message-box">
                        <p
                          className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                          ref={(el) =>
                            el &&
                            el.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            })
                          }
                        >
                          {errors.alias?.message}
                        </p>
                        <div className="cone" />
                      </div>
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                      />
                    </div>
                  )}
                </div>
              </div>

              <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
                {'Invalid characters: <>;=#{}'}
              </p>
            </div>
          )}

          <div className="input-box flex flex-col space-y-3 w-[69%]">
            <div className="label-container">
              <label htmlFor="First-name" className="form-label">
                First name <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container">
              <div className="text_input_box">
                {' '}
                <input
                  type="text"
                  id="First-name"
                  className="form-Input-Shifti-white"
                  {...register('first_name', {
                    pattern: {
                      value: /^[^0-9!<>,;?=+()@#"�{}_ $%:]*$/,
                      message:
                        'Please avoid these characters ^0-9!<>,;?=+()@#"�{}_ $%:',
                    },
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                    minLength: {
                      value: 3,
                      message: 'minimum characters are 3 !',
                    },
                    maxLength: {
                      value: 255,
                      message: 'maximum characters are 255 !',
                    },
                  })}
                  defaultValue={address.first_name}
                />
                {errors.first_name && (
                  <div className="error-box relative">
                    <div className="error-message-box">
                      <p
                        className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                        ref={(el) =>
                          el &&
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                      >
                        {errors.first_name?.message}
                      </p>
                      <div className="cone" />
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                    />
                  </div>
                )}
              </div>
            </div>

            <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
              {'Invalid characters: 0-9!<>,;?=+()@#"�{}_ $%:'}
            </p>
          </div>
          <div className="input-box flex flex-col space-y-3 w-[69%]">
            <div className="label-container">
              <label htmlFor="Last-name" className="form-label">
                Last name <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container">
              <div className="text_input_box">
                <input
                  type="text"
                  id="Last-name"
                  className="form-Input-Shifti-white"
                  {...register('last_name', {
                    pattern: {
                      value: /^[^0-9!<>,;?=+()@#"�{}_ $%:]*$/,
                      message:
                        'Please avoid these characters ^0-9!<>,;?=+()@#"�{}_ $%:',
                    },
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                    minLength: {
                      value: 3,
                      message: 'minimum characters are 3 !',
                    },
                    maxLength: {
                      value: 255,
                      message: 'maximum characters are 255 !',
                    },
                  })}
                  defaultValue={address.last_name}
                />
                {errors.last_name && (
                  <div className="error-box relative">
                    <div className="error-message-box">
                      <p
                        className="text-white h-full  self-center text-center text-[16px]"
                        ref={(el) =>
                          el &&
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                      >
                        {errors.last_name?.message}
                      </p>
                      <div className="cone" />
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                    />
                  </div>
                )}
              </div>
            </div>

            <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[69%]">
              {'Invalid characters: 0-9!<>,;?=+()@#"�{}_ $%:'}
            </p>
          </div>
          <div className="input-box flex flex-col space-y-3 w-[69%]">
            <div className="label-container">
              <label htmlFor="Company" className="form-label">
                Company
              </label>
            </div>
            <div className="input-container">
              <div className="text_input_box">
                <input
                  type="text"
                  id="Company"
                  className="form-Input-Shifti-white"
                  {...register('company', {
                    pattern: {
                      value: /^[^<>;=#{}]*$/,
                      message: 'Please avoid these characters <>;=#{}',
                    },
                    maxLength: {
                      value: 255,
                      message: 'maximum characters are 255 !',
                    },
                  })}
                  defaultValue={address.company}
                />
                {errors.company && (
                  <div className="error-box relative">
                    <div className="error-message-box">
                      <p
                        className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                        ref={(el) =>
                          el &&
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                      >
                        {errors.company?.message}
                      </p>
                      <div className="cone" />
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                    />
                  </div>
                )}
              </div>
            </div>

            <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
              {'Invalid characters: <>;=#{}'}
            </p>
          </div>
          {address.Shop.type === 'prestashop' && (
            <div className="input-box flex flex-col space-y-3 w-[69%]">
              <div className="label-container">
                <label htmlFor="VAT-number" className="form-label">
                  VAT number
                </label>
              </div>
              <div className="input-container">
                <div className="text_input_box">
                  <input
                    type="text"
                    id="VAT-number"
                    className="form-Input-Shifti-white"
                    {...register('vat_number', {
                      maxLength: {
                        value: 255,
                        message: 'maximum characters are 255 !',
                      },
                    })}
                    defaultValue={address.vat_number}
                  />
                  {errors.vat_number && (
                    <div className="error-box relative">
                      <div className="error-message-box">
                        <p
                          className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                          ref={(el) =>
                            el &&
                            el.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            })
                          }
                        >
                          {errors.vat_number?.message}
                        </p>
                        <div className="cone" />
                      </div>
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="input-box flex flex-col space-y-3 w-[69%]">
            <div className="label-container">
              <label htmlFor=" Address" className="form-label">
                Address <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container">
              <div className="text_input_box">
                <input
                  type="text"
                  id=" Address"
                  className="priceInput"
                  {...register('address1', {
                    minLength: {
                      value: 3,
                      message: 'minimum characters are 3 !',
                    },
                    maxLength: {
                      value: 128,
                      message: 'maximum characters are 128 !',
                    },
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                  })}
                  defaultValue={address.address1}
                />
                {errors.address1 && (
                  <div className="error-box relative">
                    <div className="error-message-box">
                      <p
                        className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                        ref={(el) =>
                          el &&
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                      >
                        {errors.address1?.message}
                      </p>
                      <div className="cone" />
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input-box flex flex-col space-y-3 w-[69%]">
            <div className="label-container">
              <label htmlFor="Address2" className="form-label">
                Address (2)
              </label>
            </div>
            <div className="input-container">
              <div className="text_input_box">
                <input
                  type="text"
                  id="Address2"
                  className="form-Input-Shifti-white"
                  {...register('address2', {
                    maxLength: {
                      value: 128,
                      message: 'maximum characters are 128 !',
                    },
                  })}
                  defaultValue={address.address2}
                />
                {errors.address2 && (
                  <div className="error-box relative">
                    <div className="error-message-box">
                      <p
                        className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                        ref={(el) =>
                          el &&
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                      >
                        {errors.address2?.message}
                      </p>
                      <div className="cone" />
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input-box flex flex-col space-y-3 w-[69%]">
            <div className="label-container">
              <label htmlFor="postal-code" className="form-label">
                Zip/postal code <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container">
              <div className="text_input_box">
                <input
                  type="text"
                  id="postal-code"
                  className="form-Input-Shifti-white"
                  {...register('postcode', {
                    pattern: {
                      value: /^[0-9]/,
                      message: 'Please enter a valid postal code',
                    },
                    minLength: {
                      value: 3,
                      message: 'minimum characters are 3 !',
                    },
                    maxLength: {
                      value: 12,
                      message: 'maximum characters are 12 !',
                    },
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                  })}
                  defaultValue={address.postcode}
                />
                {errors.postcode && (
                  <div className="error-box relative">
                    <div className="error-message-box">
                      <p
                        className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                        ref={(el) =>
                          el &&
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                      >
                        {errors.postcode?.message}
                      </p>
                      <div className="cone" />
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="input-box flex flex-col space-y-3 w-[69%]">
            <div className="label-container">
              <label htmlFor="City" className="form-label">
                City <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container">
              <div className="text_input_box">
                <input
                  type="text"
                  id="City"
                  className="form-Input-Shifti-white"
                  {...register('city', {
                    minLength: {
                      value: 3,
                      message: 'minimum characters are 3 !',
                    },
                    maxLength: {
                      value: 64,
                      message: 'maximum characters are 64 !',
                    },
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                  })}
                  defaultValue={address.city}
                />
                {errors.city && (
                  <div className="error-box relative">
                    <div className="error-message-box">
                      <p
                        className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                        ref={(el) =>
                          el &&
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                      >
                        {errors.city?.message}
                      </p>
                      <div className="cone" />
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {showCountries()}
          {showStates()}
          <div className="input-box flex flex-col space-y-3 w-[69%]">
            <div className="label-container">
              <label htmlFor="Phone" className="form-label">
                Phone
              </label>
            </div>
            <div className="input-container phone_box ">
              <div className=" phone_call_prefix_box">
                <p className="phone_call_prefix">+{phonePrefix}</p>
                <p className="text-[#E1DFEE] bg-[#E1DFEE] w-[3px] h-[2rem] font-light" />
              </div>
              <input
                id="Phone"
                type="phone"
                className="phone_input"
                {...register('phone', {
                  maxLength: {
                    value: 14,
                    message: 'maximum characters are 14 !',
                  },

                  pattern: {
                    value: /^\d+$/,
                    message: 'Please enter a valid phone number',
                  },
                })}
                defaultValue={address.phone}
              />
              {errors.phone && (
                <div className="error-box-phone relative">
                  <div className="error-message-box-phone">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.phone?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
          {address.Shop.type === 'prestashop' && (
            <div className="input-box flex flex-col space-y-3 w-[69%]">
              <div className="label-container">
                <label htmlFor="Mobile-phone" className="form-label">
                  Mobile phone
                </label>
              </div>
              <div className="input-container phone_box ">
                <div className=" phone_call_prefix_box">
                  <p className="phone_call_prefix">+{phonePrefix}</p>
                  <p className="text-[#E1DFEE] bg-[#E1DFEE] w-[3px] h-[2rem] font-light" />
                </div>
                <input
                  id="Mobile-phone"
                  type="phone"
                  className="phone_input"
                  {...register('phone_mobile', {
                    maxLength: {
                      value: 32,
                      message: 'maximum characters are 32 !',
                    },

                    pattern: {
                      value: /^\d+$/,
                      message: 'Please enter a valid phone number',
                    },
                  })}
                  defaultValue={address.phone_mobile}
                />
                {errors.phone_mobile && (
                  <div className="error-box-phone relative">
                    <div className="error-message-box">
                      <p
                        className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                        ref={(el) =>
                          el &&
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                      >
                        {errors.phone_mobile?.message}
                      </p>
                      <div className="cone" />
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {address.Shop.type === 'prestashop' && (
            <div className="input-box flex flex-col space-y-3 w-[69%]">
              <div className="label-container">
                <label htmlFor="other" className="form-label">
                  Other
                </label>
              </div>
              <div className="input-container space-y-2 ">
                <div className="input-box">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className="form-Textarea-shifti-white"
                    {...register('other', {
                      pattern: {
                        value: /^[^<>;=#{}]*$/,
                        message: 'Please avoid these characters <>;=#{}',
                      },
                      maxLength: {
                        value: 300,
                        message: 'maximum characters are 32 !',
                      },
                    })}
                    defaultValue={address.other}
                  />
                </div>
                {errors.other && (
                  <span
                    className="text-[#EC1577]"
                    ref={(el) =>
                      el &&
                      el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      })
                    }
                  >
                    {errors.other?.message}
                  </span>
                )}
                <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
                  {'Invalid characters: <>;=#{}'}
                </p>
              </div>
            </div>
          )}

          <div className="self-center">
            <button className="addButton w-[14rem]" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditAddress;
