export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const productsGrid = [
  {
    field: 'product_name',
    headerText: 'Name',
    width: '170',
    textAlign: 'Center',
  },
  {
    field: 'product_price',
    headerText: 'Product price',
    width: '170',
    textAlign: 'Center',
  },
  {
    field: 'total_quantity',
    headerText: 'Total quantity',
    width: '120',
    textAlign: 'Center',
  },
  {
    field: 'total_revenue',
    headerText: 'Total revenue',
    width: '120',
    textAlign: 'Center',
  },
  {
    field: 'total_sales',
    headerText: 'Total sales',
    width: '120',
    textAlign: 'Center',
  },
];
