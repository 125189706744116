import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../utils/apiService';
import { selectCurrentToken } from '../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

const DeleteCarrier = async (id) => {
  const idToast = toast.loading('Please wait...');
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const config = {
    method: 'delete',
    url: `${apiURL}/carriers/${id}`,
    headers: {
      'App-Tenant': decoded.UserInfo.tenantId,
      Authorization: `Bearer ${token}`,
    },
  };

  await api
    .request(config)
    .then((response) => {
      toast.update(idToast, {
        render: 'Deleted !',
        type: response.data === 'success',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        isLoading: false,
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1500);
    })
    .catch((error) => {});
};

export const Actions = ({ id }) => {
  const navigate = useNavigate();

  return (
    <div>
      {/* <button
        type="button"
        style={{ color: '#000' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => navigate(`/edit-carrier/${id}`)}
      >
        <FontAwesomeIcon icon={faPencil} className="w-4 h-4 text-[#AAA6C3]" />
      </button> */}

      <button
        type="button"
        style={{ color: '#000' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => DeleteCarrier(id)}
      >
        <FontAwesomeIcon icon={faTrash} className="w-4 h-4 text-[#AAA6C3]" />
      </button>
    </div>
  );
};

export const delay = (props) => <div>{props.CarrierLangs[0].delay}</div>;

export const shop = (props) => <div>{props.CarrierShops[0].Shop.name}</div>;

export const cartsGrid = [
  { field: 'id', headerText: 'Id', width: '60', textAlign: 'Center' },

  {
    headerText: 'Name',
    field: 'name',
    width: '100',
    textAlign: 'Center',
  },
  {
    headerText: 'Logo',
    field: 'name',
    width: '100',
    textAlign: 'Center',
  },
  {
    headerText: 'Delay',
    template: delay,
    width: '100',
    textAlign: 'Center',
  },
  {
    headerText: 'Status',
    field: 'active',
    width: '100',
    textAlign: 'Center',
  },
  {
    headerText: 'Free shipping',
    field: 'is_free',
    width: '100',
    textAlign: 'Center',
  },
  {
    headerText: 'Position',
    field: 'position',
    width: '100',
    textAlign: 'Center',
  },
  {
    headerText: 'Shop',
    template: shop,
    width: '100',
    textAlign: 'Center',
  },
  {
    headerText: 'Actions',
    template: Actions,
    width: '130',
    textAlign: 'Center',
  },
];
Actions.propTypes = {
  id: PropTypes.number.isRequired,
};
