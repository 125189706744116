/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../utils/apiService';
import { Modal } from '../components';
import { selectCurrentToken } from '../features/auth/authSlice';
import expiredIcon from '../img/expired.png';
import notFoundIcon from '../img/not-found.png';
import paymentIcon from '../img/cashless-payment.png';

const apiURL = process.env.REACT_APP_API_URL;

export const planContext = createContext();

export const PlanProvider = ({ children }) => {
  const [userPlan, setUserPlan] = useState({});
  const [planPrice, setPlanPrice] = useState({ price: 0, extraPrice: 0 });
  const [planStatus, setPlanStatus] = useState(200);
  const [error, setError] = useState({
    message: '',
    guide: '',
    icon: null,
    buttonText: '',
    type: null,
  });
  const [modal, setModal] = useState({
    visible: false,
    message: '',
    success: '',
    error: '',
    warning: '',
    info: '',
  });
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const navigate = useNavigate();

  // check plan status (active | expired | not paid)
  const checkPlanStatus = () =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'get',
        url: `${apiURL}/plans/check-plan-status/${decoded.UserInfo.id}`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  // get plan price and extra prices
  const getPlanPrice = () =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'get',
        url: `${apiURL}/plans/plan-price/${decoded.UserInfo.id}`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => {
          setPlanPrice({ ...response.data });
          resolve(response);
        })
        .catch((error) => {
          setPlanPrice({ ...{ price: 'N/A', extraPrice: 'N/A' } });
          reject(error);
        });
    });

  // handle error state when plan is expired
  const planExpired = async (expiredRes) => {
    const temp = { ...error };
    temp.buttonText = 'Renew';
    temp.icon = expiredIcon;
    temp.guide = 'Renew your plan by simply clicking the button below.';
    temp.type = 'expired';
    setUserPlan({ ...expiredRes });
    await getPlanPrice();
    setError(temp);
  };

  // handle error state when plan is not found
  const planNotFound = () => {
    const temp = { ...error };
    temp.buttonText = 'Add plan';
    temp.icon = notFoundIcon;
    temp.guide = 'Create your plan by simply clicking the button below.';
    temp.type = 'notFound';
    setError(temp);
  };

  // handle error state when plan is not paid
  const PlanNotPaid = () => {
    const temp = { ...error };
    temp.buttonText = 'Pay plan';
    temp.icon = paymentIcon;
    temp.guide = 'Pay your plan by simply clicking the button below.';
    temp.type = 'notPaid';
    setError(temp);
  };

  // perform actions on error
  const handleError = () => {
    if (error.type === 'expired') {
      const temp = { ...modal };
      temp.visible = true;
      setModal(temp);
    } else if (error.type === 'notFound') {
      navigate('/create-shop');
    } else if (error.type === 'notPaid') {
      navigate('/create-shop');
    }
  };

  const handleModalClose = () => {
    const temp = { ...modal };
    temp.visible = false;
    temp.message = '';
    temp.success = '';
    temp.error = '';
    temp.warning = '';
    temp.info = '';
    setModal(temp);
  };

  useEffect(() => {
    checkPlanStatus().catch(async (err) => {
      if (err.response.status < 500 && err.response.status > 399) {
        const errorRes = err.response.data;
        if (errorRes.message === 'Your active plan has expired') {
          await planExpired(errorRes);
        } else if (errorRes.message === 'No active plan is found') {
          planNotFound();
        } else if (errorRes.message === 'Plan is not paid') {
          PlanNotPaid();
        }
        setPlanStatus({ ...errorRes });
      } else if (err.response.status < 600 && err.response.status > 499) {
        return false;
      }
    });
  }, []);
  if (planStatus === 200) return children;

  return (
    <div className="relative">
      <div className="bg-white shadow-xl flex justify-center flex-col items-center w-1/2 m-auto mt-20 rounded-xl gap-2 p-4">
        <img src={error.icon} width="170" height="auto" alt="" />
        <span className="text-xl text-shifti-blue">{error.message}</span>
        <p className="text-gray-400">{error.guide}</p>
        <button
          type="button"
          onClick={() => handleError()}
          className="addButton w-[10rem] mt-4"
        >
          {error.buttonText}
        </button>
      </div>
      <div className="flex justify-center flex-col items-center w-3/2 m-auto mt-20 rounded-xl gap-2 p-4">
        {Object.keys(userPlan).length ? (
          <Modal
            showModal={modal.visible}
            handleModalClose={handleModalClose}
            title="Renew Plan"
            info=""
            warning="You are about to renew your plan"
            error={modal.error}
            success={modal.success}
          >
            <div className="bg-white">
              <p className="text-3xl font-semibold  text-[#EC1577] mb-2">
                Current plan data
              </p>

              <div className=" p-2 ">
                <div className="flex w-full justify-between mb-2">
                  <div className="flex flex-col space-y-3">
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p>purchase date : </p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan?.purchase_date
                          .replace('.000Z', '')
                          .replace('T', ' ')}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p>expiration date : </p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan?.expiration_date
                          .replace('.000Z', '')
                          .replace('T', ' ')}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p>payment method :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan?.payment_method}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p>total price httc :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan?.total_price_httc}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p>total price ttc :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan?.total_price_ttc}
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-col space-y-3">
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p> extra employees :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan?.extra_employees}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p> extra shops :</p>
                      <span className="text-sm text-[#FEC90F] ">
                        {userPlan?.extra_shops}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p>extra products :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan?.extra_products}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p>extra support time :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan?.extra_support_time}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p> extra storage :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan?.extra_storages}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex gap-2 justify-center">
                <button
                  onClick={() =>
                    window.confirm('Do you want to make this call?')
                      ? alert('Call to action')
                      : null
                  }
                  type="button"
                  className="addButton px-4 mt-4"
                >
                  <span>
                    Renew ({planPrice.price}
                    <span className="font-bold text-sm"> DT</span>)
                  </span>
                </button>
                <button
                  onClick={() =>
                    window.confirm('Do you want to make this call?')
                      ? alert('Call to action')
                      : null
                  }
                  type="button"
                  className="addButton px-4 mt-4"
                >
                  <span>
                    ({planPrice.price + planPrice.extraPrice}
                    <span className="font-bold text-sm"> DT</span>)
                  </span>
                </button>
                <button
                  onClick={() => handleModalClose()}
                  type="button"
                  className="addButton px-4 mt-4"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    </div>
  );
};

PlanProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
