import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Toggle } from '../../components';
import AssociatedStores from '../../components/AssociatedStores';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const Tax = () => {
  const token = useSelector(selectCurrentToken);
  const [stores, setStores] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    rate: '',
    active: false,
    shops: [],
    validation: {
      error: [true, true, false, true],
      errorMsg: ['required', 'required', '', 'required'],
    },
  });
  const [formDataError, setformDataError] = useState([true, true, false, true]);

  const getStores = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-group-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };

    await api
      .request(config)
      .then((response) => {
        setStores(response.data);
      })
      .catch((err) => {});
  };

  const handelFormDataChange = (value, key, index) => {
    const aux = { ...formData };
    if (key === 'name') {
      if (
        value.trim() === '' ||
        value.includes('{') ||
        value.includes('}') ||
        value.includes('<') ||
        value.includes('>') ||
        value.includes(';') ||
        value.includes('#') ||
        value.includes('=')
      ) {
        aux.validation.error[index] = true;
        if (value.trim() === '') aux.validation.errorMsg[index] = 'Required';
        else aux.validation.errorMsg[index] = 'Invalid name';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      aux[key] = value;
    }
    if (key === 'rate') {
      if (
        value.match('[0-9]+([.][0-9]*)?|[.][0-9]+') == null ||
        value.trim() === ''
      ) {
        aux.validation.error[index] = true;
        if (value.trim() === '') aux.validation.errorMsg[index] = 'Required';
        else aux.validation.errorMsg[index] = 'Invalid tax rate';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      aux[key] = value;
    }
    if (key === 'active') {
      setToggle((prevCheck) => !prevCheck);
      if (value) aux[key] = '1';
      else aux[key] = '0';
    }
    if (key === 'shops') {
      if (value.checked === false && aux.shops.length < 2) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      const itemIndex = aux[key].indexOf(value.value);
      if (value.checked === true && itemIndex === -1) {
        aux[key].push(value.value);
      } else if (itemIndex > -1) {
        aux[key].splice(itemIndex, 1);
      }
    }
    setFormData(aux);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    const decoded = jwtDecode(token);
    const ERROR = [...formData.validation.error];
    const ERROR_MSG = [...formData.validation.errorMsg];
    setformDataError(ERROR_MSG);
    if (ERROR.includes(true)) return;
    const idToast = toast.loading('Please wait...');
    const data = JSON.stringify({
      name: formData.name,
      rate: formData.rate,
      active: formData.active,
      shops: formData.shops,
    });
    const config = {
      method: 'post',
      url: `${apiURL}/taxes/add-tax`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    api
      .request(config)
      .then(() => {
        toast.update(idToast, {
          render: 'Tax created succesfully !',
          type: 'success',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        });
      })
      .catch(() =>
        toast.update(idToast, {
          render: 'Failed to create tax !',
          type: 'error',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        })
      );
  };

  useEffect(() => {
    getStores();
  }, []);

  return (
    <div className="flex flex-col gap-8 mt-8">
      <div className="w-3/4">
        <label htmlFor="Name" className="groupAttributeLabel">
          Name
        </label>
        <input
          id="Name"
          name="name"
          placeholder="Name"
          type="text"
          className="groupAttributeInput w-full  mt-3"
          onChange={(e) => handelFormDataChange(e.target.value, 'name', 0)}
        />
        <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
        <p className="text-gray-400	mt-4">
          {
            "Name of the tax to be displayed in the cart and on the invoice (e.g. 'VAT'). Prohibited characters: <>;=#{}"
          }
        </p>
      </div>
      <div className="w-3/4">
        <label htmlFor="Rate" className="groupAttributeLabel">
          Rate
        </label>
        <input
          id="Rate"
          name="rate"
          placeholder="Rate"
          type="text"
          className="groupAttributeInput w-full  mt-3"
          onChange={(e) => handelFormDataChange(e.target.value, 'rate', 1)}
        />
        <div style={{ color: '#FF9494' }}>{formDataError[1]}</div>
        <p className="text-gray-400	mt-4">
          Format: XX.XX or XX.XXX (e.g.: 19.60 or 13.925)
        </p>
      </div>
      <div className="w-3/4">
        <div>
          <label htmlFor="test" className="groupAttributeLabel">
            Activate
          </label>
          <Toggle
            className="mt-3"
            toggle={toggle}
            text={false}
            onClick={() => handelFormDataChange(!toggle, 'active', 2)}
          />
        </div>
      </div>
      <div className="w-3/4">
        <label htmlFor="associatedStores" className="groupAttributeLabel my-4">
          Associated Stores
        </label>
        <AssociatedStores
          className="mt-3 w-full"
          name="shops"
          stores={stores}
          disallowed="woocommerce"
          onChange={(e) => handelFormDataChange(e.target, 'shops', 3)}
        />
        <div style={{ color: '#FF9494' }}>{formDataError[3]}</div>
      </div>
      <div
        className="help-button mt-3"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Link
          style={{
            background: '#EC1577',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '25px',
            padding: '7.5px 40px 0px',
            fontWeight: 'normal',
            fontSize: '1,04vw',
            height: '37px',
          }}
          onClick={(e) => handelSubmit(e)}
        >
          Save
        </Link>
      </div>
    </div>
  );
};

export default Tax;
