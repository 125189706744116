import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

function EditCarrierLocations({
  setErrors,
  selectedShop,
  setCheckboxes,
  checkboxes,
  setZoneNames,
  setAllZoneNames,
  setIsAllZones,
  formData,
  setFormData,
  setSelectAll,
  zones,
  setBillingByWeight,
  setBillingByPrice,
  setSelectedTaxes,
  setSelectAllPrice,
  selectAll,
  currentTab,
  setValidatedTabs,
  setCurrentTab,
  validatedTabs,
  shopType,
  billingByPrice,
  billingByWeight,
  isAllZones,
}) {
  const token = useSelector(selectCurrentToken);
  const [taxes, setTaxes] = useState([]);
  const [delimiter1, setDelimiter1] = useState('');
  const [delimiter2, setDelimiter2] = useState('');
  const [loading, setLoading] = useState(true);
  const [validations, setValidations] = useState('');
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (Object.entries(errors).length === 0) {
      setErrors(0);
    } else {
      setErrors(1);
    }
  }, [errors]);

  const getTaxes = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/taxes/get-tax-rule-groups-by-shop/${selectedShop}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    Promise.all([getTaxes()]).then(async ([taxesResponse]) => {
      setTaxes(taxesResponse);

      setLoading(false);
    });
  }, [selectedShop]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({ ...checkboxes, [name]: checked });

    if (checked) {
      setZoneNames((prevZoneNames) => [
        ...prevZoneNames,
        event.target.attributes.getNamedItem('zoneName').value,
      ]);
      setAllZoneNames([]);
      setIsAllZones(0);
      clearErrors('zones');
    } else {
      setZoneNames((prevZoneNames) =>
        prevZoneNames.filter(
          (zone) =>
            zone !== event.target.attributes.getNamedItem('zoneName').value
        )
      );
    }
    // Update zones in formData state based on checkbox status
    const zoneId = parseInt(name.split('-')[1]); // Extract zone ID from name attribute
    const updatedZones = checked
      ? [...formData.zones, { id: zoneId, price: '' }]
      : formData.zones.filter((zone) => zone.id !== zoneId);
    setFormData({ ...formData, zones: updatedZones });
  };

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setSelectAll(checked);
    // Disable all input texts when "Select All" is checked
    Object.keys(checkboxes).forEach((key) => {
      setCheckboxes({ ...checkboxes, [key]: checked });
    });

    const zoneNames = zones.map((zone) => zone);

    // Update zones in formData state based on "Select All" checkbox status
    const updatedZones = checked
      ? zones.map((row) => ({ id: row.id, price: '' }))
      : [];
    setFormData({ ...formData, zones: updatedZones });
    if (checked) {
      setIsAllZones(1);
      setCheckboxes('');

      setAllZoneNames(zoneNames);
      clearErrors('zones');
      setZoneNames('');
    } else {
      setError('zones', {
        type: 'manual',
        message: 'you must select at least one zone',
      });

      setIsAllZones(0);
      setAllZoneNames([]);
    }
  };

  const handleInputTextChange = (event) => {
    const { name, value } = event.target;
    const zoneId = parseInt(name.split('-')[1]); // Extract zone ID from name attribute

    // Update price in formData state for the corresponding zone
    const updatedZones = formData.zones.map((zone) => {
      if (zone.id === zoneId) {
        return { ...zone, price: value };
      }
      return zone;
    });
    setFormData({ ...formData, zones: updatedZones });
  };

  const handleOnChangeBillingPrice = () => {
    setBillingByWeight(0);
    setBillingByPrice(1);
    setFormData({ ...formData, is_price_range: 1 });
    setFormData({ ...formData, is_weight_range: 0 });
  };
  const handleOnChangeBillingWeight = () => {
    setBillingByWeight(1);
    setBillingByPrice(0);
    setFormData({ ...formData, is_price_range: 0 });
    setFormData({ ...formData, is_weight_range: 1 });
  };

  const handleOnChangeActiveTaxNo = () => {
    setFormData({
      ...formData,
      shipping_handling: 0,
    });
  };

  const handleOnChangeActiveTaxYes = () => {
    setFormData({
      ...formData,
      shipping_handling: 1,
    });
  };

  const handleOnChangeFreeSippingNo = () => {
    setFormData({
      ...formData,
      free_shipping: 0,
    });
  };

  const handleOnChangeFreeSippingYes = () => {
    setFormData({
      ...formData,
      free_shipping: 1,
    });
  };

  const handleOnChangeTaxRule = (e) => {
    const { value } = e.target;
    const name = e.target.selectedOptions[0].getAttribute('taxname');
    setSelectedTaxes(name);
    setFormData({
      ...formData,
      tax_rules_group_id: value,
    });
  };

  const handleOnChangeRangeBehavior = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      range_behavior: value,
    });
  };

  const handleOnChangeDelimiter1 = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      delimiter1: value,
    });
    setDelimiter1(value);
    clearErrors('delimiter');
  };
  const handleOnChangeDelimiter2 = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      delimiter2: value,
    });
    setDelimiter2(value);
    clearErrors('delimiter');
  };

  const handleSelectAllPriceChange = (e) => {
    const price = e.target.value;
    setSelectAllPrice(price);

    let newZones = [...formData.zones];
    if (selectAll) {
      newZones = newZones.map((zone) => ({ ...zone, price }));
    }
    setFormData({ ...formData, zones: newZones });
  };

  const onSubmit = (data) => {
    if (parseFloat(data.delimiter1) > parseFloat(data.delimiter2)) {
      setError('delimiter', {
        type: 'manual',
        message:
          'the first range input must be inferior to the seconde range input',
      });
      return;
    }
    if (Object.keys(formData.zones).length === 0) {
      setError('zones', {
        type: 'manual',
        message: 'you must select at least one zone',
      });
      return;
    }

    const numberRegex = /^-?\d+(\.\d+)?$/;
    if (formData.zones.length > 0) {
      const isValid =
        formData.free_shipping === 0
          ? formData.zones.every((row) => numberRegex.test(row.price))
          : true;

      if (isValid) {
        setValidations('');
        const currentTabId = parseInt(currentTab);
        setValidatedTabs([...validatedTabs, currentTabId]);
        setCurrentTab('3');
      } else {
        setValidations('the zone prices data is not valid');
      }
    } else {
      const currentTabId = parseInt(currentTab);
      setValidatedTabs([...validatedTabs, currentTabId]);
      setCurrentTab('3');
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {shopType === 'prestashop' ? (
        <div className="space-y-5">
          <div className="input-box flex flex-row space-y-3 w-[90%]">
            <div className="label-container mr-5 mt-3">
              <label htmlFor="shipping_handling" className="form-label">
                Activate Taxes
              </label>
            </div>
            <div className="input-container">
              <div className="radio-box relative">
                <input
                  type="radio"
                  name="tax"
                  className="radio-input-left appearance-none bg-white shadow-xl rounded-xl w-12 h-8  z-10 checked:z-30 absolute checked:scale-125 checked:bg-[#EC1577] checked:outline-none hover:cursor-pointer hover:outline-none"
                  onChange={handleOnChangeActiveTaxNo}
                  checked={formData.shipping_handling === 0 ? 1 : 0}
                />
                <p className="absolute z-30 text-[#AAA6C3] left-[0.5rem] top-1 pointer-events-none">
                  No
                </p>
              </div>
              <div className="radio-box relative ">
                <input
                  type="radio"
                  name="tax"
                  className="radio-input-right appearance-none bg-white shadow-xl rounded-xl w-12 h-8 z-10 checked:z-30 absolute left-10 checked:scale-125 checked:bg-[#F99E1F] checked:outline-none hover:cursor-pointer hover:outline-none"
                  onChange={handleOnChangeActiveTaxYes}
                  checked={formData.shipping_handling === 0 ? 0 : 1}
                />
                <p className="absolute z-30 left-[3.5rem] text-[#AAA6C3] top-1 pointer-events-none">
                  Yes
                </p>
              </div>
            </div>
          </div>

          <div className="input-box flex flex-row space-y-3 w-[90%]">
            <div className="label-container mr-8 mt-3">
              <label htmlFor="shipping_handling" className="form-label">
                Free shipping
              </label>
            </div>
            <div className="input-container ">
              <div className="radio-box relative ">
                <input
                  type="radio"
                  name="free_shipping"
                  className="radio-input-left appearance-none bg-white shadow-xl rounded-xl w-12 h-8  z-10 checked:z-30 absolute checked:scale-125 checked:bg-[#EC1577] checked:outline-none hover:cursor-pointer hover:outline-none"
                  onChange={handleOnChangeFreeSippingNo}
                  checked={formData.free_shipping === 0 ? 1 : 0}
                />
                <p className="absolute z-30 text-[#AAA6C3] left-[0.5rem] top-1 pointer-events-none">
                  No
                </p>
              </div>
              <div className="radio-box relative ">
                <input
                  type="radio"
                  name="free_shipping"
                  className="radio-input-right appearance-none bg-white shadow-xl rounded-xl w-12 h-8 z-10 checked:z-30 absolute left-10 checked:scale-125 checked:bg-[#F99E1F] checked:outline-none hover:cursor-pointer hover:outline-none"
                  onChange={handleOnChangeFreeSippingYes}
                  checked={formData.free_shipping === 0 ? 0 : 1}
                />
                <p className="absolute z-30 left-[3.5rem] text-[#AAA6C3] top-1 pointer-events-none">
                  Yes
                </p>
              </div>
            </div>
          </div>
          <div className="input-box flex flex-row space-y-3 w-[90%]">
            <div className="label-container mr-8 mt-2">
              <label htmlFor="shipping_handling" className="form-label">
                Billing
              </label>
            </div>
            <div className="input-container space-x-4 flex flex-row">
              <div className="input-box flex flex-row space-x-3 relative">
                <input
                  type="radio"
                  name="billing"
                  id="billing-left"
                  className="radio-input-shifti appearance-none rounded-full border-2 border-[#181143] w-6 h-6"
                  onChange={handleOnChangeBillingPrice}
                  checked={billingByPrice === 0 ? 0 : 1}
                  defaultValue={billingByPrice}
                />
                <div className="absolute w-[0.9rem] h-[0.9rem]  rounded-full bg-[#EC1577] top-[0.3rem] -left-[0.45rem] opacity-0 pointer-events-none" />
                <label htmlFor="billing-left">According to total price.</label>
              </div>
              <div className="input-box flex flex-row space-x-3 relative">
                <input
                  type="radio"
                  name="billing"
                  id="billing-right"
                  className="radio-input-shifti appearance-none rounded-full border-2 border-[#181143] w-6 h-6"
                  onChange={handleOnChangeBillingWeight}
                  checked={billingByWeight === 0 ? 0 : 1}
                  defaultValue={billingByWeight}
                />
                <div className="absolute w-[0.9rem] h-[0.9rem]  rounded-full bg-[#EC1577] top-[0.3rem] -left-[0.45rem] opacity-0  pointer-events-none" />
                <label htmlFor="billing-right">
                  According to total weight.
                </label>
              </div>
            </div>
          </div>
          <div className="input-box grid grid-cols-2  w-[90%]">
            <div className="input-box-left space-y-3">
              <div className="label-container mr-8 mt-2">
                <label htmlFor="tax_rules_group_id" className="form-label">
                  Tax
                </label>
              </div>
              <div className="input-container">
                <select
                  id="tax_rules_group_id"
                  className="whiteSelectWithoutBorder"
                  {...register('tax_rules_group_id')}
                  onChange={handleOnChangeTaxRule}
                  value={formData.tax_rules_group_id}
                >
                  {/* <option value="0" taxname="No Tax applied"> */}
                  <option value="0">No tax</option>
                  {taxes.map((tax) => (
                    // <option key={tax.id} value={tax.id} taxname={tax.name}>
                    <option key={tax.id} value={tax.id}>
                      {tax.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input-box-right">
              <div className="input-box-left space-y-3">
                <div className="label-container mr-8 mt-2">
                  <label htmlFor="shipping_handling" className="form-label">
                    Out of Range behavior
                  </label>
                </div>
                <div className="input-container">
                  <select
                    {...register('range_behavior')}
                    id=""
                    className="whiteSelectWithoutBorder"
                    onChange={handleOnChangeRangeBehavior}
                    value={formData.range_behavior}
                  >
                    <option value={1}>
                      Apply the cost of the highest defined range
                    </option>
                    <option value={0}>Disable carrier</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {formData.free_shipping === 0 && (
            <div className="input-container">
              <div className="label-container mr-8 mt-2">
                <label htmlFor="shipping_handling" className="form-label">
                  Ranges
                </label>
              </div>
              <div className="ranges-box grid grid-cols-7 mt-3 space-x-5">
                <div className="left-box col-span-2">
                  <div className="label-container">
                    <label
                      htmlFor="shipping_handling"
                      className="text-[#181143] relative flex"
                    >
                      <p className="text-[1vw]  text-clip">
                        Will be applied when the weight is
                      </p>

                      <p className="text-[#EC1577] text-[1.5vw]  ">{'>='}</p>
                    </label>
                  </div>
                  <div className="currency-input-container bg-white text-[#AAA6C3] text-[1vw]  rounded-[1vw] md:w-full flex flex-row items-center justify-between pr-2">
                    {billingByPrice === 0 ? (
                      <>
                        <input
                          type="text"
                          id="height"
                          className="priceInput"
                          placeholder="0.0000"
                          {...register('delimiter1', {
                            pattern: {
                              value: /^-?\d+(\.\d+)?$/,
                              message: 'Please enter a valid range',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                          })}
                          onChange={handleOnChangeDelimiter1}
                          value={formData.delimiter1}
                        />
                        <p className="w-[25%] text-center"> | Kg</p>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          id="height"
                          className="priceInput"
                          placeholder="0.0000"
                          {...register('delimiter1', {
                            pattern: {
                              value: /^-?\d+(\.\d+)?$/,
                              message: 'Please enter a valid range',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                          })}
                          onChange={handleOnChangeDelimiter1}
                          value={formData.delimiter1}
                        />
                        <p className="w-[25%] text-center"> | DT</p>
                      </>
                    )}
                  </div>
                  {errors.delimiter1 && (
                    <p
                      className="text-[#EC1577] h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.delimiter1?.message}
                    </p>
                  )}
                </div>

                <div className="center-box flex flex-row justify-center col-span-2">
                  <div className="mt-8 flex flex-row justify-evenly w-full">
                    <div className="h-[0.15rem] w-8 bg-[#181143] self-center rounded-2xl" />
                    <div className="self-center text-[#181143]">Range</div>
                    <div className="h-[0.15rem] w-8 bg-[#181143] self-center rounded-2xl" />
                  </div>
                </div>
                <div className="right-box col-span-2">
                  <div className="label-container">
                    <label
                      htmlFor="shipping_handling"
                      className="text-[#181143] flex  relative "
                    >
                      <p className="text-[1vw]  text-clip">
                        Will be applied when the weight is
                      </p>
                      <p className="text-[#EC1577] text-[1.5vw] ">{'<'}</p>
                    </label>
                  </div>
                  <div className="currency-input-container bg-white text-[#AAA6C3] text-[1vw]  rounded-[1vw] md:w-full flex flex-row items-center justify-between pr-2">
                    {billingByPrice === 0 ? (
                      <>
                        <input
                          type="text"
                          name=""
                          id="height"
                          className="priceInput"
                          placeholder="0.0000"
                          {...register('delimiter2', {
                            pattern: {
                              value: /^-?\d+(\.\d+)?$/,
                              message: 'Please enter a valid range',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                          })}
                          onChange={handleOnChangeDelimiter2}
                          value={formData.delimiter2}
                        />
                        <p className="w-[25%] text-center"> | Kg</p>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          id="height"
                          className="priceInput"
                          placeholder="0.0000"
                          {...register('delimiter2', {
                            pattern: {
                              value: /^-?\d+(\.\d+)?$/,
                              message: 'Please enter a valid range',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                          })}
                          onChange={handleOnChangeDelimiter2}
                          value={formData.delimiter2}
                        />
                        <p className="w-[25%] text-center"> | TND</p>
                      </>
                    )}
                  </div>
                  {errors.delimiter2 && (
                    <p
                      className="text-[#EC1577] h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.delimiter2?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          {errors.delimiter && (
            <p
              className="text-[#EC1577] h-full w-full self-center text-center p-1 text-[16px]"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errors.delimiter?.message}
            </p>
          )}
          <div className="ZoneList-container">
            <div className="flex w-[40vw] justify-between space-x-5 space-y-5">
              <p className="mt-7 w-[40%] text-[1vw] font-semibold">All</p>
              <div className="input-container flex flex-col">
                <div className="input-container relative flex items-center space-x-3 md:w-[2rem] mt-2">
                  <input
                    type="checkbox"
                    id="allZones"
                    className="deliveryCheckbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    value={isAllZones}
                  />

                  <FontAwesomeIcon
                    icon={faCheck}
                    className="h-5 w-16  text-[#EC1577] absolute -left-[94%] md:bottom-[15%] pointer-events-none text-opacity-0 checked transition scale-75"
                  />
                </div>
              </div>

              {formData.free_shipping === 0 ? (
                <div className="currency-input-container bg-white text-[#AAA6C3] text-[1vw]  rounded-[1vw] md:w-full flex flex-row items-center justify-between pr-2">
                  {selectAll ? (
                    <input
                      type="text"
                      id="allZones"
                      className="priceInput"
                      placeholder="0.0000"
                      name="text-select-all"
                      onChange={handleSelectAllPriceChange}
                      value={
                        formData.zones.length > 0 ? formData.zones[0].price : ''
                      }
                    />
                  ) : (
                    <input
                      type="text"
                      id="allZones"
                      className="priceInput"
                      placeholder="0.0000"
                      name="text-select-all"
                      disabled={1}
                      onChange={handleSelectAllPriceChange}
                    />
                  )}

                  {billingByPrice === 0 ? (
                    <p className="w-[25%] text-center"> | Kg</p>
                  ) : (
                    <p className="w-[25%] text-center"> | TND</p>
                  )}
                </div>
              ) : (
                <div className="w-full" />
              )}
            </div>
            {zones.map((zone, index) => (
              <div
                className="flex w-[40vw] justify-between space-x-5 space-y-5"
                key={zone.id}
              >
                <p className="mt-7 w-[40%] text-[1vw] font-semibold">
                  {zone.name}
                </p>
                <div className="input-container flex flex-col">
                  <div className="input-container relative flex items-center space-x-3 md:w-[2rem] mt-2">
                    <input
                      type="checkbox"
                      id={zone.id}
                      className="deliveryCheckbox"
                      name={`checkbox-${zone.id}`}
                      // Use a unique name for each checkbox
                      checked={checkboxes[`checkbox-${zone.id}`] || selectAll} // Use the corresponding checkbox status from state
                      onChange={handleCheckboxChange}
                      disabled={isAllZones === 1 || selectAll}
                    />

                    <FontAwesomeIcon
                      icon={faCheck}
                      className="h-5 w-16  text-[#EC1577] absolute -left-[94%] md:bottom-[15%] pointer-events-none text-opacity-0 checked transition scale-75"
                    />
                  </div>
                </div>
                {formData.free_shipping === 0 ? (
                  <div className="currency-input-container bg-white text-[#AAA6C3] text-[1vw]  rounded-[1vw] md:w-full flex flex-row items-center justify-between pr-2">
                    <input
                      type="text"
                      className="priceInput"
                      placeholder="0.0000"
                      name={`text-${zone.id}`}
                      disabled={
                        !checkboxes[`checkbox-${zone.id}`] || isAllZones === 1
                      } // Disable input text if corresponding checkbox is not checked
                      onChange={handleInputTextChange}
                      value={
                        formData.zones.length > 0
                          ? formData.zones
                              .filter((item) => item.id === zone.id)
                              .map((row) => row.price)
                          : ''
                      }
                    />
                    {billingByPrice === 0 ? (
                      <p className="w-[25%] text-center"> | Kg</p>
                    ) : (
                      <p className="w-[25%] text-center"> | TND</p>
                    )}
                  </div>
                ) : (
                  <div className="w-full" />
                )}
              </div>
            ))}
            {validations !== '' && (
              <p
                className="text-[#EC1577] text-[1vw] "
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {validations !== '' && validations}
              </p>
            )}
          </div>
        </div>
      ) : null}
      {errors.zones && (
        <p
          className="text-[#EC1577] h-full w-full self-center  text-center p-1 text-[1vw]"
          ref={(el) =>
            el &&
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          }
        >
          {errors.zones?.message}
        </p>
      )}

      <div className="container-save-submit flex justify-end md:mr-10 mt-5 space-x-5">
        <button
          type="button"
          onClick={() => {
            setCurrentTab('1');
          }}
          className="pl-[0.7rem]  bg-[#AAA6C3] rounded-3xl w-[10rem] text-white h-[37px]"
        >
          previous
        </button>
        <button
          type="submit"
          className="pl-[0.7rem] bg-[#F99E1F] rounded-3xl w-[10rem] text-white h-[37px]"
        >
          Next
        </button>
      </div>
    </form>
  );
}

EditCarrierLocations.propTypes = {
  setErrors: PropTypes.func,
  setZoneNames: PropTypes.func,
  selectedShop: PropTypes.number,
  setCheckboxes: PropTypes.func,
  checkboxes: PropTypes.object,
  setAllZoneNames: PropTypes.func,
  setIsAllZones: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setSelectAll: PropTypes.func,
  zones: PropTypes.object,
  setBillingByWeight: PropTypes.func,
  setBillingByPrice: PropTypes.func,
  setSelectedTaxes: PropTypes.func,
  setSelectAllPrice: PropTypes.func,
  selectAll: PropTypes.bool,
  currentTab: PropTypes.number,
  setValidatedTabs: PropTypes.func,
  setCurrentTab: PropTypes.func,
  validatedTabs: PropTypes.number,
  shopType: PropTypes.string,
  billingByPrice: PropTypes.string,
  billingByWeight: PropTypes.string,
  isAllZones: PropTypes.bool,
};

export default EditCarrierLocations;
