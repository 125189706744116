/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, createContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../utils/apiService';
import styles from '../css/spinner.module.css';
import shopIcon from '../img/store.png';
import { selectCurrentToken } from '../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export const ShopContext = createContext();
const Loading = () => (
  <div className={styles.simpleSpinner}>
    <span />
  </div>
);

export const ShopProvider = ({
  defaultShop = true,
  isImported = false,
  children,
}) => {
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const [shops, setShops] = useState(['empty']);
  const [selectedShop, setSelectedShop] = useState(0);
  const [selectedShops, setSelectedShops] = useState([]);
  const [langs, setLangs] = useState([]);
  const [selectedLangs, setSelectedLangs] = useState([]);

  const getShops = async () =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'get',
        url: `${apiURL}/shop/get-all-shops/${isImported}`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => {
          setShops([...response.data]);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const allShopIds = shops.map((shop) => shop.id);
  const allShopTypes = 'AllShops';

  useEffect(() => {
    async function fetchData() {
      if (shops[0] === 'empty')
        await getShops().then((data) => {
          if (!selectedShops.length) {
            if (defaultShop) {
              data.map((shop) => {
                if (shop.default) {
                  const languages = shop.Langs.map((lang) => ({
                    iso_code: lang.iso_code,
                    checked: true,
                  }));
                  setLangs([...languages]);
                  setSelectedShops([{ ...shop }]);
                }
                return null;
              });
            } else {
              setSelectedShops([...data]);
            }
          }
        });
    }
    fetchData();
  }, [selectedShops]);
  const contextValue = useMemo(
    () => [
      shops,
      setShops,
      allShopTypes,
      allShopIds,
      selectedShop,
      setSelectedShop,
      selectedShops,
      setSelectedShops,
      langs,
      setLangs,
      selectedLangs,
    ],
    [
      shops,
      setShops,
      allShopTypes,
      allShopIds,
      selectedShop,
      setSelectedShop,
      selectedShops,
      setSelectedShops,
      langs,
      setLangs,
      selectedLangs,
    ]
  );
  return (
    <ShopContext.Provider value={contextValue}>
      <Content shops={shops} selectedShops={selectedShops}>
        {children}
      </Content>
    </ShopContext.Provider>
  );
};

const Content = ({ shops, selectedShops, children }) => {
  const navigate = useNavigate();
  if (shops[0] !== 'empty' && selectedShops.length) return children;
  if (!shops.length) {
    return (
      <div className="bg-white shadow-xl flex justify-center flex-col items-center w-1/2 m-auto mt-20 rounded-xl gap-2 p-4">
        <img src={shopIcon} width="170" height="auto" alt="" />
        <span className="text-xl text-shifti-blue">
          No shops have been imported yet.
        </span>
        <p className="text-gray-400">
          To redirect to import page simply click on the button below.
        </p>
        <button
          type="button"
          onClick={() => navigate('/shops')}
          className="addButton w-[10rem] mt-4"
        >
          Shops
        </button>
      </div>
    );
  }
  return <Loading />;
};

ShopProvider.propTypes = {
  defaultShop: PropTypes.bool,
  isImported: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Content.propTypes = {
  shops: PropTypes.array.isRequired,
  selectedShops: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};
