import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ProductPrice({
  handelFormDataChange,
  selectedShop,
  selectedShops,
  formData,
}) {
  const [currentShopId, setCurrentShopId] = useState(
    selectedShop || selectedShops[0].id
  );
  return (
    <div
      className="mt-12 rounded-3xl flex flex-col sm:flex-row"
      style={{
        paddingTop: '0',
        marginTop: '0',
      }}
    >
      <div className="price-container w-full">
        <h1 className="SubTitle">Price</h1>
        {selectedShop ? null : (
          <div className="bg-white h-12 rounded-t-xl divide-x -mb-4 inline-block px-4 pt-1 font-medium text-shifti-blue">
            {selectedShops.map((shop) => (
              <Link
                className={`px-4 inline-block cursor-pointer divide-x ${
                  currentShopId === shop.id
                    ? 'text-shifti-pink font-semibold'
                    : ''
                }`}
                onClick={() => setCurrentShopId(shop.id)}
              >
                {shop.name}
              </Link>
            ))}
          </div>
        )}
        <div>
          <div className="md:p-5 bg-white rounded-3xl px-1 py-2 w-full">
            <div className="selling-inputs-container flex md:flex-row flex-col md:space-x-4 w-full">
              <div className="input-container flex flex-col w-full">
                <label htmlFor="sellingPriceHT" className="essentialLabel">
                  Selling Price (HT)
                </label>
                <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11 border border-[#534B85]">
                  <input
                    type="number"
                    id="sellingPriceHT"
                    placeholder="0.000"
                    className="priceInput"
                    name="sellingPriceHT"
                    value={formData.sites[currentShopId].priceTaxExcl}
                    onChange={(e) => handelFormDataChange(e, 'priceTaxExcl')}
                  />
                  <p className="w-[25%] text-center"> | TND</p>
                </div>
              </div>
              <div className="input-container flex flex-col w-full">
                <label htmlFor="sellingPriceTTC" className="essentialLabel">
                  Selling Price (TTC)
                </label>
                <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11 border border-[#534B85]">
                  <input
                    type="number"
                    id="sellingPriceTTC"
                    placeholder="0.000"
                    className="priceInput"
                    name="sellingPriceTTC"
                    value={formData.sites[currentShopId].priceTaxIncl}
                    onChange={(e) => handelFormDataChange(e, 'priceTaxIncl')}
                  />
                  <p className="w-[25%] text-center"> | TND</p>
                </div>
              </div>
              <div className="input-container flex flex-col w-full">
                <label htmlFor="UnitPriceHT" className="essentialLabel">
                  Unit selling price (HT)
                </label>
                <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11 border border-[#534B85]">
                  <input
                    type="number"
                    id="UnitPriceHT"
                    placeholder="0.000"
                    className="priceInput"
                    name="UnitPriceHT"
                  />
                  <p className="w-[25%] text-center"> | TND</p>
                </div>
              </div>
              <div className="input-container flex flex-col justify-end w-full">
                <input
                  type="text"
                  id="sellingPriceHT"
                  placeholder="Per kilo, per liter"
                  className="deliveryInput items-center border border-[#534B85]"
                  name="sellingPriceHT"
                />
              </div>
            </div>
            <div className="tax-rule-container md:mt-10 mt-5">
              <div className="input-container flex flex-col md:w-[60%]">
                <label htmlFor="UnitPriceHT" className="essentialLabel">
                  Tax rule
                </label>
                <select className="essentialSelect">
                  <option value="0">Aucune taxe</option>
                  <option value="7">TN TVA Reduced Rate (7%)</option>
                  <option value="13">TN TVA Reduced Rate (13%)</option>
                  <option value="19">TN TVA Reduced Rate (19%)</option>
                </select>
              </div>
              <div className="Promo-container flex flex-col space-y-5">
                <div className="input-container flex relative md:w-[60rem] w-[20rem] flex-row items-center space-x-2 mt-3">
                  <input
                    type="checkbox"
                    id="promo"
                    name="promo"
                    className="deliveryCheckbox"
                  />
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="h-5 w-16  text-[#EC1577] absolute right-[85.5%] md:right-[95.2%] md:bottom-[29%] pointer-events-none text-opacity-0 checked transition scale-75"
                  />
                  <label
                    htmlFor="promo"
                    className="essentialLabel md:w-[60rem] w-[15rem] text-[0.6rem]"
                  >
                    Display a "Promo!" banner on the product sheet and on the
                    product listings.
                  </label>
                </div>
                <div className="alert-container h-[3rem] w-full md:w-[35rem] bg-[#EC1577] rounded-[1.2rem] relative flex items-center">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="h-7 w-18  text-[#181143] absolute right-[88.5%]  md:right-[92.5%] md:bottom-[28%] pointer-events-none  checked transition scale-100"
                  />
                  <p className="relative p-2 md:ml-[3rem] ml-[2rem] md:text-[1rem] text-[0.7rem]">
                    Final selling price : taxed price TND TTC / net price TND HT
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="purchase-container flex md:flex-row flex-col md:space-x-10 md:mt-10 mt-5">
          <div className="input-button-container flex flex-col md:w-6/12">
            <label htmlFor="UnitPriceHT" className="essentialLabel">
              Purchase price ( Purchase Price (HT))
            </label>
            <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11">
              <input
                type="text"
                id="UnitPriceHT"
                placeholder="0.000"
                className="priceInput"
                name="UnitPriceHT"
              />
              <p className="w-[20%] text-center"> | TND</p>
            </div>
          </div>
          <div className="button-container flex flex-col md:w-6/12">
            <label htmlFor="price" className="essentialLabel">
              Specific prices
            </label>
            <button
              type="button"
              id="price"
              className="addButton w-full md:pr-[55%]"
            >
              <span
                className="p-2"
                style={{
                  background: '#ffffff',
                  color: '#18114397',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  padding: '4px 9px',
                  fontSize: '1,20vw',
                  marginLeft: '-5%',
                  marginRight: '2%',
                }}
              >
                +
              </span>
              add a specific price
            </button>
          </div>
        </div>
        <div className="priority-management" />
      </div>
    </div>
  );
}

ProductPrice.propTypes = {
  handelFormDataChange: PropTypes.func.isRequired,
  selectedShop: PropTypes.number.isRequired,
  selectedShops: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
};
