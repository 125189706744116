/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';

const UserProfile = ({ user }) => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem('userLogged');
    navigate('/login');
  };
  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96 z-10">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <div
          className="rounded-full h-24 w-24 flex items-center justify-center"
          style={{
            backgroundColor: user.avatarColor,
            color: 'white',
            fontSize: '2rem',
          }}
          alt="user-profile"
        >
          <span className="text">{user.first_name.charAt(0)}</span>
        </div>
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {user.fullname}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            Administrator
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {user.email}
          </p>
        </div>
      </div>
      <div>
        <div className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]">
          <button
            type="button"
            className=" text-xl rounded-lg p-3 hover:bg-light-gray  bg-yellow-500 w-full"
            onClick={() => navigate('/profile')}
          >
            <div className="">
              <p className="font-semibold dark:text-gray-200 text-sm ">
                My profile
              </p>
              <p className="text-gray-500 text-sm dark:text-gray-400" />
            </div>
          </button>
        </div>
      </div>
      <div>
        <div className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]">
          <button
            type="button"
            className=" text-xl rounded-lg p-3 hover:bg-light-gray  bg-yellow-500 w-full"
            onClick={() => navigate('/currentPlan')}
          >
            <div className="">
              <p className="font-semibold dark:text-gray-200 text-sm ">
                My plan
              </p>
              <p className="text-gray-500 text-sm dark:text-gray-400" />
            </div>
          </button>
        </div>
      </div>
      <div>
        <div className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]">
          <button
            type="button"
            className=" text-xl rounded-lg p-3 hover:bg-light-gray  bg-yellow-500 w-full"
            onClick={() => navigate('/payments')}
          >
            <div className="">
              <p className="font-semibold dark:text-gray-200 text-sm ">
                Purchase history
              </p>
              <p className="text-gray-500 text-sm dark:text-gray-400" />
            </div>
          </button>
        </div>
      </div>
      <div className="mt-5">
        <a href="#" onClick={logout}>
          <Button
            color="white"
            bgColor={currentColor}
            text="Logout"
            borderRadius="10px"
            width="full"
          />
        </a>
      </div>
    </div>
  );
};
UserProfile.propTypes = {
  user: PropTypes.object.isRequired,
};
export default UserProfile;
