/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faUser,
  faMagnifyingGlass,
  faTriangleExclamation,
  faCalendarDays,
  faTrash,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { BsShopWindow } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import api from '../../utils/apiService';
import { selectCurrentToken } from '../../features/auth/authSlice';
import Loader from '../../components/Loader';

const apiURL = process.env.REACT_APP_API_URL;

export default function OrderPreview() {
  const token = useSelector(selectCurrentToken);
  const [order, setOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [allCarriers, setAllCarriers] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [orderStates, setOrderStates] = useState([]);
  const [loading, setLoading] = useState(true);
  const orderStatus = null;
  const [formData, setFormData] = useState({
    order_state_id: orderStatus,
  });
  const [status, setStatus] = useState(1);
  const [carriers, setCarriers] = useState(0);
  const [selectedPaymentIds, setSelectedPaymentIds] = useState({});
  const [privateChecked, setPrivateChecked] = useState(false);
  const decoded = jwtDecode(token);
  const [user, setUser] = useState(decoded.UserInfo);
  const [showMoreMessages, setShowMoreMessages] = useState(false);
  const [addNewProductWoo, setAddNewProductWoo] = useState(false);
  const [addNewProductPresta, setAddNewProductPresta] = useState(false);

  const [products, setProducts] = useState([]);
  const [editOrderProduct, setEditOrderProduct] = useState('');
  const [deleteOrderProduct, setDeleteOrderProduct] = useState('');
  const [deleteOrderCarrier, setDeleteOrderCarrier] = useState('');
  const [editOrderCarrier, setEditOrderCarrier] = useState('');
  const [editOrderFee, setEditOrderFee] = useState('');
  const [deleteOrderFee, setDeleteOrderFee] = useState('');
  const [productInput, setProductInput] = useState('');

  const [autoCompleteProducts, setAutoCompleteProducts] = useState('');
  const [matchingProductNames, setMatchingProductNames] = useState([]);

  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');

  const [createNewShipping, setCreateNewShipping] = useState(false);
  const [createNewFee, setCreateNewFee] = useState(false);

  const [customers, setCustomers] = useState([]);

  const [customerModal, setCustomerModal] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const wooStatusArray = [
    'Pending',
    'Processing',
    'On-hold',
    'Completed',
    'Cancelled',
    'Refunded',
    'Failed',
    'Draft',
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: registerOrderCarrier,
    formState: { errors: errorsOrderCarrier },
    handleSubmit: handleSubmitOrderCarrier,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    register: registerCustomerMessage,
    formState: { errors: errorsCustomerMessage },
    handleSubmit: handleSubmitCustomerMessage,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    register: registerOrderProduct,
    formState: { errors: errorsOrderProduct },
    handleSubmit: handleSubmitOrderProduct,
    reset: resetCreateProduct,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    register: registerDeleteOrderProduct,
    formState: { errors: errorsDeleteOrderProduct },
    handleSubmit: handleSubmitDeleteOrderProduct,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    register: registerDeleteOrderFee,
    formState: { errors: errorsDeleteOrderFee },
    handleSubmit: handleSubmitDeleteOrderFee,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    register: registerDeleteOrderShipping,
    formState: { errors: errorsDeleteOrderShipping },
    handleSubmit: handleSubmitDeleteOrderShipping,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    register: registerEditOrderProduct,
    formState: { errors: errorsEditOrderProduct },
    handleSubmit: handleSubmitEditOrderProduct,
    reset: resetProduct,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: '',
    validateCriteriaMode: 'all',
  });

  const {
    register: registerEditOrderShipping,
    formState: { errors: errorsEditOrderShipping },
    handleSubmit: handleSubmitEditOrderShipping,
    reset: resetShipping,
  } = useForm({
    defaultValues: '',
  });

  const {
    register: registerEditOrderfee,
    formState: { errors: errorsEditOrderfee },
    handleSubmit: handleSubmitEditOrderfee,
    reset: resetFee,
  } = useForm({
    defaultValues: '',
  });

  const {
    register: registerCreateOrderShipping,
    formState: { errors: errorsCreateOrderShipping },
    handleSubmit: handleSubmitCreateOrderShipping,
    reset: resetCreateShipping,
  } = useForm({
    defaultValues: '',
  });

  const {
    register: registerCreateOrderFee,
    formState: { errors: errorsCreateOrderFee },
    handleSubmit: handleSubmitCreateOrderFee,
    reset: resetCreateFee,
  } = useForm({
    defaultValues: '',
  });

  const {
    register: registerEditOrderStatus,
    formState: { errors: errorsEditOrderStatus },
    handleSubmit: handleSubmitEditOrderStatus,
    reset: resetEditOrderStatus,
  } = useForm({
    defaultValues: '',
  });

  const {
    register: registerCreateOrderNoteWoo,
    formState: { errors: errorsCreateOrderNoteWoo },
    handleSubmit: handleSubmitCreateOrderNoteWoo,
    reset: resetCreateOrderNoteWoo,
  } = useForm({
    defaultValues: '',
  });

  const { id } = useParams();
  const { shopId } = useParams();

  const getOrder = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/orders/${id}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getCustomersByShop = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/customers/shop/${shopId}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => setCustomers(response.data))
      .catch((error) => JSON.stringify(error));
  };

  const getCarriers = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/carriers`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getOrderStates = async (orderId) => {
    const config = {
      method: 'get',
      url: `${apiURL}/orders/orderStates/${orderId}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getCurrencies = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/currency/`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => setCurrencies(response.data))
      .catch((error) => JSON.stringify(error));
  };

  const getProducts = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/products/OrderPreview/${shopId}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getOrder().then((orderResponse) => {
      setOrder(orderResponse);
      getOrderStates(orderResponse.shop_id).then((response) => {
        setOrderStates(response);
        getCarriers().then((carriersResponse) => {
          getCurrencies().then(() => {
            setAllCarriers(carriersResponse);
            getProducts().then((productsResponse) => {
              getCustomersByShop();
              setProducts(productsResponse);
              setLoading(false);
            });
          });
        });
      });
    });
  }, [id]);

  useEffect(() => {
    resetShipping();
    resetProduct();
    resetFee();
    resetCreateProduct();
    resetCreateFee();
    resetCreateShipping();
    resetEditOrderStatus();
    resetCreateOrderNoteWoo();
  }, [
    editOrderCarrier,
    editOrderProduct,
    editOrderFee,
    createNewShipping,
    createNewShipping,
    addNewProductWoo,
  ]);

  if (loading === true) {
    return <Loader />;
  }

  const dateObj = new Date(
    Array.isArray(order.OrderPayments) === true &&
    order.OrderPayments.length > 0
      ? order.OrderPayments[0].date_add
      : null
  );
  const year = dateObj.getFullYear();

  const dynamicNumber = 9; // Your dynamic number from the database
  const paddedString = String(dynamicNumber).padStart(6, '0');

  const handleNumberOfOrderPayments = () => order.OrderPayments.length;

  const handleAutocompleteProducts = (productsData) => {
    const data = productsData.map((item) => item.Product.ProductLangs[0].name);

    const handleAutocompleteChange = (args) => {
      // args.value will contain the selected name
      // Find the corresponding product_id in the original data
      const selectedProduct = productsData.find(
        (item) => item.Product.ProductLangs[0].name === args.value
      );
    };

    return (
      <div className="w-[80%]">
        <AutoCompleteComponent
          dataSource={data}
          placeholder="Type a product name"
          onChange={handleAutocompleteChange}
        />
      </div>
    );
  };

  const handleAutoCompleteProducts = (e) => {
    const inputValue = e.target.value;
    setAutoCompleteProducts(inputValue);

    // Filter the strings based on the input value (case-insensitive)
    if (inputValue.trim() === '') {
      setMatchingProductNames([]); // If the input is empty, show nothing
    } else {
      const filteredStrings = products.filter((product) =>
        product.Product.ProductLangs[0].name
          .toLowerCase()
          .startsWith(inputValue)
      );

      setMatchingProductNames(filteredStrings);
    }
  };

  const hundleSelectProductInput = (productName, productId) => {
    setMatchingProductNames([]);
    setSelectedProductName(productName);
    setAutoCompleteProducts('');
    setSelectedProductId(productId);
  };

  const handleProducts = () => {
    if (order.Shop.type === 'prestashop') {
      if (order.OrderDetails) {
        let nbProducts = 0;
        order.OrderDetails.map((i) => {
          nbProducts += 1;
          return null;
        });

        return (
          <>
            <h2 className="font-semibold text-[20px] text-[#EC1577]">
              Products ({nbProducts})
            </h2>
            <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
            <div className="product-table grid grid-cols-6 items-center space-y-2">
              <div className="text-[#AAA6C3] font-medium text-center">
                Image
              </div>
              <div className="text-[#AAA6C3] font-medium text-center">
                Product
              </div>
              <div className="text-[#AAA6C3] font-medium text-center">
                Base price
              </div>
              <div className="text-[#AAA6C3] font-medium text-center">
                Quantity
              </div>
              <div className="text-[#AAA6C3] font-medium text-center">
                Total
              </div>
              <div />

              {order.OrderDetails.map((product, index) => (
                <>
                  <div>
                    <img
                      src={`${apiURL}/images/${order.shop_id}/${product.Product?.ProductShops[0].foreign_id}/${product.Product?.default_image_id}`}
                      alt={product.product_name}
                    />
                  </div>

                  <div className="text-[#181143]  text-md  font-semibold text-center">
                    {product.product_name}
                  </div>
                  <div className="text-[#181143]  text-md  font-semibold text-center">
                    {parseFloat(product.unit_price_tax_incl).toFixed(
                      order.Currency.precision
                    )}
                    {order.Currency.CurrencyLangs[0].symbol}
                  </div>
                  <div className="text-[#181143]  text-md  font-semibold text-center">
                    {product.product_quantity}
                  </div>
                  <div className="text-[#181143]  text-md  font-semibold text-center">
                    {parseFloat(product.total_price_tax_incl).toFixed(
                      order.Currency.precision
                    )}
                    {order.Currency.CurrencyLangs[0].symbol}
                  </div>
                  <div className="text-[#181143]  text-md  font-semibold flex justify-evenly">
                    <button
                      type="button"
                      onClick={() => setEditOrderProduct(index)}
                    >
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="w-4 h-4 text-[#AAA6C3]"
                      />
                    </button>

                    <button
                      type="button"
                      onClick={() => setDeleteOrderProduct(index)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="w-4 h-4 text-[#AAA6C3]"
                      />
                    </button>
                    {editOrderProduct === index &&
                      editOrderProductModal(product)}
                    {deleteOrderProduct === index &&
                      deleteOrderProductModal(product)}
                  </div>
                </>
              ))}
            </div>
          </>
        );
      }
    }
    if (order.Shop.type === 'woocommerce') {
      const nbProducts = order.OrderDetails.length;

      return (
        <>
          <h2 className="font-semibold text-[20px] text-[#EC1577]">
            Products ({nbProducts})
          </h2>

          <div>
            {' '}
            <button
              type="button"
              className="addButton p-2 w-[10rem]"
              onClick={() => {
                setAddNewProductWoo(true);
                setSelectedProductName('');
                setAutoCompleteProducts('');
              }}
            >
              Add product
            </button>
          </div>

          {addNewProductWoo === true && (
            <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center transition delay-150 duration-300 ease-in-out">
              <div className="w-[60%] bg-white rounded-xl p-4 flex flex-col space-y-2">
                <div className="flex justify-between">
                  {' '}
                  <h2 className="p-2 font-semibold text-[#EC1577]">
                    Add Product
                  </h2>
                  <button
                    type="button"
                    className="p-2 "
                    onClick={() => setAddNewProductWoo(false)}
                  >
                    X
                  </button>
                </div>

                <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
                <form onSubmit={handleSubmitOrderProduct(addnewOrderProduct)}>
                  <div className="grid grid-cols-7 p-4">
                    {' '}
                    <div className="col-span-2 h-8 text-[#181143] font-semibold text-sm" />
                    <div className="col-span-2 h-8 text-[#181143] text-center font-semibold  pl-4  text-sm">
                      Product
                    </div>
                    <div className="col-span-2 text-[#181143] font-semibold text-center text-sm">
                      quantity
                    </div>
                    <div className="col-span-1" />
                    <div className="col-span-2 relative">
                      <input
                        type="text"
                        className=" focus:border-b-2  focus:border-[#EC1577]  h-10 p-1 text-[#181143] w-[100%] text-xs font-semibold mx-auto my-auto focus: border-0  hover:border-0 focus:outline-0"
                        onChange={(e) => handleAutoCompleteProducts(e)}
                        value={autoCompleteProducts}
                        placeholder="Enter the name of the product"
                      />
                      {matchingProductNames.length !== 0 && (
                        <div className="absolute w-[100%] bg-white border-[#AAA6C3]   border-2 max-h-[17rem] overflow-y-scroll  flex flex-col">
                          {matchingProductNames.length !== 0 ? (
                            matchingProductNames.map((product) => (
                              <button
                                type="button"
                                className="text-start hover:bg-[#AAA6C3] pt-2 pb-4 pr-0 pl-1 text-sm "
                                onClick={() =>
                                  hundleSelectProductInput(
                                    product.Product.ProductLangs[0].name,
                                    product.Product.id
                                  )
                                }
                                key={product.Product.id}
                              >
                                {product.Product.ProductLangs[0].name}
                              </button>
                            ))
                          ) : (
                            <p>No matching products found.</p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-span-2  text-center flex items-center pl-4">
                      <div className="text-sm text-[#181143]">
                        {selectedProductName === ''
                          ? 'No product selected'
                          : selectedProductName}
                      </div>
                    </div>
                    <div className="col-span-2 flex justify-center relative">
                      <input
                        type="text"
                        id="quantity"
                        className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-center mx-auto my-auto self-end"
                        defaultValue={1}
                        {...registerOrderProduct('product_quantity', {
                          pattern: {
                            value: /^(?:10000|[0-9]{1,4})$/,
                            message: 'Please enter a valid  number',
                          },
                          required: {
                            value: true,
                            message: 'This field is required !',
                          },
                        })}
                      />
                      {errorsOrderProduct.product_quantity && (
                        <p
                          className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                          ref={(el) =>
                            el &&
                            el.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            })
                          }
                        >
                          {errorsOrderProduct.product_quantity?.message}
                        </p>
                      )}
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white w-full"
                      >
                        create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
          <div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${
                  order.Taxes.length === 0 ? 7 : 6 + order.Taxes.length
                }, minmax(0, 1fr))`,
                justifyContent: 'space-between',
              }}
            >
              <div className="text-[#AAA6C3] text-sm font-medium text-center pb-6 col-span-1">
                Image
              </div>
              <div className="text-[#AAA6C3] text-sm font-medium text-center pb-6 col-span-1">
                Product
              </div>
              <div className="text-[#AAA6C3] text-sm font-medium text-center pb-6 col-span-1">
                Base price
              </div>
              <div className="text-[#AAA6C3] text-sm font-medium text-center pb-6 col-span-1">
                Quantity
              </div>
              <div className="text-[#AAA6C3] text-sm font-medium text-center pb-6 col-span-1">
                Total
              </div>

              <div
                style={{
                  color: '#AAA6C3',
                  textAlign: 'center',
                  display: 'grid',
                  gridTemplateColumns: `repeat(${
                    order.Taxes.length === 0 ? 1 : order.Taxes.length
                  }, minmax(0, 1fr))`,
                  justifyContent: 'space-between',
                  height: '100%',
                  gridColumn: `span ${
                    order.Taxes.length === 0 ? 1 : order.Taxes.length
                  }`,
                }}
              >
                <div
                  style={{
                    gridColumn: `span ${
                      order.Taxes.length === 0 ? 1 : order.Taxes.length
                    }`,
                  }}
                >
                  <div className="text-sm font-medium">Taxes</div>
                </div>

                {order.OrderDetails.slice(0, 1).map((product) =>
                  product.OrderDetailsTaxes.slice(0, 2).map((productTax) => (
                    <div className="text-xs font-medium" key={product.id}>
                      {productTax.Tax.TaxLangs[0].name}
                    </div>
                  ))
                )}
              </div>
              <div className="hello there">{'  '}</div>
            </div>
            <div
              className="grid items-center md:max-h-[300px] max-w-[100%] overflow-y-auto"
              style={{
                gridTemplateColumns: `repeat(${
                  order.Taxes.length === 0 ? 7 : 6 + order.Taxes.length
                }, minmax(0, 1fr))`,
              }}
            >
              {order.OrderDetails.sort((a, b) =>
                a.product_name.localeCompare(b.product_name)
              ).map((product, index) => (
                <>
                  <div className="col-span-1 w-full">
                    <img
                      src={`${apiURL}/images/${order.shop_id}/${product.Product.ProductShops[0].foreign_id}/${product.Product.default_image_id}`}
                      alt={product.product_name}
                      className="p-2"
                    />
                  </div>

                  <div className="text-[#181143]  text-md  font-semibold text-center flex flex-col text-xs col-span-1">
                    <p>{product.product_name}</p>
                    <p>
                      <b>SKU : </b>
                      {product.Product.reference}
                    </p>
                  </div>
                  <div className="text-[#181143]  text-md  font-semibold text-center text-xs col-span-1">
                    {parseFloat(product.product_price) /
                      parseFloat(product.product_quantity)}{' '}
                    {order.woo_currency}
                  </div>
                  <div className="text-[#181143]  text-md  font-semibold text-center text-xs col-span-1">
                    {product.product_quantity}
                  </div>
                  <div className="text-[#181143]  text-md  font-semibold text-center text-xs col-span-1">
                    {product.product_price.toFixed(3)} {order.woo_currency}
                  </div>

                  {order.Taxes.length !== 0 ? (
                    <div
                      className={`flex justify-evenly col-span-${
                        order.Taxes.length === 0 ? 1 : order.Taxes.length
                      } h-full`}
                    >
                      {product.OrderDetailsTaxes.map((productTax) => (
                        <div className="text-[#181143]  text-center w-full flex flex-row justify-center h-full items-center relative text-xs">
                          <div className="text-xs font-semibold">
                            <div className="w-full">
                              {productTax.total === 0 ? (
                                <div>-</div>
                              ) : (
                                productTax.total
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center">-</div>
                  )}
                  <div className="text-[#181143]  text-md  font-semibold flex justify-evenly">
                    <button
                      type="button"
                      onClick={() => setEditOrderProduct(index)}
                    >
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="w-4 h-4 text-[#AAA6C3]"
                      />
                    </button>

                    <button
                      type="button"
                      onClick={() => setDeleteOrderProduct(index)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="w-4 h-4 text-[#AAA6C3]"
                      />
                    </button>
                    {editOrderProduct === index &&
                      editOrderProductModal(product)}
                    {deleteOrderProduct === index &&
                      deleteOrderProductModal(product)}
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      );
    }
  };

  const wooCommerceOrderTotal = () => {
    const productTotal = order.OrderDetails.reduce(
      (sum, product) => sum + product.product_price,
      0
    );

    const feeTotal = order.OrderFees.reduce((sum, fee) => sum + fee.total, 0);
    const shippingTotal = order.OrderCarriers.reduce(
      (sum, shipping) => sum + shipping.shipping_cost_tax_excl,
      0
    );
    const total =
      feeTotal + shippingTotal + productTotal + order.total_wrapping_tax_incl;
    return total.toFixed(3);
  };

  const priceTotalProduct = () => {
    if (order.Shop.type === 'prestashop') {
      return (
        <div className="bg-[#E1DFEE] flex flex-row justify-evenly rounded-xl p-5 self-center w-[70%]">
          <div className="flex flex-col space-y-3">
            <p className="font-semibold text-[16px] text-[#181143] text-center">
              Products
            </p>
            <p className="font-semibold text-[16px] text-[#EC1577] text-center">
              {parseFloat(order.total_products_wt).toFixed(
                order.Currency.precision
              )}{' '}
              {order.Currency.CurrencyLangs[0].symbol}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="font-semibold text-[16px] text-[#181143] text-center">
              Livraison
            </p>
            <p className="font-semibold text-[16px] text-[#EC1577] text-center">
              {parseFloat(order.total_shipping).toFixed(
                order.Currency.precision
              )}{' '}
              {order.Currency.CurrencyLangs[0].symbol}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="font-semibold text-[16px] text-[#181143] text-center">
              Total
            </p>
            <p className="font-semibold text-[16px] text-[#EC1577] text-center">
              {parseFloat(order.total_paid).toFixed(order.Currency.precision)}{' '}
              {order.Currency.CurrencyLangs[0].symbol}
            </p>
          </div>
        </div>
      );
    }

    if (order.Shop.type === 'woocommerce') {
      const productTotal = order.OrderDetails.reduce(
        (sum, product) => sum + product.product_price,
        0
      );

      const feeTotal = order.OrderFees.reduce((sum, fee) => sum + fee.total, 0);
      const shippingTotal = order.OrderCarriers.reduce(
        (sum, shipping) => sum + shipping.shipping_cost_tax_excl,
        0
      );

      return (
        <div className="bg-[#E1DFEE] flex flex-row justify-evenly rounded-xl p-5 self-center w-[95%]">
          <div className="flex flex-col space-y-3">
            <p className="font-semibold text-[16px] text-[#181143] text-center">
              Products
            </p>
            <p className="font-semibold text-[16px] text-[#EC1577] text-center">
              {productTotal.toFixed(3)} {order.woo_currency}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="font-semibold text-[16px] text-[#181143] text-center">
              Shipping
            </p>
            <p className="font-semibold text-[16px] text-[#EC1577] text-center">
              {shippingTotal.toFixed(3)} {order.woo_currency}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="font-semibold text-[16px] text-[#181143] text-center">
              Fees
            </p>
            <p className="font-semibold text-[16px] text-[#EC1577] text-center">
              {feeTotal.toFixed(3)} {order.woo_currency}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="font-semibold text-[16px] text-[#181143] text-center">
              Tax
            </p>
            <p className="font-semibold text-[16px] text-[#EC1577] text-center">
              {order.total_wrapping_tax_incl.toFixed(3)} {order.woo_currency}
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <p className="font-semibold text-[16px] text-[#181143] text-center">
              Total
            </p>
            <p className="font-semibold text-[16px] text-[#EC1577] text-center">
              {parseFloat(
                feeTotal +
                  shippingTotal +
                  productTotal +
                  order.total_wrapping_tax_incl
              ).toFixed(3)}{' '}
              {order.woo_currency}
            </p>
          </div>
        </div>
      );
    }
  };

  /// ////////////////////////////////////////// EDIT functions woocommerce ////////////////////////////////////////////////////////

  const editOrderCarrierModal = (orderCarrier) => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">
            Edit Carrier {orderCarrier.name}
          </h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setEditOrderCarrier('')}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <form onSubmit={handleSubmitEditOrderShipping(hundleEditOrderShipping)}>
          <div
            className="items-center justify-between"
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${
                order.Taxes.length !== 0 ? 4 + order.Taxes.length : 5
              }, minmax(0, 1fr))`,
            }}
          >
            {' '}
            <div className="col-span-2 text-[#181143] pb-4 text-start text-sm">
              Carrier name
            </div>
            <div className="col-span-1 text-[#181143] text-center  pb-4 text-sm">
              Base price
            </div>
            <div
              className=" text-[#181143]  text-sm pt-1"
              style={{
                gridColumn: `span ${
                  order.Taxes.length !== 0 ? order.Taxes.length : 1
                }`,
                justifyItems: 'center',
                textAlign: 'center',
                display: 'grid',
                gridTemplateColumns: `repeat(${
                  order.Taxes.length !== 0 ? order.Taxes.length : 1
                }, minmax(0, 1fr))`,
              }}
            >
              <div
                className={`col-span-${
                  order.Taxes.length !== 0 ? order.Taxes.length : 1
                }`}
              >
                Tax
              </div>

              {orderCarrier.OrderCarrierTaxes.slice(0, 2).map((productTax) => (
                <div className="text-center">
                  {productTax.Tax.TaxLangs[0].name}
                </div>
              ))}
            </div>
            <div className="col-span-1" />
            <div className="col-span-2 text-start items-center space-x-4">
              <div>
                <input
                  type="text"
                  id="name"
                  className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-center mx-auto my-auto self-end"
                  {...registerEditOrderShipping('name', {
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                  })}
                  defaultValue={orderCarrier.name}
                />
              </div>
            </div>
            <div className="col-span-1 flex justify-center relative">
              <input
                type="text"
                id="quantity"
                className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-center mx-auto my-auto self-end"
                defaultValue={orderCarrier.shipping_cost_tax_excl}
                {...registerEditOrderShipping('total', {
                  pattern: {
                    value: /^(?!0+(\.0{1,3})?$)^\d+(\.\d{1,3})?$/,
                    message: 'enter a valid format',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                  maxLength: {
                    value: 255,
                    message: 'maximum characters are 255 !',
                  },
                })}
              />
              {errorsEditOrderShipping.total && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsEditOrderShipping.total?.message}
                </p>
              )}
            </div>
            {order.Taxes.length !== 0 ? (
              <div
                className={`flex justify-evenly col-span-${
                  order.Taxes.length === 0 ? 1 : order.Taxes.length
                } h-full`}
              >
                {orderCarrier.OrderCarrierTaxes.map((carrierTax) => (
                  <div className="text-[#181143] w-full  text-center flex flex-row justify-center h-full items-center relative text-xs">
                    <div className="text-xs font-semibold">
                      <div>
                        {carrierTax.total === 0 ? (
                          <p>0</p>
                        ) : (
                          <p>{carrierTax.total}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center">-</div>
            )}
            <input
              type="hidden"
              id="carrier_id"
              defaultValue={orderCarrier.id}
              {...registerEditOrderShipping('carrier_id')}
            />
            <div>
              <button
                type="submit"
                className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white w-full"
              >
                Edit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  const editOrderFeeModal = (orderFee) => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">Edit Fee</h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setEditOrderFee('')}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <form onSubmit={handleSubmitEditOrderfee(hundleEditOrderFee)}>
          <div
            className="items-center justify-between"
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${
                order.Taxes.length !== 0 ? 4 + order.Taxes.length : 5
              }, minmax(0, 1fr))`,
            }}
          >
            {' '}
            <div className="col-span-2 text-[#181143] pb-4 text-start text-sm">
              Fee name {orderFee.name}
            </div>
            <div className="col-span-1 text-[#181143] text-center  pb-4 text-sm">
              Cost
            </div>
            <div
              className=" text-[#181143]  text-sm pt-1"
              style={{
                gridColumn: `span ${
                  order.Taxes.length !== 0 ? order.Taxes.length : 1
                }`,
                justifyItems: 'center',
                textAlign: 'center',
                display: 'grid',
                gridTemplateColumns: `repeat(${
                  order.Taxes.length !== 0 ? order.Taxes.length : 1
                }, minmax(0, 1fr))`,
              }}
            >
              <div
                className={`col-span-${
                  order.Taxes.length !== 0 ? order.Taxes.length : 1
                }`}
              >
                Tax
              </div>

              {orderFee.OrderFeesTaxes.slice(0, 2).map((feeTax) => (
                <div className="text-center">{feeTax.Tax.TaxLangs[0].name}</div>
              ))}
            </div>
            <div className="col-span-1" />
            <div className="col-span-2 text-start items-center space-x-4">
              <div>
                <input
                  type="text"
                  className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-center mx-auto my-auto self-end"
                  defaultValue={orderFee.name}
                  {...registerEditOrderfee('name', {
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-span-1 flex justify-center relative">
              <input
                type="text"
                id="quantity"
                className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-center mx-auto my-auto self-end"
                defaultValue={orderFee.total}
                {...registerEditOrderfee('total', {
                  pattern: {
                    value: /^(?!0+(\.0{1,3})?$)^\d+(\.\d{1,3})?$/,
                    message: 'enter a valid format',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                  maxLength: {
                    value: 255,
                    message: 'maximum characters are 255 !',
                  },
                })}
              />
              {errorsEditOrderfee.total && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsEditOrderfee.total?.message}
                </p>
              )}
            </div>
            {order.Taxes.length !== 0 ? (
              <div
                className={`flex justify-evenly col-span-${
                  order.Taxes.length === 0 ? 1 : order.Taxes.length
                } h-full`}
              >
                {orderFee.OrderFeesTaxes.map((feeTax) => (
                  <div className="text-[#181143]  text-center flex flex-row  w-full justify-center h-full items-center relative text-xs">
                    <div className="text-xs font-semibold">
                      <div>
                        {feeTax.total === 0 ? <p>0</p> : <p>{feeTax.total}</p>}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center">-</div>
            )}
            <input
              type="hidden"
              defaultValue={orderFee.id}
              {...registerEditOrderfee('fee_id')}
            />
            <div>
              <button
                type="submit"
                className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white w-full"
              >
                Edit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  const editOrderProductModal = (orderDetail) => (
    <>
      {' '}
      {order.Shop.type === 'woocommerce' && (
        <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
          <div className="w-[60%] bg-white rounded-xl p-4 flex flex-col space-y-2">
            <div className="flex justify-between">
              {' '}
              <h2 className="p-2 font-semibold text-[#EC1577]">Edit Product</h2>
              <button
                type="button"
                className="p-2"
                onClick={() => setEditOrderProduct('')}
              >
                X
              </button>
            </div>

            <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
            <form
              onSubmit={handleSubmitEditOrderProduct(hundleEditOrderProduct)}
            >
              <div
                className="items-center justify-between"
                style={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(${
                    order.Taxes.length !== 0 ? 6 + order.Taxes.length : 7
                  }, minmax(0, 1fr))`,
                }}
              >
                {' '}
                <div className="col-span-3 text-[#181143] text-start text-sm pb-4">
                  Product
                </div>
                <div className="col-span-1 text-[#181143] text-center text-sm pb-4">
                  quantity
                </div>
                <div className="col-span-1 text-[#181143] text-center  text-sm pb-4">
                  Total
                </div>
                <div
                  className="text-sm pt-1"
                  style={{
                    gridColumn: `span ${
                      order.Taxes.length !== 0 ? order.Taxes.length : 1
                    }`,
                    justifyItems: 'center',
                    textAlign: 'center',
                    display: 'grid',
                    gridTemplateColumns: `repeat(${
                      order.Taxes.length !== 0 ? order.Taxes.length : 1
                    }, minmax(0, 1fr))`,
                  }}
                >
                  <div
                    className={`col-span-${
                      order.Taxes.length !== 0 ? order.Taxes.length : 1
                    }`}
                  >
                    Tax
                  </div>

                  {orderDetail.OrderDetailsTaxes.slice(0, 2).map(
                    (productTax) => (
                      <div className="text-center">
                        {productTax.Tax.TaxLangs[0].name}
                      </div>
                    )
                  )}
                </div>
                <div className="col-span-1" />
                <div className="col-span-3 flex justify-start text-center items-center space-x-4">
                  <div>
                    <img
                      src={`${apiURL}/images/${order.shop_id}/${orderDetail.Product.ProductShops[0].foreign_id}/${orderDetail.Product.default_image_id}`}
                      alt={orderDetail.product_name}
                      className=" w-24"
                    />
                  </div>
                  <div className="text-xs"> {orderDetail.product_name}</div>
                </div>
                <div className="col-span-1 relative flex justify-center">
                  <input
                    type="text"
                    id="quantity"
                    className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[90%] text-xs font-semibold text-center mx-auto my-auto self-end"
                    defaultValue={orderDetail.product_quantity}
                    {...registerEditOrderProduct('quantity', {
                      pattern: {
                        value: /^(?:10000|[0-9]{1,4})$/,
                        message: 'Please enter a valid  number',
                      },
                      required: {
                        value: true,
                        message: 'This field is required !',
                      },
                    })}
                  />
                  {errorsEditOrderProduct.quantity && (
                    <p
                      className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errorsEditOrderProduct.quantity?.message}
                    </p>
                  )}
                </div>
                <div className="col-span-1 flex justify-center relative">
                  <input
                    type="text"
                    id="quantity"
                    className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[90%] text-xs font-semibold text-center mx-auto my-auto self-end"
                    defaultValue={orderDetail.product_price}
                    {...registerEditOrderProduct('total', {
                      pattern: {
                        value: /^(?!0+(\.0{1,3})?$)^\d+(\.\d{1,3})?$/,
                        message: 'enter a valid format',
                      },
                      required: {
                        value: true,
                        message: 'This field is required !',
                      },
                      maxLength: {
                        value: 255,
                        message: 'maximum characters are 255 !',
                      },
                    })}
                  />
                  {errorsEditOrderProduct.total && (
                    <p
                      className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errorsEditOrderProduct.total?.message}
                    </p>
                  )}
                </div>
                {order.Taxes.length !== 0 ? (
                  <div
                    className={`flex justify-evenly col-span-${
                      order.Taxes.length === 0 ? 1 : order.Taxes.length
                    } h-full`}
                  >
                    {orderDetail.OrderDetailsTaxes.map((productTax) => (
                      <div className="text-[#181143]  text-center w-full flex flex-row justify-center h-full items-center relative text-xs">
                        <div className="text-xs font-semibold">
                          <div>
                            {productTax.total === 0 ? (
                              <p className="w-full">0</p>
                            ) : (
                              <p>{productTax.total}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center">-</div>
                )}
                <input
                  type="hidden"
                  id="order_detail_foreign_id"
                  {...registerEditOrderProduct('order_detail_foreign_id')}
                  defaultValue={orderDetail.foreign_id}
                />
                <input
                  type="hidden"
                  id="product_id"
                  {...registerEditOrderProduct('product_id')}
                  defaultValue={orderDetail.product_id}
                />
                <div>
                  <button
                    type="submit"
                    className="rounded-xl bg-[#EC1577] w-24 p-2 col-span-1 text-sm text-white"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}{' '}
      {order.Shop.type === 'prestashop' && (
        <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
          <div className="w-[60%] bg-white rounded-xl p-4 flex flex-col space-y-2">
            <div className="flex justify-between">
              {' '}
              <h2 className="p-2 font-semibold text-[#EC1577]">Edit Product</h2>
              <button
                type="button"
                className="p-2"
                onClick={() => setEditOrderProduct('')}
              >
                X
              </button>
            </div>

            <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
            <form
              onSubmit={handleSubmitEditOrderProduct(hundleEditOrderProduct)}
            >
              <div
                className="items-center justify-between"
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
                }}
              >
                {' '}
                <div className="col-span-3 text-[#181143] text-start text-sm pb-2">
                  Product
                </div>
                <div className="col-span-1 text-[#181143] text-center text-sm pb-2">
                  quantity
                </div>
                <div className="col-span-1 text-[#181143] text-center  text-sm pb-2">
                  Total
                </div>
                <div
                  className="text-sm pt-1"
                  style={{
                    gridColumn: `span ${
                      order.Taxes.length !== 0 ? order.Taxes.length : 1
                    }`,
                    justifyItems: 'center',
                    textAlign: 'center',
                    display: 'grid',
                    gridTemplateColumns: `repeat(${
                      order.Taxes.length !== 0 ? order.Taxes.length : 1
                    }, minmax(0, 1fr))`,
                  }}
                >
                  {orderDetail.OrderDetailsTaxes.slice(0, 2).map(
                    (productTax) => (
                      <div className="text-center">
                        {productTax.Tax.TaxLangs[0].name}
                      </div>
                    )
                  )}
                </div>
                <div className="col-span-1" />
                <div className="col-span-3 flex justify-start text-center items-center space-x-4">
                  <div>
                    <img
                      src={`${apiURL}/images/${order.shop_id}/${orderDetail.Product.ProductShops[0].foreign_id}/${orderDetail.Product.default_image_id}`}
                      alt={orderDetail.product_name}
                      className=" w-20"
                    />
                  </div>
                  <div className="text-xs"> {orderDetail.product_name}</div>
                </div>
                <div className="col-span-1 relative flex justify-center">
                  <input
                    type="text"
                    id="quantity"
                    className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[90%] text-xs font-semibold text-center mx-auto my-auto self-end"
                    defaultValue={orderDetail.product_quantity}
                    {...registerEditOrderProduct('quantity', {
                      pattern: {
                        value: /^(?:10000|[0-9]{1,4})$/,
                        message: 'Please enter a valid  number',
                      },
                      required: {
                        value: true,
                        message: 'This field is required !',
                      },
                    })}
                  />
                  {errorsEditOrderProduct.quantity && (
                    <p
                      className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errorsEditOrderProduct.quantity?.message}
                    </p>
                  )}
                </div>
                <div className="col-span-1 flex justify-center relative">
                  {orderDetail.total_price_tax_incl}
                </div>
                <input
                  type="hidden"
                  id="order_detail_foreign_id"
                  {...registerEditOrderProduct('order_detail_foreign_id')}
                  defaultValue={orderDetail.foreign_id}
                />
                <input
                  type="hidden"
                  id="product_id"
                  {...registerEditOrderProduct('product_id')}
                  defaultValue={orderDetail.product_id}
                />
                <div>
                  <button
                    type="submit"
                    className="rounded-xl bg-[#EC1577] w-24 p-2 col-span-1 text-sm text-white"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );

  const hundleEditOrderProduct = (data) => {
    const orderProductData = JSON.stringify({
      quantity: data.quantity,
      order_detail_foreign_id: data.order_detail_foreign_id,
      total: data.total,
      shop_id: order.Shop.id,
    });

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'put',
      url: `${apiURL}/orders/editOrderProduct/${order.id}/${data.product_id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: orderProductData,
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'product updated !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            setTimeout(() => {
              window.location.reload(); // Reloads the current page
            }, 2000); // 2000 milliseconds = 2 seconds
          }, 1500);
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order Status failed , select a new state !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const hundleEditOrderShipping = (data) => {
    const orderShippingData = {
      name: data.name,
      total: data.total,
    };

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'put',
      url: `${apiURL}/orders/editOrderShipping/${order.id}/${data.carrier_id}/${order.Shop.id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: orderShippingData,
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'shipping updated !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order Status failed , select a new state !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const hundleEditOrderFee = (data) => {
    const orderFeeData = {
      name: data.name,
      total: data.total,
    };

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'put',
      url: `${apiURL}/orders/editOrderFee/${order.id}/${data.fee_id}/${order.Shop.id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: orderFeeData,
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'fee updated !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order fee failed to update !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const hundleOrderStatusWooChange = (data) => {
    const orderStatusData = {
      status: data.status === 'draft' ? 'trash' : data.status,
    };

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'put',
      url: `${apiURL}/orders/editOrderStatus/${order.id}/${order.Shop.id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: orderStatusData,
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'Status updated !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order Status failed to update !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  /// ////////////////////////////////////////// DELETE functions woocommerce ////////////////////////////////////////////////////////

  const deleteOrderCarrierModal = (orderCarrier) => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">Delete Shipping</h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setDeleteOrderCarrier('')}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <div className="flex justify-center">
          <div>Are you sure you want to delete {orderCarrier.name} ?</div>
        </div>
        <div className="flex justify-center">
          <form
            className="flex flex-row justify-between w-40"
            onSubmit={handleSubmitDeleteOrderShipping(
              hundleDeleteOrderShipping
            )}
          >
            <input
              type="hidden"
              value={orderCarrier.id}
              {...registerDeleteOrderShipping('orderCarrier_id')}
            />
            <button
              className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white"
              type="button"
            >
              No
            </button>
            <button
              className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white"
              type="submit"
            >
              Yes
            </button>
          </form>
        </div>
      </div>
    </div>
  );

  const deleteOrderProductModal = (orderDetail) => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          <h2 className="p-2 font-semibold text-[#EC1577]">Delete Product</h2>

          <button
            type="button"
            className="p-2"
            onClick={() => setDeleteOrderProduct('')}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <div className="flex justify-center">
          <div>Are you sure you want to delete {orderDetail.product_name}</div>
        </div>
        <div className="flex justify-center">
          <form
            onSubmit={handleSubmitDeleteOrderProduct(hundleDeleteOrderDetail)}
            className="flex flex-row justify-between w-40"
          >
            <input
              type="hidden"
              value={orderDetail.id}
              {...registerDeleteOrderProduct('orderDetail_id')}
            />
            <input
              type="hidden"
              value={orderDetail.ProductId}
              {...registerDeleteOrderProduct('product_id')}
            />
            <button
              className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white"
              type="button"
            >
              No
            </button>
            <button
              className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white"
              type="submit"
            >
              Yes
            </button>
          </form>
        </div>
      </div>
    </div>
  );

  const deleteOrderFeeModal = (orderFee) => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">Delete fee</h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setDeleteOrderFee('')}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <div className="flex justify-center">
          <div>Are you sure you want to delete {orderFee.name}</div>
        </div>
        <div className="flex justify-center">
          <form
            className="flex flex-row justify-between w-40"
            onSubmit={handleSubmitDeleteOrderFee(hundleDeleteOrderFees)}
          >
            <input
              type="hidden"
              {...registerDeleteOrderFee('orderFee_id')}
              value={orderFee.id}
            />
            <button
              className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white"
              type="button"
            >
              No
            </button>
            <button
              className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white"
              type="submit"
            >
              Yes
            </button>
          </form>
        </div>
      </div>
    </div>
  );

  const hundleDeleteOrderDetail = (data) => {
    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'delete',
      url: `${apiURL}/orders/deleteOrderProduct/${order.id}/${data.product_id}/${order.Shop.id}/${data.orderDetail_id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'product deleted !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order Status failed , select a new state !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const hundleDeleteOrderFees = (data) => {
    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'delete',
      url: `${apiURL}/orders/deleteOrderFee/${order.id}/${data.orderFee_id}/${order.Shop.id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'fee deleted !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order Status failed , select a new state !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const hundleDeleteOrderShipping = (data) => {
    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'delete',
      url: `${apiURL}/orders/deleteOrderCarrier/${order.id}/${data.orderCarrier_id}/${order.Shop.id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'Shipping deleted !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order Status failed , select a new state !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  /// //////////////////////////////////////////// adding New funcion woocommerce /////////////////////////////////////

  const addNewProductPrestashopModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[60%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">Add Product</h2>
          <button
            type="button"
            className="p-2 "
            onClick={() => setAddNewProductPresta(false)}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <form onSubmit={handleSubmitOrderProduct(addnewOrderProduct)}>
          <div className="grid grid-cols-7 p-4">
            {' '}
            <div className="col-span-2 h-8 text-[#181143] font-semibold text-sm" />
            <div className="col-span-2 h-8 text-[#181143] text-center font-semibold  pl-4  text-sm">
              Product
            </div>
            <div className="col-span-2 text-[#181143] font-semibold text-center text-sm">
              quantity
            </div>
            <div className="col-span-1" />
            <div className="col-span-2 relative">
              <input
                type="text"
                className=" focus:border-b-2  focus:border-[#EC1577]  h-10 p-1 text-[#181143] w-[100%] text-xs font-semibold mx-auto my-auto focus: border-0  hover:border-0 focus:outline-0"
                onChange={(e) => handleAutoCompleteProducts(e)}
                value={autoCompleteProducts}
                placeholder="Enter the name of the product"
              />
              {matchingProductNames.length !== 0 && (
                <div className="absolute w-[100%] bg-white border-[#AAA6C3]   border-2 max-h-[17rem] overflow-y-scroll  flex flex-col">
                  {matchingProductNames.length !== 0 ? (
                    matchingProductNames.map((product) => (
                      <button
                        type="button"
                        className="text-start hover:bg-[#AAA6C3] pt-2 pb-4 pr-0 pl-1 text-sm "
                        onClick={() =>
                          hundleSelectProductInput(
                            product.Product.ProductLangs[0].name,
                            product.Product.id
                          )
                        }
                        key={product.Product.id}
                      >
                        {product.Product.ProductLangs[0].name}
                      </button>
                    ))
                  ) : (
                    <p>No matching products found.</p>
                  )}
                </div>
              )}
            </div>
            <div className="col-span-2  text-center flex items-center pl-4">
              <div className="text-sm text-[#181143]">
                {selectedProductName === ''
                  ? 'No product selected'
                  : selectedProductName}
              </div>
            </div>
            <div className="col-span-2 flex justify-center relative">
              <input
                type="text"
                id="quantity"
                className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-center mx-auto my-auto self-end"
                defaultValue={1}
                {...registerOrderProduct('product_quantity', {
                  pattern: {
                    value: /^(?:10000|[0-9]{1,4})$/,
                    message: 'Please enter a valid  number',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
              {errorsOrderProduct.product_quantity && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsOrderProduct.product_quantity?.message}
                </p>
              )}
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white w-full"
              >
                create
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  const addnewOrderProduct = (data) => {
    const productData = JSON.stringify({
      quantity: data.product_quantity,
    });

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'post',
      url: `${apiURL}/orders/addNewProduct/${order.id}/${selectedProductId}/${order.Shop.id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: productData,
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'product-added !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'Adding product failed , select a product !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const createNewShippingModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">
            create new shipping
          </h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setCreateNewShipping(false)}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <form onSubmit={handleSubmitCreateOrderShipping(hundleCreateShipping)}>
          <div className="grid grid-cols-4">
            <div className="col-span-2  space-y-2 ">
              <label htmlFor="shipping_name" className="font-semibold text-sm">
                Shipping name
              </label>
              <input
                type="text"
                id="shipping_name"
                className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-start mx-auto my-auto self-end"
                {...registerCreateOrderShipping('name', {
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
            </div>
            <div className="col-span-1 space-y-2  flex flex-col justify-center items-center">
              <label htmlFor="shipping_cost" className="font-semibold text-sm">
                Shipping cost
              </label>
              <div className="relative flex justify-center">
                <input
                  type="text"
                  id="shipping_cost"
                  className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-center mx-auto my-auto self-end"
                  {...registerCreateOrderShipping('total', {
                    pattern: {
                      value: /^(?!0+(\.0{1,3})?$)^\d+(\.\d{1,3})?$/,
                      message: 'enter a valid format',
                    },
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                    maxLength: {
                      value: 255,
                      message: 'maximum characters are 255 !',
                    },
                  })}
                />
                {errorsCreateOrderShipping.total && (
                  <p
                    className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                    ref={(el) =>
                      el &&
                      el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      })
                    }
                  >
                    {errorsCreateOrderShipping.total?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-1 flex flex-col justify-end items-end">
              <button
                type="submit"
                className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white w-full"
              >
                create
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  const createNewFeeModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">create new fee</h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setCreateNewFee(false)}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <form onSubmit={handleSubmitCreateOrderFee(hundleCreateFee)}>
          <div className="grid grid-cols-4">
            <div className="col-span-2 flex flex-col space-y-2 ">
              <label htmlFor="fee_name" className="font-semibold text-sm">
                Fee name
              </label>
              <div>
                <input
                  type="text"
                  id="fee_name"
                  className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-start mx-auto my-auto self-end"
                  {...registerCreateOrderFee('name', {
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-span-1 flex flex-col space-y-2 items-center">
              <label htmlFor="fee_cost" className="font-semibold text-sm">
                Fee cost
              </label>
              <div className="relative flex justify-center">
                <input
                  type="text"
                  id="fee_cost"
                  className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[80%] text-xs font-semibold text-center mx-auto my-auto self-end"
                  {...registerCreateOrderFee('total', {
                    pattern: {
                      value: /^(?!0+(\.0{1,3})?$)^\d+(\.\d{1,3})?$/,
                      message: 'enter a valid format',
                    },
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                    maxLength: {
                      value: 255,
                      message: 'maximum characters are 255 !',
                    },
                  })}
                />
                {errorsCreateOrderFee.total && (
                  <p
                    className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                    ref={(el) =>
                      el &&
                      el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      })
                    }
                  >
                    {errorsCreateOrderFee.total?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-1 flex flex-col justify-end items-end">
              <button
                type="submit"
                className="rounded-xl bg-[#EC1577] p-2 col-span-1 text-sm text-white w-full"
              >
                create
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  const hundleCreateShipping = (data) => {
    const orderShippingData = {
      name: data.name,
      total: data.total,
    };

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'post',
      url: `${apiURL}/orders/addNewShipping/${order.id}/${order.Shop.id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: orderShippingData,
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'Shipping created !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order shipping failed to create !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const hundleCreateFee = (data) => {
    const orderFeeData = {
      name: data.name,
      total: data.total,
    };

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'post',
      url: `${apiURL}/orders/addNewFee/${order.id}/${order.Shop.id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: orderFeeData,
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'fee created !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order fee failed to create !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  /// ///////////////////////////////////////////////////////////////////////////////////
  const latestOrderStateDate =
    Array.isArray(order.OrderHistories) === true &&
    order.OrderHistories.reduce((latestOrder, currentOrder) => {
      const currentDate = new Date(currentOrder.date_add);
      if (currentDate > latestOrder.date) {
        return currentOrder;
      }
      return latestOrder;
    }, order.OrderHistories[0]);

  const getMostRecentOrder = () => {
    if (!order || !order.OrderHistories || order.OrderHistories.length === 0) {
      return null; // Return null if order or OrderHistories is invalid or empty
    }

    let mostRecentOrder = order.OrderHistories[0];

    for (let i = 1; i < order.OrderHistories.length; i += 1) {
      const currentOrder = order.OrderHistories[i];
      const currentDate = new Date(currentOrder.date_add);
      const mostRecentDate = new Date(mostRecentOrder.date_add);

      if (currentDate > mostRecentDate) {
        mostRecentOrder = currentOrder;
      }
    }

    return (
      <div>
        <div className="space-y-3">
          <div
            className="flex flex-row justify-between  rounded-xl p-1 text-white"
            style={{ backgroundColor: mostRecentOrder.OrderState.color }}
          >
            <div>{mostRecentOrder.OrderState.name}</div>
            <div>
              {mostRecentOrder.date_add.replace('.000Z', '').replace('T', ' ')}
            </div>
          </div>
        </div>
        <div>
          {/* <p className="text-[#EC1577] font-bold text-[20px]">State history:</p> */}
        </div>
        <div className="flex flex-col space-y-2">
          {order.OrderHistories.slice() // Create a shallow copy of the array
            .sort((a, b) => b.foreign_id - a.foreign_id) // Sort by foreign_id in descending order
            .filter((item) => item.id !== mostRecentOrder.id)
            .map((orderHistory) => {
              const { color } = orderHistory.OrderState;
              const divStyle = {
                backgroundColor: color,
              };
              return (
                <div
                  className="flex flex-row justify-between rounded-xl p-1 text-white bg-[#E1DFEE]"
                  // style={divStyle}
                >
                  <div>{orderHistory.OrderState.name}</div>
                  <div>
                    {orderHistory.date_add
                      .replace('.000Z', '')
                      .replace('T', ' ')}
                  </div>
                </div>
              );
            })}
        </div>
        <form action="" className="mt-2">
          <div className="flex flex-row justify-between">
            <div className="w-[50%]">
              <select
                className="essentialSelect "
                id="message"
                name="order_state"
                onChange={handleOrderStateChange}
              >
                <option selected disabled value={mostRecentOrder.OrderState.id}>
                  {mostRecentOrder.OrderState.name}
                </option>
                {orderStates.length > 0 ? (
                  <>
                    {orderStates
                      .filter(
                        (orderState) =>
                          orderState.id !== mostRecentOrder.OrderState.id
                      )
                      .map((orderState, i) => (
                        <option value={orderState.id} key={i}>
                          {orderState.name}
                        </option>
                      ))}
                  </>
                ) : (
                  <option>No State available</option>
                )}
              </select>
            </div>
            <button
              type="button"
              className="addButton w-[15rem] disabled:bg-[#E1DFEE]"
              disabled={formData.order_state_id === null}
              onClick={handleOrderStateFormSubmit}
            >
              Update status
            </button>
          </div>
        </form>
      </div>
    );
  };

  const handleOrderStateChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      order_state_id: value,
    });
  };
  const handleOrderStateFormSubmit = () => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    const data = JSON.stringify({
      order_state_id: parseInt(formData.order_state_id),
      order_id: parseInt(order.id),
      date_add: formattedDate,
      shop_id: order.shop_id,
    });

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'post',
      url: `${apiURL}/orders/orderState`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    api
      .request(config)
      .then((response) => {
        if (response.data === 'success') {
          toast.update(idToast, {
            render: 'order Status has changed !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'order Status failed , select a new state !',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const handleOrderPaymentFormsubmit = (data) => {
    const dataPayment = JSON.stringify({
      shop_id: order.shop_id,
      currency_id: data.currency_id,
      date_add: data.date_add,
      amount: data.amount,
      payment_method: data.payment_method,
      transaction_id: data.transaction_id,
    });

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'post',
      url: `${apiURL}/orders/addNewPayment/${order.id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: dataPayment,
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'New Payment Is Created !',
          type: response.data === 'success',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setTimeout(() => {
          window.location.reload(); // Reloads the current page
        }, 2000); // 2000 milliseconds = 2 seconds
      })
      .catch((error) => {});
  };

  const handleShowPaymentDetails = (paymentId) => {
    setSelectedPaymentIds((prevSelectedPaymentIds) => {
      const updatedSelectedPaymentIds = { ...prevSelectedPaymentIds };

      if (updatedSelectedPaymentIds[paymentId]) {
        delete updatedSelectedPaymentIds[paymentId];
      } else {
        updatedSelectedPaymentIds[paymentId] = true;
      }

      return updatedSelectedPaymentIds;
    });
  };

  const showPaymentDetails = (paymentId) => {
    if (selectedPaymentIds[paymentId]) {
      const selectedPayment = order.OrderPayments.find(
        (orderPayment) => orderPayment.id === paymentId
      );

      return (
        <div className="flex flex-col col-span-9 border-t-1 border-[#E1DFEE] text-[#181143] text-xs p-2 space-y-2">
          <div className="flex space-x-2">
            <p className="font-semibold">card brand</p>:{' '}
            <div>
              {selectedPayment.card_brand === null ? (
                <>not defined</>
              ) : (
                selectedPayment.card_brand
              )}
            </div>
          </div>
          <div className="flex space-x-2">
            <p className="font-semibold">card expiration</p>:{' '}
            <div>
              {selectedPayment.card_expiration === null ? (
                <>not defined</>
              ) : (
                selectedPayment.card_expiration
              )}
            </div>
          </div>
          <div className="flex space-x-2">
            <p className="font-semibold">card holder</p>:{' '}
            <div>
              {selectedPayment.card_holder === null ? (
                <>not defined</>
              ) : (
                selectedPayment.card_holder
              )}
            </div>
          </div>
          <div className="flex space-x-2">
            <p className="font-semibold">card number</p>:{' '}
            <div>
              {selectedPayment.card_number === null ? (
                <>not defined</>
              ) : (
                selectedPayment.card_number
              )}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const handleTotalorderPayment = () => {
    let total = 0;
    order.OrderPayments.map((orderPayment) => {
      if (order.conversion_rate !== orderPayment.Currency.conversion_rate) {
        // const rate = orderPayment.Currency.conversion_rate * 10;

        total += orderPayment.amount * orderPayment.Currency.conversion_rate;
      } else {
        total += orderPayment.amount;
      }
      return null;
    });

    return (
      parseFloat(total).toFixed(2) !==
        parseFloat(order.total_paid).toFixed(2) && (
        <div className="bg-[#EC1577] p-2 rounded-xl text-white flex justify-start items-center">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="h-7 w-18  text-[#181143] pointer-events-none scale-100 mr-1"
          />
          <p className="text-[#181143] ">
            {parseFloat(total).toFixed(2) !==
            parseFloat(order.total_paid).toFixed(2) ? (
              <div>
                <span>Warning</span>{' '}
                <span>
                  {parseFloat(total).toFixed(order.Currency.precision)}
                  {order.Currency.CurrencyLangs[0].symbol} paid instead of{' '}
                  {parseFloat(order.total_paid).toFixed(
                    order.Currency.precision
                  )}{' '}
                  {order.Currency.CurrencyLangs[0].symbol}
                </span>
              </div>
            ) : null}
          </p>
        </div>
      )
    );
  };

  const handleEditOrderCarrier = (data) => {
    const orderCarrierData = JSON.stringify({
      shop_id: order.shop_id,
      tracking_number: data.tracking_number,
      carrier_id: data.carrier_id,
      weigh: order.OrderCarriers[0].weigh,
      shipping_cost_tax_excl: order.OrderCarriers[0].shipping_cost_tax_excl,
      shipping_cost_tax_incl: order.OrderCarriers[0].shipping_cost_tax_incl,
      date_added: order.OrderCarriers[0].date_added,
    });

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'put',
      url: `${apiURL}/orders/editOrderCarrier/${order.id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: orderCarrierData,
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'New Payment Is Created !',
          type: response.data === 'success',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setTimeout(() => {
          window.location.reload(); // Reloads the current page
        }, 2000); // 2000 milliseconds = 2 seconds
      })
      .catch((error) => {});
  };

  const handlePrivateChange = (e) => {
    setPrivateChecked(e.target.checked);
  };

  const submitCustomerMessage = (data) => {
    if (order.Shop.type === 'prestashop') {
      const customerMessageData = JSON.stringify({
        message: data.customer_message,
        private: data.private,
        shop_id: order.shop_id,
        user_id: user.id,
        ip_address: '',
        file_name: '',
        user_agent: '',
        read: 0,
      });

      const idToast = toast.loading('Please wait...');
      const config = {
        method: 'post',
        url: `${apiURL}/orders/createOrderMessage/${order.id}/${order.lang_id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: customerMessageData,
      };
      api
        .request(config)
        .then((response) => {
          toast.update(idToast, {
            render: 'New message Is sent !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        })
        .catch((error) => {});
    }

    if (order.Shop.type === 'woocommerce') {
      const dataNote = {
        order_note: data.customer_message,
        is_private: data.private !== null ? data.private : true,
      };

      const idToast = toast.loading('Please wait...');
      const config = {
        method: 'post',
        url: `${apiURL}/orders/note/woocommerce/${order.id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: dataNote,
      };
      api
        .request(config)
        .then((response) => {
          toast.update(idToast, {
            render: response.data,
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        })
        .catch((error) => {});
    }
  };

  const handleNumberOfValidatedOrders = () => {
    let numberOfOrders = 0;

    order.Customer.Orders.forEach((CustomerOrder) => {
      const mostRecentPaidHistory = CustomerOrder.OrderHistories.sort(
        (a, b) => new Date(b.date_add) - new Date(a.date_add)
      ).slice(0, 1)[0];

      if (
        mostRecentPaidHistory &&
        mostRecentPaidHistory.OrderState.paid === true
      ) {
        numberOfOrders += 1;
      }
    });

    return numberOfOrders;
  };

  const handleOnchangeSearchProduct = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setProductInput(searchTerm);
  };

  const sumOrderPayment = () => {
    const amountsArray = order.OrderPayments.map(
      (orderPayment) => orderPayment.amount
    );

    return amountsArray.reduce((total, num) => total + num, 0);
  };

  const handleModalChangeOrderCustomerWoocommerce = () => (
    <div className="z-40 mt-0 pb-[10vw]  fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%]   bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">change customer</h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setCustomerModal(false)}
          >
            X
          </button>
        </div>
        <div className="flex justify-between gap-4">
          <select
            className="essentialSelect  w-[80%]"
            onChange={(e) => setSelectedCustomerId(e)}
          >
            <option value={order.Customer.id}>
              {order.Customer.first_name} {order.Customer.last_name} (
              {order.Customer.email})
            </option>
            {customers
              .filter((customer) => customer.id !== order.customer_id)
              .map((customer, i) => (
                <option value={customer.id} key={i}>
                  {customer.first_name} {customer.last_name} ({customer.email})
                </option>
              ))}
          </select>
          <button
            type="button"
            className="bg-[#EC1577] p-2 w-[14rem] text-white rounded-3xl"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );

  if (order.Shop.type === 'prestashop') {
    return (
      <>
        <div className="w-[95%] sticky top-[5.5rem] md:ml-[3%] ml-[3.5%] z-40">
          <div className="filter-by-shop mb-5 relative md:w-full w-[22rem]">
            <div className="bg-[#E1DFEE] border border-transparent text-[#EC1577] text-sm rounded-[0.8rem] flex justify-center items-center w-full dark:placeholder-gray-400 h-10 p-2 shadow-lg text-center">
              <div className="flex items-center">
                <BsShopWindow className="h-[1.5rem] w-[1.5rem] text-shifti-blue text-opacity-70 checked-1 transition" />
                <span className="ml-2">{order.Shop.name}</span>
              </div>
              {/* Add additional elements here */}
            </div>
          </div>
        </div>
        <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl flex flex-col space-y-5 ">
          <div className="title-info-container flex flex-row justify-between items-center">
            <h1 className="flex space-x-4">
              <p className="text-[#EC1577] text-4xl font-semibold">Order</p>
              <div className="font-semibold text-4xl text-[#181143]">
                {' '}
                #{order.foreign_id} {order.reference}
              </div>
            </h1>
            <div className="customer-info text-[20px] text-[#181143] font-medium">
              from{' '}
              {order.Customer && (
                <>
                  {order.Customer.first_name} {order.Customer.last_name}
                </>
              )}
            </div>
            <div className="bg-white text-yellow-400 rounded-lg text-center self-center p-2 shadow-sm font-medium">
              {parseFloat(order.total_paid).toFixed(order.Currency.precision)}{' '}
              {order.Currency.CurrencyLangs[0].symbol}
            </div>
            <div className="font-medium">
              {order.createdAt.replace('.000Z', '').replace('T', ' ')}
            </div>
            <div>
              <button
                className="bg-[#EC1577] rounded-[25px] text-[#fff] h-[37px] shadow-lg w-[7rem]"
                type="button"
              >
                help
              </button>
            </div>
          </div>

          <div className="grid grid-cols-7 content-box space-x-5">
            <div className="col-span-2 flex flex-col left-box space-y-5">
              {order.Customer ? (
                <div className="md:mt-2 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
                  <h2 className="font-semibold text-[20px] text-[#EC1577]">
                    Customer
                  </h2>
                  <div className="info grid grid-cols-4 justify-items-evenly col-span-1">
                    <div className="bg-[#EC1577] rounded-full w-16 h-16 relative shadow-xl self-center">
                      <FontAwesomeIcon
                        icon={faUser}
                        className=" left-[10%] top-[5%] scale-100  h-[3rem] w-[3rem]  text-[#fff] pointer-events-none absolute "
                      />
                    </div>
                    <div className="flex flex-col col-span-2 items-start">
                      <p className="font-medium text-lg text-[#181143] text-start">
                        {order.Customer.first_name} {order.Customer.last_name}
                      </p>
                      <p className="text-start sm">{order.Customer.email}</p>
                      <button
                        type="button"
                        className="font-semibold text-xs text-yellow-400"
                      >
                        View more details
                      </button>
                    </div>
                    <div className="col-span-1 text-[#AAA6C3]">
                      #{order.Customer.id}
                    </div>
                  </div>
                  <div>
                    <p className="font-semibold text-md text-[#181143]">
                      Account registered:
                    </p>
                    <p className="text-sm">
                      {order.Customer.createdAt
                        .replace('.000Z', '')
                        .replace('T', ' ')}
                    </p>
                  </div>
                  <div>
                    <p className="font-semibold text-md text-[#181143]">
                      Validated orders placed:
                    </p>
                    <p className="text-sm">
                      {' '}
                      {handleNumberOfValidatedOrders()}
                    </p>
                  </div>
                  <div>
                    <p className="font-semibold text-md text-[#181143]">
                      Total spent since registration:
                    </p>
                  </div>
                  <div className="address-box flex flex-row justify-between p-0 2xl:p-1 space-x-5">
                    <div className="left-box flex flex-col">
                      <p className="font-semibold text-sm text-[#181143] mb-1">
                        Shipping address
                      </p>
                      <p className="text-sm">
                        {order.delivery_address.first_name}

                        {order.delivery_address.last_name}
                      </p>
                      <p className="text-sm">
                        {order.delivery_address.address1}
                      </p>
                      <p className="text-sm">
                        {order.delivery_address.city},{' '}
                        {order.delivery_address.address1},{' '}
                        {order.delivery_address.postcode}
                      </p>
                      <p className="text-sm">
                        {order.delivery_address.Country.CountryLangs[0].name}
                      </p>
                    </div>
                    <div className="right-box flex flex-col">
                      <p className="font-semibold text-sm text-[#181143] mb-1">
                        Invoice address
                      </p>
                      <p className="text-sm">
                        {order.invoice_address.first_name}

                        {order.invoice_address.last_name}
                      </p>
                      <p className="text-sm">
                        {order.invoice_address.address1}
                      </p>
                      <p className="text-sm">
                        {order.invoice_address.city},{' '}
                        {order.invoice_address.address1},{' '}
                        {order.invoice_address.postcode}
                      </p>
                      <p className="text-sm">
                        {order.invoice_address.Country.CountryLangs[0].name}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="md:mt-2 mt-24 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
                <h2 className="font-semibold text-[20px] text-[#EC1577]">
                  Messages (
                  {order.Shop.type === 'prestashop' &&
                  order.OrderMessages?.length === 1 &&
                  order.CustomerThread?.CustomerMessages?.length === 0
                    ? 1
                    : order.OrderMessages &&
                      order.CustomerThread &&
                      order.CustomerThread.CustomerMessages &&
                      order.OrderMessages.length +
                        order.CustomerThread.CustomerMessages.length -
                        1}
                  )
                </h2>
                <div className="message-container w-full sm:space-y-3">
                  <div className="hi">
                    <div className="border-2 border-[#AAA6C3] rounded-xl p-2 space-y-2">
                      {order.CustomerThread !== null
                        ? order.CustomerThread.CustomerMessages.sort(
                            (a, b) =>
                              new Date(b.date_add) - new Date(a.date_add)
                          )
                            .slice(0, 2)
                            .map((message) => (
                              <div className="flex flex-col   text-xs ">
                                <div className="flex justify-start items-center">
                                  <FontAwesomeIcon
                                    icon={faCalendarDays}
                                    className="h-7 w-18  text-[#AAA6C3] pointer-events-none scale-50"
                                  />
                                  <p className="text-[#AAA6C3]">
                                    {message.date_add
                                      .replace('.000Z', '')
                                      .replace('T', ' ')
                                      .substring(0, 10)}
                                  </p>
                                  <p className="m-1">-</p>
                                  <p className="text-[#181143]">
                                    {message.User.first_name}{' '}
                                    {message.User.last_name}
                                  </p>
                                  {message.private === true ? (
                                    <p className="ml-2 bg-[#EC1577] text-white p-1 rounded-xl">
                                      private
                                    </p>
                                  ) : null}
                                </div>
                                <div className="flex items-center text-[#181143]">
                                  <p className="w-2 text-xl ml-1 text-[#AAA6C3]">
                                    |
                                  </p>
                                  <p className="text-center ml-2">
                                    {message.message}
                                  </p>
                                </div>
                              </div>
                            ))
                        : null}
                      {order.OrderMessages.filter(
                        (item) => item.private === false
                      ).map((orderMessage) =>
                        order.CustomerThread !== null
                          ? order.CustomerThread.CustomerMessages.length <
                              2 && (
                              <div className="flex flex-col   text-xs ">
                                <div className="flex justify-start items-center">
                                  <FontAwesomeIcon
                                    icon={faCalendarDays}
                                    className="h-7 w-18  text-[#AAA6C3] pointer-events-none scale-50"
                                  />
                                  <p className="text-[#AAA6C3]">
                                    {orderMessage.date_add
                                      .replace('.000Z', '')
                                      .replace('T', ' ')
                                      .substring(0, 10)}
                                  </p>
                                  <p className="m-1">-</p>
                                  <p className="text-[#181143]">
                                    {orderMessage.Customer.first_name}{' '}
                                    {orderMessage.Customer.last_name}
                                  </p>
                                  {orderMessage.private !== true ? (
                                    <p className="ml-2 bg-[#EC1577] text-white p-1 rounded-xl">
                                      private
                                    </p>
                                  ) : null}
                                </div>
                                <div className="flex items-center text-[#181143]">
                                  <p className="w-2 text-xl ml-1 text-[#AAA6C3]">
                                    |
                                  </p>
                                  <p className="text-center ml-2">
                                    {orderMessage.message}
                                  </p>
                                </div>
                              </div>
                            )
                          : null
                      )}
                      {showMoreMessages === true && (
                        <div className="space-y-2">
                          {order.CustomerThread !== null &&
                          order.CustomerThread.CustomerMessages !== null
                            ? order.CustomerThread.CustomerMessages.sort(
                                (a, b) =>
                                  new Date(b.date_add) - new Date(a.date_add)
                              )
                                .slice(2)
                                .map((message) => (
                                  <div className="flex flex-col   text-xs ">
                                    <div className="flex justify-start items-center">
                                      <FontAwesomeIcon
                                        icon={faCalendarDays}
                                        className="h-7 w-18  text-[#AAA6C3] pointer-events-none scale-50"
                                      />
                                      <p className="text-[#AAA6C3]">
                                        {message.date_add
                                          .replace('.000Z', '')
                                          .replace('T', ' ')
                                          .substring(0, 10)}
                                      </p>
                                      <p className="m-1">-</p>
                                      <p className="text-[#181143]">
                                        {message.User.first_name}{' '}
                                        {message.User.last_name}
                                      </p>
                                      {message.private === true ? (
                                        <p className="ml-2 bg-[#EC1577] text-white p-1 rounded-xl">
                                          private
                                        </p>
                                      ) : null}
                                    </div>
                                    <div className="flex items-center text-[#181143]">
                                      <p className="w-2 text-xl ml-1 text-[#AAA6C3]">
                                        |
                                      </p>
                                      <p className="text-center ml-2">
                                        {message.message}
                                      </p>
                                    </div>
                                  </div>
                                ))
                            : null}
                          {order.OrderMessages.filter(
                            (item) => item.private === false
                          ).map((orderMessage) =>
                            order.CustomerThread !== null ? (
                              <div className="flex flex-col   text-xs ">
                                <div className="flex justify-start items-center">
                                  <FontAwesomeIcon
                                    icon={faCalendarDays}
                                    className="h-7 w-18  text-[#AAA6C3] pointer-events-none scale-50"
                                  />
                                  <p className="text-[#AAA6C3]">
                                    {orderMessage.date_add
                                      .replace('.000Z', '')
                                      .replace('T', ' ')
                                      .substring(0, 10)}
                                  </p>
                                  <p className="m-1">-</p>
                                  <p className="text-[#181143]">
                                    {orderMessage.Customer.first_name}{' '}
                                    {orderMessage.Customer.last_name}
                                  </p>
                                  {orderMessage.private !== true ? (
                                    <p className="ml-2 bg-[#EC1577] text-white p-1 rounded-xl">
                                      private
                                    </p>
                                  ) : null}
                                </div>
                                <div className="flex items-center text-[#181143]">
                                  <p className="w-2 text-xl ml-1 text-[#AAA6C3]">
                                    |
                                  </p>
                                  <p className="text-center ml-2">
                                    {orderMessage.message}
                                  </p>
                                </div>
                              </div>
                            ) : null
                          )}
                        </div>
                      )}
                      {order.CustomerThread !== null
                        ? order.CustomerThread.CustomerMessages.length > 2 && (
                            <button
                              type="button"
                              className="text-xs text-[#EC1577] font-semibold"
                              onClick={() =>
                                showMoreMessages === false
                                  ? setShowMoreMessages(true)
                                  : setShowMoreMessages(false)
                              }
                            >
                              {showMoreMessages === false
                                ? 'Show more'
                                : 'Show less'}
                            </button>
                          )
                        : null}
                    </div>
                  </div>
                </div>
                <form
                  onSubmit={handleSubmitCustomerMessage(submitCustomerMessage)}
                  className="flex flex-col space-y-5 "
                >
                  <div className="relative flex items-center mr-3 w-[40rem]">
                    <input
                      className="permission-checkbox h-8 w-8 rounded-full	outline-0	appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
                      type="checkbox"
                      id="display"
                      {...registerCustomerMessage('private')}
                      checked={privateChecked}
                      onChange={handlePrivateChange}
                    />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="h-5 w-16  text-[#EC1577] absolute -left-[2.5%] pointer-events-none text-opacity-0 checked-1 transition scale-75"
                    />
                    <label
                      htmlFor="display"
                      className="text-[#181143] text-lg  font-semibold ml-3"
                    >
                      Display to customer
                    </label>
                  </div>
                  <div className="message-container w-full sm:space-y-3">
                    <div className="label-container">
                      <label
                        htmlFor="message-box"
                        className="text-[#181143] pt-3 text-lg  font-semibold"
                      >
                        Message
                      </label>
                    </div>
                    <div className="input-container">
                      {' '}
                      <textarea
                        type="text"
                        className="bg-[#fff] border-2 border-[#AAA6C3] text-[#AAA6C3] text-sm rounded-[0.8rem] block w-full sm:w-12/12 dark:placeholder-gray-400 h-[10rem] p-2 max-h-80"
                        id="message-box"
                        {...registerCustomerMessage('customer_message', {
                          required: {
                            value: true,
                            message: 'This field is required',
                          },
                          maxLength: {
                            value: 512,
                            message: 'message is too long!',
                          },
                        })}
                      />
                      {errorsCustomerMessage.customer_message && (
                        <span className="error-message text-[#EC1577]  w-full text-xs">
                          {errorsCustomerMessage.customer_message?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row self-end">
                    <button
                      type="submit"
                      className="bg-[#EC1577] rounded-[25px] text-[#fff] h-[37px] shadow-lg w-[14rem] "
                    >
                      send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-span-5 flex flex-col right-box space-y-5 ">
              <div className="md:mt-2 mt-24 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
                {/* products */}
                {handleProducts()}
                <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
                <div className="flex flex-row justify-end space-x-5">
                  <button
                    className="pt-[-0.5rem] bg-[#EC1577] text-white p-2 rounded-3xl  w-[12rem]"
                    type="button"
                    onClick={() => setAddNewProductPresta(true)}
                  >
                    <Link
                      className="p-2"
                      style={{
                        background: '#ffffff',
                        color: '#18114397',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        padding: '4px 9px',
                        fontSize: '1,20vw',
                        marginLeft: '-10%',
                        marginRight: '2%',
                      }}
                    >
                      +
                    </Link>
                    add new product
                  </button>
                </div>
                {addNewProductPresta === true && addNewProductPrestashopModal()}
                {/* totalProducts */}
                {priceTotalProduct()}
                <p className="font-medium text-sm text-[#E1DFEE] self-center w-[70%]">
                  For this customer group, prices are displayed as: Tax
                  included. Merchandise returns are disabled
                </p>
              </div>
              <div className="md:mt-2 mt-24 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
                <div className="m-2 mt-5 p-2 bg-white rounded-xl w-[100%] border-2 border-[#E1DFEE] ">
                  <div className="divide-x flex justify-between flex-col md:flex-row  ">
                    <button
                      type="button"
                      className="font-semibold text-[#AAA6C3] disabled:text-[#EC1577]"
                      onClick={() => {
                        setStatus(1);
                        setCarriers(0);
                      }}
                      disabled={status === 1}
                    >
                      Status
                    </button>
                    <button
                      type="button"
                      className="ml-5 font-semibold text-[#AAA6C3] disabled:text-[#EC1577]"
                      onClick={() => {
                        setStatus(0);
                        setCarriers(1);
                      }}
                      disabled={carriers === 1}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Carriers
                    </button>
                    <button
                      type="button"
                      className="ml-5 font-semibold text-[#AAA6C3] "
                      disabled
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Merchandise returns
                    </button>
                  </div>
                </div>
                {status === 1 && (
                  <div>
                    <p className="text-[#EC1577] font-bold text-[20px]">
                      Order states :
                    </p>
                    {getMostRecentOrder()}
                  </div>
                )}
                {carriers === 1 && (
                  <div className="grid grid-cols-12 space-y-1">
                    <div className="text-[#AAA6C3] font-medium text-sm  text-start flex justify-start items-center p-2 col-span-2">
                      Date
                    </div>
                    <div className="text-[#AAA6C3] font-medium text-sm  text-start flex justify-start items-center  col-span-2">
                      Carrier
                    </div>
                    <div className="text-[#AAA6C3] font-medium text-sm  text-start flex justify-start items-center  col-span-2">
                      Weight
                    </div>
                    <div className="text-[#AAA6C3] font-medium text-sm  text-start flex justify-start items-center  col-span-2">
                      Shipping cost
                    </div>
                    <div className="text-[#AAA6C3] font-medium text-sm  text-center flex justify-start items-center whitespace-nowrap ooverflow-ellipsis col-span-2">
                      Tracking number
                    </div>
                    <div />

                    {order.OrderCarriers.map((carrier) => (
                      <>
                        <div className="text-start flex justify-start items-center   text-[#181143] text-xs border-t-1 border-[#E1DFEE] p-2 col-span-2">
                          {carrier.date_added
                            .replace('.000Z', '')
                            .replace('T', ' ')}
                        </div>
                        <div className="col-span-2 border-t-1 border-[#E1DFEE] text-xs text-start flex justify-start items-center">
                          {order.Carrier.name}
                        </div>
                        <div className="col-span-2 border-t-1 border-[#E1DFEE] text-xs text-start flex justify-start items-center">
                          {carrier.weight !== null
                            ? carrier.weight
                            : parseFloat(0).toPrecision(4)}{' '}
                          Kg
                        </div>
                        <div className="col-span-2 border-t-1 border-[#E1DFEE] text-xs text-start flex justify-start items-center">
                          {carrier.shipping_cost_tax_incl}{' '}
                          {order.Currency.CurrencyLangs[0].symbol}
                        </div>
                        <div className="col-span-2 border-t-1 border-[#E1DFEE] text-xs text-start flex justify-start items-center">
                          {carrier.tracking_number}{' '}
                        </div>
                        <div className=" border-t-1 border-[#E1DFEE] p-2 pr-0 flex justify-end items-center col-span-2">
                          <button
                            type="button"
                            className="flex justify-center items-center bg-[#EC1577] w-20 h-[2rem] text-xs rounded-xl  text-white "
                            onClick={() => setShowModal(true)}
                          >
                            Edit
                          </button>
                        </div>
                        {showModal === true && (
                          <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center transition delay-150 duration-300 ease-in-out">
                            <div className="w-6/12 bg-white rounded-xl flex flex-col space-5">
                              <div>
                                <h2 className="p-2 font-semibold text-[#EC1577]">
                                  Edit Carrier
                                </h2>
                              </div>
                              <form
                                onSubmit={handleSubmitOrderCarrier(
                                  handleEditOrderCarrier
                                )}
                                className="p-5"
                              >
                                <div className="grid grid-cols-3  place-items-center border-t-1 border-[#E1DFEE] p-5">
                                  <label htmlFor="tracking_number">
                                    Tracking number
                                  </label>
                                  <input
                                    type="text"
                                    id="tracking_number"
                                    className=" essentialInput col-span-2"
                                    {...registerOrderCarrier('tracking_number')}
                                    defaultValue={
                                      order.OrderCarriers[0].tracking_number
                                    }
                                  />
                                  {errorsOrderCarrier.tracking_number && (
                                    <span className="error-message text-[#EC1577]  w-full text-xs">
                                      {
                                        errorsOrderCarrier.tracking_number
                                          ?.message
                                      }
                                    </span>
                                  )}

                                  <label htmlFor="carrier_id" className="mt-4">
                                    carrier
                                  </label>

                                  <select
                                    id="carrier_id"
                                    {...registerOrderCarrier('carrier_id')}
                                    className="essentialSelect col-span-2 mt-4"
                                    defaultValue={order.carrier_id}
                                  >
                                    <option value={order.Carrier.id} selected>
                                      {order.Carrier.name} (
                                      {order.Carrier.CarrierLangs[0].delay})
                                    </option>
                                    {allCarriers
                                      .filter(
                                        (item) => item.id !== order.Carrier.id
                                      )
                                      .map((item) => {
                                        const notAvailableCarrier =
                                          item.CarrierZones.map(
                                            (zone) => zone.zone_id
                                          ).some(
                                            (zone) =>
                                              zone ===
                                              order.delivery_address.Country
                                                .zone_id
                                          );

                                        if (notAvailableCarrier) {
                                          return (
                                            <option value={item.id}>
                                              {item.name}(
                                              {item.CarrierLangs[0].delay})
                                            </option>
                                          );
                                        }
                                        return null;
                                      })}
                                  </select>
                                </div>
                                <div className="p-2 flex justify-evenly border-t-1 border-[#E1DFEE]">
                                  <button
                                    type="button"
                                    className="addButtonBlue w-20"
                                    onClick={() => setShowModal(false)}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="addButton w-20"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </div>

              <div className="md:mt-2 mt-24 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
                <h2 className="font-semibold text-[20px] text-[#EC1577]">
                  Payment ({handleNumberOfOrderPayments(order)})
                </h2>
                <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
                <div className>{handleTotalorderPayment()}</div>
                <div className="table-container">
                  <div className="grid grid-cols-9 max-w-full">
                    <div className="text-[#AAA6C3] font-medium text-sm  text-start flex justify-start items-center p-2 col-span-2 ">
                      Date
                    </div>
                    <div className="text-[#AAA6C3] font-medium text-sm  text-center flex justify-start items-center whitespace-nowrap ooverflow-ellipsis col-span-2">
                      Payment method
                    </div>
                    <div className="text-[#AAA6C3] font-medium whitespace-nowrap ooverflow-ellipsis text-sm text-center flex justify-start items-center col-span-2">
                      Transaction ID
                    </div>
                    <div className="text-[#AAA6C3] font-medium text-sm  text-center flex justify-start items-center col-span-2">
                      Amount
                    </div>
                    {/* <div className="text-[#AAA6C3]  font-medium text-sm  text-center flex justify-start items-center col-span-2">
													Invoice
												</div> */}

                    <div className=" border-t-1 border-[#E1DFEE] p-2 opacity-0" />

                    {order.OrderPayments.slice() // Create a shallow copy of the array
                      .sort(
                        (a, b) => new Date(a.date_add) - new Date(b.date_add)
                      ) // Sort by date_add in descending order
                      .map((orderPayment) => (
                        <>
                          <div className="text-start flex justify-start items-center text-[#181143] text-xs border-t-1 border-[#E1DFEE] p-2 col-span-2">
                            {orderPayment.date_add
                              .replace('.000Z', '')
                              .replace('T', ' ')}
                          </div>
                          <div className="text-start flex justify-start items-center text-[#181143] text-xs border-t-1 border-[#E1DFEE] p-2 col-span-2">
                            {orderPayment.payment_method}
                          </div>
                          <div className="text-start flex justify-start items-center text-[#181143] text-xs border-t-1 border-[#E1DFEE] p-2 col-span-2">
                            {orderPayment.transaction_id}
                          </div>
                          <div className="text-start flex justify-start items-center text-[#181143] text-xs border-t-1 border-[#E1DFEE] p-2 col-span-2">
                            {orderPayment.amount}{' '}
                            {orderPayment.Currency.CurrencyLangs[0].symbol}
                          </div>

                          <div className="border-t-1 border-[#E1DFEE] p-2 pr-0 flex justify-end items-center">
                            <button
                              className="flex justify-center items-center bg-[#EC1577] w-20 h-[2rem] text-xs rounded-xl text-white"
                              type="button"
                              onClick={() =>
                                handleShowPaymentDetails(orderPayment.id)
                              }
                            >
                              <p>
                                <FontAwesomeIcon
                                  icon={faMagnifyingGlass}
                                  className="pointer-events-none scale-100"
                                />
                              </p>
                              <p>Details</p>
                            </button>
                          </div>

                          {showPaymentDetails(orderPayment.id)}
                        </>
                      ))}
                  </div>
                  <form
                    onSubmit={handleSubmit(handleOrderPaymentFormsubmit)}
                    className="grid grid-cols-9 border-t-1 border-[#E1DFEE]"
                  >
                    <div className="input-container flex justify-start items-center col-span-2">
                      <div className="relative">
                        <input
                          className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] text-xs font-semibold text-center mx-auto my-auto"
                          id="searchProduct"
                          type="date"
                          {...register('date_add', {
                            required: {
                              value: true,
                              message: 'This field is required',
                            },
                          })}
                        />
                        {errors.date_add && (
                          <span className="error-message text-[#EC1577] absolute top-[2.5rem] left-0 w-full text-xs">
                            {errors.date_add?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="input-container col-span-2 flex flex-col justify-start items-start pt-1 ">
                      <div className="relative">
                        <input
                          className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 self-start text-[#181143] text-xs font-semibold text-start w-[95%] mx-auto my-auto"
                          id="searchProduct"
                          {...register('payment_method', {
                            required: {
                              value: true,
                              message: 'This field is required',
                            },
                          })}
                          type="text"
                        />
                        {errors.payment_method && (
                          <span className="error-message text-[#EC1577] absolute top-[2.5rem] left-0 w-full text-xs">
                            {errors.payment_method?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="input-container col-span-2 flex justify-start items-center">
                      <div className="relative">
                        <input
                          className="border-2 border-[#AAA6C3] rounded-xl p-1 h-10 self-start text-[#181143]  text-xs  font-semibold text-start w-[95%] mx-auto my-auto"
                          id="searchProduct"
                          {...register('transaction_id', {
                            required: {
                              value: true,
                              message: 'This field is required',
                            },
                          })}
                          type="text"
                        />
                        {errors.transaction_id && (
                          <span className="error-message text-[#EC1577] absolute top-[2.5rem] left-0 w-full text-xs">
                            {errors.transaction_id?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="input-container flex flex-row col-span-2 justify-start items-center p-1 space-x-2 relative">
                      <input
                        className="border-2 border-[#AAA6C3] rounded-xl  h-10 self-start text-[#181143] p-1 text-xs  font-semibold text-start w-[60%]  "
                        id="searchProduct"
                        {...register('amount', {
                          required: {
                            value: true,
                            message: 'This field is required',
                          },
                        })}
                        type="text"
                      />
                      {errors.amount && (
                        <span className="error-message text-[#EC1577] absolute top-[2.8rem] left-0 w-full text-xs">
                          {errors.amount?.message}
                        </span>
                      )}

                      <select
                        className="border-2 border-[#AAA6C3] rounded-xl  h-10 self-start text-[#181143] p-1  text-xs  font-semibold text-start"
                        id="searchProduct"
                        {...register('currency_id', {
                          required: {
                            value: true,
                            message: 'This field is required',
                          },
                        })}
                        type="text"
                        defaultValue={order.currency_id}
                      >
                        {currencies.map((currency) => (
                          <option value={currency.id}>
                            {currency.CurrencyLangs[0].symbol}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="border-t-1 border-[#E1DFEE] p-2 pr-0 flex justify-end items-center">
                      <button
                        className=" bg-[#EC1577] w-20 h-[2rem] text-xs rounded-xl text-white"
                        type="submit"
                      >
                        add payment
                      </button>
                    </div>
                  </form>
                </div>

                <div>
                  <p className="font-medium text-sm text-[#E1DFEE] self-center w-[70%]">
                    Do not forget to update your exchange rate before making
                    this change.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (order.Shop.type === 'woocommerce') {
    return (
      <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl flex flex-col space-y-5 ">
        {customerModal === true && handleModalChangeOrderCustomerWoocommerce()}
        <div className="title-info-container flex flex-row justify-between items-center">
          <h1 className="flex space-x-4">
            <p className="text-[#EC1577] text-4xl font-semibold">Order</p>
            <div className="font-semibold text-4xl text-[#181143]">
              {' '}
              #{order.reference}
            </div>
          </h1>
          <div className="customer-info text-[20px] text-[#181143] font-medium">
            from {order.Customer.first_name} {order.Customer.last_name}
          </div>
          <div className="bg-white text-yellow-400 rounded-lg text-center self-center p-2 shadow-sm font-medium">
            {wooCommerceOrderTotal()} {order.woo_currency}
          </div>
          <div className="font-medium">
            {order.createdAt.replace('.000Z', '').replace('T', ' ')}
          </div>
          <div>
            <button
              className="bg-[#EC1577] rounded-[25px] text-[#fff] h-[37px] shadow-lg w-[7rem]"
              type="button"
            >
              help
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmitEditOrderStatus(hundleOrderStatusWooChange)}
        >
          <div className="grid grid-cols-7 space-x-5 ">
            <div className="col-span-2">
              <select
                className="bg-[#fff] rounded-xl text-[#AAA6C3] h-[37px] shadow-lg w-full p-1"
                defaultValue={order.woo_status}
                {...registerEditOrderStatus('status')}
              >
                <option value={order.woo_status}>
                  {order.woo_status === 'pending'
                    ? 'Pending payment'
                    : order.woo_status}
                </option>
                {wooStatusArray
                  .filter(
                    (wooStatus) => wooStatus.toLowerCase() !== order.woo_status
                  )
                  .map((wooStatus, index) => (
                    <option value={wooStatus.toLowerCase()} key={index}>
                      {wooStatus === 'On-hold' ? 'On hold' : wooStatus}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-span-5">
              <button
                className="bg-shifti-main-bg text-white p-1 w-[15%] rounded-xl h-[37px]"
                type="submit"
              >
                Update status
              </button>
            </div>
          </div>
        </form>
        <div className="grid grid-cols-7 content-box space-x-5">
          <div className="col-span-2 flex flex-col left-box space-y-5">
            {order.Customer ? (
              <div className="md:mt-2 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
                <h2 className="font-semibold text-[20px] text-[#EC1577]">
                  Customer
                </h2>
                <div className="info grid grid-cols-4 justify-items-evenly col-span-1">
                  <div className="bg-[#EC1577] rounded-full w-16 h-16 relative shadow-xl self-center">
                    <FontAwesomeIcon
                      icon={faUser}
                      className=" left-[10%] top-[5%] scale-100  h-[3rem] w-[3rem]  text-[#fff] pointer-events-none absolute "
                    />
                  </div>
                  <div className="flex flex-col col-span-2 items-start">
                    <p className="font-medium text-lg text-[#181143]">
                      {order.Customer.first_name} {order.Customer.last_name}
                    </p>
                    <p className="text-sm">{order.Customer.email}</p>
                    <button
                      type="button"
                      className="font-semibold text-xs text-yellow-400 border-2 border-yellow-400 p-2 rounded-3xl"
                      onClick={() => setCustomerModal(true)}
                    >
                      Change customer
                    </button>
                  </div>
                  <div className="col-span-1 text-[#AAA6C3]">
                    #{order.Customer.id}
                  </div>
                </div>
                <div>
                  <p className="font-semibold text-md text-[#181143]">
                    Account registered:
                  </p>
                  <p>
                    {order.Customer.createdAt
                      .replace('.000Z', '')
                      .replace('T', ' ')}
                  </p>
                </div>
                <div>
                  <p className="font-semibold text-md text-[#181143]">
                    Validated orders placed:
                  </p>
                  <p>0</p>
                </div>
                <div>
                  <p className="font-semibold text-md text-[#181143]">
                    Total spent since registration:
                  </p>
                </div>
                <div className="address-box flex flex-row justify-between 2xl:p-2 space-x-5">
                  <div className="left-box flex flex-col">
                    <p className="font-semibold text-sm text-[#181143] mb-1">
                      Shipping address
                    </p>{' '}
                    <div className="flex text-sm  space-x-2">
                      {' '}
                      <p> {order.Shipping_address_woo?.address_1}</p>
                      <p>{order.Shipping_address_woo?.address_2}</p>
                    </div>
                    <div className="flex text-sm  space-x-2">
                      {' '}
                      <p> {order.Shipping_address_woo?.first_name}</p>
                      <p>{order.Shipping_address_woo?.last_name}</p>
                    </div>
                    <div className="flex flex-col text-sm">
                      <p>{order.Shipping_address_woo?.address1}</p>
                      <p>{order.Shipping_address_woo?.city}</p>
                      <p>{order.Shipping_address_woo?.state}</p>
                      <p>{order.Shipping_address_woo?.postcode}</p>
                    </div>
                  </div>
                  <div className="right-box flex flex-col">
                    <p className="font-semibold text-sm text-[#181143] mb-1 ">
                      Invoice address
                    </p>

                    <div className="flex text-sm  space-x-2">
                      {' '}
                      <p> {order.Billing_address_woo?.address_1}</p>
                      <p>{order.Billing_address_woo?.address_2}</p>
                    </div>
                    <div className="flex text-sm space-x-2">
                      {' '}
                      <p> {order.Billing_address_woo?.first_name}</p>
                      <p>{order.Billing_address_woo?.last_name}</p>
                    </div>
                    <div className="flex flex-col text-sm">
                      <p>{order.Billing_address_woo?.address1}</p>
                      <p>{order.Billing_address_woo?.city}</p>
                      <p>{order.Billing_address_woo?.state}</p>
                      <p>{order.Billing_address_woo?.postcode}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="md:mt-2 mt-24 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
              <h2 className="font-semibold text-[20px] text-[#EC1577]">
                Messages ({order.OrderMessages.length})
              </h2>

              {order.OrderMessages.map((orderMessage) => (
                <div className="flex flex-col   text-xs ">
                  <div className="flex justify-start items-center">
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      className="h-7 w-18  text-[#AAA6C3] pointer-events-none scale-50"
                    />
                    <p className="text-[#AAA6C3]">
                      {orderMessage.date_add
                        .replace('.000Z', '')
                        .replace('T', ' ')
                        .substring(0, 10)}
                    </p>
                    <p className="m-1">-</p>
                    <p className="text-[#181143]">
                      {orderMessage.Customer.first_name}{' '}
                      {orderMessage.Customer.last_name}
                    </p>
                    {orderMessage.private !== true ? (
                      <p className="ml-2 bg-[#EC1577] text-white p-1 rounded-xl">
                        private
                      </p>
                    ) : null}
                  </div>
                  <div className="flex items-center text-[#181143]">
                    <p className="w-2 text-xl ml-1 text-[#AAA6C3]">|</p>
                    <p className="text-center ml-2">{orderMessage.message}</p>
                  </div>
                </div>
              ))}
              <form
                onSubmit={handleSubmitCreateOrderNoteWoo(submitCustomerMessage)}
                className="flex flex-col space-y-5 "
              >
                <div className="relative flex items-center mr-3 w-[40rem]">
                  <input
                    className="permission-checkbox h-8 w-8 rounded-full	outline-0	appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
                    type="checkbox"
                    id="display"
                    {...registerCreateOrderNoteWoo('private')}
                    checked={privateChecked}
                    onChange={handlePrivateChange}
                  />
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="h-5 w-16  text-[#EC1577] absolute -left-[2.5%] pointer-events-none text-opacity-0 checked-1 transition scale-75"
                  />
                  <label
                    htmlFor="display"
                    className="text-[#181143] text-lg  font-semibold ml-3"
                  >
                    Display to customer
                  </label>
                </div>
                <div className="message-container w-full sm:space-y-3">
                  <div className="label-container">
                    <label
                      htmlFor="message-box"
                      className="text-[#181143] pt-3 text-lg  font-semibold"
                    >
                      Message
                    </label>
                  </div>
                  <div className="input-container">
                    {' '}
                    <textarea
                      type="text"
                      className="bg-[#fff] border-2 border-[#AAA6C3] text-[#AAA6C3] text-sm rounded-[0.8rem] block w-full sm:w-12/12 dark:placeholder-gray-400 h-[10rem] p-2 max-h-80"
                      id="message-box"
                      {...registerCreateOrderNoteWoo('customer_message', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                        maxLength: {
                          value: 512,
                          message: 'message is too long!',
                        },
                      })}
                    />
                    {errorsCreateOrderNoteWoo.customer_message && (
                      <span className="error-message text-[#EC1577]  w-full text-xs">
                        {errorsCreateOrderNoteWoo.customer_message?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex flex-row self-end">
                  <button
                    className="bg-[#EC1577] rounded-[25px] text-[#fff] h-[37px] shadow-lg w-[14rem] "
                    type="submit"
                  >
                    send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-span-5 flex flex-col right-box space-y-5 ">
            <div className="md:mt-2 mt-24 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
              {handleProducts()}
            </div>
            <div className="md:mt-2 mt-24 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
              <h2 className="font-semibold text-[20px] text-[#EC1577]">
                Shipping ({order.OrderCarriers.length})
              </h2>
              <div>
                {' '}
                <button
                  type="button"
                  className="addButton p-2  w-[10rem]"
                  onClick={() => setCreateNewShipping(true)}
                >
                  Add Shipping
                </button>
              </div>
              {createNewShipping === true && createNewShippingModal()}
              <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />

              <div>
                <div
                  className={`grid grid-cols-${
                    order.Taxes.length === 0 ? 4 : 3 + order.Taxes.length
                  } justify-between`}
                >
                  <div className="text-[#AAA6C3] text-xs font-medium text-start pb-8 col-span-1">
                    Name
                  </div>

                  <div className="text-[#AAA6C3] text-xs font-medium text-center pb-8 col-span-1">
                    Cost
                  </div>

                  <div
                    className={`text-[#AAA6C3] font-medium text-center grid grid-cols-${
                      order.Taxes.length !== 0 ? order.Taxes.length : 1
                    } ${
                      order.Taxes.length !== 0
                        ? 'justify-between'
                        : 'justify-center'
                    } h-full text-xs col-span-${
                      order.Taxes.length !== 0 ? order.Taxes.length : 1
                    }`}
                  >
                    <div
                      className={`col-span-${
                        order.Taxes.length !== 0 ? order.Taxes.length : 1
                      }`}
                    >
                      Taxes{' '}
                    </div>

                    {order.OrderDetails.slice(0, 1).map((product) =>
                      product.OrderDetailsTaxes.slice(0, 2).map(
                        (productTax) => (
                          <div>{productTax.Tax.TaxLangs[0].name}</div>
                        )
                      )
                    )}
                  </div>
                  <div className="hello there">{'  '}</div>
                </div>
                <div
                  className={` grid grid-cols-${
                    order.Taxes.length !== 0 ? 3 + order.Taxes.length : 4
                  } items-center md:max-h-[300px] max-w-[100%] overflow-y-auto`}
                >
                  {order.OrderCarriers.map((carrier, index) => (
                    <>
                      <div className="text-[#181143]  text-md  font-semibold text-start  text-xs col-span-1">
                        <p>{carrier.name}</p>
                      </div>

                      <div className="text-[#181143]  text-md  font-semibold text-center text-xs col-span-1">
                        {carrier.shipping_cost_tax_excl.toFixed(3)}{' '}
                        {order.woo_currency}
                      </div>

                      {order.Taxes.length !== 0 ? (
                        <div
                          className={`flex justify-evenly col-span-${
                            order.Taxes.length === 0 ? 1 : order.Taxes.length
                          } h-full`}
                        >
                          {carrier.OrderCarrierTaxes.map((carrierTax) => (
                            <div className="text-[#181143]  text-center flex flex-row justify-center h-full w-full items-center relative text-xs">
                              <div className="text-xs font-semibold">
                                <div>
                                  {carrierTax.total === 0 ? (
                                    <div>-</div>
                                  ) : (
                                    carrierTax.total.toFixed(3)
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="text-center">-</div>
                      )}
                      <div className="text-[#181143]  text-md  font-semibold flex justify-evenly">
                        <button
                          type="button"
                          onClick={() => setEditOrderCarrier(index)}
                        >
                          <FontAwesomeIcon
                            icon={faPencil}
                            className="w-4 h-4 text-[#AAA6C3]"
                          />
                        </button>

                        <button
                          type="button"
                          onClick={() => setDeleteOrderCarrier(index)}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="w-4 h-4 text-[#AAA6C3]"
                          />
                        </button>
                        {editOrderCarrier === index &&
                          editOrderCarrierModal(carrier)}
                        {deleteOrderCarrier === index &&
                          deleteOrderCarrierModal(carrier)}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="md:mt-2 mt-24 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
              <h2 className="font-semibold text-[20px] text-[#EC1577]">
                Fees ({order.OrderFees.length})
              </h2>
              <div>
                {' '}
                <button
                  type="button"
                  className="addButton p-2 w-[10rem]"
                  onClick={() => setCreateNewFee(true)}
                >
                  Add fee
                </button>
              </div>
              {createNewFee === true && createNewFeeModal()}
              <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />

              <div>
                <div
                  className={`grid grid-cols-${
                    order.Taxes.length === 0 ? 4 : 3 + order.Taxes.length
                  } justify-between`}
                >
                  <div className="text-[#AAA6C3] text-xs font-medium text-start pb-8 col-span-1">
                    Name
                  </div>

                  <div className="text-[#AAA6C3] text-xs font-medium text-center pb-8 col-span-1">
                    Cost
                  </div>

                  <div
                    className={`text-[#AAA6C3] font-medium text-center grid grid-cols-${
                      order.Taxes.length !== 0 ? order.Taxes.length : 1
                    } ${
                      order.Taxes.length !== 0
                        ? 'justify-between'
                        : 'justify-center'
                    }  h-full text-xs col-span-${
                      order.Taxes.length !== 0 ? order.Taxes.length : 1
                    }`}
                  >
                    <div
                      className={`col-span-${
                        order.Taxes.length !== 0 ? order.Taxes.length : 1
                      }`}
                    >
                      Taxes
                    </div>

                    {order.OrderDetails.slice(0, 1).map((product) =>
                      product.OrderDetailsTaxes.slice(0, 2).map(
                        (productTax) => (
                          <div>{productTax.Tax.TaxLangs[0].name}</div>
                        )
                      )
                    )}
                  </div>
                  <div className="hello there">{'  '}</div>
                </div>
                <div
                  className={` grid grid-cols-${
                    order.Taxes.length !== 0 ? 3 + order.Taxes.length : 4
                  } items-center md:max-h-[300px] max-w-[100%] overflow-y-auto`}
                >
                  {order.OrderFees.map((fee, index) => (
                    <>
                      <div className="text-[#181143]  text-md  font-semibold text-start  text-xs col-span-1">
                        <p>{fee.name}</p>
                      </div>

                      <div className="text-[#181143]  text-md  font-semibold text-center text-xs col-span-1">
                        {fee.total.toFixed(3)}

                        {order.woo_currency}
                      </div>

                      {order.Taxes.length !== 0 ? (
                        <div
                          className={`flex justify-evenly col-span-${
                            order.Taxes.length === 0 ? 1 : order.Taxes.length
                          } h-full`}
                        >
                          {fee.OrderFeesTaxes.map((feeTax) => (
                            <div className="text-[#181143]  text-center flex flex-row justify-center h-full w-full items-center relative text-xs">
                              <div className="text-xs font-semibold">
                                <div>
                                  {feeTax.total === 0 ? (
                                    <div>-</div>
                                  ) : (
                                    feeTax.total.toFixed(3)
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="text-center">-</div>
                      )}
                      <div className="text-[#181143]  text-md  font-semibold flex justify-evenly">
                        <button
                          type="button"
                          onClick={() => setEditOrderFee(index)}
                        >
                          <FontAwesomeIcon
                            icon={faPencil}
                            className="w-4 h-4 text-[#AAA6C3]"
                          />
                        </button>
                        {editOrderFee === index && editOrderFeeModal(fee)}
                        <button
                          type="button"
                          onClick={() => setDeleteOrderFee(index)}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="w-4 h-4 text-[#AAA6C3]"
                          />
                        </button>
                        {deleteOrderFee === index && deleteOrderFeeModal(fee)}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="md:mt-2 mt-24 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5 customer-box shadow-2xl">
              {priceTotalProduct()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
