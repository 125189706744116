import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import EditCarrierLocations from './EditCarrierLocations';
import EditCarrierDimensions from './EditCarrierDimensions';
import EditCarrierSettings from './EditCarrierSettings';
import EditCarrierSummary from './EditCarrierSummary';
import upload from '../../data/upload.png';
import Loader from '../../components/Loader';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

function EditCarrier() {
  const token = useSelector(selectCurrentToken);
  const [currentTab, setCurrentTab] = useState('1');
  const [billingByWeight, setBillingByWeight] = useState(1);
  const [billingByPrice, setBillingByPrice] = useState(0);
  const [validatedTabs, setValidatedTabs] = useState(['1', '2', '3', '4']);
  const [allZoneNames, setAllZoneNames] = useState();
  const [shops, setShops] = useState([]);
  const [zoneNames, setZoneNames] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState('No tax applied');
  const [isAllZones, setIsAllZones] = useState(0);
  const [checkboxes, setCheckboxes] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllPrice, setSelectAllPrice] = useState(0);
  const [selectedShop, setSelectedShop] = useState('');
  const [shopType, setShopType] = useState('');
  const [loading, setLoading] = useState(true);
  const [carrier, setCarrier] = useState([]);
  const [selectedShopName, setSelectedShopName] = useState('');
  const [errors, setErrors] = useState(0);
  const [zones, setZones] = useState([]);
  const [formData, setFormData] = useState({
    shops: [],
    name: '',
    grade: '',
    url: '',
    delay: '',
    shipping_handling: 0,
    free_shipping: 0,
    tax_rules_group_id: 0,
    range_behavior: 1,
    delimiter1: '',
    delimiter2: '',
    zones: [],
    max_width: '',
    max_height: '',
    max_depth: '',
    max_weight: '',
    active: 1,
    shipping_external: 0,
    shipping_method: 0, // need review
    position: 0,
    deleted: 0,
  });

  const { id } = useParams();

  const getCarrier = async (carrierId) => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/carriers/${carrierId}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getZones = async (shopId) => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/zones/getAllZonesByShop/${shopId}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getShops = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-all-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [shopsResponse, carrierResponse] = await Promise.all([
          getShops(),
          getCarrier(id),
        ]);

        setShops(shopsResponse);
        setCarrier(carrierResponse);
        setShopType(carrierResponse.Shops[0].type);
        setSelectedShop(carrierResponse.Shops[0].id);
        setSelectedShopName(carrierResponse.Shops[0].name);

        setFormData((prevFormData) => ({
          ...prevFormData,
          name: carrierResponse.name,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          delay: carrierResponse.CarrierLangs[0].delay,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          grade: carrierResponse.grade,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          url: carrierResponse.url,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          shipping_handling:
            carrierResponse.shipping_handling === false ? 0 : 1,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          free_shipping: carrierResponse.is_free === false ? 0 : 1,
        }));
        if (carrierResponse.WeightRange != null) {
          setBillingByPrice(0);
          setBillingByWeight(1);
        }
        if (carrierResponse.PriceRange != null) {
          setBillingByPrice(1);
          setBillingByWeight(0);
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          tax_rules_group_id: carrierResponse.tax_rules_group_id,
        }));

        setFormData((prevFormData) => ({
          ...prevFormData,
          range_behavior: carrierResponse.range_behavior === false ? 0 : 1,
        }));

        if (carrierResponse.PriceRange || carrierResponse.WeightRange) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            delimiter1:
              carrierResponse.PriceRange != null
                ? carrierResponse.PriceRange.delimiter1
                : carrierResponse.WeightRange.delimiter1,
          }));

          setFormData((prevFormData) => ({
            ...prevFormData,
            delimiter2:
              carrierResponse.PriceRange != null
                ? carrierResponse.PriceRange.delimiter2
                : carrierResponse.WeightRange.delimiter2,
          }));
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          max_width: carrierResponse.max_width,
        }));

        setFormData((prevFormData) => ({
          ...prevFormData,
          max_height: carrierResponse.max_height,
        }));

        setFormData((prevFormData) => ({
          ...prevFormData,
          max_depth: carrierResponse.max_depth,
        }));

        setFormData((prevFormData) => ({
          ...prevFormData,
          max_weight: carrierResponse.max_weight,
        }));

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const prepareData = async () => {
      await Promise.all([getCarrier(id)]).then((carrierResponse) => {
        getZones(carrierResponse[0].Shops[0].id).then(async (item) => {
          setZones(item);

          if (item.length > 0) {
            const zoneList = !carrier.is_free
              ? await carrierResponse[0].Deliveries.map((row) => {
                  setCheckboxes((prevCheckboxes) => ({
                    ...prevCheckboxes,
                    [`checkbox-${row.zone_id}`]: true,
                  }));
                  return {
                    id: row.zone_id,
                    price: row.price,
                  };
                })
              : await carrierResponse[0].Zones.map((row) => {
                  setCheckboxes((prevCheckboxes) => ({
                    ...prevCheckboxes,
                    [`checkbox-${row.id}`]: true,
                  }));
                  return {
                    id: row.id,
                    price: '',
                  };
                });

            setFormData((prevFormData) => ({
              ...prevFormData,
              zones: zoneList,
            }));

            if (zones.length === zoneList.length) {
              setSelectAll(1);
              setCheckboxes('');
              await carrierResponse[0].Deliveries.map((row) => {
                setCheckboxes((prevCheckboxes) => ({
                  ...prevCheckboxes,
                  [`checkbox-${row.zone_id}`]: false,
                }));
                return null;
              });
              const updatedZoneNames = await item.map((zone) => zone.name);
              setIsAllZones(1);
              setAllZoneNames(updatedZoneNames);
              setZoneNames('');
            } else {
              const zoneNameList = await carrierResponse[0].Zones.map(
                (zone) => zone.name
              );
              setIsAllZones(0);
              setAllZoneNames('');
              setZoneNames(zoneNameList);
            }
          }
        });
      });
    };
    prepareData();
  }, [id]);

  const handleShop = (e) => {
    setSelectedShop(e.target.value);

    setSelectedShopName(e.target.selectedOptions[0].getAttribute('shopname'));
    setShopType(e.target.selectedOptions[0].getAttribute('type'));
  };

  const handleSubmit = () => {
    const carrierData = JSON.stringify({
      shops: [selectedShop],
      name: formData.name,
      grade: formData.grade,
      url: formData.url,
      delay: formData.delay,
      shipping_handling: formData.shipping_handling,
      free_shipping: formData.free_shipping,
      tax_rules_group_id:
        formData.tax_rules_group_id === '' ? 0 : formData.tax_rules_group_id,
      range_behavior: formData.range_behavior,
      delimiter1: formData.delimiter1,
      delimiter2: formData.delimiter2,
      is_price_range: billingByPrice,
      is_weight_range: billingByWeight,
      zones: formData.zones,
      max_width: formData.max_width,
      max_height: formData.max_height,
      max_depth: formData.max_depth,
      max_weight: formData.max_weight,
      active: formData.active,
      // shipping_method: formData.shipping_method,
      position: 1,
      deleted: 0,
      shipping_external: 1,
      shipping_method: 1, // need review
    });

    const idToast = toast.loading('Please wait...');
    const decoded = jwtDecode(token);
    const config = {
      method: 'put',
      url: `${apiURL}/carriers/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: carrierData,
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'Carrier is edited !',
          type: response.data === 'success',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setTimeout(() => {
          window.location.assign('/carriers');
        }, 1500);
      })
      .catch((error) => {});
  };

  const handleTabClick = (e) => {
    const tabId = e.target.id;

    setCurrentTab(tabId);
  };

  const tabs = [
    {
      id: 1,
      tabTitle: 'General settings',
      title: 'General settings',
      content: (
        <EditCarrierSettings
          setFormData={setFormData}
          formData={formData}
          setCurrentTab={setCurrentTab}
          setValidatedTabs={setValidatedTabs}
          validatedTabs={validatedTabs}
          currentTab={currentTab}
          setErrors={setErrors}
          errors={errors}
          carrier={carrier}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'Shipping locations and costs',
      title: 'Shipping locations and costs',
      content: (
        <EditCarrierLocations
          setFormData={setFormData}
          formData={formData}
          setCurrentTab={setCurrentTab}
          setBillingByPrice={setBillingByPrice}
          billingByPrice={billingByPrice}
          billingByWeight={billingByWeight}
          setBillingByWeight={setBillingByWeight}
          selectedShop={selectedShop}
          shopType={shopType}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectAllPrice={selectAllPrice}
          setSelectAllPrice={setSelectAllPrice}
          setZoneNames={setZoneNames}
          zoneNames={zoneNames}
          setSelectedTaxes={setSelectedTaxes}
          setIsAllZones={setIsAllZones}
          isAllZones={isAllZones}
          setValidatedTabs={setValidatedTabs}
          validatedTabs={validatedTabs}
          currentTab={currentTab}
          setAllZoneNames={setAllZoneNames}
          setErrors={setErrors}
          errors={errors}
          carrier={carrier}
          zones={zones}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'Size, weight and group access',
      title: 'General settings',
      content: (
        <EditCarrierDimensions
          setFormData={setFormData}
          formData={formData}
          setCurrentTab={setCurrentTab}
          setValidatedTabs={setValidatedTabs}
          validatedTabs={validatedTabs}
          currentTab={currentTab}
          selectedShop={selectedShop}
          setErrors={setErrors}
          errors={errors}
        />
      ),
    },
    {
      id: 4,
      tabTitle: 'Summary',
      title: 'Summary',
      content: (
        <EditCarrierSummary
          setFormData={setFormData}
          formData={formData}
          setCurrentTab={setCurrentTab}
          zoneNames={zoneNames}
          selectedTaxes={selectedTaxes}
          billingByPrice={billingByPrice}
          handleSubmit={handleSubmit}
          setValidatedTabs={setValidatedTabs}
          validatedTabs={validatedTabs}
          currentTab={currentTab}
          isAllZones={isAllZones}
          allZoneNames={allZoneNames}
          selectedShopName={selectedShopName}
          setErrors={setErrors}
          errors={errors}
        />
      ),
    },
  ];

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="w-[95%] sticky top-[5.4rem] md:ml-[3%] ml-[3.5%] z-40">
        <div className="filter-by-shop mb-5 relative md:w-full w-[22rem]">
          <select className="shopSelect text-center" onChange={handleShop}>
            <option
              type={carrier.Shops[0].type}
              value={carrier.Shops[0].id}
              className="text-[#EC1577] text-center"
            >
              {carrier.Shops[0].name}
            </option>
          </select>
          <FontAwesomeIcon
            icon={faShop}
            className="h-[1.5rem] w-[1.5rem] scale-100 md:left-[42%] md:top-[19%] top-[19%] left-[30%]  absolute  text-[#AAA6C3] text-opacity-70 checked-1 transition "
          />
        </div>
      </div>
      <div className="md:mt-2 mt-24 md:ml-16 p-2 md:p-5 rounded-3xl flex flex-col">
        <Header category="Page" title="View" />

        {shopType === 'prestashop' && (
          <div className="py-2 px-4 bg-white rounded-xl mr-auto sft-shadow w-[90%] flex justify-between ">
            {tabs.map((tab, i) => {
              let buttonClassName = 'px-4 font-semibold flex';
              if (currentTab === `${tab.id}`) {
                buttonClassName += ' text-[#F99E1F]';
              } else if (validatedTabs.includes(tab.id)) {
                buttonClassName += ' text-[#EC1577]';
              } else {
                buttonClassName += ' text-[#81828B]';
              }

              const isDisabled = currentTab === `${tab.id}` || errors > 0;

              return (
                <div key={i}>
                  <button
                    type="button"
                    id={tab.id}
                    className={buttonClassName}
                    disabled={isDisabled}
                    onClick={handleTabClick}
                  >
                    <div className="rounded-full w-6 h-6  flex justify-center pointer-events-none">
                      <p>{tab.id}</p>
                    </div>
                    {tab.tabTitle}
                  </button>
                </div>
              );
            })}
          </div>
        )}
        {shopType === 'prestashop' && (
          <div className="form flex flex-row w-full mt-5 space-x-5">
            <div className="flex flex-col w-[70%]">
              {loading === 1 ? (
                <Loader />
              ) : (
                <>
                  {' '}
                  {tabs.map((tab, i) => (
                    <div key={i}>
                      {currentTab === `${tab.id}` && <div> {tab.content} </div>}
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="flex flex-col w-[25%]">
              <p>Logo</p>
              <div className="flex justify-center items-center">
                <div className="cursor-pointer border-2 border-[#AAA6C3] rounded-2xl w-full">
                  <div className="m-4 py-4">
                    <span className="flex justify-center items-center text-[12px] mb-1 text-red-500" />
                    <div className="flex items-center justify-center w-full">
                      <label className="flex cursor-pointer flex-col w-full border-2 rounded-md border-dashed hover:bg-gray-100 hover:border-gray-300">
                        <div className="flex flex-col items-center justify-center pt-7">
                          <img src={upload} alt="" />
                          <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                            Upload your Image here
                          </p>
                        </div>
                        <input
                          type="file"
                          className="opacity-0"
                          multiple="multiple"
                          name="files[]"
                        />
                      </label>
                    </div>
                    <div className="flex justify-evenly gap-2 mt-2 flex-wrap" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {shopType === 'woocommerce' && (
          <div className="flex justify-center">
            <p className="text-[#EC1577] text-[1vw] w-full text-center">
              in WooCommerce , Carriers are bound to orders ,to add a carrier
              you must create in the create order form.
            </p>
          </div>
        )}
        {shopType === 'prestashop' && (
          <div className="container-save-submit flex justify-end md:mr-10">
            <button
              type="button"
              onClick={handleSubmit}
              className="pl-[0.7rem] addButton w-[10rem]"
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditCarrier;
