import React, { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/apiService';

const apiURL = process.env.REACT_APP_API_URL;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [dataErrorMsg, setDataErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault(); // 👈️ prevent page refresh
    if (!isEmail(email)) {
      setDataErrorMsg('Invalid email');
      return;
    }
    const formdata = JSON.stringify({
      email,
    });
    const config = {
      method: 'patch',
      url: `${apiURL}/password/send-reset-link`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: formdata,
    };
    api
      .request(config)
      .then(() => {
        toast.success('Password reset email sent! Please check your inbox.');
        setIsEmailSent(true);
      })
      .catch(() => {
        toast.error('Error occured!');
      });
  };

  return (
    <div className="flex h-[100vh]">
      <div className="w-3/12  flex flex-col items-center   justify-start p-10">
        <div className="image-div w-full bg-[#181144] rounded-3xl  flex justify-center h-full items-center pr-32 ">
          <img src="../register2.png" alt="" className="bg-no-repeat w-full" />
        </div>
      </div>
      <div className="w-9/12 ">
        <div className="flex flex-col justify-center items-center pr-32 h-[100vh]  pt-10 pb-10">
          <form className="bg-[#E1DFEE]  flex flex-col justify-center items-center w-full pt-5 pb-5 h-full space-y-6 drop-shadow-xl shadow-2xl rounded-2xl">
            <div className="title flex flex-col justify-center items-center space-y-2">
              <h1 className="text-[#181144] text-3xl font-bold">
                Reset password
              </h1>
            </div>
            {!isEmailSent ? (
              <div className=" w-2/3 flex flex-col items-center space-y-5">
                <div className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start  justify-end w-2/3">
                  <label htmlFor="FirstName" className="text-l font-semibold">
                    E-mail
                  </label>
                  <input
                    placeholder="Adresse email"
                    onChange={(event) => setEmail(event.target.value)}
                    className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                  />
                </div>
                <div className="w-full space-y-2 flex flex-col justify-center items-center">
                  <div style={{ color: '#FF9494' }}>{dataErrorMsg}</div>
                  <input
                    type="submit"
                    className="border-2 border-[#EC1577] rounded-xl bg-[#EC1577] text-white p-3 cursor-pointer h-12 w-2/3 text-center hover:outline-0 hover:scale-105 transition duration-150"
                    value="Reset password"
                    onClick={handleSubmit}
                  />
                  <div style={{ color: '#FF9494' }}>{dataErrorMsg}</div>
                  <input
                    type="submit"
                    className="border-2 border-[#EC1577] rounded-xl text-[#EC1577] p-3 cursor-pointer h-12 w-2/3 text-center hover:outline-0 hover:scale-105 transition duration-150"
                    value="Return"
                    onClick={() => navigate('/login')}
                  />
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col space-y-5 justify-center items-center">
                <p>Password reset email sent! Please check your inbox.</p>
                <input
                  type="submit"
                  className="border-2 border-[#EC1577] rounded-xl text-[#EC1577] p-3 cursor-pointer h-12 w-2/3 text-center"
                  value="Return"
                  onClick={() => navigate('/login')}
                />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
