import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../utils/apiService';
import { selectCurrentToken } from '../../features/auth/authSlice';
import Loader from '../../components/Loader';

const apiURL = process.env.REACT_APP_API_URL;

function CreateShopCheckout({
  shopCategories,
  setCurrentTab,
  currentTab,
  formData,
  setFormData,
  shops,
  setShops,
  employees,
  setEmployees,
  subscriptionTime,
  setSubscriptionTime,
  extraEmployees,
  setExtraEmployees,
  extraShops,
  setExtraShops,
}) {
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const [withGiftCode, setWithGiftCode] = useState(false);
  const [withPaymee, setWithPaymee] = useState(true);
  const [giftCode, setGiftCode] = useState(null);
  const [confirmPlan, setConfirmPlan] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const getPlan = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/plans/${formData.plan_id}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setPlan(response.data);
        setLoading(false);
      })
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getPlan();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const totalPriceTTC = () => {
    let total = 0;
    decoded.UserInfo.country === 'Tunisia' &&
      (subscriptionTime === 'monthly'
        ? (total =
            plan.price_dt_monthly + extraEmployees * 2 + extraShops * 5 * 1.19)
        : (total =
            plan.price_dt_yearly + extraEmployees * 2 + extraShops * 5 * 1.19));

    decoded.UserInfo.country === 'France' &&
      (subscriptionTime === 'monthly'
        ? (total =
            plan.price_euro_monthly +
            extraEmployees * 2 +
            extraShops * 5 * 1.19)
        : (total =
            plan.price_euro_yearly +
            extraEmployees * 2 +
            extraShops * 5 * 1.19));

    decoded.UserInfo.country !== 'Tunisia' &&
      decoded.UserInfo.country !== 'France' &&
      (subscriptionTime === 'monthly'
        ? (total =
            plan.price_dollar_monthly +
            extraEmployees * 2 +
            extraShops * 5 * 1.19)
        : (total =
            plan.price_dollar_yearly +
            extraEmployees * 2 +
            extraShops * 5 * 1.19));

    return total;
  };

  function payingWithGiftCode() {
    if (withPaymee) {
      setWithPaymee(false);
      setWithGiftCode(true);
    }
  }

  function payingWithPaymee() {
    if (withGiftCode) {
      setWithPaymee(true);
      setWithGiftCode(false);
    }
  }

  async function checkoutWithGiftCode() {
    const idToast = toast.loading('Please wait...');

    const data = {
      plan_id: formData.plan_id,
      user_id: decoded.UserInfo.id,
    };

    const config = {
      method: 'post',
      url: `${apiURL}/giftCodes/${giftCode}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    await api.request(config).then(async (responseGiftCodeTest) => {
      if (!responseGiftCodeTest.data.message === 'success') {
        toast.update(idToast, {
          render: 'failed to create your plan',
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      } else {
        const userPlanData = {
          with_extra: 0,
          giftCodeData: {
            id: responseGiftCodeTest.data.data.id,
            expiration_date: responseGiftCodeTest.data.data.expiration_date,
            nb_free_months: responseGiftCodeTest.data.data.nb_free_months,
            nb_free_shops: responseGiftCodeTest.data.data.nb_free_shops,
            nb_free_employees: responseGiftCodeTest.data.data.nb_free_employees,
            nb_free_products: responseGiftCodeTest.data.data.nb_free_shops,
          },
          formData,
          shops,
          employees,
        };

        const config2 = {
          method: 'post',
          url: `${apiURL}/payment/paymentPlanWithGiftCode`,
          headers: {
            'Content-Type': 'application/json',
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
          data: userPlanData,
        };

        await api
          .request(config2)
          .then((responseUserPlan) => {
            if (responseUserPlan.status === 200) {
              toast.update(idToast, {
                render: 'your plan has been created !',
                type: responseUserPlan.data === 'success',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                isLoading: false,
              });
              setTimeout(() => {
                window.location.href =
                  'https://workplace.shifti.co/payment/userPlanFree';
              }, 2000);
            }
          })
          .catch((err) => console.log(err, 'Errorrr'));
      }
    });
  }

  function generateUniqueId() {
    const now = new Date();
    const day = now.getDate();
    const year = now.getFullYear();
    const minute = now.getMinutes();
    const second = now.getSeconds();
    const millisecond = now.getMilliseconds();

    const generatedOrderId = `${year}${day}${minute}${second}${millisecond}`;

    return generatedOrderId;
  }

  console.log(decoded.UserInfo);

  const handleSubmit = async () => {
    try {
      setLoadingSubmit(true);
      const idToast = toast.loading('Please wait...');

      const uniqueId = generateUniqueId();

      const paymentData = {
        amount: totalPriceTTC(),
        note: `Order n°#${uniqueId}`,
        first_name: decoded.UserInfo.first_name,
        last_name: decoded.UserInfo.last_name,
        email: decoded.UserInfo.email,
        phone: decoded.UserInfo.phone,
        order_id: uniqueId,
        webhook_url: 'https://workplace.shifti.co/create-shop',
        return_url: 'https://workplace.shifti.co/paymentResult',
        cancel_url: 'https://workplace.shifti.co/paymentResult',
      };

      const config = {
        method: 'post',
        url: 'https://sandbox.paymee.tn/api/v2/payments/create',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token 88d14b79b11188f130a06bea8c2003e9bdfd200a',
        },
        data: paymentData,
      };

      await api.request(config).then(async (responseAPI) => {
        const data = JSON.stringify({
          formData,
          shops,
          employees,
          token: responseAPI.data.data.token,
        });

        const config1 = {
          method: 'post',
          url: `${apiURL}/plans`,
          headers: {
            'Content-Type': 'application/json',
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
          data,
        };

        await api
          .request(config1)
          .then(async (response) => {
            if (response.status === 200) {
              toast.update(idToast, {
                render: 'your plan has been created !',
                type: response.data === 'success',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                isLoading: false,
              });

              const config2 = {
                method: 'patch',
                url: `${apiURL}/plans/token/${response.data.id}`,
                headers: {
                  'Content-Type': 'application/json',
                  'App-Tenant': decoded.UserInfo.tenantId,
                  Authorization: `Bearer ${token}`,
                },
                data,
              };

              await api.request(config2).then(() => {
                window.location.href = responseAPI.data.data.payment_url;
              });
            }
          })
          .catch((error) => {
            toast.update(idToast, {
              render: 'order Status failed , select a new state !',
              type: 'failed',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              isLoading: false,
            });
          });
      });

      /// shop
    } catch (error) {
      setLoadingSubmit(false);
      alert('alert ERROR');
      console.error('An error occurred:', error);
      // Handle any unexpected errors
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="border-1 border-[#A0A0A0] mt-[4vw] flex flex-col p-8 w-[30rem] rounded-xl shadow-2xl">
        <div className="flex justify-start">
          <button
            type="button"
            onClick={() => setCurrentTab('3')}
            className="text-[#EC1577] text-xl"
          >
            <span className="text-3xl">&#8592;</span> Return
          </button>
        </div>
        <div className="flex justify-start mt-4 mb-2">
          <span className="text-[#0A0D13] text-xl font-semibold">Payment</span>
        </div>
        <div className="border-b-1 border-[#A0A0A0] w-full" />
        <div className="flex justify-start mt-4">
          <span className="text-[#0A0D13]  font-semibold">Payment with :</span>
        </div>
        <div className="flex justify-start w-[30rem] mt-4 space-x-14">
          <div className="flex justify-start relative gap-2">
            <input
              type="radio"
              name="payment"
              id="paymee"
              className="outline-none appearance-none w-5 h-5 border-2 border-[#EC1577] rounded-full hover:outline-0 focus:outline-0 cursor-pointer"
              onClick={() => payingWithPaymee()}
            />
            {withPaymee && (
              <div className="w-3 h-3 bg-[#EC1577] rounded-full absolute left-1 top-1 " />
            )}
            <label htmlFor="paymee" className="text-sm">
              payment with paymee
            </label>
          </div>
          <div className="flex justify-start relative gap-2">
            <input
              type="radio"
              name="payment"
              id="paymee"
              className="outline-none appearance-none w-5 h-5 border-2 border-[#EC1577] rounded-full hover:outline-0 focus:outline-0 cursor-pointer"
              onClick={() => payingWithGiftCode()}
            />
            {withGiftCode && (
              <div className="w-3 h-3 bg-[#EC1577] rounded-full absolute left-1 top-1 " />
            )}
            <label htmlFor="paymee" className="text-sm">
              gift code
            </label>
          </div>
        </div>
        {withGiftCode && (
          <div className="flex flex-col mt-4">
            <input
              type="text"
              className="w-full outline-none appearance-none pt-3 pb-3 pl-4 border-1 border-[#ACACAC] rounded-xl text-[#ACACAC] hover:outline-0 focus:outline-0 text-sm"
              placeholder="Enter your gift code"
              onChange={(e) => setGiftCode(e.target.value)}
            />
          </div>
        )}
        <div className="flex  mt-6">
          <button
            type="button"
            className="bg-[#EC1577] text-white rounded-xl w-full p-2"
            onClick={() => {
              withGiftCode === true ? checkoutWithGiftCode() : handleSubmit();
            }}
          >
            {withGiftCode ? (
              <span>Submit!</span>
            ) : (
              <span>
                Pay {totalPriceTTC()}{' '}
                {decoded.UserInfo.country === 'Tunisia' && 'dt'}
                {decoded.UserInfo.country === 'France' && '€'}
                {decoded.UserInfo.country !== 'Tunisia' &&
                  decoded.UserInfo.country !== 'France' &&
                  '$'}
              </span>
            )}
          </button>
        </div>
        <div className="mt-4 w-full">
          <p className="text-[#ACACAC] text-sm w-full ">
            Your personal data will be used to process your order, support your
            experience on this website and for other purposes described in our
            privacy policy.
          </p>
        </div>
      </div>
    </div>
  );
}

CreateShopCheckout.propTypes = {
  currentTab: PropTypes.object.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  shopCategories: PropTypes.array.isRequired,
  shops: PropTypes.array.isRequired,
  setShops: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  setEmployees: PropTypes.func.isRequired,
  subscriptionTime: PropTypes.object.isRequired,
  setSubscriptionTime: PropTypes.func.isRequired,
  extraEmployees: PropTypes.object.isRequired,
  setExtraEmployees: PropTypes.func.isRequired,
  extraShops: PropTypes.object.isRequired,
  setExtraShops: PropTypes.func.isRequired,
};

export default CreateShopCheckout;
