/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const Toggle = ({ onClick, toggle, text = true, className }) => {
  const toggleClass = ' transform translate-x-6';

  return (
    <div className={className}>
      <div
        className={`md:w-14 md:h-7 w-12 h-6 flex items-center ${
          toggle ? 'bg-shifti-pink-bg' : 'bg-gray-400'
        } rounded-full p-1 cursor-pointer`}
        onClick={onClick}
      >
        <div
          className={`bg-white md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out${
            toggle ? toggleClass : ''
          }`}
        />
      </div>
      {text ? <span className="ml-4">{toggle ? 'Yes' : 'No'}</span> : ''}
    </div>
  );
};
Toggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  toggle: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Toggle;
