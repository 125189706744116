import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';

const UserSummary = ({ setFormData, setCurrentTab, formData }) => {
  const [rules, setRules] = useState(false);
  const [conflict, setConflict] = useState(false);
  const [backendErrors, setErrors] = useState('');
  const [sumbitLoading, setSumbitLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const hundleSubmitUserSummary = () => {
    const idToast = toast.loading('Please wait...');
    setSumbitLoading(true);
    console.log(formData, 'formDataformData');
    const formdata = JSON.stringify({
      fname: formData.first_name,
      lname: formData.last_name,
      email: formData.email,
      phone: `${formData.phoneCode} ${formData.phone} `,
      phoneCode: formData.phoneCode,
      pwd: formData.password,
      taxName: formData.taxName,
      companyAddress: formData.address,
      birth_day: formData.birthdate,
      companyEmail: formData.companyEmail,
      companyName: formData.company_name,
      companyWebsite: formData.website,
      companyActivitySector: formData.activitySector,
      companyPostCode: formData.postcode,
      description: formData.description,
      country: formData.country,
    });

    const config = {
      method: 'post',
      url: `/register`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: formdata,
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'User created !',
          type: response.data === 'success',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setTimeout(() => {
          window.location.assign(`/login`);
        }, 1500);
      })
      .catch((error) => {
        toast.update(idToast, {
          render: 'Connection failed !',
          type: 'error',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });

        if (error.response.status === 409) {
          setErrors('email or username already in use');
          setConflict(true);
          setTimeout(() => {
            setCurrentTab('1');
          }, 3000);
        } else {
          setErrors(error.response.data.message);
          setConflict(true);
        }
      });
  };

  const hundleRulesChecked = () => {
    rules === false ? setRules(true) : setRules(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ruleCkecked: rules === false,
    }));
  };

  const hundleConflictModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          <h2 className="p-2 font-semibold text-[#EC1577]">Error</h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setConflict(false)}
          >
            X
          </button>
        </div>
        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <div className="flex justify-center">
          <div>{backendErrors}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col justify-center items-center pr-32 pt-10 pb-10">
      {conflict === true && hundleConflictModal()}
      <form
        onSubmit={handleSubmit(hundleSubmitUserSummary)}
        className="bg-[#E1DFEE]  flex flex-col justify-center items-center w-full pt-5 pb-5   space-y-6 drop-shadow-xl shadow-2xl rounded-2xl h-full"
      >
        <div className="title flex flex-col justify-center items-center space-y-2">
          <h1 className="text-[#181144] text-3xl font-bold">Sign Up</h1>
          <h2 className="text-[#5E6366] text-2xl font-semibold">
            Confirm your information
          </h2>
        </div>
        <div className="flex flex-col w-full pr-10 pl-32 space-y-2">
          <h2 className="text-[#5E6366] text-2xl mb-2 space-y-5 font-semibold">
            Personal info:
          </h2>
          <div className="flex flex-col">
            <div className="flex flex-col w-[90%] space-y-1">
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> First name</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.first_name}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Last name</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.last_name}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Email</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.email}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Password</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.password}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Country</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.country}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Phone number</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">
                    {formData.phoneCode} {formData.phone}
                  </p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Birthday date</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.birthdate}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full pr-10 pl-32 space-y-2">
          <h2 className="text-[#5E6366] text-2xl mb-2 font-semibold">
            Company info:
          </h2>
          <div className="flex flex-col">
            <div className="flex flex-col w-[90%] space-y-1">
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Company name</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.company_name}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Address</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.address}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Tax number</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.taxName}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Company Email</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.companyEmail}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Activity sector</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.activitySector}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Wenbsite</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.website}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> PostCode</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.postcode}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Description</p>
                  <div className=" text-sm font-semibold">:</div>
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{formData.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full pr-10 pl-32 space-y-2">
          <h2 className="text-[#5E6366] text-2xl mb-2 space-y-5 font-semibold">
            Terms and Conditions:
          </h2>
          <div className=" w-[80%] flex flex-col items-center h-[20rem]  justify-center relative ">
            <div className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg pt-3 pl-3 pr-3 h-[50rem] overflow-scroll bg-white items-start  justify-start w-full">
              <p className="pt-1 pl-3 pr-3">
                <span className="text-l font-semibold">Introduction</span>
                <br />
                Prior to subscribing to Estores Factory's services through the
                website or application, it is imperative to agree to the
                following terms of use. The services provided by Estores Factory
                under these terms of use encompass various products, features,
                and tools aiding you in creating and managing your online
                business. Estores Factory reserves the right to update and
                modify the terms of use by posting updates on its website and
                application. It is advisable to regularly visit the site to
                check for possible updates that may affect you. You must read
                and accept all the terms of use and the privacy policy before
                becoming an official Shifti user. Plain language summaries are
                provided for clarity. Please read our terms of use for a
                complete understanding of your legal obligations. Additionally,
                by using Shifti or any of its services, you agree to these
                conditions.
                <br />
                <span className="text-l font-semibold">
                  1. Opening a New Account
                </span>
                <br />
                <span className="text-l font-semibold">
                  1.1 Account Eligibility
                </span>
                <br />
                You must be 18 years or older, or of legal age as per the laws
                governing the country from which you use the service. You must
                register on Shifti to use and benefit from the service by
                providing accurate information as indicated in the registration
                form. Estores Factory reserves the right to reject or cancel any
                account creation request.
                <br />
                <span className="text-l font-semibold">
                  1.2 Account Security
                </span>
                <br />
                You are responsible for the security of your password. Estores
                Factory is not liable for losses or damages resulting from
                forgotten passwords or failure to comply with account security.
                <br />
                <span className="text-l font-semibold">
                  1.3 User Content Responsibility
                </span>
                <br />
                You are responsible for all activities and content (e.g., data,
                graphics, images) uploaded under your Shifti account (the "Store
                Content"). Ensure that the content is used legally and avoid any
                violation of intellectual property rights. Violation of any
                clause in the terms of use may result in immediate termination
                of services and a request for damages.
                <br />
                <span className="text-l font-semibold">1.4 Refund Policy</span>
                <br />
                Registered users are not entitled to a full or partial refund of
                account activation fees. This applies even if the account is
                activated, and it is later found that the registered user has
                violated the terms of use.
                <br />
                <span className="text-l font-semibold">
                  2. Account Activation
                </span>
                <br />
                <span className="text-l font-semibold">2.1 Shifti Account</span>
                <br />
                The person benefiting from the service is the contractor
                (account holder), authorized to manage any other provided
                account. If you subscribe to the service on behalf of an
                employer, the employer owns the account, and you are authorized
                by them, subject to the conditions applicable to them.
                <br />
                <span className="text-l font-semibold">
                  2.2 Payment Services, Visa/MasterCard, and Credit Card
                </span>
                <br />
                Shifti has the right, on behalf of the user, to provide Visa or
                MasterCard with information upon request after registration is
                completed. Users are informed that the activation of credit card
                payment services via Visa and/or MasterCard is their sole
                responsibility as account holders. If they wish to deactivate
                it, the responsibility for cancellation also rests on them. By
                using payment services with Visa/MasterCard in the store, users
                agree to comply with the credit card terms displayed when the
                service is activated. All changes will be effective from the
                date of publication.
                <br />
                <span className="text-l font-semibold">
                  2.3 Bank Transfer Service
                </span>
                <br />
                Estores Factory has the right to activate the bank transfer
                service upon subscription and at the account holder's request.
                The account holder is fully responsible for managing bank
                transfer services, ensuring that their store's banking
                information is correct. They accept the general conditions of
                the bank by using the bank transfer service. Estores Factory is
                not responsible for losses or damages resulting from the account
                holder's inability to manage bank transfers.
                <br />
                <span className="text-l font-semibold">
                  3. General Provisions
                </span>
                <br />
                Read and accept all the terms of use and the privacy policy in
                the terms of use before becoming a Shifti member. Support is
                provided to all Shifti users solely via the website/application
                and email. The terms of use are subject to the laws of Tunisia,
                and both parties agree to the jurisdiction of the Tunisian
                judiciary for any disputes arising from the terms of use.
                Account holders acknowledge that Estores Factory may change
                these terms at any time by posting updates on the website and/or
                the application at Estores Factory Terms. Changes are effective
                from the date of publication. Cease using the service if you
                disagree with changes in the terms of use. Account holders may
                not use the Shifti service for illegal, regulatory, or
                unauthorized purposes. They are not authorized to violate a
                system or law, especially copyright and intellectual property
                laws.
                <br />
                <span className="text-l font-semibold">
                  4. Estores Factory Rights
                </span>
                <br />
                Estores Factory reserves the right to modify or terminate the
                service for any reason deemed sufficient without notice at any
                time. Estores Factory reserves the right to refuse activation of
                the service to anyone.
              </p>
            </div>
          </div>
        </div>
        <div className="flex  items-center justify-start pr-10 pl-32 w-full">
          <div className="relative flex  items-start w-[40rem]">
            <input
              className="permission-checkbox h-8 w-8 rounded-full	outline-0	appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
              type="checkbox"
              id="display"
              checked={formData.ruleCkecked !== false}
              onChange={() => hundleRulesChecked()}
            />
            <FontAwesomeIcon
              icon={faCheck}
              className="h-5 w-16  text-[#EC1577] absolute -left-[1rem] top-[0.4rem] pointer-events-none text-opacity-0 checked-1 transition scale-75"
            />
            <label
              htmlFor="display"
              className="text-[#181143] text-lg  font-semibold mt-1 ml-3"
            >
              I have read and I accept the rules.
            </label>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-5 pl-32 pr-10 justify-center w-full">
          <button
            type="submit"
            className="rounded-xl relative h-12 w-full flex justify-center p-3 bg-[#EC1577] text-[#FFF] cursor-pointer disabled:bg-[#AAA6C3] disabled:cursor-not-allowed disabled:hover:scale-100 hover:outline-0 "
            disabled={formData.ruleCkecked === false || sumbitLoading}
          >
            {sumbitLoading && (
              <div className="absolute w-5 h-5 left-2  border-[#fff] ">
                <div className="animate-spin h-5 w-5  border-4  text-white border-r-4 rounded-full border-white  border-r-[#EC1577] " />
              </div>
            )}
            Submit
          </button>
          <button
            type="button"
            className="border-2 border-[#EC1577] rounded-xl text-[#EC1577] p-3 cursor-pointer h-12 w-full text-center hover:outline-0 hover:scale-105 transition duration-150"
            onClick={() => setCurrentTab('2')}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};
UserSummary.propTypes = {
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
};

export default UserSummary;
