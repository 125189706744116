/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../utils/apiService';
import { selectCurrentToken } from '../features/auth/authSlice';

import { productContext } from '../pages/products/CreateProduct';

const apiURL = process.env.REACT_APP_API_URL;

const PriceBox = ({ className = '' }) => {
  const token = useSelector(selectCurrentToken);
  const [
    shops,
    setShops,
    allShopTypes,
    allShopIds,
    selectedShop,
    setSelectedShop,
    selectedShops,
    setSelectedShops,
    langs,
    setLangs,
    selectedLangs,
    setSelectedLangs,
    formData,
    setFormData,
  ] = useContext(productContext);
  const [currentShopId, setCurrentShopId] = useState(
    selectedShop || selectedShops[0].id
  );
  const [taxRules, setTaxRules] = useState({});
  const getTaxRules = async (id) => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/taxes/get-tax-rule-groups-by-shop/${id}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    api.request(config).then((response) => {
      const temp = { ...taxRules };
      temp[currentShopId] = response.data;
      setTaxRules(temp);
    });
  };

  const handlePriceChange = async (e) => {
    const temp = { ...formData };
    if (e.target.name === 'priceTaxExcl')
      temp.sites[currentShopId].priceTaxExcl = e.target.value;
    else temp.sites[currentShopId].priceTaxIncl = e.target.value;
    setFormData(temp);
  };

  useEffect(() => {
    if (selectedShop && currentShopId !== selectedShop) {
      setCurrentShopId(selectedShop);
    }
    if (currentShopId && typeof taxRules[currentShopId] !== 'object') {
      getTaxRules(currentShopId);
    } else if (
      !currentShopId &&
      typeof taxRules[selectedShops[0].id] !== 'undefined'
    ) {
      getTaxRules(selectedShops[0].id);
    }
  });
  return (
    <div className={className}>
      <div>
        {selectedShop ? null : (
          <div className="bg-white h-12 rounded-t-xl divide-x -mb-4 inline-block px-4 pt-1 font-medium text-shifti-blue">
            {selectedShops.map((shop) => (
              <div
                className={`px-4 inline-block cursor-pointer divide-x ${
                  currentShopId === shop.id
                    ? 'text-shifti-pink font-semibold'
                    : ''
                }`}
                onClick={() => setCurrentShopId(shop.id)}
              >
                {shop.name}
              </div>
            ))}
          </div>
        )}
        <div className="md:p-5 bg-white rounded-3xl px-1 py-2 w-full">
          <div className="flex justify-between">
            <label htmlFor={`HT-${currentShopId}`} className="block w-full">
              HT
              <input
                className="bg-transparent border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block sm:w-11/12 dark:placeholder-gray-400 h-[2.8rem] p-2"
                type="number"
                placeholder="0.000"
                name="priceTaxExcl"
                value={
                  formData.sites[currentShopId] &&
                  formData.sites[currentShopId].priceTaxExcl
                }
                onChange={(e) => handlePriceChange(e, 'priceTaxExcl')}
              />
            </label>
            <label htmlFor={`TTC-${currentShopId}`} className="block w-full">
              TTC
              <input
                className="bg-transparent border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block sm:w-11/12 dark:placeholder-gray-400 h-[2.8rem] p-2"
                type="number"
                placeholder="0.000"
                name="priceTaxIncl"
                value={
                  formData.sites[currentShopId] &&
                  formData.sites[currentShopId].priceTaxIncl
                }
                onChange={(e) => handlePriceChange(e, 'priceTaxIncl')}
              />
            </label>
          </div>
          <div className="p-2 sm:p-0">
            <label htmlFor="Tax rule" className="essentialLabel">
              Tax rule
            </label>
            <select
              className="essentialSearch"
              onChange={(e) => handlePriceChange(e)}
            >
              <option value="0">Aucune taxe</option>
              {taxRules[currentShopId] &&
                taxRules[currentShopId].length &&
                taxRules[currentShopId].map((taxRule) => (
                  <option value={taxRule.name}>{taxRule.name}</option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
PriceBox.propTypes = {
  className: PropTypes.string.isRequired,
};
export default PriceBox;
