import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import PropTypes from 'prop-types';
import { selectCurrentToken } from '../../features/auth/authSlice';
import api from '../../utils/apiService';

const apiURL = process.env.REACT_APP_API_URL;

const EmployeesMain = ({ users }) => {
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const [shops, setShops] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const [selectedShop, setSelectedShop] = useState([]);
  const [exportEmployeeModal, setExportEmployeeModal] = useState(false);
  const contextMenuItems = [
    'AutoFit',
    'AutoFitAll',
    'SortAscending',
    'SortDescending',
    'Copy',
    'Edit',
    'Delete',
    'Save',
    'Cancel',
    'PdfExport',
    'ExcelExport',
    'CsvExport',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];

  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };

  const deletEmp = async (id) => {
    const idToast = toast.loading('Please wait...');

    const config = {
      method: 'delete',
      url: `${apiURL}/user/deleteUser/${id}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };

    await api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'Deleted !',
          type: response.data === 'success',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1500);
      })
      .catch((error) => {
        toast.update(id, {
          render: "Couldn't delete !",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const getShops = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-all-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getShops().then((response) => {
      if (shops.length === 0) {
        setShops(response);
        setSelectedShop(response[0]);
      }
    });
  }, []);

  const actions = ({ id }) => (
    <div>
      <button
        type="button"
        style={{ color: '#000' }}
        className="text-[#AAA6C3] py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => {
          window.location.href = `/edit-user/?id=${id}`;
        }}
      >
        <FontAwesomeIcon icon={faPencil} className="w-6 h-6 text-[#AAA6C3]" />
      </button>
      <button
        type="button"
        style={{ color: '#000' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => deletEmp(id)}
      >
        <FontAwesomeIcon icon={faTrash} className="w-6 h-6 text-[#AAA6C3]" />
      </button>
    </div>
  );

  const exportEmployees = (shopId, userId, isValidate) => {
    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'post',
      url: `${apiURL}/user/export/prestashop/${shopId}/employee/${userId}/lang/1`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: { isValidate, isAllowed: false },
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'employees exported !',
          type: response.status === 200,
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setTimeout(() => {
          window.location.reload(); // Reloads the current page
        }, 2000); // 2000 milliseconds = 2 seconds
      })
      .catch((err) => {
        toast.update(idToast, {
          render: 'error happened while exporting employee',
          type: 'failed',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  function handleExportEmployeeModal() {
    return (
      <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center transition delay-150 duration-300 ease-in-out">
        <div className="bg-white flex flex-col items-center justify-center p-5  absolute  w-2/6space-y-4 pt-8">
          <div className="w-full flex justify-end">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => setExportEmployeeModal(false)}
            >
              X
            </button>
          </div>
          <p className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
            You're moments away from importing employees from the external site.
          </p>

          <select
            className="essentialSelect"
            onChange={(e) => selectedShop(e.target.value)}
          >
            {shops
              .filter((shop) => shop.type === 'prestashop')
              .map((shop) => (
                <option value={shop.id}>{shop.name}</option>
              ))}
          </select>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              className=" border-2 border-[#EC1577] text-[#EC1577] w-[12rem] rounded-3xl p-2 text-sm"
              onClick={() => setExportEmployeeModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-[#EC1577] w-[12rem] text-white rounded-3xl p-2 text-sm"
              onClick={() => {
                const confirmed = window.confirm(
                  'are you sure you want to export this employee to '
                );

                if (confirmed) {
                  exportEmployees(selectedShop.id, selectedEmployeeId);
                }
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }
  const employees = ({ id }) => (
    <button
      type="button"
      className="text-[#fff] py-1 px-2 capitalize rounded-2xl text-md bg-[#181143] disabled:bg-shifti-grey-bg"
      // eslint-disable-next-line camelcase
      onClick={() => {
        setExportEmployeeModal(true);
        setSelectedEmployeeId(id);
      }}
    >
      Import employees
    </button>
  );

  const employeesGrid = [
    {
      field: 'id',
      headerText: 'ID',
      width: '80',
      textAlign: 'Start',
    },
    {
      field: 'first_name',
      headerText: 'First Name',
      width: '120',
      textAlign: 'Start',
    },
    {
      field: 'last_name',
      headerText: 'Last Name',
      width: '120',
      textAlign: 'Start',
    },
    {
      field: 'email',
      headerText: 'Email',
      width: '200',
      textAlign: 'Start',
    },
    {
      field: 'Role.name',
      headerText: 'Role',
      width: '120',
      textAlign: 'Start',
    },
    {
      headerText: 'Export',
      template: employees,
      width: '120',
      textAlign: 'End',
    },
    {
      field: 'action',
      headerText: 'Action',
      template: actions,
      width: '120',
      textAlign: 'End',
    },
  ];

  return (
    <div className="m-2 mt-3 p-2 md:p-5 bg-white rounded-3xl">
      {exportEmployeeModal && handleExportEmployeeModal()}
      <GridComponent
        dataSource={users}
        width="auto"
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        pageSettings={{ pageCount: 5, pageSize: 10 }}
        editSettings={editing}
        toolbar={toolbarOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {employeesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar]} />
      </GridComponent>
    </div>
  );
};

EmployeesMain.propTypes = {
  users: PropTypes.object.isRequired,
};
export default EmployeesMain;
