import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';
import styles from './css/login.module.css';

const Login = () => {
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [pwdShown, setPwdShown] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userData = await login({ user, pwd }).unwrap();
      dispatch(setCredentials({ ...userData, user }));
      setUser('');
      setPwd('');
      navigate('/');
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg('No Server Response');
      } else if (err.originalStatus === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.originalStatus === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      // errRef.current.focus();
    }
  };

  const handleUserInput = (e) => setUser(e.target.value);

  const handlePwdInput = (e) => setPwd(e.target.value);

  const togglePassword = () => {
    setPwdShown((prevState) => !prevState);
  };

  const content = isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <section className="flex h-[100vh]">
      {/* <p
        ref={errRef}
        className={errMsg ? 'errmsg' : 'offscreen'}
        aria-live="assertive"
      >
        {errMsg}
      </p> */}
      <div className="flex flex-row justify-between w-full h-full">
        <div className="w-3/12  flex flex-col items-start justify-start p-10">
          <div className="image-div w-full bg-[#181144] rounded-3xl h-full  flex justify-center items-center pr-32">
            <img
              src="../register2.png"
              alt=""
              className="bg-no-repeat w-full"
            />
          </div>
        </div>
        <div className="w-9/12 flex flex-col justify-center items-center pr-32 pt-10 pb-10">
          <form
            className="bg-[#E1DFEE] w-full flex flex-col justify-center items-center p-32 space-y-6 drop-shadow-xl shadow-2xl rounded-2xl h-full"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="flex flex-col justify-center items-center mb-10">
              <div className="logo-shifti w-24">
                <img src="../logo512.png" alt="" />
              </div>
              <h2 className="text-[#5E6366] text-3xl font-semibold">
                Log in to the platform.{' '}
              </h2>
            </div>
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end w-1/2"
              style={{ borderColor: errMsg !== '' ? '#AAA6C3' : '#EC1577' }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-sm font-semibold"
              >
                E-mail address
              </label>
              <input
                type="text"
                id="username"
                ref={userRef}
                placeholder="Adresse email"
                onChange={handleUserInput}
                value={user}
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                autoComplete="off"
                required
              />
            </div>

            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end w-1/2 relative"
              style={{ borderColor: errMsg !== '' ? '#AAA6C3' : '#EC1577' }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-sm font-semibold"
              >
                Password
              </label>
              <div className="flex justify-between w-full">
                <input
                  id="password"
                  type={pwdShown ? 'text' : 'password'}
                  placeholder="Mot de passe"
                  onChange={handlePwdInput}
                  className="w-11/12 bg-white text-[#AAA6C3] border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                  value={pwd}
                  required
                />
                <span className="z-20 absolute right-[2%] bottom-[2%]">
                  <button
                    type="button"
                    onClick={togglePassword}
                    className="text-[#EC1577] "
                  >
                    {pwdShown === true ? (
                      <AiOutlineEyeInvisible size={30} />
                    ) : (
                      <AiOutlineEye size={30} />
                    )}
                  </button>
                </span>
              </div>
              {errMsg !== '' && (
                <div className="absolute top-16 text-[#EC1577]">{errMsg}</div>
              )}
            </div>
            <div className="flex justify-end w-1/2">
              <Link to="/forgot-password" className={styles.formSign_in}>
                Forgot Password ?
              </Link>
            </div>
            {/* <div className="w-full ">
              <div className={styles.sftLoginFooter}>
                <Link to="/forgot-password" className={styles.formSign_in}>
                  Forgot Password ?
                </Link>
                <Link to="/signup" className={styles.formSign_in}>
                  Sign up
                </Link>
              </div>
            </div> */}
            <input
              type="submit"
              className="rounded-xl h-12 w-1/2  p-3 bg-[#EC1577] text-[#FFF] cursor-pointer hover:outline-0 hover:scale-105 transition duration-150"
              value="Sign In"
            />
            <Link
              to="/signup"
              className="border-2 border-[#EC1577] rounded-xl text-[#EC1577] p-3 cursor-pointer h-12 w-1/2 text-center hover:outline-0 hover:scale-105 transition duration-150"
            >
              Sign up
            </Link>
          </form>
        </div>
      </div>
    </section>
  );

  return content;
};
export default Login;
