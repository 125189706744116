import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShop,
  faTrash,
  faTruck,
  faPlus,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';
import { selectCurrentToken } from '../../features/auth/authSlice';

const CreateOrder = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);

  const navigate = useNavigate();

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');

  const [shops, setShops] = useState([]);
  const [shopType, setShopType] = useState('');
  const [selectedShop, setSelectedShop] = useState('');
  const [isShopImported, setIsShopImported] = useState();

  const [loading, setLoading] = useState(true);

  const [autoCompleteCustomers, setAutoCompleteCustomers] = useState('');
  const [matchingCustomerNames, setMatchingCustomerNames] = useState('');

  const [autoCompleteProducts, setAutoCompleteProducts] = useState('');
  const [matchingProductsNames, setMatchingProductsNames] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [products, setProducts] = useState([]);

  const [selectedProductForeignId, setSelectedProductForeignId] = useState('');
  const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(1);
  const [selectedImageId, setSelectedImageId] = useState('');
  const [selectedProductPrice, setSelectedProductPrice] = useState('');

  const [shippingName, setShippingName] = useState('');
  const [shippingCost, setShippingCost] = useState('');
  const [shippingMethod, setShippingMethod] = useState('');
  const [addedShippings, setAddedShippings] = useState([]);

  const [feeName, setFeeName] = useState('');
  const [feeCost, setFeeCost] = useState('');
  const [addedFees, setAddedFees] = useState([]);

  const [wooOrderStatus, setWooOrderStatus] = useState('pending');

  const [selectedShipping, setSelectedShipping] = useState(true);
  const [selectedBilling, setSelectedBilling] = useState(false);

  const [showProductModal, setShowProductModal] = useState(false);
  const [showFeeModal, setShowFeeModal] = useState(false);
  const [showShippingModal, setShowShippingModal] = useState(false);

  const [orderNote, setOrderNote] = useState('');

  const [shippingData, setShippingData] = useState({
    first_name: '',
    last_name: '',
    company: '',
    address1: '',
    city: '',
    postcode: '',
    country: '',
    email: '',
    payment_method: 'N/A',
    transaction_id: '',
  });

  const [billingData, setBillingData] = useState({
    first_name: '',
    last_name: '',
    company: '',
    address1: '',
    city: '',
    postcode: '',
    country: '',
    customer_note: '',
  });

  const wooStatusArray = [
    'Pending',
    'Processing',
    'On-hold',
    'Completed',
    'Cancelled',
    'Refunded',
    'Failed',
    'Draft',
  ];

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
  const day = currentDate.getDate();

  const getCustomers = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/customers/woo/shop/${selectedShop}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getShops = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-all-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getProducts = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/products/shop/${selectedShop}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getShops().then((shopResponse) => {
      setShops(shopResponse);
      getProducts().then((productsResponse) => {
        setProducts(productsResponse);
        getCustomers().then((customersResponse) => {
          setCustomers(customersResponse);

          if (shopType === '') {
            setShopType(shopResponse[0].type);
            setSelectedShop(shopResponse[0].id);
            setIsShopImported(shopResponse[0].is_imported);
          }
          setSelectedProductName('');
          setSelectedProductQuantity('');
          setSelectedProducts([]);
          setSelectedCustomer('');
          setMatchingProductsNames([]);
          setAutoCompleteProducts('');
          setLoading(false);
        });
      });
    });
  }, [selectedShop]);

  useEffect(() => {
    setBillingData({
      first_name: '',
      last_name: '',
      company: '',
      address1: '',
      city: '',
      postcode: '',
      country: '',
      customer_note: '',
    });

    setShippingData({
      first_name: '',
      last_name: '',
      company: '',
      address1: '',
      city: '',
      postcode: '',
      country: '',
      email: '',
      payment_method: '',
      transaction_id: '',
    });
  }, [selectedCustomer]);

  const hundleOnChangeShops = (e) => {
    setSelectedShop(e.target.value);
    setShopType(e.target.selectedOptions[0].getAttribute('type'));
    setIsShopImported(
      e.target.selectedOptions[0].getAttribute('data-imported')
    );
  };

  const hundleOnChangeCustomer = (e) => {
    const inputValue = e.target.value;
    setAutoCompleteCustomers(inputValue);

    // Filter the strings based on the input value (case-insensitive)

    if (inputValue.trim() === '') {
      setMatchingCustomerNames([]); // If the input is empty, show nothing
    } else {
      const filteredStrings = customers.filter((customer) =>
        customer.first_name.toLowerCase().startsWith(inputValue)
      );

      setMatchingCustomerNames(filteredStrings);
    }
  };

  const hundleOnChangeProduct = (e) => {
    const inputValue = e.target.value;
    setAutoCompleteProducts(inputValue);

    // Filter the strings based on the input value (case-insensitive)

    if (inputValue.trim() === '') {
      setMatchingProductsNames([]); // If the input is empty, show nothing
    } else {
      const filteredStrings = products.filter((product) =>
        product.Product.ProductLangs[0].name
          .toLowerCase()
          .startsWith(inputValue)
      );

      setMatchingProductsNames(filteredStrings);
    }
  };

  const hundleChangeSelectedProduct = (e) => {
    setSelectedProductId(e.target.value);
    setSelectedProductForeignId(
      e.target.selectedOptions[0].getAttribute('data-forein_id')
    );
    setSelectedProductName(
      e.target.selectedOptions[0].getAttribute('data-name')
    );
    setSelectedImageId(
      e.target.selectedOptions[0].getAttribute('data-image_id')
    );

    setSelectedProductPrice(
      e.target.selectedOptions[0].getAttribute('data-price')
    );
  };

  const hundleOnClickSelectedProduct = () => {
    const newSelectedProduct = {
      name: selectedProductName,
      id: selectedProductId,
      foreign_id: selectedProductForeignId,
      quantity:
        selectedProductQuantity === '' || 0 ? 1 : selectedProductQuantity,
      default_image_id: selectedImageId,
      price: selectedProductPrice,
    };

    setSelectedProducts([...selectedProducts, newSelectedProduct]);
  };

  const handleRemoveProduct = (index) => {
    const updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts.splice(index, 1);
    setSelectedProducts(updatedSelectedProducts);
  };

  /// ///////////////////////////////////// shipping hundling ///////////////////////////////////////////////
  const hundleOnClickAddShipping = () => {
    const newAddedShipping = {
      method_title: shippingName,
      method_id:
        shippingMethod.charAt(0).toUpperCase() + shippingMethod.slice(1),
      cost: shippingCost === '' || 0 ? 1 : shippingCost,
    };

    setAddedShippings([...addedShippings, newAddedShipping]);
  };

  const handleRemoveShipping = (index) => {
    const updatedSelectedProducts = [...addedShippings];
    updatedSelectedProducts.splice(index, 1);
    setAddedShippings(updatedSelectedProducts);
  };

  /// /////////////////////////////////////// fee hundling /////////////////////////////////////////

  const hundleOnClickAddFee = () => {
    const newAddedFee = {
      name: feeName,

      cost: feeCost === '' || 0 ? 1 : feeCost,
    };

    setAddedFees([...addedFees, newAddedFee]);
  };

  const handleRemoveFee = (index) => {
    const updatedSelectedFees = [...addedFees];
    updatedSelectedFees.splice(index, 1);
    setAddedFees(updatedSelectedFees);
  };

  const addProductModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">Add new product</h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setShowProductModal(false)}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <div className="flex justify-between">
          <div className="col-span-2 relative flex flex-col">
            <label htmlFor="product" className="text-sm font-semibold">
              product name
            </label>
            <select
              type="text"
              className=" focus:border-b-2  focus:border-[#EC1577]  h-10 p-1 text-[#181143] w-[100%] text-xs font-semibold mx-auto my-auto focus: border-0  hover:border-0 focus:outline-0"
              onChange={(e) => hundleChangeSelectedProduct(e)}
              id="product"
            >
              <option value="">select a product</option>
              {products.map((product) => (
                <option
                  value={product.Product.id}
                  data-forein_id={product.foreign_id}
                  data-name={product.Product.ProductLangs[0].name}
                  data-image_id={product.Product.default_image_id}
                  data-price={product.Product.price}
                >
                  {product.Product.ProductLangs[0].name}
                </option>
              ))}
            </select>
          </div>
          <div className="quanity flex flex-col items-center justify-center gap-2">
            <label htmlFor="QUANITY" className="text-sm font-semibold">
              quantity
            </label>
            <input
              type="text"
              className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[50%] text-xs font-semibold text-center mx-auto my-auto self-end"
              value={selectedProductQuantity}
              onChange={(e) => setSelectedProductQuantity(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start justify-end w-[30%]">
            <div />
            <button
              type="button"
              className="bg-[#EC1577] p-2 text-white w-[8rem] rounded-xl disabled:bg-[#E1DFEE]"
              onClick={() => hundleOnClickSelectedProduct()}
              disabled={
                selectedProductQuantity === 0 ||
                selectedProductQuantity === '' ||
                selectedProductQuantity === '0' ||
                selectedProductName === ''
              }
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const addShippingModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[60%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between w-full">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">Add new Shipping</h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setShowShippingModal(false)}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <div className="flex justify-between">
          <div className="col-span-2 relative flex flex-col items-start justify-start">
            <label htmlFor="product" className="text-sm font-semibold">
              Shipping name
            </label>
            <input
              type="text"
              className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-full text-xs font-semibold text-start mx-auto my-auto self-end"
              onChange={(e) => setShippingName(e.target.value)}
            />
          </div>
          <div className="col-span-2 relative flex flex-col">
            <label
              htmlFor="product"
              className="text-sm font-semibold text-center"
            >
              shipping method
            </label>
            <select
              type="text"
              id="shipping_payment"
              placeholder="Payment"
              className="w-full bg-white border-2 border-[#AAA6C3] h-10 rounded-xl p-1 outline-none focus:outline-none  hover:outline-none  text-md"
              onChange={(e) => setShippingMethod(e.target.value)}
            >
              <option value="">N/A</option>
              <option value="flate rate">Flate rate</option>
              <option value="Free shipping">Free shipping</option>
              <option value="local pickup">Local pickup</option>
              <option value="weight Based Shipping">
                Weight Based Shipping
              </option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="col-span-2 relative flex flex-col">
            <label
              htmlFor="product"
              className="text-sm font-semibold text-center"
            >
              Cost
            </label>
            <input
              type="text"
              className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[50%] text-xs font-semibold text-center mx-auto my-auto self-end"
              onChange={(e) => setShippingCost(e.target.value)}
            />
          </div>

          <div className="flex flex-col items-center justify-end w-[30%]">
            <div />
            <button
              type="button"
              className="bg-[#EC1577] p-2 text-white w-[8rem] rounded-xl disabled:bg-[#E1DFEE]"
              onClick={() => hundleOnClickAddShipping()}
              disabled={shippingName === '' || shippingCost === ''}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const addFeeModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-opacity-50 bg-black flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="w-[40%] bg-white rounded-xl p-4 flex flex-col space-y-2">
        <div className="flex justify-between">
          {' '}
          <h2 className="p-2 font-semibold text-[#EC1577]">Add new Fee</h2>
          <button
            type="button"
            className="p-2"
            onClick={() => setShowFeeModal(false)}
          >
            X
          </button>
        </div>

        <div className="h-[1px] mt-2 mb-2 bg-[#E1DFEE]" />
        <div className="flex justify-between">
          <div className="col-span-2 relative flex flex-col items-start justify-start">
            <label htmlFor="product" className="text-sm font-semibold">
              Fee name
            </label>
            <input
              type="text"
              className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-full text-xs font-semibold text-start mx-auto my-auto self-end"
              onChange={(e) => setFeeName(e.target.value)}
            />
          </div>
          <div className="col-span-2 relative flex flex-col">
            <label
              htmlFor="product"
              className="text-sm font-semibold text-center"
            >
              Cost
            </label>
            <input
              type="text"
              className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-[50%] text-xs font-semibold text-center mx-auto my-auto self-end"
              onChange={(e) => setFeeCost(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start justify-end w-[30%]">
            <div />
            <button
              type="button"
              className="bg-[#EC1577] p-2 text-white w-[8rem] rounded-xl disabled:bg-[#E1DFEE]"
              onClick={() => hundleOnClickAddFee()}
              disabled={feeName === '' || feeCost === ''}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return <Loader />;
  }

  const handleShippingData = (e) => {
    const { name, value } = e.target;

    setShippingData((prevShippingData) => ({
      ...prevShippingData,
      [name]: value,
    }));
  };

  const handleBillingData = (e) => {
    const { name, value } = e.target;

    setBillingData((prevBillingData) => ({
      ...prevBillingData,
      [name]: value,
    }));
  };

  const handleLoadShippingData = () => {
    const shippingAddress = selectedCustomer.Addresses.find(
      (address) => address.woo_shipping === true
    );

    setShippingData({
      first_name: shippingAddress.first_name,
      last_name: shippingAddress.last_name,
      company: shippingAddress.company,
      address1: shippingAddress.address1,
      city: shippingAddress.city,
      postcode: shippingAddress.postcode,
      country: shippingAddress.woo_country,
      email: selectedCustomer.email,
      payment_method: 'N/A',
    });
  };

  const handleLoadBillingData = () => {
    const billingAddress = selectedCustomer.Addresses.find(
      (address) => address.woo_billing === true
    );

    setBillingData({
      first_name: billingAddress.first_name,
      last_name: billingAddress.last_name,
      company: billingAddress.company,
      address1: billingAddress.address1,
      city: billingAddress.city,
      postcode: billingAddress.postcode,
      country: billingAddress.woo_country,
      customer_note: '',
    });
  };

  function checkIsCustomerShippingAddressEqualToCurrentShippingData() {
    if (selectedCustomer !== '') {
      const shippingAddress = selectedCustomer.Addresses.find(
        (address) => address.woo_shipping === true
      );

      const customerShippingAddress = {
        first_name: shippingAddress.first_name,
        last_name: shippingAddress.last_name,
        company: shippingAddress.company,
        address1: shippingAddress.address1,
        city: shippingAddress.city,
        postcode: shippingAddress.postcode,
        country: shippingAddress.woo_country,
        email: selectedCustomer.email,
      };

      const areEqual =
        JSON.stringify(customerShippingAddress) ===
        JSON.stringify(shippingData);

      return areEqual;
    }
  }

  function checkIsCustomerBillingAddressEqualToCurrentBillingData() {
    if (selectedCustomer !== '') {
      const billingAddress = selectedCustomer.Addresses.find(
        (address) => address.woo_shipping === false
      );

      const customerbillingAddress = {
        first_name: billingAddress.first_name,
        last_name: billingAddress.last_name,
        company: billingAddress.company,
        address1: billingAddress.address1,
        city: billingAddress.city,
        postcode: billingAddress.postcode,
        country: billingAddress.country,
      };

      const areEqual =
        JSON.stringify(customerbillingAddress) === JSON.stringify(billingData);
      return areEqual;
    }
  }

  function calculateProductsTotal() {
    if (selectedProducts.length > 0) {
      const sum = selectedProducts.reduce(
        (p, c) => parseFloat(p) + parseFloat(c.price) * parseInt(c.quantity),
        0
      );

      return sum;
    }
    return 0;
  }

  function calculateShippingTotal() {
    if (addedShippings.length > 0) {
      const sum = addedShippings.reduce(
        (p, c) => parseFloat(p) + parseFloat(c.cost),
        0
      );
      return sum;
    }
    return 0;
  }

  function calculateFeesTotal() {
    if (addedFees.length > 0) {
      const sum = addedFees.reduce(
        (p, c) => parseFloat(p) + parseFloat(c.cost),
        0
      );
      return sum;
    }
    return 0;
  }

  function calculateToTalOrder() {
    const total =
      calculateShippingTotal() +
      calculateFeesTotal() +
      calculateProductsTotal();
    return total;
  }

  function transFormProductsDataForAPIData() {
    if (selectedProducts.length > 0) {
      const transformedProducts = selectedProducts.map((product) => ({
        product_id: parseInt(product.id),
        quantity: parseInt(product.quantity),
      }));

      return transformedProducts;
    }
    return [];
  }

  function transFormCarriersDataForAPIData() {
    if (addedShippings.length > 0) {
      const transformedShippings = addedShippings.map((shipping) => ({
        method_title: shipping.method_title,
        method_id: shipping.method_id,
        total: shipping.cost,
      }));

      return transformedShippings;
    }
    return [];
  }

  function transFormFeesDataForAPIData() {
    if (addedFees.length > 0) {
      const transformedFees = addedFees.map((fee) => ({
        name: fee.name,
        total: fee.cost,
      }));

      return transformedFees;
    }
    return [];
  }

  const hundleCreateOrder = () => {
    const orderData = JSON.stringify({
      shop_id: selectedShop,
      lang_id: 1,
      set_paid: wooOrderStatus === 'completed',
      currency: 'TND',
      customer_id: selectedCustomer !== '' ? selectedCustomer.id : 0,
      status: wooOrderStatus,
      customer_note: billingData.customer_note,
      first_name_billing: billingData.first_name,
      last_name_billing: billingData.last_name,
      address_1_billing: billingData.address1,
      address_2_billing: 'TEST',
      company_billing: 'TES',
      city_billing: billingData.city,
      state_billing: 'TTEST',
      postcode_billing: billingData.postcode,
      country_billing: billingData.country,
      email_billing: billingData.email,
      phone_billing: '97232233',
      first_name_shipping: shippingData.first_name,
      last_name_shipping: shippingData.last_name,
      address_1_shipping: shippingData.address1,
      address_2_shipping: 'test',
      company_shipping: 'test',
      phone_shipping: '97232233',
      city_shipping: shippingData.city,
      state_shipping: 'TES',
      postcode_shipping: shippingData.postcode,
      country_shipping: 'TN',
      payment: shippingData.payment_method,
      products: transFormProductsDataForAPIData(),
      carriers: transFormCarriersDataForAPIData(),
      fees: transFormFeesDataForAPIData(),
      order_note: orderNote,
    });

    const idToast = toast.loading('Please wait...');

    const config = {
      method: 'post',
      url: `${apiURL}/orders/woocommerce`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: orderData,
    };
    api
      .request(config)
      .then((response) => {
        if (response.status === 201) {
          toast.update(idToast, {
            render: response.data.message,
            type: 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.assign(
              `/order/${response.data.order_id}/${selectedShop}`
            );
          }, 1500);
        }
      })
      .catch((error) => {
        toast.update(idToast, {
          render: error.response.data.message,
          type: 'failed',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  return (
    <div>
      <div className="w-[95%] sticky top-[5rem] md:ml-[3%] ml-[3.5%] z-40">
        <div className="filter-by-shop mb-5 relative md:w-full w-[22rem]">
          <select
            className="shopSelect text-center"
            onChange={hundleOnChangeShops}
          >
            {shops.map((shop) => (
              <option
                type={shop.type}
                data-imported={shop.is_imported}
                value={shop.id}
                className="text-[#EC1577] text-center"
                key={shop.id}
              >
                {shop.name}
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            icon={faShop}
            className="h-[1.5rem] w-[1.5rem] scale-100 md:left-[42%] md:top-[19%] top-[19%] left-[30%]  absolute  text-[#AAA6C3] text-opacity-70 checked-1 transition "
          />
        </div>
      </div>

      {shopType === 'woocommerce' && (
        <>
          {' '}
          {/* search a customer block */}
          {selectedCustomer === '' && (
            <div className="md:mt-24 mt-24 md:ml-16 p-5 md:mr-16  md:p-5 rounded-3xl space-y-5">
              <div className="flex" />
              <div className="space-y-5">
                <div className='className="label-container"'>
                  {' '}
                  <label
                    htmlFor="customer"
                    className="text-[#EC1577] text-xl font-semibold"
                  >
                    Search for a customer
                  </label>
                </div>

                <div className="input-container">
                  <input
                    type="text"
                    id="customer"
                    className="border-2 border-[#AAA6C3] rounded-xl h-10 p-1 text-[#181143] w-1/4 text-xs font-semibold text-start mx-auto my-auto self-end"
                    onChange={hundleOnChangeCustomer}
                    value={autoCompleteCustomers}
                  />
                </div>
              </div>
              {selectedCustomer === '' && (
                <div className="grid grid-cols-4 ">
                  {matchingCustomerNames !== '' &&
                    matchingCustomerNames.map((customerData) => (
                      <div
                        className="border-2 border-[#AAA6C3] rounded-xl bg-white flex flex-col mr-2 mt-2 mb-2 p-4 space-y-4"
                        key={customerData.id}
                      >
                        <div className="flex justify-between">
                          <div className="text-[#EC1577] font-semibold">
                            {customerData.first_name} {customerData.last_name}
                          </div>{' '}
                          <div className="text-[#AAA6C3]">
                            #{customerData.id}
                          </div>
                        </div>
                        <div className="bg-[#AAA6C3] border-1  w-full" />
                        <div className="flex">
                          <div>{customerData.email}</div>{' '}
                        </div>
                        <div className="bg-[#AAA6C3] border-1  w-full" />
                        <div className="flex justify-between">
                          <div>
                            <button
                              type="button"
                              className="bg-[#181143] text-white w-full rounded-xl p-2 "
                            >
                              Details
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="bg-[#EC1577] text-white w-full rounded-xl p-2"
                              onClick={() => {
                                setSelectedCustomer(customerData);
                                setAutoCompleteCustomers('');
                                setMatchingCustomerNames([]);
                              }}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
          {selectedCustomer !== '' && (
            <>
              <div className="order-details flex justify-between md:mt-2 mt-2 md:ml-16 p-5  md:p-5 mr-6  space-y-5">
                <div className="order detail flex flex-col">
                  <p className="text-xl font-bold">Order detail </p>
                  <p className="text-[#AAA6C3] text-xl ">
                    creation date: {year}-{month}-{day}
                  </p>
                </div>{' '}
                <div className="order status w-2/5 border-1 border-[#AAA6C3] flex ">
                  <div
                    className={` ${
                      wooOrderStatus === 'pending' && `bg-[#FFB629]`
                    } ${wooOrderStatus === 'on-hold' && `bg-[#FFB629]`} ${
                      wooOrderStatus === 'draft' && `bg-[#FFB629]`
                    } ${wooOrderStatus === 'processing' && `bg-[#FFB629]`}
                    ${wooOrderStatus === 'cancelled' && `bg-red-600`} ${
                      wooOrderStatus === 'failed' && `bg-red-600`
                    } ${wooOrderStatus === 'completed' && `bg-green-500`} ${
                      wooOrderStatus === 'refunded' && `bg-green-500`
                    } w-[3%] `}
                  />
                  <div className="flex flex-col items-end w-[97%]">
                    <p className="bg-[#fff] text-start w-full pl-2 pt-1 text-[#AAA6C3]">
                      Order Status
                    </p>
                    <select
                      className="bg-[#fff]  h-[37px]  w-full p-1"
                      defaultValue={wooOrderStatus}
                      onChange={(e) => setWooOrderStatus(e.target.value)}
                    >
                      {wooStatusArray.map((wooStatus, index) => (
                        <option value={wooStatus.toLowerCase()} key={index}>
                          {wooStatus === 'On-hold' ? 'On hold' : wooStatus}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="customer flex justify-between">
                <div className=" md:mt-2 mt-2 md:ml-16 p-5 w-3/5 md:p-5 rounded-3xl space-y-5">
                  {' '}
                  {selectedCustomer !== '' && (
                    <>
                      <div className="w-1/8">
                        <button
                          className="bg-[#181143] text-white w-[14rem] rounded-xl p-2 "
                          type="button"
                          onClick={() => setSelectedCustomer('')}
                        >
                          Change customer
                        </button>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-col w-full">
                            <div className="w-full">
                              <div
                                className="border-2 border-[#AAA6C3] bg-white rounded-xl flex flex-col mr-2 mt-2 mb-2 p-4 space-y-4"
                                key={selectedCustomer.id}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#EC1577] font-semibold">
                                    {selectedCustomer.first_name}{' '}
                                    {selectedCustomer.last_name}
                                  </div>{' '}
                                  <div className="text-[#AAA6C3]">
                                    #{selectedCustomer.id}
                                  </div>
                                </div>
                                <div className="bg-[#AAA6C3] border-1  w-full" />
                                <div className="flex">
                                  <div>{selectedCustomer.email}</div>{' '}
                                </div>
                                <div className="bg-[#AAA6C3] border-1  w-full" />
                                <div className="flex justify-between">
                                  {/* <div>
                                    <button
                                      type="button"
                                      className="bg-[#181143] text-white w-full rounded-xl p-2 "
                                    >
                                      Details
                                    </button>
                                  </div> */}
                                  <div />
                                </div>
                              </div>
                            </div>
                            <div className="grid grid-cols-4 ">
                              {matchingCustomerNames !== '' &&
                                matchingCustomerNames.map((customerData) => (
                                  <div
                                    className="border-2 border-[#AAA6C3] bg-white rounded-xl flex flex-col mr-2 mt-2 mb-2 p-4 space-y-4"
                                    key={customerData.id}
                                  >
                                    <div className="flex justify-between">
                                      <div className="text-[#EC1577] font-semibold">
                                        {customerData.first_name}{' '}
                                        {customerData.last_name}
                                      </div>{' '}
                                      <div className="text-[#AAA6C3]">
                                        #{customerData.id}
                                      </div>
                                    </div>
                                    <div className="bg-[#AAA6C3] border-1  w-full" />
                                    <div className="flex">
                                      <div>{customerData.email}</div>{' '}
                                    </div>
                                    <div className="bg-[#AAA6C3] border-1  w-full" />
                                    <div className="flex justify-between">
                                      <div>
                                        <button
                                          type="button"
                                          className="bg-[#181143] text-white w-full rounded-xl p-2 "
                                        >
                                          Details
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          className="bg-[#EC1577] text-white w-full rounded-xl p-2"
                                          onClick={() => {
                                            setSelectedCustomer(customerData);
                                            setAutoCompleteCustomers('');
                                            setMatchingCustomerNames([]);
                                          }}
                                        >
                                          Select
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="addresses flex flex-col    md:mt-2 mt-2 md:ml-16 p-5  md:p-5 mr-6  space-y-7 ">
                <div className="flex justify-start ml-16">
                  <h2 className="text-xl font-bold">Adresses :</h2>
                </div>
                <div className="flex justify-center">
                  <div className="w-1/5 flex justify-between">
                    <div>
                      <button
                        className={`text-xl ${
                          selectedShipping === true &&
                          `underline underline-offset-8 text-[#EC1577]`
                        }`}
                        type="button"
                        onClick={() => {
                          setSelectedShipping(true);
                          setSelectedBilling(false);
                        }}
                      >
                        Shipping
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className={`text-xl ${
                          selectedShipping === false &&
                          `underline underline-offset-8 text-[#EC1577]`
                        }`}
                        onClick={() => {
                          setSelectedBilling(true);
                          setSelectedShipping(false);
                        }}
                      >
                        Billing
                      </button>
                    </div>
                  </div>
                </div>
                {selectedShipping === true && (
                  <>
                    {checkIsCustomerShippingAddressEqualToCurrentShippingData() ===
                    true ? null : (
                      <button
                        type="button"
                        className="text-[#3974B0] text-start text-xl underline underline-offset-4"
                        onClick={() => handleLoadShippingData()}
                      >
                        Load shipping address
                      </button>
                    )}

                    <div className="grid grid-rows-4 grid-flow-col gap-4 ">
                      <div className="col-span-1 flex flex-col  border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_first_name"
                          className="text-sm font-semibold font-semibold"
                        >
                          first Name
                        </label>
                        <input
                          type="text"
                          id="shipping_first_name"
                          placeholder="First name"
                          className="w-full bg-white  border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          value={shippingData.first_name}
                          name="first_name"
                          onChange={(e) => handleShippingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_last_name"
                          className="text-sm font-semibold"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="shipping_last_name"
                          placeholder="Last name"
                          value={shippingData.last_name}
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          name="last_name"
                          onChange={(e) => handleShippingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_company"
                          className="text-sm font-semibold"
                        >
                          Company
                        </label>
                        <input
                          type="text"
                          id="shipping_company"
                          placeholder="Company name"
                          value={shippingData.company}
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          name="company"
                          onChange={(e) => handleShippingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_address1"
                          className="text-sm font-semibold"
                        >
                          Address 1
                        </label>
                        <input
                          type="text"
                          id="shipping_address1"
                          placeholder="address 1"
                          value={shippingData.address1}
                          className=" w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          name="address1"
                          onChange={(e) => handleShippingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_city"
                          className="text-sm font-semibold"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          id="shipping_city"
                          placeholder="City name"
                          value={shippingData.city}
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          name="city"
                          onChange={(e) => handleShippingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_post_code"
                          className="text-sm font-semibold"
                        >
                          postCode / Zip
                        </label>
                        <input
                          type="text"
                          id="shipping_post_code"
                          placeholder="postCode"
                          value={shippingData.postcode}
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          name="postcode"
                          onChange={(e) => handleShippingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_country"
                          className="text-sm font-semibold"
                        >
                          Country / region
                        </label>
                        <input
                          type="text"
                          id="shipping_name"
                          placeholder="Name"
                          value={shippingData.woo_country}
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          name="country"
                          onChange={(e) => handleShippingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_email"
                          className="text-sm font-semibold"
                        >
                          email
                        </label>
                        <input
                          type="email"
                          id="shipping_email"
                          placeholder="Email address"
                          value={shippingData.email}
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          name="email"
                          onChange={(e) => handleShippingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_payment"
                          className="text-sm font-semibold"
                        >
                          Payment method
                        </label>
                        <select
                          type="text"
                          id="shipping_payment"
                          placeholder="Payment"
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          name="payment_method"
                          onChange={(e) => handleShippingData(e)}
                        >
                          <option value="">N/A</option>
                          <option value="Paiement à la livraison">
                            Paiement à la livraison
                          </option>
                          <option value="Carte de crédit">
                            Carte de crédit
                          </option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="shipping_transaction"
                          className="text-sm font-semibold"
                        >
                          Transaction ID
                        </label>
                        <input
                          type="text"
                          id="shipping_transaction"
                          placeholder="transaction id"
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          name="transaction_id"
                          onChange={(e) => handleShippingData(e)}
                        />
                      </div>
                    </div>
                  </>
                )}

                {selectedBilling === true && (
                  <>
                    {checkIsCustomerBillingAddressEqualToCurrentBillingData() ===
                    true ? null : (
                      <button
                        type="button"
                        className="text-[#3974B0] text-start text-xl underline underline-offset-4"
                        onClick={() => handleLoadBillingData()}
                      >
                        Load billing address
                      </button>
                    )}
                    <div className="grid grid-rows-4 grid-flow-col gap-4 ">
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="billing_first_name"
                          className="text-sm font-semibold"
                        >
                          first Name
                        </label>
                        <input
                          type="text"
                          id="billing_first_name"
                          placeholder="First name"
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          value={billingData.first_name}
                          name="first_name"
                          onChange={(e) => handleBillingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="billing_last_name"
                          className="text-sm font-semibold"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="billing_last_name"
                          placeholder="Last name"
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          value={billingData.last_name}
                          name="last_name"
                          onChange={(e) => handleBillingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="billing_company"
                          className="text-sm font-semibold"
                        >
                          Company
                        </label>
                        <input
                          type="text"
                          id="billing_company"
                          placeholder="Company name"
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          value={billingData.company}
                          name="company"
                          onChange={(e) => handleBillingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="billing_address1"
                          className="text-sm font-semibold"
                        >
                          Address 1
                        </label>
                        <input
                          type="text"
                          id="billing_address1"
                          placeholder="address 1"
                          className=" w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          value={billingData.address1}
                          name="address1"
                          onChange={(e) => handleBillingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="billing_city"
                          className="text-sm font-semibold"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          id="billing_city"
                          placeholder="City name"
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          value={billingData.city}
                          name="city"
                          onChange={(e) => handleBillingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="billing_post_code"
                          className="text-sm font-semibold"
                        >
                          postCode / Zip
                        </label>
                        <input
                          type="text"
                          id="billing_post_code"
                          placeholder="postCode"
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          value={billingData.postcode}
                          name="postcode"
                          onChange={(e) => handleBillingData(e)}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end">
                        <label
                          htmlFor="billing_country"
                          className="text-sm font-semibold"
                        >
                          Country / region
                        </label>
                        <input
                          type="text"
                          id="billing_name"
                          placeholder="Name"
                          className="w-full bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                          value={billingData.woo_country}
                          name="country"
                          onChange={(e) => handleBillingData(e)}
                        />
                      </div>

                      <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end row-span-2">
                        <label
                          htmlFor="billing_note"
                          className="text-sm font-semibold"
                        >
                          Customer provided note
                        </label>
                        <textarea
                          type="text"
                          id="billing_note"
                          className=" bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md h-full w-full"
                          name="customer_note"
                          value={billingData.customer_note}
                          onChange={(e) => handleBillingData(e)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="products flex flex-col   md:mt-2 mt-2 md:ml-16 p-5  md:p-5 mr-6  space-y-7">
                <div className="flex justify-start ml-16">
                  <h2 className="text-xl font-bold">Product(s) :</h2>
                </div>
                <div className="bg-white p-4 space-y-5 rounded-xl">
                  <div className="grid grid-cols-7 p-2 text-[#ABAFB1] text-lg">
                    <div className="col-span-1 start">
                      <p>Image</p>
                    </div>
                    <div className="col-span-2 start">
                      <p>Name</p>
                    </div>
                    <div className="col-span-1 text-center">
                      <p>Price</p>
                    </div>
                    <div className="col-span-1  text-center">
                      <p>Quantity:</p>
                    </div>
                    <div className="col-span-1  text-center">
                      <p>Total</p>
                    </div>
                    <div>
                      <div />
                    </div>
                  </div>
                  <div className="products-list space-y-2">
                    <div className=" flex flex-col gap-4">
                      <div className="text-[#EC1577] font-semibold text-xl">
                        Products({selectedProducts.length})
                      </div>
                      <button
                        type="button"
                        className="text-white bg-[#181143] w-[12rem] relative p-2 rounded-lg"
                        onClick={() => setShowProductModal(true)}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className=" w-6 h-6 scale-100  left-[5%] bottom-2  bg-white rounded-full  absolute  text-[#181143] text-opacity-70 checked-1 transition "
                        />
                        <p>Add product</p>
                      </button>
                      {showProductModal === true && addProductModal()}
                    </div>

                    <div className="grid grid-cols-7 ">
                      {selectedProducts.map((product, index) => (
                        <>
                          <div className="col-span-1 w-16 h-16 ">
                            <img
                              src={`${apiURL}/images/${selectedShop}/${product.foreign_id}/${product.default_image_id}`}
                              alt={product.product_name}
                              className="p-2 w-full h-full"
                            />
                          </div>
                          <div className="col-span-2 flex justify-start items-center text-start text-sm font-semibold">
                            <p>{product.name}</p>
                          </div>
                          <div className="col-span-1 flex items-center justify-center text-center text-sm font-semibold">
                            <p>{product.price}</p>
                          </div>
                          <div className="col-span-1 flex items-center justify-center text-center text-sm font-semibold">
                            <p>{product.quantity}</p>
                          </div>
                          <div className="col-span-1 flex items-center justify-center text-center text-sm font-semibold">
                            {product.quantity * product.price}
                          </div>

                          <div className="col-span-1 flex justify-center">
                            <button
                              type="button"
                              className="relative  w-8 h-8"
                              onClick={() => handleRemoveProduct(index)}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className=" w-6 h-6 scale-100  left-[30%] bottom-1 top-4  absolute  text-[#EC1577] text-opacity-70 checked-1 transition "
                              />
                            </button>
                          </div>
                          <div className="col-span-7 " />
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="shipping-list space-y-2">
                    <div className="flex flex-col gap-4">
                      <div className="text-[#EC1577] font-semibold text-xl">
                        Shippings({addedShippings.length})
                      </div>
                      <button
                        type="button"
                        className="text-white bg-[#181143] w-[12rem] relative p-2 rounded-lg"
                        onClick={() => setShowShippingModal(true)}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className=" w-6 h-6 scale-100  left-[5%] bottom-2  bg-white rounded-full  absolute  text-[#181143] text-opacity-70 checked-1 transition "
                        />
                        <p>Add shipping</p>
                      </button>
                    </div>

                    {showShippingModal === true && addShippingModal()}

                    <div className="grid grid-cols-7">
                      {addedShippings.map((shipping, index) => (
                        <>
                          <div className="col-span-1 flex justify-between">
                            <div className="relative  h-8 w-8">
                              {' '}
                              <FontAwesomeIcon
                                icon={faTruck}
                                className=" w-6 h-6 scale-100  left-[30%] top-1 absolute  text-[#181143] text-opacity-70 checked-1 transition "
                              />
                            </div>
                          </div>
                          <div className="w-full col-span-2 flex flex-col items-center justify-center ">
                            <div className="text-start w-full text-sm font-semibold">
                              {shipping.method_title}
                            </div>
                          </div>

                          <div />
                          <div />
                          <div className="col-span-1 text-center flex flex-col justify-center text-sm font-semibold">
                            <div> {shipping.cost}</div>
                          </div>
                          <div className="col-span-1 flex justify-center">
                            <button
                              type="button"
                              className="relative  w-8 h-8"
                              onClick={() => handleRemoveShipping(index)}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className=" w-6 h-6 scale-100  left-[30%] bottom-1  absolute  text-[#EC1577] text-opacity-70 checked-1 transition "
                              />
                            </button>
                          </div>
                          <div className="col-span-7 " />
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="fee-list space-y-2">
                    <div className="flex flex-col gap-4">
                      <div className="text-[#EC1577] font-semibold text-xl">
                        Fees({addedFees.length})
                      </div>
                    </div>
                    <button
                      type="button"
                      className="text-white bg-[#181143] w-[12rem] relative p-2 rounded-lg flex justify-start"
                      onClick={() => setShowFeeModal(true)}
                    >
                      <div className="w-[30%]">
                        <FontAwesomeIcon
                          icon={faPlus}
                          className=" w-6 h-6 scale-100  left-[5%] bottom-2  bg-white rounded-full  absolute  text-[#181143] text-opacity-70 checked-1 transition "
                        />
                      </div>
                      <p className="text-start w-full">Add fee</p>
                    </button>

                    {showFeeModal === true && addFeeModal()}
                    <div className="grid grid-cols-7  ">
                      {addedFees.map((fee, index) => (
                        <>
                          <div className=" flex justify-between">
                            <div className="w-8 h-8 relative">
                              <FontAwesomeIcon
                                icon={faPlus}
                                className=" w-6 h-6 scale-100  left-[30%] bottom-1  absolute  text-[#181143] text-opacity-70 checked-1 transition "
                              />
                            </div>
                          </div>
                          <div className="w-full col-span-2 flex flex-col items-center justify-center  ">
                            <div className="text-start w-full text-sm font-semibold">
                              {fee.name}
                            </div>
                          </div>
                          <div />
                          <div />
                          <div className="col-span-1 text-center flex flex-col justify-center">
                            <div className="text-sm font-semibold">
                              {' '}
                              {fee.cost}
                            </div>
                          </div>
                          <div className="col-span-1 flex justify-center ">
                            <button
                              type="button"
                              className="relative  w-8 h-8"
                              onClick={() => handleRemoveFee(index)}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className=" w-6 h-6 scale-100  left-[30%] bottom-1  absolute  text-[#EC1577] text-opacity-70 checked-1 transition "
                              />
                            </button>
                          </div>
                        </>
                      ))}
                    </div>
                    <div className="flex justify-end">
                      <div className="flex flex-col justify-center items-center w-[50%] border-t-1 border-[#AAA6C3] mt-4 ">
                        <div className="flex  justify-between mt-4 w-[60%]">
                          <p className="font-semibold">product(s) Subtotal</p>{' '}
                          <p>: {calculateProductsTotal()} dt</p>
                        </div>
                        <div className="flex  justify-between mt-4 w-[60%]">
                          <p className="font-semibold">shipping Subtotal</p>{' '}
                          <p>: {calculateShippingTotal()} dt</p>
                        </div>
                        <div className="flex  justify-between mt-4 w-[60%]">
                          <p className="font-semibold">Fees Subtotal</p>{' '}
                          <p>: {calculateFeesTotal()} dt</p>
                        </div>

                        <div className="flex justify-between mt-4 w-[60%]">
                          <p className="font-semibold">Order Total</p>{' '}
                          <p>: {calculateToTalOrder()} dt</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end row-span-2">
                  <label
                    htmlFor="billing_note"
                    className="text-sm font-semibold"
                  >
                    Order note
                  </label>
                  <textarea
                    type="text"
                    id="billing_note"
                    className=" bg-white border-none outline-none focus:outline-none focus:border-none hover:outline-none hover:border-none text-md h-[8vw] w-full"
                    onChange={(e) => setOrderNote(e.target.value)}
                  />
                </div>
                <div className="w-full flex justify-center">
                  <button
                    type="button"
                    className="bg-[#EC1577] p-2 w-full text-white rounded-3xl"
                    onClick={() => hundleCreateOrder()}
                  >
                    Create order
                  </button>
                </div>
                <div className="w-full flex justify-center">
                  <button
                    type="button"
                    className=" border-2 border-[#EC1577] p-2 w-full text-[#EC1577] rounded-3xl"
                    onClick={() => navigate('/orders')}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {shopType === 'prestashop' && (
        <div className="flex flex-col justify-between items-center">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="h-16 w-16  text-[#EC1577]  pointer-events-none transition scale-75"
          />{' '}
          <span className="text-[#EC1577] text-3xl font-semibold ">
            this feature is still in development!
          </span>
        </div>
      )}
    </div>
  );
};
export default CreateOrder;
