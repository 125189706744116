import React from 'react';
import PropTypes from 'prop-types';
import { useStateContext } from '../contexts/ContextProvider';

const Button = ({
  icon,
  bgColor,
  color,
  bgHoverColor,
  size,
  text,
  borderRadius,
  width,
}) => {
  const { setIsClicked, initialState } = useStateContext();

  return (
    <button
      type="button"
      onClick={() => setIsClicked(initialState)}
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      {icon} {text}
    </button>
  );
};
Button.propTypes = {
  icon: PropTypes.element.isRequired,
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  bgHoverColor: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  borderRadius: PropTypes.object.isRequired,
  width: PropTypes.object.isRequired,
};
export default Button;
