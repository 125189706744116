import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../utils/apiService';

const apiURL = process.env.REACT_APP_API_URL;

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault(); // 👈️ prevent page refresh
    if (!validatePassword(password)) {
      setPasswordErrorMsg(
        'Password must have at least 8 characters, including one uppercase letter, one lowercase letter, and one number.'
      );
      return;
    }
    setPasswordErrorMsg('');
    if (password !== confirmPassword) {
      setConfirmPasswordErrorMsg(
        'Passwords do not match. Please make sure your passwords match.'
      );
      return;
    }
    setConfirmPasswordErrorMsg('');
    const formdata = JSON.stringify({
      password,
      confirmPassword,
      token,
    });
    const config = {
      method: 'patch',
      url: `${apiURL}/password/reset`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: formdata,
    };
    api
      .request(config)
      .then(() => {
        toast.success('Password changed successfully!');
        navigate('/login');
      })
      .catch((error) => {
        toast.error('Error occured!');
      });
  };

  const validatePassword = (pwd) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(pwd);
  };

  return (
    <div className="flex h-[100vh]">
      <div className="w-3/12  flex flex-col items-center justify-start p-10">
        <div className="image-div w-full bg-[#181144] rounded-3xl  flex justify-center h-full items-center pr-32 ">
          <img src="../register2.png" alt="" className="bg-no-repeat w-full" />
        </div>
      </div>
      <div className="w-9/12 ">
        <div className="flex flex-col justify-center items-center pr-32 h-[100vh]  pt-10 pb-10">
          <form className="bg-[#E1DFEE]  flex flex-col justify-center items-center w-full pt-5 pb-5 h-full  space-y-6 drop-shadow-xl shadow-2xl rounded-2xl">
            <div className="title flex flex-col justify-center items-center space-y-2">
              <h1 className="text-[#181144] text-3xl font-bold">
                Reset password
              </h1>
            </div>
            <div className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end w-2/3 relative">
              <div>
                <label htmlFor="FirstName" className="text-l font-semibold">
                  New Password
                </label>
                <div className="flex justify-between w-full">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="New password"
                    onChange={(event) => setPassword(event.target.value)}
                    className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                  />
                </div>
              </div>
              <div style={{ color: '#FF9494' }}>{passwordErrorMsg}</div>
              <span className="z-20 absolute right-[2%]">
                <button
                  type="button"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                  className="text-[#EC1577] "
                >
                  {showPassword === true ? (
                    <AiOutlineEyeInvisible size={30} />
                  ) : (
                    <AiOutlineEye size={30} />
                  )}
                </button>
              </span>
            </div>
            <div className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end w-2/3 relative">
              <div>
                <label htmlFor="FirstName" className="text-l font-semibold">
                  Confirm New Password
                </label>
                <div className="flex justify-between w-full">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Confirm new password"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-md"
                  />
                </div>
              </div>
              <div style={{ color: '#FF9494' }}>{confirmPasswordErrorMsg}</div>
              <span className="z-20 absolute right-[2%]">
                <button
                  type="button"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                  className="text-[#EC1577] "
                >
                  {showPassword === true ? (
                    <AiOutlineEyeInvisible size={30} />
                  ) : (
                    <AiOutlineEye size={30} />
                  )}
                </button>
              </span>
            </div>
            <input
              type="submit"
              className="border-2 border-[#EC1577] rounded-xl text-[#EC1577] p-3 cursor-pointer h-12 w-2/3 text-center"
              value="Reset password"
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
