import React from 'react';
import { useNavigate } from 'react-router-dom';
import shopIcon from '../img/store.png';

const ShopNotImported = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white shadow-xl flex justify-center flex-col items-center w-1/2 m-auto mt-20 rounded-xl gap-2 p-4">
      <img
        src={shopIcon}
        className="rounded-full"
        width="170"
        height="auto"
        alt=""
      />
      <span className="text-xl text-shifti-blue">
        No shops have been imported yet.
      </span>
      <p className="text-gray-400">
        Add a new shop by simply clicking the button below.
      </p>
      <button
        type="button"
        onClick={() => navigate('/shops')}
        className="addButton w-[10rem] mt-4"
      >
        Add shop
      </button>
    </div>
  );
};

export default ShopNotImported;
