import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import {
  faTrash,
  faPencil,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import api from '../../utils/apiService';
import prestashopLogo from '../../img/prestaShop.png';
import shopifyLogo from '../../img/shopify.png';
import woocommerceLogo from '../../img/woocommerce.png';
import { selectCurrentToken } from '../../features/auth/authSlice';
import Loader from '../../components/Loader';

const apiURL = process.env.REACT_APP_API_URL;

function CreateShopInfos({
  shopCategories,
  setCurrentTab,
  currentTab,
  formData,
  setFormData,
  shops,
  setShops,
  employees,
  setEmployees,
  extraEmployees,
  setExtraEmployees,
  extraShops,
  setExtraShops,
}) {
  const token = useSelector(selectCurrentToken);

  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [editEmployee, setEditEmployee] = useState('');
  const [showAddShopModal, setShowAddShopModal] = useState(false);
  const [showEditShopModal, setShowEditShopModal] = useState(false);
  const [pwdShown, setPwdShown] = useState(false);
  const [pwdCShown, setPwdCShown] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState({});
  const [errors, setErrors] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const getPlan = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/plans/${formData.plan_id}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setPlan(response.data);
        setLoading(false);
      })
      .catch((error) => JSON.stringify(error));
  };

  const {
    register: registerAddEmployee,
    formState: { errors: errorsAddEmployee },
    handleSubmit: handleSubmitAddEmployee,
    reset: resetAddEmployee,
  } = useForm();
  const {
    register: registerEditEmployee,
    formState: { errors: errorsEditEmployee },
    handleSubmit: handleSubmitEditEmployee,
    reset: resetEditEmployee,
  } = useForm();
  const {
    register: registerAddShop,
    formState: { errors: errorsAddShop },
    handleSubmit: handleSubmitAddShop,
    reset: resetAddShop,
  } = useForm();
  const {
    register: registerEditShop,
    formState: { errors: errorsEditShop },
    handleSubmit: handleSubmitEditShop,
    reset: resetEditShop,
  } = useForm();

  useEffect(() => {
    resetAddEmployee();
    resetEditEmployee();
    resetAddShop();
    resetEditShop();
    setErrors('');
  }, [employees, editEmployee, showAddShopModal, showEditShopModal]);

  useEffect(() => {
    getPlan();
  }, []);

  const [types, setTypes] = useState([
    {
      id: 1,
      name: 'Shopify',
      type: 'shopify',
      logo: shopifyLogo,
      active: false,
      disabled: true,
    },
    {
      id: 2,
      name: 'Woocommerce',
      type: 'woocommerce',
      logo: woocommerceLogo,
      disabled: false,
    },
    {
      id: 5,
      name: 'Prestashop',
      type: 'prestashop',
      logo: prestashopLogo,
      disabled: false,
    },
  ]);

  const handleShopType = (e, type) => {
    if (e.target.checked) {
      setSelectedType(type);
    } else {
      setSelectedType(null);
    }
  };

  const hundleAddEmployee = async (data) => {
    const decoded = jwtDecode(token);
    setLoadingSubmit(true);
    const newEmployee = {
      first_name: data.name,
      last_name: data.lastname,
      email: data.email,
      phone: data.phone,
      is_admin: false,
      password: data.password,
    };

    const checkdata = {
      username: `${newEmployee.first_name}@${newEmployee.last_name}`,
      email: newEmployee.email,
    };

    const config = {
      method: 'post',
      url: `${apiURL}/user/checkDuplicate/`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: checkdata,
    };

    await api
      .request(config)
      .then(() => {
        setLoadingSubmit(false);
        setEmployees([...employees, newEmployee]);
        setShowAddEmployeeModal(false);
      })
      .catch((error) => {
        setLoadingSubmit(false);
        setErrors(error.response.data.message);
      });
  };

  const hundledeleteEmployee = (index) => {
    const updatedEmployees = [...employees];

    updatedEmployees.splice(index, 1);
    setEmployees(updatedEmployees);
  };

  const hundleEditEmployee = async (data) => {
    const decoded = jwtDecode(token);
    setLoadingSubmit(true);
    const editedEmployeeData = {
      first_name: data.name,
      last_name: data.lastname,
      email: data.email,
      phone: data.phone,
      is_admin: false,
      password: data.password,
    };

    const checkdata = {
      username: `${editedEmployeeData.first_name}@${editedEmployeeData.last_name}`,
      email: editedEmployeeData.email,
    };

    const config = {
      method: 'post',
      url: `${apiURL}/user/checkDuplicate/`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: checkdata,
    };

    await api
      .request(config)
      .then(() => {
        const updatedEmployees = [...employees];
        setLoadingSubmit(false);
        updatedEmployees[editEmployee] = editedEmployeeData;
        setEmployees(updatedEmployees);
        setEditEmployee('');
      })
      .catch((err) => {
        setLoadingSubmit(false);
        setErrors(err.response.data.message);
      });
  };

  const addEmployeeModal = () => (
    <div className="mt-0 fixed left-0 top-0 right-0 bottom-0 z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
      <form
        onSubmit={handleSubmitAddEmployee(hundleAddEmployee)}
        className="bg-white flex flex-col items-center justify-center p-5 h-[40rem] absolute top-[10%]  w-2/6 left-[35%] space-y-4 pt-12"
      >
        <div className="text-lg text-red-600 ">{errors}</div>
        <div
          className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
          style={{ borderColor: errorsAddEmployee.name ? 'red' : '#AAA6C3' }}
        >
          <label
            htmlFor="name"
            className="text-xs font-semibold text-[#181143] desktop:h-full desktop:text-sm"
          >
            First Name
          </label>
          <input
            id="name"
            type="text"
            placeholder="First Name"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerAddEmployee('name', {
              pattern: {
                value: /^[A-Za-z'-]{1,50}$/,
                message: 'Enter a valid name',
              },
              required: {
                value: true,
                message: 'This field is required !',
              },
            })}
          />
        </div>
        {errorsAddEmployee.name && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddEmployee.name?.message}
          </p>
        )}
        <div
          className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
          style={{
            borderColor: errorsAddEmployee.lastname ? 'red' : '#AAA6C3',
          }}
        >
          <label
            htmlFor="last_name"
            className="text-xs font-semibold text-[#181143] desktop:h-full  desktop:sm"
          >
            Last Name
          </label>
          <input
            id="last_name"
            type="text"
            placeholder="Last Name"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerAddEmployee('lastname', {
              pattern: {
                value: /^[A-Za-z'-]{1,50}$/,
                message: 'Enter a valid name',
              },
              required: {
                value: true,
                message: 'This field is required !',
              },
            })}
          />
          {errorsAddEmployee.lastname && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsAddEmployee.lastname?.message}
            </p>
          )}
        </div>
        <div
          className="flex flex-col w-2/3 border-1 rounded-lg border-[#5E6366] relative  p-2"
          style={{
            borderColor: errorsAddEmployee.password ? 'red' : '#AAA6C3',
          }}
        >
          <label
            htmlFor="Password"
            className="desktop:text-sm  text-xs font-semibold text-[#181143]"
          >
            Password
          </label>
          <input
            id="Password"
            type={pwdShown ? 'text' : 'password'}
            placeholder="Password"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerAddEmployee('password', {
              required: {
                value: true,
                message: 'This field is required !',
              },
              minLength: {
                value: 8,
                message: 'password needs to be more than 8 characters',
              },
              maxLength: {
                value: 255,
                message: 'max characters are 255 !',
              },
            })}
          />
          <span className="z-20 absolute right-[2%]  bottom-[2%]">
            <button
              type="button"
              onClick={() =>
                pwdShown === false ? setPwdShown(true) : setPwdShown(false)
              }
              className="text-[#AAA6C3] "
            >
              {pwdShown === true ? (
                <AiOutlineEyeInvisible size={30} />
              ) : (
                <AiOutlineEye size={30} />
              )}
            </button>
          </span>
          {errorsAddEmployee.password && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsAddEmployee.password?.message}
            </p>
          )}
        </div>
        <div
          className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
          style={{ borderColor: errorsAddEmployee.email ? 'red' : '#AAA6C3' }}
        >
          <label
            htmlFor="E-mail"
            className="desktop:text-sm  text-xs  font-semibold text-[#181143]"
          >
            E-mail
          </label>
          <input
            id="E-mail"
            type="text"
            placeholder="E-mail"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerAddEmployee('email', {
              required: 'this field is required',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Please enter a valid email addres',
              },
            })}
          />
          {errorsAddEmployee.email && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsAddEmployee.email?.message}
            </p>
          )}
        </div>
        <div
          className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
          style={{ borderColor: errorsAddEmployee.phone ? 'red' : '#AAA6C3' }}
        >
          <label
            htmlFor="phone"
            className="desktop:text-sm  text-xs  font-semibold text-[#181143]"
          >
            Phone
          </label>
          <input
            id="phone"
            type="text"
            placeholder="Phone"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerAddEmployee('phone', {
              required: 'this field is required',
              pattern: {
                value: /^\+?[0-9\s-()]{8,}$/,
                message: 'Enter a valid phone number',
              },
            })}
          />
          {errorsAddEmployee.phone && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsAddEmployee.phone?.message}
            </p>
          )}
        </div>
        <div className="w-3/6">
          <button
            type="submit"
            className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold relative"
            disabled={loadingSubmit}
          >
            {loadingSubmit && (
              <div className="absolute w-5 h-5 left-2  border-[#fff] ">
                <div className="animate-spin h-5 w-5  border-4  text-white border-r-4 rounded-full border-white  border-r-[#EC1577] " />
              </div>
            )}
            Add
          </button>
        </div>
        <div className="w-3/6">
          <button
            type="button"
            className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
            onClick={() => {
              setShowAddEmployeeModal(false);
              setErrors('');
            }}
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  );

  const editEmployeeModal = (employee) => (
    <div className="mt-0 fixed left-0 top-0 right-0 bottom-0 z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
      <form
        onSubmit={handleSubmitEditEmployee(hundleEditEmployee)}
        className="bg-white flex flex-col items-center justify-center p-5 h-[40rem] absolute top-[10%]  w-2/6 left-[35%] space-y-4 pt-12"
      >
        <div className="text-[#EC1577] text-xl font-medium w-full text-center">
          {employee.first_name}
        </div>
        <div className="text-lg text-red-600 ">{errors}</div>
        <div
          className="flex flex-col w-2/3 border-1 rounded-lg border-[#5E6366] relative  p-2"
          style={{ borderColor: errorsEditEmployee.name ? 'red' : '#AAA6C3' }}
        >
          <label
            htmlFor="name-edit"
            className="text-sm font-semibold text-[#181143]"
          >
            First Name
          </label>
          <input
            id="name-edit"
            type="text"
            placeholder="First Name"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            defaultValue={employee.first_name}
            {...registerEditEmployee('name', {
              pattern: {
                value: /^[A-Za-z'-]{1,50}$/,
                message: 'Enter a valid name',
              },
              required: {
                value: true,
                message: 'This field is required !',
              },
            })}
          />
        </div>
        {errorsEditEmployee.name && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsEditEmployee.name?.message}
          </p>
        )}
        <div
          className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
          style={{
            borderColor: errorsEditEmployee.lastname ? 'red' : '#AAA6C3',
          }}
        >
          <label
            htmlFor="edit-last_name"
            className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:sm"
          >
            Last Name
          </label>
          <input
            id="edit-last_name"
            type="text"
            placeholder="Last Name"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            defaultValue={employee.last_name}
            {...registerEditEmployee('lastname', {
              pattern: {
                value: /^[A-Za-z'-]{1,50}$/,
                message: 'Enter a valid name',
              },
              required: {
                value: true,
                message: 'This field is required !',
              },
            })}
          />
          {errorsEditEmployee.lastname && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsEditEmployee.lastname?.message}
            </p>
          )}
        </div>
        <div
          className="flex flex-col w-2/3 border-1 rounded-lg border-[#5E6366] relative  p-2"
          style={{
            borderColor: errorsEditEmployee.password ? 'red' : '#AAA6C3',
          }}
        >
          <label
            htmlFor="edit-Password"
            className="text-sm font-semibold text-[#181143]"
          >
            Password
          </label>
          <input
            id="edit-Password"
            type={pwdShown ? 'text' : 'password'}
            placeholder="Password"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            defaultValue={employee.password}
            autoComplete="current-password"
            {...registerEditEmployee('password', {
              required: {
                value: true,
                message: 'This field is required !',
              },
              minLength: {
                value: 8,
                message: 'password needs to be more than 8 characters',
              },
              maxLength: {
                value: 255,
                message: 'max characters are 255 !',
              },
            })}
          />
          <span className="z-20 absolute right-[2%]  bottom-[2%]">
            <button
              type="button"
              onClick={() =>
                pwdShown === false ? setPwdShown(true) : setPwdShown(false)
              }
              className="text-[#AAA6C3] "
            >
              {pwdShown === true ? (
                <AiOutlineEyeInvisible size={30} />
              ) : (
                <AiOutlineEye size={30} />
              )}
            </button>
          </span>
          {errorsEditEmployee.password && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsEditEmployee.password?.message}
            </p>
          )}
        </div>
        <div
          className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
          style={{ borderColor: errorsEditEmployee.email ? 'red' : '#AAA6C3' }}
        >
          <label
            htmlFor="edit-E-mail"
            className="text-sm font-semibold text-[#181143]"
          >
            E-mail
          </label>
          <input
            id="edit-E-mail"
            type="text"
            placeholder="E-mail"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            defaultValue={employee.email}
            {...registerEditEmployee('email', {
              required: 'this field is required',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Please enter a valid email Addres',
              },
            })}
          />
          {errorsEditEmployee.email && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsEditEmployee.email?.message}
            </p>
          )}
        </div>
        <div
          className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
          style={{ borderColor: errorsEditEmployee.phone ? 'red' : '#AAA6C3' }}
        >
          <label
            htmlFor="edit-phone"
            className="text-sm font-semibold text-[#181143]"
          >
            Phone
          </label>
          <input
            id="edit-phone"
            type="text"
            placeholder="Phone"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            defaultValue={employee.phone}
            {...registerEditEmployee('phone', {
              required: 'this field is required',
              pattern: {
                value: /^\+?[0-9\s-()]{8,}$/,
                message: 'Enter a valid phone number',
              },
            })}
          />
          {errorsEditEmployee.phone && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsEditEmployee.phone?.message}
            </p>
          )}
        </div>

        <div className="w-3/6">
          <button
            type="submit"
            className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold relative"
          >
            {loadingSubmit && (
              <div className="absolute w-5 h-5 left-2  border-[#fff] ">
                <div className="animate-spin h-5 w-5  border-4  text-white border-r-4 rounded-full border-white  border-r-[#EC1577] " />
              </div>
            )}
            Edit
          </button>
        </div>
        <div className="w-3/6">
          <button
            type="button"
            className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
            onClick={() => setEditEmployee('')}
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  );

  const prestashopForm = () => (
    <>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.url ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Url
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Shop url"
          {...registerAddShop('url', {
            pattern: {
              value:
                /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
              message: 'Enter a valid website address',
            },
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.url && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.url?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.key ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Webservice key"
          {...registerAddShop('key', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.key && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.key?.message}
          </p>
        )}
      </div>
    </>
  );

  const woocommerceForm = () => (
    <>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.url ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Url
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Shop url"
          {...registerAddShop('url', {
            pattern: {
              value:
                /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
              message: 'Enter a valid website address',
            },
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.url && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.url?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.woo_sk ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Secret Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Secret Key"
          {...registerAddShop('woo_sk', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.woo_sk && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.woo_sk?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.woo_ck ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Consumer Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Consumer Key"
          {...registerAddShop('woo_ck', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.woo_ck && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.woo_ck?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.version ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Version
        </label>
        <select
          name=""
          id=""
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          {...registerAddShop('version', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        >
          <option value="wc/v3">wc/v3</option>
        </select>
        {errorsAddShop.version && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.version?.message}
          </p>
        )}
      </div>
    </>
  );

  const editWoocommerceForm = (shop) => (
    <>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsEditShop.url ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Url
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Shop url"
          defaultValue={shop.url}
          {...registerEditShop('url', {
            pattern: {
              value:
                /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
              message: 'Enter a valid website address',
            },
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsEditShop.url && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsEditShop.url?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsEditShop.woo_sk ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Secret Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Secret Key"
          defaultValue={shop.woo_sk}
          {...registerEditShop('woo_sk', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsEditShop.woo_sk && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsEditShop.woo_sk?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsEditShop.woo_ck ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Consumer Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Consumer Key"
          defaultValue={shop.woo_ck}
          {...registerEditShop('woo_ck', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsEditShop.woo_ck && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsEditShop.woo_ck?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsEditShop.version ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Version
        </label>
        <select
          name=""
          id=""
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          defaultValue={shop.version}
          {...registerAddShop('version', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        >
          <option value="wc/v3">wc/v3</option>
        </select>
        {errorsEditShop.version && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsEditShop.version?.message}
          </p>
        )}
      </div>
    </>
  );

  const editPrestashopForm = (shop) => (
    <>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsEditShop.url ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Url
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Shop url"
          defaultValue={shop.url}
          {...registerEditShop('url', {
            pattern: {
              value:
                /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
              message: 'Enter a valid website address',
            },
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsEditShop.url && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsEditShop.url?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsEditShop.key ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Webservice key"
          defaultValue={shop.key}
          {...registerEditShop('key', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsEditShop.key && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsEditShop.key?.message}
          </p>
        )}
      </div>
    </>
  );

  const addShopModal = () => (
    <div className="mt-0 fixed left-0 top-0 right-0 bottom-5  z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
      <form
        onSubmit={handleSubmitAddShop(hundleAddShop)}
        className="bg-white flex flex-col items-center justify-center p-5  absolute top-[5%]  w-2/6 left-[35%] space-y-4 pt-12"
      >
        <div
          className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
          style={{ borderColor: errorsAddShop.name ? 'red' : '#AAA6C3' }}
        >
          <label
            htmlFor="shop-name"
            className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
          >
            Shop Name
          </label>
          <input
            id="shop-name"
            type="text"
            placeholder="Shop Name"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerAddShop('name', {
              required: {
                value: true,
                message: 'This field is required !',
              },
            })}
          />
          {errorsAddShop.name && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsAddShop.name?.message}
            </p>
          )}
        </div>

        <div
          className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#5E6366] relative  p-2"
          style={{
            borderColor: errorsAddShop.shop_group_id ? 'red' : '#AAA6C3',
          }}
        >
          <label
            htmlFor="Shop-Group"
            className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
          >
            Shop Group
          </label>
          <select
            id="Shop-Group"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerAddShop('shop_group_id', {
              required: {
                value: true,
                message: 'This field is required !',
              },
            })}
            defaultValue={shopCategories[0].id}
          >
            {shopCategories.map((cat) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </select>
          {errorsAddShop.shop_group_id && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsAddShop.shop_group_id?.message}
            </p>
          )}
        </div>
        <div className="text-sm font-semibold text-[#181143] w-2/3">
          Shop Type
        </div>
        <div className="flex flex-col w-5/6 p-2 justify-evenly space-y-3">
          <div className="flex flex-row w-full justify-evenly">
            {types.map((type) => (
              <label
                htmlFor={`type-${type.id}`}
                className={`flex items-center cursor-pointer w-1/5 p-3 rounded-2xl shadow-lg ${
                  // eslint-disable-next-line no-nested-ternary
                  type.disabled === false
                    ? selectedType === type.type
                      ? 'bg-[#F99E1FDE]'
                      : 'bg-white'
                    : 'pointer-events-none'
                } `}
              >
                <img
                  src={type.logo}
                  alt={type.name}
                  style={{
                    filter:
                      type.disabled === true
                        ? 'grayscale(100%)'
                        : 'grayscale(0%)',
                  }}
                />
                <input
                  id={`type-${type.id}`}
                  type="radio"
                  checked={selectedType === type.type}
                  disabled={type.disabled}
                  value={type.type}
                  onClick={(e) => handleShopType(e, type.type)}
                  className="appearance-none hover:outline-0 focus:outline-0"
                  {...registerAddShop('type')}
                />
              </label>
            ))}
          </div>
        </div>
        {selectedType === 'prestashop' && prestashopForm()}
        {selectedType === 'woocommerce' && woocommerceForm()}
        <div className="w-3/6">
          <button
            type="submit"
            className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold disabled:bg-[#AAA6C3] relative"
            disabled={selectedType === null || loadingSubmit}
          >
            {loadingSubmit && (
              <div className="absolute w-5 h-5 left-2  border-[#fff] ">
                <div className="animate-spin h-5 w-5  border-4  text-white border-r-4 rounded-full border-white  border-r-[#EC1577] " />
              </div>
            )}
            Add
          </button>
        </div>
        <div className="w-3/6">
          <button
            type="button"
            className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold "
            onClick={() => {
              setShowAddShopModal(false);
              setSelectedType(null);
            }}
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  );

  const hundleAddShop = async (shopData) => {
    const id = toast.loading('Please wait...');
    const decoded = jwtDecode(token);
    setLoadingSubmit(true);
    const data = JSON.stringify({
      name: shopData.name,
      type: shopData.type,
      group_shop_id: shopData.shop_group,
      url: shopData.url,
      pres_key: shopData.key,
      woo_consumer_key: shopData.woo_ck,
      woo_consumer_secret: shopData.woo_sk,
      woo_version: shopData.version,
    });

    const config = {
      method: 'post',
      url: `${apiURL}/plans/${shopData.type}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    await api
      .request(config)
      .then(() => {
        setLoadingSubmit(false);
        toast.update(id, {
          render: 'Connected',
          type: 'success',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setShops([...shops, shopData]);
        setShowAddShopModal(false);
      })
      .catch(() => {
        setLoadingSubmit(false);
        toast.update(id, {
          render: 'Connection failed !',
          type: 'error',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const hundleEditShop = async (shopData) => {
    const id = toast.loading('Please wait...');
    const decoded = jwtDecode(token);
    setLoadingSubmit(true);
    const data = JSON.stringify({
      name: shopData.name,
      type: shopData.type,
      group_shop_id: shopData.shop_group,
      url: shopData.url,
      pres_key: shopData.key,
      woo_consumer_key: shopData.woo_ck,
      woo_consumer_secret: shopData.woo_sk,
      woo_version: shopData.version,
    });

    const config = {
      method: 'post',
      url: `${apiURL}/plans/${shopData.type}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    await api
      .request(config)
      .then(() => {
        toast.update(id, {
          render: 'Connected',
          type: 'success',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setLoadingSubmit(false);
        const updatedShops = [...shops];

        updatedShops[showEditShopModal] = shopData;
        setShops(updatedShops);

        setShowEditShopModal(false);
      })
      .catch(() => {
        setLoadingSubmit(false);
        toast.update(id, {
          render: 'Connection failed !',
          type: 'error',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      });
  };

  const editShopModal = (shop) => (
    <div className="mt-0 fixed left-0 top-0 right-0 bottom-5  z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
      <form
        onSubmit={handleSubmitEditShop(hundleEditShop)}
        className="bg-white flex flex-col items-center justify-center p-5  absolute top-[5%]  w-2/6 left-[35%] space-y-3 desktop:space-y-4 pt-12"
      >
        <div className="text-[#EC1577] text-md desktop:text-xl font-medium w-full text-center">
          {shop.name}
        </div>
        <div
          className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
          style={{ borderColor: errorsEditShop.name ? 'red' : '#AAA6C3' }}
        >
          <label
            htmlFor="shop-name"
            className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
          >
            Shop Name
          </label>
          <input
            id="shop-name"
            type="text"
            placeholder="Shop Name"
            defaultValue={shop.name}
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerEditShop('name', {
              required: {
                value: true,
                message: 'This field is required !',
              },
            })}
          />
          {errorsEditShop.name && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsEditShop.name?.message}
            </p>
          )}
        </div>

        <div
          className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#5E6366] relative  p-2"
          style={{
            borderColor: errorsEditShop.shop_group_id ? 'red' : '#AAA6C3',
          }}
        >
          <label
            htmlFor="Shop-Group"
            className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
          >
            Shop Group
          </label>
          <select
            id="Shop-Group"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerEditShop('shop_group_id', {
              required: {
                value: true,
                message: 'This field is required !',
              },
            })}
            defaultValue={shopCategories[0].id}
          >
            {shopCategories.map((cat) => (
              <option value={cat.id}>{cat.name}</option>
            ))}
          </select>
          {errorsEditShop.shop_group_id && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsEditShop.shop_group_id?.message}
            </p>
          )}
        </div>
        <div className="text-sm font-semibold text-[#181143] w-2/3">
          Shop Type
        </div>
        <div className="flex flex-col w-5/6 p-2 justify-evenly space-y-3">
          <div className="flex flex-row w-full justify-evenly">
            {types.map((type) => (
              <label
                htmlFor={`type-${type.id}`}
                className={`flex items-center cursor-pointer w-1/5 p-3 rounded-2xl shadow-lg ${
                  // eslint-disable-next-line no-nested-ternary
                  type.disabled === false
                    ? selectedType === type.type
                      ? 'bg-[#F99E1FDE]'
                      : 'bg-white'
                    : 'pointer-events-none'
                } `}
              >
                <img
                  src={type.logo}
                  alt={type.name}
                  style={{
                    filter:
                      type.disabled === true
                        ? 'grayscale(100%)'
                        : 'grayscale(0%)',
                  }}
                />
                <input
                  id={`type-${type.id}`}
                  type="radio"
                  checked={selectedType === type.type}
                  disabled={type.disabled}
                  defaultValue={shop.type}
                  value={type.type}
                  onClick={(e) => handleShopType(e, type.type)}
                  className="appearance-none hover:outline-0 focus:outline-0"
                  {...registerEditShop('type')}
                />
              </label>
            ))}
          </div>
        </div>
        {selectedType === 'prestashop' && editPrestashopForm(shop)}
        {selectedType === 'woocommerce' && editWoocommerceForm(shop)}
        <div className="w-3/6">
          <button
            type="submit"
            className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold disabled:bg-[#AAA6C3] relative"
            disabled={selectedType === null || loadingSubmit}
          >
            {loadingSubmit && (
              <div className="absolute w-5 h-5 left-2  border-[#fff] ">
                <div className="animate-spin h-5 w-5  border-4  text-white border-r-4 rounded-full border-white  border-r-[#EC1577] " />
              </div>
            )}
            Edit
          </button>
        </div>
        <div className="w-3/6">
          <button
            type="button"
            className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
            onClick={() => {
              setShowEditShopModal(false);
              setSelectedType(null);
            }}
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  );

  const hundledeleteShop = (index) => {
    const updatedShops = [...shops];

    updatedShops.splice(index, 1);
    setShops(updatedShops);
  };

  const calculateRemainingShops = () => {
    if (
      shops.length !== 0 &&
      parseInt(shops.length) < parseInt(plan.limit_shops)
    ) {
      return parseInt(plan.limit_shops) - parseInt(shops.length);
    }

    if (shops.length === 0) {
      return plan.limit_shops;
    }

    return 0;
  };

  const calculateRemainingEmployees = () => {
    if (
      employees.length !== 0 &&
      parseInt(employees.length) < parseInt(plan.limit_employees)
    ) {
      return parseInt(plan.limit_employees) - parseInt(employees.length);
    }

    if (employees.length === 0) {
      return plan.limit_employees;
    }

    return 0;
  };

  const calculateExtraEmployees = () => {
    if (parseInt(employees.length) > parseInt(plan.limit_employees)) {
      setExtraEmployees(
        parseInt(employees.length) - parseInt(plan.limit_employees)
      );
      return parseInt(employees.length) - parseInt(plan.limit_employees);
    }

    setExtraEmployees(0);
    return 0;
  };

  const calculateExtraShops = () => {
    if (parseInt(shops.length) > parseInt(plan.limit_shops)) {
      setExtraShops(parseInt(shops.length) - parseInt(plan.limit_shops));
      return parseInt(shops.length) - parseInt(plan.limit_shops);
    }
    setExtraShops(0);
    return 0;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {showAddEmployeeModal === true && addEmployeeModal()}
      {showAddShopModal === true && addShopModal()}

      <div className="flex flex-col space-y-8 ml-16 mt-12 relative">
        <div className="flex justify-between w-[95%] items-center">
          <h1 className="text-[3rem] font-medium text-[#EC1577]">
            {plan.name}
          </h1>

          <div className="flex justify-evenly w-[90%] items-center">
            <p className="text-[1.5rem] font-medium text-[#231D4F] text-center">
              shops remaining :
              <span className="text-[#EC1577]">
                {calculateRemainingShops()}
              </span>
            </p>
            <p className="text-[1.5rem] font-medium text-[#231D4F]">
              employees remaining :
              <span className="text-[#EC1577]">
                {calculateRemainingEmployees()}
              </span>
            </p>
            <div className="border-2 border-[#EC1577] rounded-xl p-2">
              <p className="text-[1rem] font-medium text-[#231D4F] text-center">
                extra shops :
                <span className="text-[#EC1577]">{calculateExtraShops()}</span>
              </p>
            </div>
            <div className="border-2 border-[#EC1577] rounded-xl p-2">
              <p className="text-[1rem] font-medium text-[#231D4F]">
                extra employees :
                <span className="text-[#EC1577]">
                  {calculateExtraEmployees()}
                </span>
              </p>
            </div>
          </div>
          <div />
        </div>
        <div className="ml-16 space-y-8">
          <div className="flex flex-col space-y-5">
            <p className="text-[#231D4F] font-semibold text-2xl">Employees :</p>
            <button
              type="button"
              className="bg-[#EC1577] p-2 rounded-3xl text-white w-24"
              onClick={() => setShowAddEmployeeModal(true)}
            >
              + ADD
            </button>

            <div className="bg-white rounded-2xl w-5/6 pt-5 pl-6 pr-4 pb-5 min-h-[10rem]  shadow-lg">
              <div className="grid grid-cols-9 ">
                <div className="text-[#AAA6C3] col-span-2 font-semibold">
                  Name
                </div>
                <div className="text-[#AAA6C3] col-span-2 font-semibold">
                  E-mail
                </div>
                <div className="text-[#AAA6C3] col-span-2 font-semibold">
                  Phone
                </div>
                <div className="text-[#AAA6C3] col-span-2 font-semibold relative">
                  <p>Password </p>
                  <span className="z-20 absolute left-[5rem]  -top-1">
                    <button
                      type="button"
                      onClick={() =>
                        pwdCShown === false
                          ? setPwdCShown(true)
                          : setPwdCShown(false)
                      }
                      className="text-[#AAA6C3] "
                    >
                      {pwdCShown === true ? (
                        <AiOutlineEyeInvisible size={30} />
                      ) : (
                        <AiOutlineEye size={30} />
                      )}
                    </button>
                  </span>
                </div>
                <div className="text-[#231D4F] font-semibold text-center" />
              </div>
              <div className="border-1 w-full mt-2" />
              {employees.length === 0 && (
                <div className="flex justify-center h-full mt-12">
                  <div className="flex flex-col items-center justify-center h-full">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="w-8 h-8 text-[#EC1577]"
                    />
                    <p className="font-semibold text-lg text-[#EC1577]">
                      No employees are added
                    </p>
                  </div>
                </div>
              )}
              {employees.map((employee, i) => (
                <div
                  className="grid grid-cols-9 w-full justify-between mt-5"
                  key={i}
                >
                  <div className="text-[#231D4F] col-span-2 font-semibold flex space-x-4  truncate overflow-ellipsis">
                    <div className="mt-2 mr-2">
                      <p> {employee.first_name}</p>
                      <p>{employee.last_name}</p>
                    </div>
                  </div>
                  <div className="text-[#231D4F] col-span-2 font-semibold truncate overflow-ellipsis mt-2">
                    {employee.email}
                  </div>
                  <div className="text-[#231D4F] col-span-2 font-semibold truncate overflow-ellipsis mt-2">
                    {employee.phone}
                  </div>
                  <div className="text-[#231D4F] col-span-2 font-semibold relative truncate overflow-ellipsis mt-2">
                    <input
                      type={pwdCShown ? 'text' : 'password'}
                      disabled
                      value={employee.password}
                      className="hover:outline-0 focu:outline-0 bg-white"
                    />
                  </div>

                  <div className="text-[#231D4F]   font-semibold flex justify-around">
                    <button type="button" onClick={() => setEditEmployee(i)}>
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="w-4 h-4 text-[#3974B0]"
                      />
                    </button>
                    <button type="button">
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="w-4 h-4 text-[#FF0000]"
                        onClick={() => hundledeleteEmployee(i)}
                      />
                    </button>
                  </div>
                  {editEmployee === i && editEmployeeModal(employee)}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col space-y-5">
            <p className="text-[#231D4F] font-semibold text-2xl">Shops :</p>
            <button
              type="button"
              className="bg-[#EC1577] p-2 rounded-3xl text-white w-24"
              onClick={() => setShowAddShopModal(true)}
            >
              + ADD
            </button>
            <div className="bg-white rounded-2xl w-5/6 pt-5 pl-6 pr-4 pb-5 min-h-[10rem] shadow-lg">
              <div className="grid grid-cols-9 ">
                <div className="text-[#AAA6C3] col-span-2 font-semibold">
                  Name
                </div>
                <div className="text-[#AAA6C3] col-span-2 font-semibold">
                  Type
                </div>
                <div className="text-[#AAA6C3] col-span-2 font-semibold">
                  Url
                </div>

                <div className="text-[#231D4F] col-span-2 font-semibold text-center" />
              </div>
              <div className="border-1 w-full mt-2" />
              {shops.length === 0 && (
                <div className="flex justify-center h-full mt-12">
                  <div className="flex flex-col items-center justify-center h-full">
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="w-8 h-8 text-[#EC1577]"
                    />
                    <p className="font-semibold text-lg text-[#EC1577]">
                      No shops are added
                    </p>
                  </div>
                </div>
              )}
              {shops.length !== 0 &&
                shops.map((shop, i) => (
                  <div
                    className="grid grid-cols-9 w-full justify-between mt-5"
                    key={i}
                  >
                    <div className="text-[#231D4F] col-span-2 font-semibold flex space-x-4  truncate overflow-ellipsis">
                      <div className="mt-2 mr-2 flex flex-col justify-center">
                        <p className="text-center"> {shop.name}</p>
                      </div>
                    </div>
                    <div className="text-[#231D4F] col-span-2 font-semibold truncate overflow-ellipsis mt-2">
                      {shop.type === 'woocommerce' && (
                        <img
                          src={woocommerceLogo}
                          alt={shop.type}
                          className="w-14 h-14"
                        />
                      )}
                      {shop.type === 'prestashop' && (
                        <img
                          src={prestashopLogo}
                          alt={shop.type}
                          className="w-14 h-14"
                        />
                      )}
                      {shop.type === 'shopify' && (
                        <img
                          src={shopifyLogo}
                          alt={shop.type}
                          className="w-14 h-14"
                        />
                      )}
                    </div>
                    <div className="text-[#231D4F] col-span-2 font-semibold truncate overflow-ellipsis mt-2">
                      <p className="mt-2 mr-2 flex flex-col justify-center">
                        {shop.url}
                      </p>
                    </div>
                    <div className="col-span-2" />
                    <div className="text-[#231D4F]   font-semibold flex justify-around space-x-10">
                      <button
                        type="button"
                        onClick={() => {
                          setShowEditShopModal(i);
                          setSelectedType(shop.type);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPencil}
                          className="w-4 h-4 text-[#3974B0]"
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          hundledeleteShop(i);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="w-4 h-4 text-[#FF0000]"
                        />
                      </button>
                    </div>
                    {showEditShopModal === i && editShopModal(shop)}
                  </div>
                ))}
            </div>
          </div>
          <div className="flex flex-col space-y-5">
            <div className="w-5/6">
              <button
                type="button"
                className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold disabled:bg-[#AAA6C3]"
                disabled={shops.length === 0}
                onClick={() => setCurrentTab('3')}
              >
                Create
              </button>
            </div>
            <div className="w-5/6">
              <button
                type="button"
                className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
                onClick={() => setCurrentTab('1')}
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CreateShopInfos.propTypes = {
  currentTab: PropTypes.object.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  shopCategories: PropTypes.array.isRequired,
  shops: PropTypes.array.isRequired,
  setShops: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  setEmployees: PropTypes.func.isRequired,
  extraEmployees: PropTypes.object.isRequired,
  setExtraEmployees: PropTypes.func.isRequired,
  extraShops: PropTypes.object.isRequired,
  setExtraShops: PropTypes.func.isRequired,
};

export default CreateShopInfos;
