import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { Link, useNavigate } from 'react-router-dom';
import { Line } from 'rc-progress';
import { toast } from 'react-toastify';

import { SiBigcommerce, SiMagento } from 'react-icons/si';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from '@syncfusion/ej2-react-grids';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { GiConsoleController } from 'react-icons/gi';
import api from '../../utils/apiService';
import woocommerceLogo from '../../img/woocommerce.png';
import shopifyLogo from '../../img/shopify.png';
import prestashopLogo from '../../img/prestaShop.png';
import { Header, Modal, Toggle } from '../../components';
import { selectCurrentToken } from '../../features/auth/authSlice';
import Loader from '../../components/Loader';

const apiURL = process.env.REACT_APP_API_URL;
const Shops = () => {
  const token = useSelector(selectCurrentToken);
  const contextMenuItems = [
    'AutoFit',
    'AutoFitAll',
    'SortAscending',
    'SortDescending',
    'Copy',
    'Edit',
    'Delete',
    'Save',
    'Cancel',
    'PdfExport',
    'ExcelExport',
    'CsvExport',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];
  const editing = { allowDeleting: true, allowEditing: true };
  const decoded = jwtDecode(token);
  const [user, setUser] = useState(decoded.UserInfo);
  const [items, setItems] = useState([]);
  const [employeesData, setEmployeesData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [info, setInfo] = useState('');
  const [success, setSuccess] = useState('');
  const [warning, setWarning] = useState('');
  const [error, setError] = useState('');
  const [showImportEmployeesModal, setShowImportEmployeesModal] =
    useState(false);
  const [selectedShopId, setSelectedShopId] = useState('');
  const [selectedShopType, setSelectedShopType] = useState('');

  const getUserCountry = () => decoded.UserInfo.country;

  function getCurrencySymbol() {
    let currency = '';

    if (getUserCountry() === 'Tunisia') {
      currency = 'dt';
    } else if (getUserCountry() === 'France') {
      currency = '€';
    } else {
      currency = '$';
    }

    return currency;
  }

  const {
    register: registerAddShop,
    formState: { errors: errorsAddShop },
    handleSubmit: handleSubmitAddShop,
    reset: resetAddShop,
  } = useForm();

  const [productImport, setProductImport] = useState({
    total_products: 0,
    progress: 0,
    offset: 0,
    limit: 10,
    info: '',
    success: '',
    error: '',
    warning: '',
  });
  const getShops = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-all-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => setItems(response.data))
      .catch((error) => JSON.stringify(error));
  };

  const getEmployees = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/user/getAllUsers`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => setEmployeesData(response.data))
      .catch((error) => JSON.stringify(error));
  };

  const gridShopImage = ({ type }) => (
    <div>
      <center>
        {type === 'prestashop' ? (
          <img
            src={prestashopLogo}
            alt="prestashop Logo"
            className="w-16 h-16"
          />
        ) : (
          ''
        )}
        {type === 'shopify' ? (
          <img src={shopifyLogo} alt="shopify Logo" className="w-16 h-16" />
        ) : (
          ''
        )}
        {type === 'woocommerce' ? (
          <img
            src={woocommerceLogo}
            alt="woocommerce Logo"
            className="w-16 h-16"
          />
        ) : (
          ''
        )}
        {type === 'bigcommerce' ? <SiBigcommerce size={20} /> : ''}
        {type === 'magento' ? <SiMagento size={20} /> : ''}
      </center>
    </div>
  );

  // eslint-disable-next-line camelcase
  const isImported = ({ is_imported }) => (
    // eslint-disable-next-line camelcase
    <div>{is_imported ? <div>Yes</div> : <div>No</div>}</div>
  );

  // eslint-disable-next-line camelcase
  const employees = ({ is_imported, id, type }) => (
    <button
      type="button"
      className="text-[#fff] py-1 px-2 capitalize rounded-2xl text-md bg-[#181143] disabled:bg-shifti-grey-bg"
      // eslint-disable-next-line camelcase
      disabled={!is_imported || type !== 'prestashop'}
      onClick={() => {
        setShowImportEmployeesModal(true);
        setSelectedShopId(id);
        setSelectedShopType(type);
        // importCustomers
      }}
    >
      Import employees
    </button>
  );

  // eslint-disable-next-line camelcase
  const actions = ({ is_imported, id, type }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    return (
      <div>
        <button
          type="button"
          className="text-[#fff] py-1 px-2 capitalize rounded-2xl text-md bg-[#181143] disabled:bg-shifti-grey-bg"
          // eslint-disable-next-line camelcase
          disabled={is_imported}
          onClick={() => {
            verifyShop(id, type);
          }}
        >
          Import
        </button>

        <button
          type="button"
          style={{ color: '#000' }}
          className="text-white py-1 px-2 capitalize rounded-2xl text-md"
          onClick={() => navigate(`/edit-shop/${id}`)}
        >
          <FontAwesomeIcon icon={faPencil} className="w-6 h-6 text-[#AAA6C3]" />
        </button>
        <button
          type="button"
          style={{ color: '#000' }}
          className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        >
          <FontAwesomeIcon icon={faTrash} className="w-6 h-6 text-[#AAA6C3]" />
        </button>
      </div>
    );
  };

  const importFeatures = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/features/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const checkDataIntegrity = (shopId) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/shop/check-data-integrity/${shopId}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importCustomers = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/customers/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importAttributes = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/attributes/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importManufacturers = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/manufacturers/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importCategories = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/categories/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importInternational = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/international/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importAddresses = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/addresses/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importTaxes = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/taxes/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importCarriers = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/carriers/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importWeightRange = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/weightrange/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importPriceRange = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/pricerange/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importDeliveries = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/deliveries/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importProducts = (
    id,
    type,
    isValidate,
    count = true,
    totalProducts = 0,
    offset = 0,
    limit = 10
  ) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/products/${id}/1/${count}/${offset}/${limit}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then(async (response) => {
          if (response.data.isFinished) {
            setProductImport((prevState) => ({
              ...prevState,
              offset: prevState.total_products,
              progress: 100,
            }));
            resolve(response);
          } else if (count && response.data.totalProducts) {
            // Recursive call with updated offset and limit
            setProductImport((prevState) => ({
              ...prevState,
              total_products: response.data.totalProducts,
            }));
            const recursiveResponse = await importProducts(
              id,
              type,
              isValidate,
              false,
              response.data.totalProducts
            );
            resolve(recursiveResponse);
          } else {
            const progressPercentage = Math.round(
              (offset * 100) / totalProducts
            );
            setProductImport((prevState) => ({
              ...prevState,
              offset,
              progress: progressPercentage,
            }));
            const recursiveResponse = await importProducts(
              id,
              type,
              isValidate,
              false,
              totalProducts,
              offset + 10,
              limit
            );
            setTimeout(() => {}, 1000);
            resolve(recursiveResponse);
          }
        })
        .catch((err) => reject(err));
    });

  const importCarts = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/carts/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importCartRules = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/cart-rules/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importOrders = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/orders/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importOrderDetails = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/order-details/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importOrderState = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/order-state/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importOrderHistory = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/order-history/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importOrderPayment = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/order-payments/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importOrderInvoice = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/order-invoice/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importOrderCarrier = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/order-carrier/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importCustomerThread = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/customer-thread/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importOrderMessage = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/order-messages/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  const importCustomerMessages = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/customer-messages/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const importEmployees = (id, type, isValidate) =>
    new Promise((resolve, reject) => {
      const idToast = toast.loading('Please wait...');
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/import/employees/${id}/1`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: { isValidate, isAllowed: false },
      };
      api
        .request(config)
        .then((response) => {
          toast.update(idToast, {
            render: 'employees imported !',
            type: response.status === 200,
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(); // Reloads the current page
          }, 2000); // 2000 milliseconds = 2 seconds
        })
        .catch((err) => {
          toast.update(idToast, {
            render: 'error happened while importing employees',
            type: 'failed',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
        });
    });

  const shopIsImported = (id, type) =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'post',
        url: `${apiURL}/${type}/shopIsImported/${id}/1/${user.id}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => {
          setTimeout(() => {}, 1000);
          resolve(response);
        })
        .catch((err) => reject(err));
    });

  const verifyShop = async (id, type) => {
    const config = {
      method: 'get',
      url: `${apiURL}/shop/verify-status/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .request(config)
      .then(() => importShop(id, type))
      .catch((err) => err);
  };

  const importShop = async (id, type, isValidate = true) => {
    setShowModal(true);
    try {
      const importFunctions = getShopFunctionsByType(type, isValidate);
      for (let i = 0; i < importFunctions.length; i += 1) {
        const currentFunction = importFunctions[i].func;
        const progressPercentage = Math.round(
          ((i + 1) / importFunctions.length) * 100
        );
        setMessage(importFunctions[i].msg);
        // Call the current import function
        await currentFunction(id, type, isValidate).then(() =>
          setProgress(progressPercentage)
        );
      }
      setMessage('completed');
      setSuccess('Import completed successfully!');
    } catch (err) {
      setError('An error occurred during the import');
    }
  };

  const status = ({ id, active }) => {
    const shopStatus = !active;
    const handleClick = () => {
      const config = {
        url: `${apiURL}/shop/${id}/${shopStatus}`,
        method: 'patch',
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then(async () => {
          await getShops();
          toast.success('Status updated successfully!');
        })
        .catch((error) => {
          toast.error('Failed to update status.');
        });
    };

    return (
      <center>
        <Toggle onClick={handleClick} toggle={active} text={false} />
      </center>
    );
  };

  function getLimitNbEmplyeesPlan() {
    return plan.limit_employees;
  }

  function nbEmployees() {
    return employeesData.length;
  }

  function handleImportEmployeeModal(shop) {
    return (
      <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center transition delay-150 duration-300 ease-in-out">
        <div className="bg-white flex flex-col items-center justify-center p-5  absolute  w-2/6space-y-4 pt-8">
          <div className="w-full flex justify-end">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => setShowImportEmployeesModal(false)}
            >
              X
            </button>
          </div>
          <p className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
            {nbEmployees() >= getLimitNbEmplyeesPlan()
              ? "We're unable to import more employees as you've reached the maximum limit allowed by your current plan. Consider upgrading for expanded capacity."
              : "You're moments away from importing employees from the external site. Ensure your settings are configured correctly before proceeding with the import"}
          </p>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              className=" border-2 border-[#EC1577] text-[#EC1577] w-[12rem] rounded-3xl p-2 text-sm"
              onClick={() => setShowImportEmployeesModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-[#EC1577] w-[12rem] text-white rounded-3xl p-2 text-sm"
              onClick={() => {
                const confirmed = window.confirm('hi');

                if (confirmed) {
                  importEmployees(selectedShopId, selectedShopType, {
                    isValidate: true,
                  });
                }
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }

  const shopsGrid = [
    {
      field: 'id',
      headerText: 'ID',
      width: '70',
      textAlign: 'Center',
    },
    {
      field: 'name',
      headerText: 'Name',
      width: '120',
      textAlign: 'Center',
    },
    {
      field: 'type',
      headerText: 'Technology',
      template: gridShopImage,
      width: '170',
      textAlign: 'Center',
    },
    {
      field: 'url',
      headerText: 'Url',
      width: '180',
      textAlign: 'Center',
    },
    {
      template: isImported,
      headerText: 'Is imported',
      width: '120',
      textAlign: 'Center',
    },
    {
      template: status,
      headerText: 'Status',
      width: '100',
      // textAlign: 'Start',
    },
    {
      field: 'action',
      headerText: 'Employees',
      template: employees,
      width: '200',
      textAlign: 'Center',
    },
    {
      field: 'action',
      headerText: 'Action',
      template: actions,
      width: '100',
      textAlign: 'Center',
    },
  ];

  const getShopFunctionsByType = (type) => {
    if (type === 'prestashop')
      return [
        // { func: importEmployees, msg: 'importing employees' },
        { func: importFeatures, msg: 'importing features' },
        { func: importCustomers, msg: 'importing customers' },
        { func: importAttributes, msg: 'importing attributes' },
        { func: importManufacturers, msg: 'importing manufacturers' },
        { func: importCategories, msg: 'importing categories' },
        { func: importInternational, msg: 'importing international' },
        { func: importAddresses, msg: 'importing addresses' },
        { func: importTaxes, msg: 'importing taxes' },
        { func: importCarriers, msg: 'importing carriers' },
        { func: importWeightRange, msg: 'importing weight range' },
        { func: importPriceRange, msg: 'importing price range' },
        { func: importDeliveries, msg: 'importing deliveries' },
        { func: importProducts, msg: 'importing products' },
        { func: importCarts, msg: 'importing carts' },
        { func: importCartRules, msg: 'importing cart rules' },
        { func: importOrders, msg: 'importing orders' },
        { func: importOrderDetails, msg: 'importing orders details' },
        { func: importOrderState, msg: 'importing orders state' },
        { func: importOrderHistory, msg: 'importing orders history' },
        { func: importOrderPayment, msg: 'importing orders payments' },
        { func: importOrderInvoice, msg: 'importing orders invoice' },
        { func: importOrderCarrier, msg: 'importing orders carriers' },
        { func: importOrderMessage, msg: 'importing orders messages' },
        { func: importCustomerThread, msg: 'importing customer threads' },
        { func: importCustomerMessages, msg: 'importing customer messages' },
        { func: shopIsImported, msg: 'shop is imported' },
      ];

    if (type === 'woocommerce')
      return [
        // { func: checkDataIntegrity, msg: 'checking data integrity...' },
        { func: importCustomers, msg: 'importing customers' },
        { func: importCategories, msg: 'importing categories' },
        { func: importAddresses, msg: 'importing addresses' },
        { func: importTaxes, msg: 'importing taxes' },
        { func: importProducts, msg: 'importing products' },
        { func: importOrders, msg: 'importing orders' },
      ];
  };

  const handleModalClose = () => {
    setMessage('');
    setSuccess('');
    setError('');
    setWarning('');
    setInfo('');
    setShowModal(false);
    setProgress(0);
    setProductImport({
      ...{
        total_products: 0,
        progress: 0,
        offset: 0,
        limit: 10,
        info: '',
        success: '',
        error: '',
        warning: '',
      },
    });
  };

  const [selectedType, setSelectedType] = useState(null);
  const [shopCategories, setShopCategories] = useState([]);
  const [showAddShopModal, setShowAddShopModal] = useState(false);
  const [userPlan, setUserPlan] = useState([]);
  const [plan, setPlan] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCurrenUserPlan = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/plans/currentPlan`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getPlan = async (planId) => {
    const config = {
      method: 'get',
      url: `${apiURL}/plans/${planId}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getShopCategories = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/group-shop/groups`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setShopCategories(response.data);
        setLoading(false);
        //  setFirstGroupId(response.data[0].id);
      })
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getShops();
    getCurrenUserPlan().then((userPlanResponse) => {
      setUserPlan(userPlanResponse);
      getPlan(userPlanResponse.plan_id).then((planResponse) => {
        setPlan(planResponse);
        getEmployees();
        getShopCategories();
        setLoading(false);
      });
    });
  }, []);

  const [types, setTypes] = useState([
    {
      id: 1,
      name: 'Shopify',
      type: 'shopify',
      logo: shopifyLogo,
      active: false,
      disabled: true,
    },
    {
      id: 2,
      name: 'Woocommerce',
      type: 'woocommerce',
      logo: woocommerceLogo,
      disabled: false,
    },
    // { id: 3, name: 'Bigcommerce',type: 'bigcommerce', logo:"", active:false },
    // { id: 4, name: 'Magento',type: 'magento', logo:"", active:false },
    {
      id: 5,
      name: 'Prestashop',
      type: 'prestashop',
      logo: prestashopLogo,
      disabled: false,
    },
  ]);

  const handleShopType = (e, type) => {
    if (e.target.checked) {
      setSelectedType(type);
    } else {
      setSelectedType(null);
    }
  };

  const hundleAddExtraShopsModal = () => {
    if (
      items.length > plan.limit_shops + userPlan.extra_shops ||
      items.length === plan.limit_shops + userPlan.extra_shops
    ) {
      return (
        <div className="mt-0 fixed left-0 top-0 right-0 bottom-5  z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
          <form
            onSubmit={handleSubmitAddShop(hundleAddShop)}
            className="bg-white flex flex-col items-center justify-center p-5  absolute top-[30%]  w-2/6 left-[35%] space-y-4 pt-5"
          >
            <div className="flex justify-end w-full">
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={() => {
                  setShowAddShopModal(false);
                }}
              >
                X
              </button>
            </div>
            <p className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
              Oops! You're about to add new extra products space to your current
              plan
            </p>
            <div className="w-3/6">
              <button
                type="submit"
                className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold disabled:bg-[#AAA6C3]"
              >
                proceed to payment 5 2
              </button>
            </div>
            <div className="w-3/6">
              <button
                type="button"
                className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
                onClick={() => {
                  setShowAddShopModal(false);
                }}
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      );
    }

    if (items.length < plan.limit_shops + userPlan.extra_shops) {
      return (
        <div className="mt-0 fixed left-0 top-0 right-0 bottom-5  z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
          <form
            onSubmit={handleSubmitAddShop(hundleAddShop)}
            className="bg-white flex flex-col items-center justify-center p-5  absolute top-[5%]  w-2/6 left-[35%] space-y-4 pt-12"
          >
            <div
              className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
              style={{ borderColor: errorsAddShop.name ? 'red' : '#AAA6C3' }}
            >
              <label
                htmlFor="shop-name"
                className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
              >
                Shop Name
              </label>
              <input
                id="shop-name"
                type="text"
                placeholder="Shop Name"
                className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
                {...registerAddShop('name', {
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
              {errorsAddShop.name && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsAddShop.name?.message}
                </p>
              )}
            </div>

            <div
              className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#5E6366] relative  p-2"
              style={{
                borderColor: errorsAddShop.shop_group_id ? 'red' : '#AAA6C3',
              }}
            >
              <label
                htmlFor="Shop-Group"
                className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
              >
                Shop Group
              </label>
              <select
                id="Shop-Group"
                className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
                {...registerAddShop('shop_group_id', {
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
                defaultValue={shopCategories[0].id}
              >
                {shopCategories.map((cat, i) => (
                  <option value={cat.id} key={i}>
                    {cat.name}
                  </option>
                ))}
              </select>
              {errorsAddShop.shop_group_id && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsAddShop.shop_group_id?.message}
                </p>
              )}
            </div>
            <div className="text-sm font-semibold text-[#181143] w-2/3">
              Shop Type
            </div>
            <div className="flex flex-col w-5/6 p-2 justify-evenly space-y-3">
              <div className="flex flex-row w-full justify-evenly">
                {types.map((type, i) => (
                  <label
                    key={i}
                    htmlFor={`type-${type.id}`}
                    className={`flex items-center cursor-pointer w-1/5 p-3 rounded-2xl shadow-lg ${
                      // eslint-disable-next-line no-nested-ternary
                      type.disabled === false
                        ? selectedType === type.type
                          ? 'bg-[#F99E1FDE]'
                          : 'bg-white'
                        : 'pointer-events-none'
                    } `}
                  >
                    <img
                      src={type.logo}
                      alt={type.name}
                      style={{
                        filter:
                          type.disabled === true
                            ? 'grayscale(100%)'
                            : 'grayscale(0%)',
                      }}
                    />
                    <input
                      id={`type-${type.id}`}
                      type="radio"
                      checked={selectedType === type.type}
                      disabled={type.disabled}
                      value={type.type}
                      onClick={(e) => handleShopType(e, type.type)}
                      className="appearance-none hover:outline-0 focus:outline-0"
                      {...registerAddShop('type')}
                    />
                  </label>
                ))}
              </div>
            </div>
            {selectedType === 'prestashop' && prestashopForm()}
            {selectedType === 'woocommerce' && woocommerceForm()}
            <div className="w-3/6">
              <button
                type="submit"
                className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold disabled:bg-[#AAA6C3]"
                disabled={selectedType === null}
              >
                add shop
              </button>
            </div>
            <div className="w-3/6">
              <button
                type="button"
                className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
                onClick={() => {
                  setShowAddShopModal(false);
                  setSelectedType(null);
                }}
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      );
    }
  };

  const prestashopForm = () => (
    <>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.url ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Url
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Shop url"
          {...registerAddShop('url', {
            pattern: {
              value:
                /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
              message: 'Enter a valid website address',
            },
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.url && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.url?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.key ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Webservice key"
          {...registerAddShop('key', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.key && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.key?.message}
          </p>
        )}
      </div>
    </>
  );

  const woocommerceForm = () => (
    <>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.url ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Url
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Shop url"
          {...registerAddShop('url', {
            pattern: {
              value:
                /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
              message: 'Enter a valid website address',
            },
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.url && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.url?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.woo_sk ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Secret Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Secret Key"
          {...registerAddShop('woo_sk', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.woo_sk && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.woo_sk?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.woo_ck ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Consumer Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Consumer Key"
          {...registerAddShop('woo_ck', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.woo_ck && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.woo_ck?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.version ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Version
        </label>
        <select
          name=""
          id=""
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          {...registerAddShop('version', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        >
          <option value="wc/v3">wc/v3</option>
        </select>
        {errorsAddShop.version && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.version?.message}
          </p>
        )}
      </div>
    </>
  );

  const hundleAddShop = async (shopData) => {
    const id = toast.loading('Please wait...');
    if (items.length >= plan.limit_shops + userPlan.extra_shops) {
      const userData = JSON.stringify({
        first_name: decoded.UserInfo.first_name,
        last_name: decoded.UserInfo.last_name,
        email: decoded.UserInfo.email,
        phone: decoded.UserInfo.phone,
        priceType: 'shop',
        value: 1,
      });

      const config = {
        method: 'post',
        url: `${apiURL}/payment/`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: userData,
      };

      api.request(config).then((responsePayment) => {
        if (responsePayment.status === 200) {
          window.location.href = responsePayment.data.data.payment_url;
        }
      });
    } else {
      const data = JSON.stringify({
        name: shopData.name,
        type: shopData.type,
        shop_group_id: shopData.shop_group_id,
        url: shopData.url,
        pres_key: shopData.key,
        woo_ck: shopData.woo_ck,
        woo_sk: shopData.woo_sk,
        version: shopData.version,
        user_plan_id: userPlan.id,
      });

      const config = {
        method: 'post',
        url: `${apiURL}/plans/createShop`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data,
      };

      await api
        .request(config)
        .then((responseAddShop) => {
          if (responseAddShop.status === 201) {
            toast.update(id, {
              render: 'Connected',
              type: 'success',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              isLoading: false,
            });
            setShowAddShopModal(false);
            window.location.reload();
          }
        })
        .catch(() => {
          toast.update(id, {
            render: 'Connection failed !',
            type: 'error',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
        });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl relative">
      {showAddShopModal === true && hundleAddExtraShopsModal()}
      {showImportEmployeesModal === true && handleImportEmployeeModal()}

      <Header category="Page" title="Shops" />
      {items.length > 0 ? (
        <button
          type="button"
          className="bg-[#EC1577] rounded-3xl p-2 flex items-center space-x-2  hover:scale-110 transition"
          onClick={() => setShowAddShopModal(true)}
        >
          <div className="rounded-full bg-white w-6 h-6 text-center flex items-center justify-center">
            <span> +</span>
          </div>
          <p className="text-white text-sm">
            {items.length > plan.limit_shops + userPlan.extra_shops ||
            items.length === plan.limit_shops + userPlan.extra_shops
              ? 'Add extra shop'
              : 'add shop'}{' '}
          </p>
        </button>
      ) : (
        <div
          className="add-button"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Link
            className="p-2"
            style={{
              background: '#EC1577',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '25px',
              padding: '7.1px 30px 0px',
              fontWeight: 'normal',
              fontSize: '1,04vw',
              height: '37px',
            }}
            to="/create-shop"
          >
            <Link
              className="p-2"
              style={{
                background: '#ffffff',
                color: '#181143',
                cursor: 'pointer',
                borderRadius: '50%',
                padding: '4px 10px',
                marginRight: '32%',
                marginLeft: '-35%',
                fontSize: '1,20vw',
              }}
              to="/create-order"
            >
              +
            </Link>
            Add
          </Link>
        </div>
      )}
      <div className="m-2 mt-10 p-2 md:p-10 bg-white rounded-3xl">
        <GridComponent
          id="gridcomp"
          dataSource={items}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
          pageSettings={{ pageSize: 10 }}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {shopsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Sort,
              ContextMenu,
              Filter,
              Page,
              ExcelExport,
              Edit,
              PdfExport,
            ]}
          />
        </GridComponent>
      </div>
      <Modal
        showModal={showModal}
        handleModalClose={handleModalClose}
        title="Processing Import ..."
        info={info}
        warning={warning}
        error={error}
        success={success}
      >
        {/* strokeColor="#EC1577" trailColor="#000" */}
        <Line percent={progress} strokeColor="#4BB543" />
        <div className="flex justify-between font-light text-shifti-blue">
          <span>{message}</span>
          <span>{progress}%</span>
        </div>

        {productImport.total_products ? (
          <div className="my-8">
            <Line percent={productImport.progress} />
            {/* <div className="flex justify-between font-light text-shifti-blue">
              <span>{message}</span> */}
            <span className="float-right">
              {productImport.offset}/{productImport.total_products}
            </span>
            {/* </div> */}
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

export default Shops;
