import React, { useState, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import { selectCurrentToken } from '../../features/auth/authSlice';

import styles from '../../css/login.module.css';
import ShopNotImported from '../../components/ShopNotImported';
import Loader from '../../components/Loader';

const apiURL = process.env.REACT_APP_API_URL;

export default function CreateUser() {
  const token = useSelector(selectCurrentToken);
  const [shops, setShops] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shopType, setShopType] = useState('');
  const [selectedShop, setSelectedShop] = useState('');
  const [isShopImported, setIsShopImported] = useState();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    shop_id: 0,
    email: '',
    password: '',
    validation: {
      error: [true, true, false, true, true, true],
      errorMsg: [
        'Required',
        'Required',
        'Required',
        'Required',
        'Required',
        'Required',
      ],
    },
  });
  const [formDataError, setformDataError] = useState([
    true,
    true,
    false,
    true,
    true,
    true,
  ]);
  const getShops = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-all-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getShops().then((response) => {
      setShops(response);
      if (shopType === '') {
        setShopType(response[0].type);
        setSelectedShop(response[0].id);
        setIsShopImported(response[0].is_imported);
        setLoading(false);
      }
    });
  }, [selectedShop]);

  const handelSubmit = async (e) => {
    e.preventDefault();
    const decoded = jwtDecode(token);
    const idToast = toast.loading('Please wait...');
    const ERROR = [...formData.validation.error];
    const ERROR_MSG = [...formData.validation.errorMsg];
    setformDataError(ERROR_MSG);

    const data = JSON.stringify({
      first_name: formData.first_name,
      last_name: formData.last_name,
      shop_id: formData.shop_id,
      email: formData.email,
      password: formData.password,
    });
    const config = {
      method: 'post',
      url: `${apiURL}/customers/add`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'New Customer Is Created !',
          type: response.data === 'success',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 1500);
      })
      .catch((error) =>
        toast.update(idToast, {
          render: 'Failed to create customer !',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        })
      );
    // }
  };

  const handelFormDataChange = (value, key, index) => {
    const aux = { ...formData };
    aux[key] = value;
    if (key === 'first_name') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'last_name') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'email') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else if (!isEmail(value)) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Inavlid Email';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'password') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    setFormData(aux);
  };

  const handleShop = (e) => {
    setSelectedShop(e.target.value);
    setShopType(e.target.selectedOptions[0].getAttribute('type'));
    e.target.selectedOptions[0].getAttribute('isImported') === 'true'
      ? setIsShopImported(true)
      : setIsShopImported(false);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="w-[95%] sticky top-[5.5rem] md:ml-[3%] ml-[3.5%] z-40">
        <div className="filter-by-shop mb-5 relative md:w-full w-[22rem]">
          <select className="shopSelect text-center" onChange={handleShop}>
            {shops.map((shop) => (
              <option
                type={shop.type}
                value={shop.id}
                className="text-[#EC1577] text-center"
                key={shop.id}
              >
                {shop.name}
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            icon={faShop}
            className="h-[1.5rem] w-[1.5rem] scale-100 md:left-[42%] md:top-[19%] top-[19%] left-[30%]  absolute  text-[#AAA6C3] text-opacity-70 checked-1 transition "
          />
        </div>
      </div>
      {isShopImported === true ? (
        <div className="p-2 md:p-5 ml-3 rounded-3xl mb-3 md:mt-8 mt-20 flex justify-center">
          <div className="flex justify-center flex-col w-[90%]">
            <Header category="Page" title="Create Customer" />
            <form
              action=""
              onSubmit={handelSubmit}
              className="sft-form formStyle mt-2 md:mt-0 p-2 md:p-10 mr-3 bg-white rounded-3xl md:w-full"
            >
              <div className="sft-input-container grid grid-cols-2 gap-y-4 pt-5 mr-10 md:mr-0 md:w-[40%] lg:w-[81%]">
                <label htmlFor="name" className="formLabel">
                  First Name :
                </label>
                <div className="input-container">
                  <input
                    id="name"
                    onChange={(e) =>
                      handelFormDataChange(e.target.value, 'first_name', 0)
                    }
                    type="text"
                    className="formInput"
                  />
                  <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
                </div>
              </div>
              <div className="sft-input-container grid grid-cols-2 gap-y-4 mt-6 mr-10 md:mr-0 md:w-[40%] lg:w-[81%]">
                <label htmlFor="name" className="formLabel">
                  Last Name :
                </label>
                <div className="input-container">
                  <input
                    type="text"
                    onChange={(e) =>
                      handelFormDataChange(e.target.value, 'last_name', 1)
                    }
                    className="formInput"
                  />
                  <div style={{ color: '#FF9494' }}>{formDataError[1]}</div>
                </div>
              </div>

              <div className="sft-input-container grid grid-cols-2 mt-6 gap-y-4 mr-10 md:mr-0 md:w-[40%] lg:w-[81%]">
                <label
                  htmlFor="name"
                  className="formLabel mr-[2.1rem] md:mr-[3rem]"
                >
                  Email :
                </label>
                <div className="input-container">
                  <input
                    type="email"
                    onChange={(e) =>
                      handelFormDataChange(e.target.value, 'email', 3)
                    }
                    className="formInput"
                  />

                  <div style={{ color: '#FF9494' }}>{formDataError[3]}</div>
                </div>
              </div>
              <div className="sft-input-container grid grid-cols-2 mt-6 gap-y-4 mr-10 md:mr-0 md:w-[40%] lg:w-[81%]">
                <label htmlFor="name" className="formLabel">
                  Password :
                </label>
                <div className={styles.passwordContainer}>
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    onChange={(e) =>
                      handelFormDataChange(e.target.value, 'password', 5)
                    }
                    className="formInput"
                  />
                  <Link
                    className={styles.eye}
                    onClick={() => setPasswordShown(!passwordShown)}
                  >
                    {passwordShown === true ? (
                      <AiOutlineEyeInvisible size={30} />
                    ) : (
                      <AiOutlineEye size={30} />
                    )}
                  </Link>
                </div>
                <div style={{ color: '#FF9494' }}>{formDataError[5]}</div>

                <div className="flex justify-end">
                  <button type="submit" className="formButton">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <ShopNotImported />
      )}
    </div>
  );
}
