/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
  AiOutlineTag,
  AiFillMinusCircle,
  AiFillPlusCircle,
  AiFillCheckCircle,
  AiOutlineFolderOpen,
} from 'react-icons/ai';
import { BsCircle } from 'react-icons/bs';
import PropTypes from 'prop-types';

const AssociatedStores = ({
  stores,
  onChange,
  disallowed = '',
  className = '',
}) => {
  const [open, setOpen] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const disabledShops = disallowed.split(',');

  return (
    <div className={`bg-white rounded-xl ${className}`}>
      <div className="flex justify-between items-center px-6">
        <div className="text-shifti-main-text py-4">
          <AiOutlineTag className="inline" /> Shops
        </div>
        <div className="flex justify-end gap-5">
          <div className="py-4" onClick={() => setOpen(false)}>
            <span>
              <AiFillMinusCircle className="text-lg inline" /> reduce all
            </span>
          </div>
          <div className="py-4" onClick={() => setOpen(true)}>
            <span>
              <AiFillPlusCircle className="text-lg inline" /> view all
            </span>
          </div>
          <div className="py-4" onClick={() => setCheckAll(true)}>
            <span>
              <AiFillCheckCircle className="text-lg inline" /> check all
            </span>
          </div>
          <div className="py-4" onClick={() => setCheckAll(false)}>
            <span>
              <BsCircle className="text-lg inline" /> uncheck all
            </span>
          </div>
        </div>
      </div>
      <hr />
      <div className="mt-8 px-6 py-4">
        {stores.map((group, index) => (
          <div className="block" key={index}>
            <div className="flex items-center gap-2">
              <input type="checkbox" />
              <label
                htmlFor={`${group.name}-${group.id}`}
                className="groupAttributeLabel mr-2	"
              >
                <AiOutlineFolderOpen className="inline" />
                <span className="ml-2">Group : {group.name}</span>
              </label>
            </div>
            {open &&
              group.Shops.map((shop, i) => (
                <div className="ml-10 flex items-center gap-2" key={i}>
                  <input
                    type="checkbox"
                    onChange={onChange}
                    value={shop.id}
                    disabled={disabledShops.includes(shop.type)}
                  />
                  <label
                    htmlFor={`${shop.name}-${shop.id}`}
                    className="groupAttributeLabel mr-2	"
                  >
                    {shop.name}
                  </label>
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};
AssociatedStores.propTypes = {
  stores: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disallowed: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default AssociatedStores;
