import React from 'react';
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationDataLabel,
  Inject,
  AccumulationTooltip,
} from '@syncfusion/ej2-react-charts';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import { useStateContext } from '../../contexts/ContextProvider';

const Doughnut = ({
  id,
  data,
  legendVisiblity,
  height,
  width,
  xName,
  yName,
  name,
}) => {
  const { currentMode } = useStateContext();
  return (
    <div className="relative">
      {!data || (data && !data.length) ? (
        <Spinner className="absolute left-0 right-0 top-0 bottom-0 m-auto w-20 h-20" />
      ) : null}
      <AccumulationChartComponent
        id={id}
        legendSettings={{ visible: legendVisiblity, background: 'white' }}
        height={height}
        width={width}
        background={currentMode === 'Dark' ? '#33373E' : '#fff'}
        tooltip={{ enable: true }}
      >
        <Inject
          services={[
            AccumulationLegend,
            PieSeries,
            AccumulationDataLabel,
            AccumulationTooltip,
          ]}
        />
        <AccumulationSeriesCollectionDirective>
          <AccumulationSeriesDirective
            name="Sale"
            dataSource={data}
            xName={xName}
            yName={yName}
            innerRadius="80%"
            startAngle={0}
            endAngle={360}
            radius="50%"
            explode
            explodeOffset="10%"
            explodeIndex={2}
            dataLabel={{
              visible: true,
              name,
              position: 'Outside',
              font: {
                fontWeight: '600',
                color: '#000',
              },
            }}
            enableSmartLabels
            enableAnimation
          />
        </AccumulationSeriesCollectionDirective>
      </AccumulationChartComponent>
    </div>
  );
};
Doughnut.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  legendVisiblity: PropTypes.bool.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  xName: PropTypes.string.isRequired,
  yName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
export default Doughnut;
