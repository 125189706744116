import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({
  showModal,
  handleModalClose,
  title = '',
  info,
  warning,
  error,
  success,
  children,
}) => (
  <div
    id="modal"
    className={`absolute inset-0 flex items-center justify-center p-2 md:p-10 rounded-3xl  ${
      showModal ? '' : 'hidden'
    }`}
  >
    <div className="fixed inset-0 bg-black opacity-50" />
    <div className="m-2 mt-10 p-2 md:p-10 md:pt-6 w-full bg-white rounded-3xl z-50 relative">
      <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
        {title}
      </h3>
      <button
        type="button"
        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
        onClick={() => handleModalClose()}
      >
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
      <div className="pt-8">{children}</div>
      <div className="pt-8">
        {info ? (
          <div
            className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
            role="alert"
          >
            <span className="font-medium">Info: </span> {info}
          </div>
        ) : null}

        {error ? (
          <div
            className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <span className="font-medium">Error: </span> {error}
          </div>
        ) : null}

        {success ? (
          <div
            className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
            role="alert"
          >
            <span className="font-medium">Success: </span> {success}
          </div>
        ) : null}
        {warning ? (
          <div
            className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
            role="alert"
          >
            <span className="font-medium">Warning: </span> {warning}
          </div>
        ) : null}
        <div
          className={`${error || success ? null : 'hidden'} mt-8 float-right`}
        >
          <button
            type="button"
            onClick={() => handleModalClose()}
            className="addButton w-[10rem] mt-4"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
);
Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  warning: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  success: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};
export default Modal;
