import React, { useState, useEffect, createContext } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../utils/apiService';
import styles from '../../css/spinner.module.css';
import shopImg from '../../img/store.png';
import { Header } from '../../components';
import ProductEssential from './ProductEssential To do';
import ProductDelivrey from './ProductDelivery';
import ProductQuantity from './ProductQuantity';
import ProductPrice from './ProductPrice';
import ProductOptions from './ProductOptions';
import ProductVariations from './ProductVariations';
import ProductSeoReferencing from './ProductSeoReferencing';
import MultiShopsSelection from '../../components/MultiShopsSelection';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export const productContext = createContext();
const Loading = () => (
  <div className={styles.simpleSpinner}>
    <span />
  </div>
);

export default function CreateProduct() {
  const FAKE_DATA = {
    shopsId: [1, 2, 3],
    name: 'test product name shifti ',
    woocommerceData: {
      editCategory: false,
      editAttribute: false,
      slug: 'slug test',
      description: 'description test',
      type: 'simple',
      status: 'draft',
      featured: 1,
      short_description: 'short description test',
      sku: 'jd4ss',
      regular_price: '10',
      sale_price: '8',
      virtual: false,
      downloadable: false,
      tax_status: 'taxable',
      weight: '5.0',
      purchase_note: 'purshase note test',
      manage_stock: true,
      stock_quantity: 5,
      stock_status: 'instock',
      backorders: 'no',
      parent_id: 0,
      dimensions: {
        length: '2.5',
        width: '2.1',
        height: '3',
      },
      images: [
        {
          src: 'https://cdn.pixabay.com/photo/2014/06/03/19/38/road-sign-361514_960_720.png',
          name: 'test-image',
          alt: 'test alt image',
        },
      ],
      tags: [
        {
          id: 1,
          name: 'test tag',
          slug: 'slug tag test',
        },
      ],
    },
    prestashopData: {
      editCategory: false,
      editAttribute: false,
      description: 'test description',
      description_short: 'test description_short',
      manufacturer_id: 1,
      supplier_id: null,
      category_default_id: null,
      TaxRuleGroupId: 2,
      new: true,
      price: 20.0,
      reference: 'test refrence', // max 64
      supplier_reference: 'test supplier refrence', // max 64
      location: 'location test', // max 255,
      width: 12.56,
      height: 10.5,
      depth: 5.5,
      weight: 7.5,
      quantity_discount: 0,
      ean13: '0132325198641', // max 13,
      isbn: '013232519864', // max 32,
      upc: '013232519864', // max 12
      mpn: '122312122323', // max 40
      cache_is_pack: 0,
      is_virtual: 0,
      state: 1,
      delivery_in_stock: 'test', // max 255
      delivery_out_stock: 'test', // max 255
      on_sale: 0,
      online_only: 0,
      cache_has_attachments: 0,
      ecotax: 0.0,
      minimal_quantity: 1,
      low_stock_threshold: null,
      low_stock_alert: 0,
      wholesale_price: 15, // b9adech chrit el poduit
      unity: null,
      unit_price_ratio: 0.0,
      additional_shipping_cost: 0.0,
      customizable: 0,
      text_fields: 0,
      uploadable_files: 0,
      available_for_order: 1,
      available_date: '0000-00-00',
      show_condition: 0,
      condition: 'new',
      show_price: 1,
      indexed: 1,
      visibility: 'both',
      advanced_stock_management: 0,
      pack_stock_type: 3,
      meta_description: 'test meta description', // max 512
      meta_keywords: 'test meta keywords', // max 255
      meta_title: 'meta title test', // max 255
      available_now: 'yes',
      available_later: 'no',
    },
  };
  const { id } = useParams();
  const pageAction = id ? 'Update' : 'Create';
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const [shops, setShops] = useState(['empty']);
  const [selectedShop, setSelectedShop] = useState(0);
  const [selectedShops, setSelectedShops] = useState([]);
  const [langs, setLangs] = useState([]);
  const [selectedLangs, setSelectedLangs] = useState([]);
  const shopType = '';
  const [type, setType] = useState('Standard product');
  const [currentTab, setCurrentTab] = useState('1');
  const [variations, setVariations] = useState('simple');
  const [formData, setFormData] = useState({
    name: '',
    languages: {},
    reference: '',
    sites: {},
    images: [],
    attributes: [],
    variations: {
      attributes: [],
      options: [],
      selected: [],
      selectDefaultValues: [],
      variations: [],
    },
    categories: {
      categories: [],
      categoryHierarchy: [],
      toggledItems: [],
      selected: [],
    },
    features: { features: [], selected: [] },
    productImage: [],
    productCustomizedType: [],
    productCustomizedLabel: [],
    productCustomizedRequired: [],
    metaTitle: '',
    metaDescription: '',
    metaKeywords: [
      { value: 'keyword1', label: 'Keyword 1' },
      { value: 'keyword2', label: 'Keyword 2' },
    ],
    friendlyUrl: '',
    type: '',
    status: 1,
    description: '',
    short_description: '',
    sku: '',
    price: 0,
    regular_price: 0,
    height: '',
    width: '',
    depth: '',
    weight: '',
    packSingleProductQuantity: 1,
    ean13: '',
    isbn: '',
    upc: '',
    mpn: '',
    lang_id: 1,
    id_category_default: 2,
    default_image_id: 1,
    position_in_category: 3,
    new: 1,
    quantity: 0,
    minQuantity: 0,
    unity: '',
    unity_ratio_price: 0,
    additional_shipping_cost: 0,
    ecotax: 0,
    characteristics: [],
    predefinedValues: [],
    packProducts: [],
    validation: {
      error: [],
      errorMsg: [],
    },
  });
  const [formDataError, setformDataError] = useState([
    true,
    true,
    false,
    true,
    true,
    true,
  ]);

  const handelFormDataChange = (event, key, isoLang = null, index = null) => {
    event.preventDefault();
    const { value } = event.target;
    const aux = { ...formData };
    aux[key] = value;
    if (key === 'name') {
      aux.languages[isoLang][key] = value;
      setFormData(aux);
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      return;
    }
    if (key === 'reference') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'quantity') {
      if (!selectedShop) {
        selectedShops.map((item) => {
          aux.sites[item.id].quantity = value;
          return null;
        });
      } else {
        aux.sites[selectedShop].quantity = value;
      }
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'priceTaxExcl') {
      const priceTaxIncl = (value * (100 + 20)) / 100;
      if (!selectedShop) {
        selectedShops.map((item) => {
          aux.sites[item.id].priceTaxExcl = value;
          aux.sites[item.id].priceTaxIncl = priceTaxIncl;
          return null;
        });
      } else {
        aux.sites[selectedShop].priceTaxExcl = value;
        aux.sites[selectedShop].priceTaxIncl = priceTaxIncl;
      }
      setFormData(aux);
      return;
    }
    if (key === 'priceTaxIncl') {
      const priceTaxExcl = (value * 100) / (100 + 20);
      if (!selectedShop) {
        selectedShops.map((item) => {
          aux.sites[item.id].priceTaxIncl = value;
          aux.sites[item.id].priceTaxExcl = priceTaxExcl;
          return null;
        });
      } else {
        aux.sites[selectedShop].priceTaxIncl = value;
        aux.sites[selectedShop].priceTaxExcl = priceTaxExcl;
      }
      setFormData(aux);
      return;
    }
    if (key === 'description') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'short_description') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'type') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'sku') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'price') {
      if (value.trim() === 0) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'regular_price') {
      if (value.trim() === 0) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'height') {
      if (value.trim() === 0) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'width') {
      if (value.trim() === 0) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    setFormData(aux);
  };

  const tabs = [
    {
      id: '1',
      tabTitle: 'Basic settings',
      title: 'Basic settings',
      content: (
        <ProductEssential
          handelFormDataChange={handelFormDataChange}
          formData={formData}
          setFormData={setFormData}
          type={type}
          variations={variations}
          setVariations={setVariations}
          shopType={shopType}
        />
      ),
    },
    {
      id: '2',
      tabTitle: 'Quantities',
      title: 'Quantities',
      content: (
        <ProductQuantity
          handelFormDataChange={handelFormDataChange}
          formData={formData}
          setFormData={setFormData}
          selectedShop={selectedShop}
          selectedShops={selectedShops}
        />
      ),
    },
    {
      id: '3',
      tabTitle: 'Variations',
      title: 'Variations',
      content: (
        <ProductVariations
          handelFormDataChange={handelFormDataChange}
          // formData={formData}
          // setFormData={setFormData}
        />
      ),
    },
    {
      id: '4',
      tabTitle: 'Shipping',
      title: 'Shipping',
      content: (
        <ProductDelivrey
          handelFormDataChange={handelFormDataChange}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      id: '5',
      tabTitle: 'Pricing',
      title: 'Pricing',
      content: (
        <ProductPrice
          handelFormDataChange={handelFormDataChange}
          formData={formData}
          setFormData={setFormData}
          selectedShop={selectedShop}
          selectedShops={selectedShops}
        />
      ),
    },
    {
      id: '6',
      tabTitle: 'SEO Referencing',
      title: 'SEO Referencing',
      content: (
        <ProductSeoReferencing
          handelFormDataChange={handelFormDataChange}
          formData={formData}
          setFormData={setFormData}
          selectedShop={selectedShop}
          selectedShops={selectedShops}
          shops={shops}
        />
      ),
    },
    {
      id: '7',
      tabTitle: 'Options',
      title: 'Options',
      content: (
        <ProductOptions
          handelFormDataChange={handelFormDataChange}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
  ];
  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  async function fetchProduct(productId) {
    const config = {
      method: 'get',
      url: `${apiURL}/products/${productId}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.request(config);
    return response.data;
  }

  const handelSubmit = async (e) => {
    e.preventDefault();
    const ERROR_MSG = [...formData.validation.errorMsg];
    setformDataError(ERROR_MSG);
    let response;
    if (id) {
      response = await updateProduct();
    } else {
      response = await createProduct();
      await uploadImages(response.data.data.ProductShops);
    }
    // imageFormData.append(
    //   'shops',
    //   JSON.stringify(response.data.data.ProductShops)
    // );
  };

  const uploadImages = async (product) => {
    const idToast = toast.loading('Uploading media files...');
    const imageFormData = new FormData();
    imageFormData.append('product', JSON.stringify(product));
    formData.images.forEach((file) => {
      imageFormData.append('imgCollection', file);
    });
    const fileConfig = {
      method: 'post',
      url: `${apiURL}/products/upload-images`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: imageFormData,
    };

    const response = await api.request(fileConfig).then(() => {
      toast.update(idToast, {
        render: 'Media files created successfully !',
        type: 'success',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        isLoading: false,
      });
    });
    return response; // Return the response after successful upload
  };

  const createProduct = async () => {
    // console.log(formData, 'fdfdfdfdf');
    // return;
    try {
      const idToast = toast.loading('Please wait...');
      const data = JSON.stringify({
        name: formData.name,
        shops: [selectedShops],
        sites: formData.sites,
        reference: formData.reference,
        variations: formData.variations.variations,
        default_image_id: 1,
        manufacturer_id: formData.manufacturer_id,
        quantity: formData.quantity,
        min_quantity: formData.minQuantity,
        categories: formData.categories.selected,
        id_category_default: 2,
        position_in_category: 3,
        type: formData.type,
        status: 1,
        languages: formData.languages,
        description: formData.description,
        short_description: formData.short_description,
        sku: formData.sku,
        price: formData.price,
        regular_price: formData.regular_price,
        priceTaxExcl: formData.priceTaxExcl,
        priceTaxIncl: formData.priceTaxIncl,
        height: formData.height,
        width: formData.width,
        depth: formData.depth,
        weight: formData.weight,
        ean13: formData.ean13,
        isbn: formData.isbn,
        upc: formData.upc,
        mpn: formData.mpn,
        new: 1,
        ecotax: 0,
        unity: formData.unity,
        unity_ratio_price: 0,
        is_virtual: 0,
        additional_shipping_cost: 0,
        file: formData.file,
        brand: formData.brand,
        condition: formData.condition,
        lang_id: 1,
      });
      const config = {
        method: 'post',
        url: `${apiURL}/products`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      const response = await api.request(config);
      toast.update(idToast, {
        render: `Product ${pageAction} Successfully !`,
        type: 'success',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        isLoading: false,
      });
      return response;
    } catch (error) {
      toast.error(
        'An error occured. Our dev team notified for immediate resolution',
        {
          toastId: 'error',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        }
      );
      throw error;
    }
  };

  const updateProduct = async () => {
    try {
      const idToast = toast.loading('Please wait...');

      const config = {
        method: 'put',
        url: `${apiURL}/products/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: FAKE_DATA,
      };
      const response = await api.request(config);
      toast.update(idToast, {
        render: `Product ${pageAction} Successfully !`,
        type: 'success',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        isLoading: false,
      });
      return response;
    } catch (error) {
      toast.error(
        'An error occured. Our dev team notified for immediate resolution',
        {
          toastId: 'error',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        }
      );
      throw error;
    }
  };

  const filteredTabs =
    variations === 'Product with variations'
      ? tabs.filter((tab) => tab.id !== '2' || tab.id === '3')
      : tabs.filter((tab) => tab.id === '2' || tab.id !== '3');

  const getCategories = async (shopId) =>
    new Promise((resolve) => {
      const config = {
        method: 'get',
        url: `${apiURL}/categories/shops/${shopId}`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then(async (response) => {
          const tempCategories = await response.data.map((item) => ({
            label: item.CategoryLangs[0].name,
            value: item.id,
            parent_id: item.parent_id,
            foreign_id: item.CategoryShops[0].foreign_id,
          }));
          const categoryMap = {}; // empty object to store categories

          // loop through categories and add to categoryMap
          for (const category of tempCategories) {
            categoryMap[category.foreign_id] = category;
            category.children = []; // add empty children array
            category.active = false;
          }

          const categoryHierarchy = []; // array to store top-level categories

          // loop through categories again and add to parent categories' children arrays
          for (const category of tempCategories) {
            if (category.parent_id) {
              // find parent category in categoryMap and add current category to its children array
              categoryMap[category.parent_id].children.push(category);
            } else {
              // no parent category, so add to top-level categories
              categoryHierarchy.push(category);
            }
          }
          resolve({
            categories: tempCategories,
            categoryHierarchy,
            selected: [],
            toggledItems: [],
          });
        })
        .catch(() => {
          resolve({
            categories: [],
            categoryHierarchy: [],
            selected: [],
            toggledItems: [],
          });
        });
    });

  const getShops = async () =>
    new Promise((resolve, reject) => {
      const config = {
        method: 'get',
        url: `${apiURL}/shop/get-all-shops`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => {
          setShops([...response.data]);
          resolve(response.data);
        })
        .catch((error) => {
          JSON.stringify(error);
          reject(error);
        });
    });

  const allShopIds = shops.map((item) => item.id);
  const allShopTypes = 'AllShops';

  function initializeLanguages(shop, temp, productLangs = false) {
    console.log(productLangs, 'obj1');
    console.log(shop.Langs, 'obj2');

    const languages = shop.Langs.map((lang) => {
      const isoCode = lang.iso_code;
      if (!temp.languages[isoCode]) {
        temp.languages[isoCode] = { name: '' };
      }
      if (productLangs) {
        const matchingProductLang = productLangs.find(
          (productLang) => productLang.lang_id === lang.id
        );

        // If a matching productLang is found, assign its name
        if (matchingProductLang) {
          temp.languages[isoCode].name = matchingProductLang.name || ''; // Assign the name
        }
      }

      return {
        id: lang.id,
        iso_code: isoCode,
        checked: true,
      };
    });

    return languages;
  }

  function initializeShopLanguages(languages, productLangs = false) {
    // object 1
    console.log(productLangs, 'productLangs');
    // object 2
    console.log(languages, 'lsdzanguages');
    const shopLanguages = {};
    if (!productLangs) {
      languages.map(
        (lang) =>
          (shopLanguages[lang.iso_code] = {
            id: lang.id,
            iso_code: lang.iso_code,
            name: '',
            description: '',
            short_description: '',
            custom_features: '',
            metaTitle: '',
            metaDescription: '',
            metaKeywords: '',
            friendlyUrl: '',
          })
      );
      return shopLanguages;
    }
    languages.forEach((lang) => {
      // Find the corresponding language object in Object 1 based on lang.id
      const correspondingLang = productLangs.find(
        (productLang) => productLang.LangId === lang.id
      );

      // If a match is found, assign values to shopLanguages
      if (correspondingLang) {
        shopLanguages[lang.iso_code] = {
          id: correspondingLang.id,
          iso_code: lang.iso_code,
          name: correspondingLang.name || '',
          description: correspondingLang.description || '',
          short_description: correspondingLang.short_description || '',
          custom_features: '', // Add your logic here if needed
          metaTitle: correspondingLang.meta_title || '',
          metaDescription: correspondingLang.meta_description || '',
          metaKeywords: correspondingLang.meta_keywords || '',
          friendlyUrl: '', // Add your logic here if needed
        };
      }
    });

    return shopLanguages;
  }

  function getFormDataShop(shop, shopLanguages, categories) {
    // temp.sites[shop.id] =
    console.log(shop, 'myshop');
    return {
      id: shop.id,
      type: shop.type,
      url: shop.url,
      pres_key: shop.pres_key,
      woo_consumer_key: shop.woo_consumer_key,
      woo_consumer_secret: shop.woo_consumer_secret,
      woo_version: shop.woo_version,
      languages: shopLanguages,
      images: [],
      attributes: [],
      variations: {
        attributes: [],
        options: [],
        selected: [],
        selectDefaultValues: [],
        variations: [],
      },
      categories,
      features: {
        features: [],
        selected: [],
      },
      productImage: [],
      productCustomizedType: [],
      productCustomizedLabel: [],
      productCustomizedRequired: [],
      status: 1,
      sku: '',
      price: 0,
      regular_price: 0,
      priceTaxExcl: 0,
      priceTaxIncl: 0,
      height: '',
      width: '',
      depth: '',
      weight: '',
      packSingleProductQuantity: 1,
      lang_id: 1,
      id_category_default: 2,
      default_image_id: 1,
      position_in_category: 3,
      new: 1,
      quantity: 0,
      minQuantity: 0,
      unity: '',
      unity_ratio_price: 0,
      additional_shipping_cost: 0,
      ecotax: 0,
      characteristics: [],
      predefinedValues: [],
      packProducts: [],
      validation: {
        error: [],
        errorMsg: [],
      },
      active: 1,
    };
  }

  async function getProductShopFormData(product) {
    const productShops = product.ProductShops;
    const productLangs = product.ProductLangs;
    const temp = { ...formData };
    const languages = [];
    const shop = [];
    for (const productShop of productShops) {
      // initialize languages
      const language = initializeLanguages(
        productShop.Shop,
        temp,
        productLangs
      );
      languages.push(...language);
      // initialize Shop categories
      const categories = await getCategories(productShop.Shop.id);
      // initialize languages by shop
      const shopLanguages = initializeShopLanguages(languages, productLangs);
      // initialize shop data
      temp.sites[productShop.Shop.id] = getFormDataShop(
        productShop.Shop,
        shopLanguages,
        categories
      );
      console.log(product, 'blyat');
      temp.reference = product.reference;
      temp.quantity = product.quantity;
      temp.sku = product.sku;
      temp.price = product.price;
      temp.regular_price = product.regular_price;
      temp.height = product.height;
      temp.width = product.width;
      temp.depth = product.length;
      temp.weight = product.weight;
      temp.ean13 = product.ean13;
      shop.push(productShop.Shop);
    }
    const mergedLangs = filterDuplicatedLanguages(languages);
    return { mergedLangs, shop, temp };
  }

  async function getDefaultShopFormData(shop) {
    const temp = { ...formData };
    // initialize languages
    const languages = initializeLanguages(shop, temp);
    // initialize Shop categories
    const categories = await getCategories(shop.id);
    // initialize languages by shop
    const shopLanguages = initializeShopLanguages(languages);
    // initialize shop data
    temp.sites[shop.id] = getFormDataShop(shop, shopLanguages, categories);

    return { languages, shop, temp };
  }

  async function getShopFormData(shop) {
    const temp = { ...formData };
    const languages = initializeLanguages(shop, temp);
    const categories = await getCategories(shop.id);
    const shopLanguages = initializeShopLanguages(languages);
    temp.sites[shop.id] = getFormDataShop(shop, shopLanguages, categories);

    const mergedLangs = filterDuplicatedLanguages(languages);
    return { mergedLangs, temp };
  }

  function filterDuplicatedLanguages(languages) {
    const uniqueIsoCodes = new Set(langs.map((l) => l.iso_code));
    const filteredNewObj = languages.filter(
      (l) => !uniqueIsoCodes.has(l.iso_code)
    );
    const mergedLangs = [...langs, ...filteredNewObj];
    return mergedLangs;
  }

  useEffect(() => {
    const prepareData = async () => {
      let languages = [];
      let shop = null;
      let newFormData = {};

      // check if shops are fetched
      if (shops[0] === 'empty') {
        try {
          const response = await getShops();
          // check if there is no selected shops among the shops
          if (!selectedShops.length) {
            if (id) {
              const product = await fetchProduct(id);
              ({
                mergedLangs: languages,
                shop,
                temp: newFormData,
              } = await getProductShopFormData(product));
            } else {
              // get default shop
              for (const item of response) {
                if (item.default) {
                  ({
                    languages,
                    shop,
                    temp: newFormData,
                  } = await getDefaultShopFormData(item));
                  break; // Stop after finding the default shop
                }
              }
            }
          }
        } catch (error) {
          console.error('Error fetching shops:', error);
          // Handle errors fetching shops
        }
      } else {
        for (const item of selectedShops) {
          if (!formData.sites[item.id]) {
            ({ mergedLangs: languages, temp: newFormData } =
              await getShopFormData(item));
          }
        }
      }
      // Set languages, shop, and newFormData
      if (languages.length) {
        setLangs(languages);
      }
      if (shop) {
        if (Array.isArray(shop)) {
          setSelectedShops(shop);
        } else {
          setSelectedShops([{ ...shop }]);
        }
      }
      setFormData(Object.keys(newFormData).length ? newFormData : formData);
    };

    prepareData();
  }, [selectedShops]);

  if (shops[0] !== 'empty' && selectedShops.length) {
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const contextValue = [
      shops,
      setShops,
      allShopTypes,
      allShopIds,
      selectedShop,
      setSelectedShop,
      selectedShops,
      setSelectedShops,
      langs,
      setLangs,
      selectedLangs,
      setSelectedLangs,
      formData,
      setFormData,
    ];

    return (
      <productContext.Provider value={contextValue}>
        <div
          className="m-2 md:ml-7 mt-8 p-2 md:p-5 rounded-3xl relative"
          style={{ paddingbottom: '0', marginBottom: '0' }}
        >
          <MultiShopsSelection
            context="product"
            className="absolute top-2 right-0 left-0 m-auto"
          />
          <div className="mt-14">
            <Header category="Page" title={`${pageAction} Product`} />
          </div>
        </div>
        <div className="content md:ml-3">
          <form action="" onSubmit={handelSubmit}>
            <div className="top-container flex flex-col ml-[3%] mb-5">
              <div className="flex flex-col md:flex-row  md:justify-between space-y-5">
                <div className="flex flex-col w-[90%]">
                  <label htmlFor="Name" className="createProductLabel">
                    Name :
                  </label>
                  <div className="flex gap-6 flex-wrap w-full">
                    {langs.map((lang, i) => {
                      const isoCode = lang.iso_code;
                      if (lang.checked)
                        return (
                          <div className="flex gap-2 items-stretch w-2/5">
                            <input
                              name="name"
                              className="w-11/12 bg-transparent border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block dark:placeholder-gray-400 h-[2.8rem] p-2"
                              type="text"
                              placeholder="Product Name"
                              key={i}
                              value={
                                formData.languages[isoCode] &&
                                formData.languages[isoCode].name
                              }
                              onChange={(e) =>
                                handelFormDataChange(e, 'name', isoCode, 0)
                              }
                            />
                            <span className="w-12 bg-shifti-light-grey-bg text-shifti-blue text-center text-sm rounded-[0.8rem] dark:placeholder-gray-400 h-[2.8rem] p-2">
                              {lang.iso_code}
                            </span>
                          </div>
                        );
                      return null;
                    })}
                  </div>
                </div>
              </div>
              <div className="w-full mt-5">
                <div className="bg-white h-12 rounded-t-xl divide-x -mb-4 inline-block px-4 pt-1 font-medium text-shifti-blue">
                  <Link onClick={() => setSelectedShop(0)}>
                    <div
                      className={`px-4 inline-block cursor-pointer divide-x ${
                        selectedShop ? '' : 'text-shifti-pink font-semibold'
                      }`}
                    >
                      All
                    </div>
                  </Link>
                  {selectedShops.map((item) => (
                    <Link onClick={() => setSelectedShop(item.id)}>
                      <div
                        className={`px-4 inline-block cursor-pointer divide-x ${
                          selectedShop === item.id
                            ? 'text-shifti-pink font-semibold'
                            : ''
                        }`}
                      >
                        {item.name}
                      </div>
                    </Link>
                  ))}
                </div>
                <br />
                <div className="py-2 px-4 bg-white rounded-xl mr-auto sft-shadow block w-2/3">
                  <div className="divide-x inline-block flex-col md:flex-row">
                    {filteredTabs.map((tab, i) => (
                      <button
                        type="button"
                        key={i}
                        id={tab.id}
                        className="px-4 font-semibold text-shifti-grey disabled:text-shifti-orange"
                        disabled={currentTab === `${tab.id}`}
                        onClick={handleTabClick}
                      >
                        {tab.tabTitle}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-8">
              {filteredTabs.map((tab) =>
                currentTab === tab.id ? tab.content : null
              )}
              <button
                type="submit"
                className="text-white font-semibold bg-shifti-pink-bg rounded-full px-12 py-2 float-right mt-4"
              >
                {pageAction}
              </button>
            </div>
          </form>
        </div>
      </productContext.Provider>
    );
  }
  if (!shops.length) {
    return (
      <div className="bg-white shadow-xl flex justify-center flex-col items-center w-1/2 m-auto mt-20 rounded-xl gap-2 p-4">
        <img
          src={shopImg}
          className="rounded-full"
          width="170"
          height="auto"
          alt=""
        />
        <span className="text-xl text-shifti-blue">
          No shops has been added yet.
        </span>
        <p className="text-gray-400">
          Add a new shop by simply clicking the button below.
        </p>
        <button
          type="button"
          onClick={() => navigate('/create-shop')}
          className="pl-[0.7rem] addButton w-[10rem] mt-4"
        >
          Add shop
        </button>
      </div>
    );
  }
  return <Loading />;
}
