import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

function PlanFinal() {
  const [loaded, setLoaded] = useState(false);
  const [isStatusSuccess, setIsStatusSuccess] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector(selectCurrentToken);

  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentToken = searchParams.get('payment_token');

  const getPaymentResponse = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `https://sandbox.paymee.tn/api/v2/payments/${paymentToken}/check`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token 88d14b79b11188f130a06bea8c2003e9bdfd200a',
      },
    };

    await api.request(config).then(async (responseAPI) => {
      setIsStatusSuccess(responseAPI.data.data.payment_status);
      setPaymentData(responseAPI.data.data);

      const planToken = { token: responseAPI.data.data.token };

      const config2 = {
        method: 'patch',
        url: `${apiURL}/plans/`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: planToken,
      };

      await api.request(config2).then(async (response) => {
        const invoiceConfig = {
          method: 'patch',
          url: `${apiURL}/payment/generateInvoicePdf/${response.data.payment_id}`,
          headers: {
            'Content-Type': 'application/json',
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
        };

        await api.request(invoiceConfig);
      });
    });
  };

  useEffect(() => {
    getPaymentResponse().then(() => {
      setLoading(false);
    });
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="w-full flex justify-center items-center desktop:mt-[10%] mt-[5%]">
        <div className="w-[80%] flex flex-col justify-center items-center space-y-12">
          <div className=" bg-white p-4 w-[30%]  space-y-10">
            <p className="text-[#181143] text-center text-xl">
              Thank you for your purchase
            </p>
            <div className="flex justify-center">
              <div
                className={`relative w-[8rem] h-[8rem] bg-[#32C770] rounded-full
                ${loaded ? 'animate-grow' : 'opacity-0'}`}
                style={{
                  backgroundColor:
                    isStatusSuccess === true ? '#32C770' : '#EC1577',
                }}
              >
                {isStatusSuccess === true ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={`w-[7rem] h-[7rem] left-2 top-2 text-white absolute pointer-events-none transition-transform ${
                      loaded ? 'animate-grow' : 'hidden'
                    }`}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className={`w-[7rem] h-[7rem] left-2 top-2 text-white absolute pointer-events-none transition-transform ${
                      loaded ? 'animate-grow' : 'hidden'
                    }`}
                  />
                )}
              </div>
            </div>
            <div />
          </div>
          <div className="text-3xl font-semibold text-[#181143]">
            {paymentData.note}
            {paymentData.payment_status === true ? 'confirmed' : 'unconfirmed'}
          </div>

          <div className="w-full flex justify-center">
            <button
              type="button"
              className="bg-[#EC1577] p-2 w-full text-white rounded-3xl"
              onClick={() => navigate('/')}
            >
              Home page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanFinal;
