import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const AttributeBox = ({
  attribute,
  onChange,
  disallowed = '',
  className = '',
  selected,
}) => (
  <div className={className}>
    <label htmlFor="" className="font-medium">
      {attribute.name}
    </label>
    <div className="bg-transparent rounded-xl border-[#534B8580] border-solid border-2 border-black overflow-y-scroll p-4 h-52	">
      {attribute.attributes.map((item, index) => {
        const value = `${attribute.id}-${attribute.foreign_id}-${item.id}-${item.foreign_id}`;
        return (
          <span key={index} className="inline-block text-center w-1/2 mb-4">
            <div className="relative flex items-center mr-3">
              <input
                type="checkbox"
                name=""
                className="permission-checkbox rounded-xl outline-0 border-1 border-[#181143]	appearance-none checked:bg-[#181143]"
                style={{ backgroundColor: item.color, width: 33, height: 31 }}
                value={value}
                onChange={onChange}
                checked={selected.includes(value)}
              />
              <FontAwesomeIcon
                icon={faCheck}
                className="h-5 w-8 text-[#EC1577] absolute pointer-events-none  text-opacity-0 checked-1"
              />
              <label htmlFor="" className="w-full">
                {item.name}
              </label>
            </div>
          </span>
        );
      })}
    </div>
  </div>
);
AttributeBox.propTypes = {
  attribute: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disallowed: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  selected: PropTypes.object.isRequired,
};

export default AttributeBox;
