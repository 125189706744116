import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import AttributeGroupContext from '../groupAttributeContext/GroupAttributeContext';
import Select from '../../components/Select';
import AssociatedStores from '../../components/AssociatedStores';
import colorPicker from '../../data/color-picker.jpg';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const AttributeValue = () => {
  const token = useSelector(selectCurrentToken);
  const { value, setValue } = useContext(AttributeGroupContext) || {
    value: null,
    setValue: () => {},
  };
  const [stores, setStores] = useState([]);
  const [groupAttributes, setGroupAttributes] = useState([]);
  const [isColor, setIsColor] = useState(false);

  const [formData, setFormData] = useState({
    group: null,
    name: null,
    shops: [],
    color: null,
    Texture: null,
    validation: {
      error: [true, true, true, false, false],
      errorMsg: ['required', 'required', 'required', null, null],
    },
  });
  const [formDataError, setformDataError] = useState([
    true,
    true,
    true,
    false,
    false,
  ]);

  const getStores = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-group-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };

    await api
      .request(config)
      .then((response) => {
        setStores(response.data);
      })
      .catch((err) => {});
  };

  const getGroupAttributes = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/attributes/get-all-attributes-group`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        const data = response.data.map((item) => ({
          id: item.id,
          name: item.AttributeGroupLangs[0].name,
          type: item.type,
        }));
        setGroupAttributes(data);
      })
      .catch((err) => {});
  };

  const handelFormDataChange = (v, key, index) => {
    const aux = { ...formData };
    if (key === 'group') {
      if (v.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        const arr = v.split('-');
        // eslint-disable-next-line prefer-destructuring, no-param-reassign
        v = arr[0];
        if (arr[1] === 'color') setIsColor(true);
        else setIsColor(false);
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      aux[key] = v;
    }
    if (key === 'name') {
      if (v.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      aux[key] = v;
    }

    if (key === 'shops') {
      if (v.checked === false && aux.shops.length < 2) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      const itemIndex = aux[key].indexOf(v.v);
      if (v.checked === true && itemIndex === -1) {
        aux[key].push(v.v);
      } else if (itemIndex > -1) {
        aux[key].splice(itemIndex, 1);
      }
    }
    if (key === 'color') {
      if (v.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      aux[key] = v;
    }
    setFormData(aux);
  };

  const handelSubmit = async (e, redirect = false) => {
    e.preventDefault();
    const decoded = jwtDecode(token);
    const ERROR = [...formData.validation.error];
    const ERROR_MSG = [...formData.validation.errorMsg];
    setformDataError(ERROR_MSG);
    if (ERROR.includes(true)) return;
    const idToast = toast.loading('Please wait...');
    const data = JSON.stringify({
      name: formData.name,
      group: formData.group,
      shops: formData.shops,
      color: formData.color,
      type: formData.type,
    });
    const config = {
      method: 'post',
      url: `${apiURL}/attributes/add-attribute`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'Attribute group created succesfully !',
          type: 'success',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        });
        if (redirect) {
          setTimeout(() => {
            setValue({
              title: 'Attributes',
              component: 'GroupAttribute',
              id: null,
            });
          }, 1500);
        } else {
          const aux = { ...formData };
          aux.group = null;
          aux.name = null;
          aux.shops = [];
          aux.color = null;
          aux.Texture = null;
          aux.validation = {
            error: [true, true, true, false, false],
            errorMsg: ['required', 'required', 'required', null, null],
          };
          setFormData(aux);
          document.getElementById('attributeForm').reset();
        }
      })
      .catch(() =>
        toast.update(idToast, {
          render: 'Failed to create group attribute !',
          type: 'error',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        })
      );
  };

  useEffect(() => {
    getStores();
    getGroupAttributes();
  }, []);

  return (
    <form id="attributeForm">
      <div className="flex flex-col gap-8 mt-8">
        <div className="w-1/3">
          <label htmlFor="AttributeGroup" className="groupAttributeLabel">
            Attribute Group
          </label>
          <Select
            icon="icon"
            items={groupAttributes}
            name="type"
            onChange={(e) => handelFormDataChange(e.target.value, 'group', 0)}
          />
          <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
        </div>
        <div className="flex flex-col gap-8 ml-32">
          <div>
            <label htmlFor="Value" className="groupAttributeLabel">
              Value
            </label>
            <input
              id="Value"
              name="value"
              placeholder="Value"
              type="text"
              className="groupAttributeInput w-1/3"
              onChange={(e) => handelFormDataChange(e.target.value, 'name', 1)}
            />
            <div style={{ color: '#FF9494' }}>{formDataError[1]}</div>
          </div>
          <div>
            <label
              htmlFor="associatedStores"
              className="groupAttributeLabel my-4"
            >
              Associated Stores
            </label>
            <AssociatedStores
              name="shops"
              stores={stores}
              onChange={(e) => handelFormDataChange(e.target, 'shops', 2)}
            />
            <div style={{ color: '#FF9494' }}>{formDataError[2]}</div>
          </div>
          <div className={isColor ? 'block' : 'hidden'}>
            <label htmlFor="color" className="groupAttributeLabel my-4">
              color
            </label>
            <div className="input-group inline flex items-center">
              <input
                type="color"
                name="color"
                className="colorInput"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'color', 3)
                }
              />
              <span className="cursor-pointer">
                <img
                  src={colorPicker}
                  width="40"
                  height="40"
                  className="inline rounded-r-2xl"
                  alt=""
                />
              </span>
            </div>
            <div style={{ color: '#FF9494' }}>{formDataError[3]}</div>
          </div>
          <div className={isColor ? 'block' : 'hidden'}>
            <label htmlFor="texture" className="groupAttributeLabel my-4 block">
              Texture
            </label>
            <div className="fileWrapper">
              <input
                type="file"
                name="texture"
                className="textureInput"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'texture', 4)
                }
              />
            </div>
            <div style={{ color: '#FF9494' }}>{formDataError[4]}</div>
          </div>
        </div>
        <div
          className="help-button mt-3"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Link
            style={{
              background: '#EC1577',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '25px',
              padding: '7.5px 40px 0px',
              fontWeight: 'normal',
              fontSize: '1,04vw',
              height: '37px',
              marginRight: '16px',
            }}
            onClick={(e) => handelSubmit(e)}
          >
            Save and add a new value
          </Link>
          <Link
            style={{
              background: '#EC1577',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '25px',
              padding: '7.5px 40px 0px',
              fontWeight: 'normal',
              fontSize: '1,04vw',
              height: '37px',
            }}
            onClick={(e) => handelSubmit(e, true)}
          >
            Save
          </Link>
        </div>
      </div>
    </form>
  );
};

export default AttributeValue;
