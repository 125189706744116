import React from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import PropTypes from 'prop-types';

const DropDown = ({
  currentMode,
  className,
  id,
  text,
  value,
  data,
  onChange,
  defaultValue,
}) => (
  <div className={className}>
    <DropDownListComponent
      id={id}
      fields={{ text, value }}
      style={{ border: 'none', color: currentMode === 'Dark' && 'white' }}
      value={defaultValue}
      dataSource={data}
      popupHeight="220px"
      popupWidth="120px"
      onChange={(e) => onChange(e.value)}
    />
  </div>
);

DropDown.propTypes = {
  currentMode: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
};
export default DropDown;
