import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import CarrierLocations from './CarrierLocations';
import CarrierDimensions from './CarrierDimensions';
import CarrierSettings from './CarrierSettings';
import CarrierSummary from './CarrierSummary';
import upload from '../../data/upload.png';
import Loader from '../../components/Loader';
import ShopNotImported from '../../components/ShopNotImported';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

function CreateCarrier() {
  const token = useSelector(selectCurrentToken);
  const [currentTab, setCurrentTab] = useState('1');
  const [billingByWeight, setBillingByWeight] = useState(1);
  const [billingByPrice, setBillingByPrice] = useState(0);
  const [validatedTabs, setValidatedTabs] = useState([]);
  const [allZoneNames, setAllZoneNames] = useState();
  const [shops, setShops] = useState([]);
  const [zoneNames, setZoneNames] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState('No tax applied');
  const [isAllZones, setIsAllZones] = useState(0);
  const [checkboxes, setCheckboxes] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllPrice, setSelectAllPrice] = useState(0);
  const [selectedShop, setSelectedShop] = useState('');
  const [shopType, setShopType] = useState('');
  const [isShopImported, setIsShopImported] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedShopName, setSelectedShopName] = useState('');
  const [errors, setErrors] = useState(0);
  const [formData, setFormData] = useState({
    shops: [],
    name: '',
    grade: '',
    url: '',
    delay: '',
    shipping_handling: 0,
    free_shipping: 0,
    tax_rules_group_id: 0,
    range_behavior: 1,
    delimiter1: '',
    delimiter2: '',
    zones: [],
    max_width: '',
    max_height: '',
    max_depth: '',
    max_weight: '',
    active: 1,
    shipping_external: 0,
    shipping_method: 0, // need review
    position: 0,
    deleted: 0,
  });

  const getShops = async () =>
    new Promise((resolve, reject) => {
      const decoded = jwtDecode(token);
      const config = {
        method: 'get',
        url: `${apiURL}/shop/get-all-shops`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  useEffect(() => {
    getShops().then(async (shopsResponse) => {
      setShops(shopsResponse);
      if (shopType === '') {
        setShopType(shopsResponse[0].type);
        setSelectedShop(shopsResponse[0].id);
        setSelectedShopName(shopsResponse[0].name);
        setIsShopImported(shopsResponse[0].is_imported);
        setLoading(false);
      }
    });
  }, [selectedShop]);

  const handleShop = (e) => {
    setSelectedShop(e.target.value);
    e.target.selectedOptions[0].getAttribute('isImported') === 'true'
      ? setIsShopImported(true)
      : setIsShopImported(false);
    setSelectedShopName(e.target.selectedOptions[0].getAttribute('shopname'));
    setShopType(e.target.selectedOptions[0].getAttribute('type'));
  };

  const handleSubmit = (event) => {
    const decoded = jwtDecode(token);
    const carrierData = JSON.stringify({
      shops: [selectedShop],
      name: formData.name,
      grade: formData.grade,
      url: formData.url,
      delay: formData.delay,
      shipping_handling: formData.shipping_handling,
      free_shipping: formData.free_shipping,
      tax_rules_group_id:
        formData.tax_rules_group_id === '' ? 0 : formData.tax_rules_group_id,
      range_behavior: formData.range_behavior,
      delimiter1: formData.delimiter1,
      delimiter2: formData.delimiter2,
      is_price_range: billingByPrice,
      is_weight_range: billingByWeight,
      zones: formData.zones,
      max_width: formData.max_width,
      max_height: formData.max_height,
      max_depth: formData.max_depth,
      max_weight: formData.max_weight,
      active: formData.active,
      position: 1,
      deleted: 0,
      shipping_external: 1,
      shipping_method: 1, // need review
    });

    const idToast = toast.loading('Please wait...');
    const config = {
      method: 'post',
      url: `${apiURL}/carriers`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: carrierData,
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'New Carrier Is Created !',
          type: response.data === 'success',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
        setTimeout(() => {
          window.location.assign('/carriers');
        }, 1500);
      })
      .catch((error) => {});
  };

  const handleTabClick = (e) => {
    const tabId = e.target.id;

    setCurrentTab(tabId);
  };

  const tabs = [
    {
      id: 1,
      tabTitle: 'General settings',
      title: 'General settings',
      content: (
        <CarrierSettings
          setFormData={setFormData}
          formData={formData}
          setCurrentTab={setCurrentTab}
          setValidatedTabs={setValidatedTabs}
          validatedTabs={validatedTabs}
          currentTab={currentTab}
          setErrors={setErrors}
          errors={errors}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'Shipping locations and costs',
      title: 'Shipping locations and costs',
      content: (
        <CarrierLocations
          setFormData={setFormData}
          formData={formData}
          setCurrentTab={setCurrentTab}
          setBillingByPrice={setBillingByPrice}
          billingByPrice={billingByPrice}
          billingByWeight={billingByWeight}
          setBillingByWeight={setBillingByWeight}
          selectedShop={selectedShop}
          shopType={shopType}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectAllPrice={selectAllPrice}
          setSelectAllPrice={setSelectAllPrice}
          setZoneNames={setZoneNames}
          zoneNames={zoneNames}
          setSelectedTaxes={setSelectedTaxes}
          setIsAllZones={setIsAllZones}
          isAllZones={isAllZones}
          setValidatedTabs={setValidatedTabs}
          validatedTabs={validatedTabs}
          currentTab={currentTab}
          setAllZoneNames={setAllZoneNames}
          setErrors={setErrors}
          errors={errors}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'Size, weight and group access',
      title: 'General settings',
      content: (
        <CarrierDimensions
          setFormData={setFormData}
          formData={formData}
          setCurrentTab={setCurrentTab}
          setValidatedTabs={setValidatedTabs}
          validatedTabs={validatedTabs}
          currentTab={currentTab}
          selectedShop={selectedShop}
          setErrors={setErrors}
          errors={errors}
        />
      ),
    },
    {
      id: 4,
      tabTitle: 'Summary',
      title: 'Summary',
      content: (
        <CarrierSummary
          setFormData={setFormData}
          formData={formData}
          setCurrentTab={setCurrentTab}
          zoneNames={zoneNames}
          selectedTaxes={selectedTaxes}
          billingByPrice={billingByPrice}
          handleSubmit={handleSubmit}
          setValidatedTabs={setValidatedTabs}
          validatedTabs={validatedTabs}
          currentTab={currentTab}
          isAllZones={isAllZones}
          allZoneNames={allZoneNames}
          selectedShopName={selectedShopName}
          setErrors={setErrors}
          errors={errors}
        />
      ),
    },
  ];

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className="w-[95%] sticky top-[5.4rem] md:ml-[3%] ml-[3.5%] z-40">
        <div className="filter-by-shop mb-5 relative md:w-full w-[22rem]">
          <select className="shopSelect text-center" onChange={handleShop}>
            {shops.map((shop) => (
              <option
                type={shop.type}
                value={shop.id}
                className="text-[#EC1577] text-center"
                key={shop.id}
              >
                {shop.name}
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            icon={faShop}
            className="h-[1.5rem] w-[1.5rem] scale-100 md:left-[42%] md:top-[19%] top-[19%] left-[30%]  absolute  text-[#AAA6C3] text-opacity-70 checked-1 transition "
          />
        </div>
      </div>
      {isShopImported === true ? (
        <div className="md:mt-2 mt-24 md:ml-16 p-2 md:p-5 rounded-3xl flex flex-col">
          <Header category="Page" title="View" />

          {shopType === 'prestashop' && (
            <div className="py-2 px-4 bg-white rounded-xl mr-auto sft-shadow w-[90%] flex justify-between ">
              {tabs.map((tab, i) => {
                let buttonClassName = 'px-4 font-semibold flex';

                if (currentTab === `${tab.id}`) {
                  buttonClassName += ' text-[#F99E1F]';
                } else if (validatedTabs.includes(tab.id)) {
                  buttonClassName += ' text-[#EC1577]';
                } else {
                  buttonClassName += ' text-[#81828B]';
                }

                const isDisabled =
                  currentTab === `${tab.id}` ||
                  !validatedTabs.includes(tab.id) ||
                  errors > 0;

                return (
                  <div key={i}>
                    <button
                      type="button"
                      id={tab.id}
                      className={buttonClassName}
                      disabled={isDisabled}
                      onClick={handleTabClick}
                    >
                      <div className="rounded-full w-6 h-6  flex justify-center pointer-events-none">
                        <p>{tab.id}</p>
                      </div>
                      {tab.tabTitle}
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {shopType === 'prestashop' && (
            <div className="form flex flex-row w-full mt-5 space-x-5">
              <div className="flex flex-col w-[70%]">
                {tabs.map((tab, i) => (
                  <div key={i}>
                    {currentTab === `${tab.id}` && <div> {tab.content} </div>}
                  </div>
                ))}
              </div>
              <div className="flex flex-col w-[25%]">
                <p>Logo</p>
                <div className="flex justify-center items-center">
                  <div className="cursor-pointer border-2 border-[#AAA6C3] rounded-2xl w-full">
                    <div className="m-4 py-4">
                      <span className="flex justify-center items-center text-[12px] mb-1 text-red-500" />
                      <div className="flex items-center justify-center w-full">
                        <label className="flex cursor-pointer flex-col w-full border-2 rounded-md border-dashed hover:bg-gray-100 hover:border-gray-300">
                          <div className="flex flex-col items-center justify-center pt-7">
                            <img src={upload} alt="" />
                            <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                              Upload your Image here
                            </p>
                          </div>
                          <input
                            type="file"
                            className="opacity-0"
                            multiple="multiple"
                            name="files[]"
                          />
                        </label>
                      </div>
                      <div className="flex justify-evenly gap-2 mt-2 flex-wrap" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {shopType === 'woocommerce' && (
            <div className="flex justify-center">
              <p className="text-[#EC1577] text-[1vw] w-full text-center">
                in WooCommerce , Carriers are bound to orders ,to add a carrier
                you must create in the create order form.
              </p>
            </div>
          )}
          {shopType === 'prestashop' && (
            <div className="container-save-submit flex justify-end md:mr-10">
              <button
                type="button"
                onClick={handleSubmit}
                className="pl-[0.7rem] addButton w-[10rem]"
              >
                Save
              </button>
            </div>
          )}
        </div>
      ) : (
        <ShopNotImported />
      )}
    </div>
  );
}

export default CreateCarrier;
