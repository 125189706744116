import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';
import { selectCurrentToken } from '../../features/auth/authSlice';
import CreateShopOffre from './CreateShopOffre';
import CreateShopInfos from './CreateShopInfos';
import CreateShopSummary from './CreateShopSummary';
import CreateShopCheckout from './CreateShopCheckout';

const apiURL = process.env.REACT_APP_API_URL;

export default function CreateShop() {
  const token = useSelector(selectCurrentToken);
  const [currentTab, setCurrentTab] = useState('1');
  const [shopCategories, setShopCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [shops, setShops] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [subscriptionTime, setSubscriptionTime] = useState('monthly');
  const [extraEmployees, setExtraEmployees] = useState(0);
  const [extraShops, setExtraShops] = useState(0);
  const decodedPlan = jwtDecode(token);

  const getShopCategories = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/group-shop/groups`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setShopCategories(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getShopCategories().then(() => {
      setLoading(false);
    });
  }, []);

  console.log(shopCategories);

  const [formData, setFormData] = useState({
    user_id: decodedPlan.UserInfo.id,
    plan_id: '',
    extra_employees: extraEmployees,
    extra_shops: extraShops,
    extra_support_time: 0,
    extra_products: 0,
    payment_method: 'Bank',
    total_price_httc: '',
    total_price_ttc: '',
    is_monthly: true,
    is_yearly: false,
  });

  const tabs = [
    {
      id: 1,
      tabTitle: 'Offres',
      title: 'Offres',
      content: (
        <CreateShopOffre
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          formData={formData}
          setFormData={setFormData}
          subscriptionTime={subscriptionTime}
          setSubscriptionTime={setSubscriptionTime}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'Infos',
      title: 'Infos',
      content: (
        <CreateShopInfos
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          formData={formData}
          setFormData={setFormData}
          shopCategories={shopCategories}
          shops={shops}
          setShops={setShops}
          employees={employees}
          setEmployees={setEmployees}
          subscriptionTime={subscriptionTime}
          setSubscriptionTime={setSubscriptionTime}
          extraEmployees={extraEmployees}
          setExtraEmployees={setExtraEmployees}
          extraShops={extraShops}
          setExtraShops={setExtraShops}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'Summary',
      title: 'Summary',
      content: (
        <CreateShopSummary
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          formData={formData}
          setFormData={setFormData}
          shopCategories={shopCategories}
          shops={shops}
          setShops={setShops}
          employees={employees}
          setEmployees={setEmployees}
          subscriptionTime={subscriptionTime}
          setSubscriptionTime={setSubscriptionTime}
          extraEmployees={extraEmployees}
          setExtraEmployees={setExtraEmployees}
          extraShops={extraShops}
          setExtraShops={setExtraShops}
        />
      ),
    },
    {
      id: 4,
      tabTitle: 'Summary',
      title: 'Summary',
      content: (
        <CreateShopCheckout
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          formData={formData}
          setFormData={setFormData}
          shopCategories={shopCategories}
          shops={shops}
          setShops={setShops}
          employees={employees}
          setEmployees={setEmployees}
          subscriptionTime={subscriptionTime}
          setSubscriptionTime={setSubscriptionTime}
          extraEmployees={extraEmployees}
          setExtraEmployees={setExtraEmployees}
          extraShops={extraShops}
          setExtraShops={setExtraShops}
        />
      ),
    },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {tabs.map(
        (tab, i) =>
          currentTab === `${tab.id}` && (
            <div key={i} className="w-[100%] h-full">
              {tab.content}
            </div>
          )
      )}
    </>
  );
}
