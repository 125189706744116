import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import UsersList from './features/users/UsersList';
import NotFoundPage from './pages/404/NotFoundPage';
import {
  Ecommerce,
  Editor,
  CreateShop,
  Shops,
  CreateUser,
  CreateRole,
  EditEmp,
  Employees,
  ProductsPage,
  CategoriesPage,
  CreateCustomer,
  ForgotPassword,
  SignUp,
  ResetPassword,
  PasswordResetSent,
  Customers,
  ErrorBoundary,
  EditCustomer,
  CreateProduct,
  CreateCategory,
  AttributeFeaturePage,
  TaxPage,
  EditCategory,
  Manufacturers,
  EditEmpManufacturer,
  CreateManufacturer,
  CreateOrder,
  Orders,
  ShopExport,
  OrderPreview,
  Carts,
  CartPreview,
  Addresses,
  CreateAddress,
  EditAddress,
  PlanFinal,
  Carriers,
  CreateCarrier,
  EditCarrier,
  EditCarrierSettings,
  EditCarrierLocations,
  Profile,
  PaymentsPage,
  CurrentPlan,
} from './pages';

import RenewPlanPaymentPage from './pages/payments/RenewPlanPaymentPage';
import UpgradePlanPaymentPage from './pages/payments/UpgradePlanPaymentPage';
import ToMonthlyPlanPaymentPage from './pages/payments/ToMonthlyPlanPayment';
import ToYearlyPlanPaymentPage from './pages/payments/ToYearlyPlanPayment';
import UserPlanFree from './pages/payments/UserPlanFree';

import './App.css';
import RequireAuth from './features/auth/RequireAuth';
import Login from './features/auth/Login';
import { useStateContext } from './contexts/ContextProvider';
import { ShopProvider } from './contexts/ShopContext';
import { PlanProvider } from './contexts/PlanContext';

import EditShop from './pages/shops/EditShop';

const App = () => {
  const { setCurrentColor, setCurrentMode } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');

    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      {/* error catch */}
      <Route element={<ErrorBoundary />}>
        {/* public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/password-reset-sent" element={<PasswordResetSent />} />

        {/* protected routes */}
        <Route element={<RequireAuth />}>
          <Route path="/test" element={<UsersList />} />
          <Route
            path="/"
            element={
              <PlanProvider>
                <ShopProvider defaultShop={false} isImported>
                  <Ecommerce />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route path="/renewPlanPayment" element={<RenewPlanPaymentPage />} />
          <Route
            path="/UpgradePlanPayment"
            element={<UpgradePlanPaymentPage />}
          />
          <Route
            path="/ToMonthlyPlanPayment"
            element={<ToMonthlyPlanPaymentPage />}
          />
          <Route
            path="/ToYearlyPlanPayment"
            element={<ToYearlyPlanPaymentPage />}
          />
          <Route
            path="/ecommerce"
            element={
              <PlanProvider>
                <ShopProvider defaultShop={false} isImported>
                  <Ecommerce />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route path="/paymentResult" element={<PlanFinal />} />
          {/* pages  */}
          <Route
            path="/shops"
            element={
              <PlanProvider>
                <Shops />
              </PlanProvider>
            }
          />
          <Route
            path="/shop-export"
            element={
              <PlanProvider>
                <ShopProvider defaultShop={false}>
                  <ShopExport />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route
            path="/edit-shop/:id"
            element={
              <PlanProvider>
                <ShopProvider>
                  <EditShop />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route path="/profile" element={<Profile />} />
          <Route path="/payments" element={<PaymentsPage />} />
          <Route path="/currentPlan" element={<CurrentPlan />} />
          <Route path="/create-shop" element={<CreateShop />} />

          <Route path="/payment/userPlanFree" element={<UserPlanFree />} />

          <Route
            path="/products"
            element={
              <PlanProvider>
                <ProductsPage />
              </PlanProvider>
            }
          />
          <Route
            path="/attributes-and-features"
            element={
              <PlanProvider>
                <AttributeFeaturePage />
              </PlanProvider>
            }
          />
          <Route
            path="/taxes"
            element={
              <PlanProvider>
                <TaxPage />
              </PlanProvider>
            }
          />
          <Route
            path="/categories"
            element={
              <PlanProvider>
                <ShopProvider defaultShop={false} isImported>
                  <CategoriesPage />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route
            path="/create-product"
            element={
              <PlanProvider>
                <CreateProduct />
              </PlanProvider>
            }
          />
          <Route
            path="/edit-product/:id"
            element={
              <PlanProvider>
                <CreateProduct />
              </PlanProvider>
            }
          />
          <Route
            path="/create-category"
            element={
              <PlanProvider>
                <ShopProvider>
                  <CreateCategory />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route
            path="/edit-category/:id"
            element={
              <PlanProvider>
                <ShopProvider>
                  <EditCategory />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route
            path="/orders"
            element={
              <PlanProvider>
                <ShopProvider>
                  <Orders />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route
            path="/employees"
            element={
              <PlanProvider>
                <Employees />
              </PlanProvider>
            }
          />
          <Route
            path="/create-user"
            element={
              <PlanProvider>
                <CreateUser />
              </PlanProvider>
            }
          />
          <Route
            path="/create-customer"
            element={
              <PlanProvider>
                <CreateCustomer />
              </PlanProvider>
            }
          />
          <Route
            path="/edit-user"
            element={
              <PlanProvider>
                <EditEmp />
              </PlanProvider>
            }
          />
          <Route
            path="/edit-customer"
            element={
              <PlanProvider>
                <EditCustomer />
              </PlanProvider>
            }
          />
          <Route
            path="/customers"
            element={
              <PlanProvider>
                <Customers />
              </PlanProvider>
            }
          />
          <Route
            path="/edit-customer"
            element={
              <PlanProvider>
                <EditCustomer />
              </PlanProvider>
            }
          />
          <Route
            path="/create-role"
            element={
              <PlanProvider>
                <CreateRole />
              </PlanProvider>
            }
          />
          <Route
            path="/manufacturers"
            element={
              <PlanProvider>
                <Manufacturers />
              </PlanProvider>
            }
          />
          <Route
            path="/create-manufacturer"
            element={
              <PlanProvider>
                <CreateManufacturer />
              </PlanProvider>
            }
          />
          <Route
            path="/edit-manufacturer"
            element={
              <PlanProvider>
                <EditEmpManufacturer />
              </PlanProvider>
            }
          />
          <Route
            path="/cart/:id"
            element={
              <PlanProvider>
                <CartPreview />
              </PlanProvider>
            }
          />
          <Route
            path="/carts"
            element={
              <PlanProvider>
                <Carts />
              </PlanProvider>
            }
          />
          <Route
            path="/create-order"
            element={
              <PlanProvider>
                <CreateOrder />
              </PlanProvider>
            }
          />
          <Route
            path="/order/:id/:shopId"
            element={
              <PlanProvider>
                <OrderPreview />
              </PlanProvider>
            }
          />
          <Route
            path="/addresses"
            element={
              <PlanProvider>
                <Addresses />
              </PlanProvider>
            }
          />
          <Route
            path="/create-address"
            element={
              <PlanProvider>
                <ShopProvider>
                  <CreateAddress />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route
            path="/edit-address/:id"
            element={
              <PlanProvider>
                <EditAddress />
              </PlanProvider>
            }
          />
          <Route
            path="/edit-address/:id"
            element={
              <PlanProvider>
                <EditAddress />
              </PlanProvider>
            }
          />
          <Route
            path="/carriers"
            element={
              <PlanProvider>
                <Carriers />
              </PlanProvider>
            }
          />
          <Route
            path="/create-carrier"
            element={
              <PlanProvider>
                <ShopProvider defaultShop={false}>
                  <CreateCarrier />
                </ShopProvider>
              </PlanProvider>
            }
          />
          <Route
            path="/edit-carrier/:id"
            element={
              <PlanProvider>
                <EditCarrier />
              </PlanProvider>
            }
          />
          <Route
            path="/editCarrierSettings"
            element={
              <PlanProvider>
                <EditCarrierSettings />
              </PlanProvider>
            }
          />
          <Route
            path="/editCarrierLocations"
            element={
              <PlanProvider>
                <EditCarrierLocations />
              </PlanProvider>
            }
          />
          <Route
            path="/editor"
            element={
              <PlanProvider>
                <Editor />
              </PlanProvider>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
