import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export default function ProductDelivery({ formData, setFormData }) {
  const handleFormData = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="mt-12 rounded-3xl flex flex-col sm:flex-row">
      <div className="Main-container flex flex-col md:space-y-7 space-y-7 sm:w-full w-[100%]">
        <div className="top-container flex flex-col w-[100%] md:w-[50%]">
          <h1 className="SubTitle">Package Dimensions</h1>
          <p className="text-[#AAA6C3] font-medium">
            Adjust your shipping costs by entering the product Dimensions.
          </p>
        </div>
        <div className="dimensions-container flex md:flex-row flex-col  md:space-x-5 md:w-full w-[100%]">
          <div className="input-container flex flex-col md:w-full">
            <label htmlFor="width" className="essentialLabel">
              Width
            </label>
            <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11">
              <input
                type="text"
                name="width"
                id="width"
                className="priceInput"
                placeholder="Width"
                value={formData.width}
                onChange={handleFormData}
              />
              <p className="w-[25%] text-center"> | cm</p>
            </div>
          </div>
          <div className="input-container flex flex-col md:w-full ">
            <label htmlFor="height" className="essentialLabel">
              Height
            </label>
            <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11">
              <input
                type="text"
                name="height"
                id="height"
                className="priceInput"
                value={formData.height}
                onChange={handleFormData}
                placeholder="Height"
              />
              <p className="w-[25%] text-center"> | cm</p>
            </div>
          </div>
          <div className="input-container flex flex-col md:w-full ">
            <label htmlFor="depth" className="essentialLabel">
              Depth
            </label>
            <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11">
              <input
                type="text"
                name="depth"
                id="depth"
                className="priceInput"
                value={formData.depth}
                onChange={handleFormData}
                placeholder="Depth"
              />
              <p className="w-[25%] text-center"> | cm</p>
            </div>
          </div>
          <div className="input-container flex flex-col md:w-full ">
            <label htmlFor="weight" className="essentialLabel">
              Weight
            </label>
            <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11">
              <input
                type="text"
                name="weight"
                id="weight"
                className="priceInput"
                value={formData.weight}
                onChange={handleFormData}
                placeholder="Weight"
              />
              <p className="w-[23%] text-center"> | kg</p>
            </div>
          </div>
        </div>
        <div className="DeliveryTime-container flex flex-col md:flex-row justify-between">
          <div className="DeliveryTime-left w-[20rem] md:w-6/12">
            <h1 className="SubTitle">Delivery Time</h1>
            <div className="input-container relative flex items-center space-x-3 w-[20rem] md:w-[30rem] mt-5">
              <input
                type="radio"
                id="none"
                name="deliveryTime"
                className="deliveryCheckbox"
              />
              <label htmlFor="none" className="essentialLabel">
                None
              </label>
              <FontAwesomeIcon
                icon={faCheck}
                className="h-5 w-16  text-[#EC1577] absolute right-[85.5%] md:right-[90.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
              />
            </div>
            <div className="input-container relative flex items-center space-x-3 sm:w-[34rem]">
              <input
                type="radio"
                id="default"
                name="deliveryTime"
                className="deliveryCheckbox"
              />
              <label htmlFor="default" className="essentialLabel">
                Default delivery time
              </label>
              <FontAwesomeIcon
                icon={faCheck}
                className="h-5 w-16  text-[#EC1577] absolute right-[85.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
              />
            </div>
            <div className="input-container relative flex items-center space-x-3 sm:w-[34rem]">
              <input
                type="radio"
                id="specific"
                name="deliveryTime"
                className="deliveryCheckbox"
              />
              <label htmlFor="specific" className="essentialLabel">
                Specific delivery time for this product
              </label>
              <FontAwesomeIcon
                icon={faCheck}
                className="h-5 w-16  text-[#EC1577] absolute right-[85.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
              />
            </div>
          </div>
          <div className="DeliveryTime-right md:w-6/12 space-y-2 md:mt-0 mt-10">
            <div className="input-container space-y-2 flex flex-col items-stretch">
              <label htmlFor="delivery-stock" className="essentialLabel">
                Delivery Time for products in stock :
              </label>
              <input
                type="text"
                id="delivery-stock"
                name="deliveryTimeStock"
                className="essentialInput w-full self-auto"
                value={formData.deliveryTimeStock}
                onChange={handleFormData}
                placeholder="Delivery between 3 and 4 days"
              />
              <p className="self-end md:mr-[9%] text-[#AAA6C3] font-extralight text-sm">
                leave blank to deactivate
              </p>
            </div>
            <div className="input-container space-y-2 flex flex-col items-stretch">
              <label htmlFor="delivery-sold" className="essentialLabel">
                Delivery Time for Sold out products with authorized order :
              </label>
              <input
                type="text"
                id="delivery-sold"
                name="deliveryTimeSoldOut"
                className="essentialInput w-full p-3"
                value={formData.deliveryTimeSoldOut}
                onChange={handleFormData}
                placeholder="Delivery between 5 and 7 days"
              />
              <p className="self-end md:mr-[9%] text-[#AAA6C3] font-thin text-sm">
                leave blank to deactivate
              </p>
            </div>
          </div>
        </div>
        <div className="bottom-container flex md:flex-row flex-col justify-between md:space-y-0 space-y-5">
          <div className="shipping-costs md:space-y-5 space-y-3 space-x-2 md:w-6/12">
            <h1 className="SubTitle">Shipping costs</h1>
            <p className="text-[#AAA6C3] font-medium">
              Are there any additional shipping charges for this product?
            </p>
            <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-[80%] flex flex-row items-center justify-between pr-2">
              <input
                type="text"
                name="shippingCosts"
                value={formData.shippingCosts}
                onChange={handleFormData}
                className="priceInput"
                placeholder="Width"
              />
              <p className="w-[23%] text-center"> | TND</p>
            </div>
          </div>
          <div className="available carriers md:space-y-2 space-y-3 space-x-2  md:w-6/12">
            <h1 className="SubTitle">Available carriers</h1>
            <div className="input-container relative flex items-center space-x-3 sm:w-[34rem]">
              <input
                type="radio"
                id="amazonia"
                name="carriers"
                className="deliveryCheckbox"
              />
              <label htmlFor="amazonia" className="essentialLabel">
                10 - Amazonia (in-store pickup)
              </label>
              <FontAwesomeIcon
                icon={faCheck}
                className="h-5 w-16  text-[#EC1577] absolute right-[84.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
              />
            </div>
            <div className="input-container relative flex items-center space-x-3 sm:w-[34rem]">
              <input
                type="radio"
                id="home-delivery"
                name="carriers"
                className="deliveryCheckbox"
              />
              <label htmlFor="home-delivery" className="essentialLabel">
                9 - Home delivery (24h - 72h)
              </label>
              <FontAwesomeIcon
                icon={faCheck}
                className="h-5 w-16  text-[#EC1577] absolute right-[84.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
              />
            </div>
          </div>
        </div>
        <div className="alert-container h-[3rem] w-full bg-[#EC1577] rounded-[1.2rem] relative flex items-center">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="h-7 w-18  text-[#181143] absolute right-[88.5%]  md:right-[95.5%] md:bottom-[28%] pointer-events-none  checked transition scale-100"
          />
          <p className="relative p-2 md:ml-[3rem] ml-[2rem] md:text-[1rem] text-[0.7rem]">
            if no carrier is selected then all carriers will be considerate
            available to customers!
          </p>
        </div>
      </div>
    </div>
  );
}

ProductDelivery.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};
