/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { FiShoppingCart } from 'react-icons/fi';
import { BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../utils/apiService';
import logo from '../data/logonavbar.png';
import { Cart, Chat, Notification, UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { selectCurrentToken } from '../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useStateContext();

  const [loading, setLoading] = useState(true);
  const [userPlan, setUserPlan] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date().getTime();
      const expiryTime = new Date(userPlan.expiration_date).getTime();
      const timeDifference = expiryTime - now;

      if (timeDifference <= 0) {
        // The expiration date has passed
        setTimeRemaining({
          months: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        return;
      }
      const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setTimeRemaining({ months, days, hours, minutes, seconds });
    };

    // Update the time remaining every second
    const timerInterval = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [userPlan.expiration_date]);

  const getCurrenUserPlan = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/plans/currentPlan`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };

    await api.request(config).then((response) => {
      setUserPlan(response.data);
    });
  };

  useEffect(() => {
    getCurrenUserPlan().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const user = decoded.UserInfo;

  const totalSeconds =
    timeRemaining.months * 30 * 24 * 60 * 60 +
    timeRemaining.days * 24 * 60 * 60 +
    timeRemaining.hours * 60 * 60 +
    timeRemaining.minutes * 60 +
    timeRemaining.seconds;
  const percentageWidth = (totalSeconds / (30 * 24 * 60 * 60)) * 100;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative  items-center">
        <Link
          to="/"
          className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
        >
          <img src={logo} width="100" height="auto" alt="shifti" />
        </Link>
        {/* {userPlan.length !== 0 && (
          <div className="progressBar w-[70%]  flex justify-start items-center ">
            <div className="w-[90%] relative flex justify-center items-center ">
              <p className="text-xs w-32 font-semibold">
                Subscription Time Remaining :{' '}
              </p>

              <div className="w-[50%] h-6 pb-[3px] pt-[2px] pr-[2px] pl-[2px] rounded-3xl border-2 bg-white">
                <p
                  className="text-xs  text-white text-center bg-[#181143] rounded-3xl "
                  style={{ width: `${percentageWidth}%` }}
                >
                  {userPlan.is_yearly ? (
                    <>
                      {timeRemaining.months}mo {timeRemaining.days}d
                      {timeRemaining.hours}h {timeRemaining.minutes}m
                    </>
                  ) : (
                    <>
                      {' '}
                      {timeRemaining.days}d {timeRemaining.hours} h{' '}
                      {timeRemaining.minutes} m
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        )} */}

        <div className="flex">
          <NavButton
            title="Cart"
            customFunc={() => handleClick('cart')}
            color={currentColor}
            icon={<FiShoppingCart />}
          />
          <NavButton
            title="Chat"
            dotColor="#03C9D7"
            customFunc={() => handleClick('chat')}
            color={currentColor}
            icon={<BsChatLeft />}
          />
          <NavButton
            title="Notification"
            dotColor="rgb(254, 201, 15)"
            customFunc={() => handleClick('notification')}
            color={currentColor}
            icon={<RiNotification3Line />}
          />
          <TooltipComponent content="Profile" position="BottomCenter">
            <div
              className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
              onClick={() => handleClick('userProfile')}
            >
              <div
                className="rounded-full w-8 h-8 flex items-center justify-center"
                style={{ backgroundColor: user.avatarColor, color: 'white' }}
                alt="user-profile"
              >
                <span className="text">{user.first_name.charAt(0)}</span>
              </div>
              <p>
                <span className="text-gray-400 text-14">Hi,</span>{' '}
                <span className="text-gray-400 font-bold ml-1 text-14">
                  {user.username}
                </span>
              </p>
              <MdKeyboardArrowDown className="text-gray-400 text-14" />
            </div>
          </TooltipComponent>

          {isClicked.cart && <Cart />}
          {isClicked.chat && <Chat />}
          {isClicked.notification && <Notification />}
          {isClicked.userProfile && <UserProfile user={user} />}
        </div>
      </div>
    </div>
  );
};
NavButton.propTypes = {
  title: PropTypes.string.isRequired,
  customFunc: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  dotColor: PropTypes.string,
};
export default Navbar;
