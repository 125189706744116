import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import bg from '../../img/404.png';
import logo from '../../data/logonavbar.png';

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div className="bg-main-bg">
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
          height: '100vh',
          width: '60vw',
          marginLeft: 'auto',
        }}
      >
        <Link to="/">
          <img
            src={logo}
            className="fixed top-4 left-28 cursor-pointer"
            alt="shifti"
            width="100"
            height="auto"
          />
        </Link>
        <div
          className="text-center fixed top-2/4 -translate-y-1/2 m-auto"
          style={{ left: '15vw' }}
        >
          <h1 className="font-bold	text-shifti-blue text-9xl">404</h1>
          <h2 className="text-shifti-blue text-4xl">PAGE NOT FOUND</h2>
          <button
            type="button"
            onClick={() => navigate('/')}
            className="bg-shifti-pink-bg py-1 px-12 text-white font-semibold rounded-full shadow-xl mt-8"
          >
            Back Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
