import React from 'react';
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  LineSeries,
  DateTime,
  Legend,
  Tooltip,
} from '@syncfusion/ej2-react-charts';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';

const LineChart = ({
  className,
  data,
  id,
  PrimaryXAxis,
  PrimaryYAxis,
  background,
  legendSettings,
}) => (
  <div className={`relative ${className}`}>
    {!data || !data.length ? (
      <Spinner className="absolute left-0 right-0 top-0 bottom-0 m-auto w-20 h-20" />
    ) : null}
    <ChartComponent
      id={id}
      height="420px"
      primaryXAxis={PrimaryXAxis}
      primaryYAxis={PrimaryYAxis}
      chartArea={{ border: { width: 0 } }}
      tooltip={{ enable: true }}
      background={background}
      legendSettings={legendSettings}
    >
      <Inject services={[LineSeries, DateTime, Legend, Tooltip]} />
      <SeriesCollectionDirective>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {data.map((item, index) => (
          <SeriesDirective key={index} {...item} />
        ))}
      </SeriesCollectionDirective>
    </ChartComponent>
  </div>
);
LineChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  PrimaryXAxis: PropTypes.object.isRequired,
  PrimaryYAxis: PropTypes.object.isRequired,
  background: PropTypes.string.isRequired,
  legendSettings: PropTypes.object.isRequired,
};
export default LineChart;
