import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export default function ProductQuantity({
  formData,
  setFormData,
  handelFormDataChange,
  selectedShop,
  selectedShops,
}) {
  const handleFormData = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const showQuantity = () => {
    if (selectedShop) {
      return formData.sites[selectedShop].quantity;
    }
    const value = formData.sites[selectedShops[0].id].quantity;
    let diff = false;
    selectedShops.map((item) => {
      if (value !== formData.sites[item.id].quantity) diff = true;
      return null;
    });
    if (diff) {
      return '';
    }
    return value;
  };

  return (
    <div className="mt-12 rounded-3xl flex flex-col sm:flex-row">
      <div className="top-container w-full space-y-3">
        <div className="quantity-container flex flex-col md:flex-row  sm:space-x-5">
          <div className="input-container flex flex-col md:w-5/12">
            <label htmlFor="quantity" className="essentialLabel">
              Quantity
            </label>
            <input
              type="text"
              id="quantity"
              placeholder="0"
              className="rounded-xl p-5 h-11"
              name="quantity"
              value={showQuantity()}
              onChange={(e) => handelFormDataChange(e, 'quantity')}
            />
            {selectedShop ? null : 'Warning this input will apply to all shops'}
          </div>
          <div className="input-container flex flex-col md:w-5/12">
            <label htmlFor="minQuantity" className="essentialLabel">
              Minimum quantity for sale
            </label>
            <input
              type="text"
              id="minQuantity"
              placeholder="1"
              className="rounded-xl p-5 h-11"
              name="minQuantity"
              value={formData.minQuantity}
              onChange={handleFormData}
            />
          </div>
        </div>
        <div className="stock-container flex flex-col">
          <h1 className="SubTitle">Stock</h1>
          <div className="input-container-box flex flex-col md:flex-row md:space-x-5">
            <div className="input-container flex flex-col md:w-5/12">
              <label htmlFor="stockLocation" className="essentialLabel">
                Stock location
              </label>
              <input
                type="text"
                id="stockLocation"
                placeholder="Stock location"
                className="rounded-xl p-5 h-11"
                name="stockLocation"
                value={formData.stockLocation}
                onChange={handleFormData}
              />
            </div>
            <div className="input-container flex flex-col md:w-5/12">
              <label htmlFor="lowStockLevel" className="essentialLabel">
                Low stock level
              </label>
              <input
                type="text"
                id="lowStockLevel"
                placeholder="1"
                className="rounded-xl p-5 h-11"
                name="lowStockLevel"
                value={formData.lowStockLevel}
                onChange={handleFormData}
              />
            </div>
          </div>
          <div className="input-container flex flex-col md:w-5/12">
            <div className="input-container relative flex items-center space-x-3 md:w-[40rem] mt-5">
              <input
                type="checkbox"
                id="sendMail"
                name="sendMail"
                className="deliveryCheckbox"
              />
              <label
                htmlFor="Sendmail"
                className="essentialLabel md:w-full w-[17rem]"
              >
                Send me an email when the quantity below or equal to this level
              </label>
              <FontAwesomeIcon
                icon={faCheck}
                className="h-5 w-16  text-[#EC1577] absolute right-[85.1%] md:right-[93%] md:bottom-[27%] pointer-events-none text-opacity-0 checked transition scale-75"
              />
            </div>
          </div>
        </div>
        <div className="availability-container flex md:flex-row flex-col md:space-x-10 space-y-3">
          <div className="left-container md:w-5/12 flex flex-col space-y-5">
            <h1 className="SubTitle">Availability preferences</h1>
            <h4 className="text-[1rem] font-bold text-[#181143]">
              Behavior in case of stock shortage
            </h4>
            <div className="input-container-box">
              <div className="input-container">
                <div className="input-container relative flex items-center space-x-3 md:w-[34rem] w-[19rem]">
                  <input
                    type="radio"
                    id="refuseOrders"
                    name="preferences"
                    className="deliveryCheckbox"
                  />
                  <label htmlFor="refuseOrders" className="essentialLabel">
                    Refuse orders
                  </label>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="h-5 w-16  text-[#EC1577] absolute right-[84.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
                  />
                </div>
              </div>
              <div className="input-container">
                <div className="input-container relative flex items-center space-x-3 md:w-[34rem] w-[19rem]">
                  <input
                    type="radio"
                    id="acceptingOrders"
                    name="preferences"
                    className="deliveryCheckbox"
                  />
                  <label htmlFor="acceptingOrders" className="essentialLabel">
                    Accepting orders
                  </label>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="h-5 w-16  text-[#EC1577] absolute right-[84.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
                  />
                </div>
              </div>
              <div className="input-container">
                <div className="input-container relative flex items-center space-x-3 md:w-[34rem] w-[19rem]">
                  <input
                    type="radio"
                    id="refuseCommands"
                    name="preferences"
                    className="deliveryCheckbox"
                  />
                  <label
                    htmlFor="refuseCommands"
                    className="essentialLabel w-[15rem] md:w-full"
                  >
                    Use default behavior (refuse commands)
                  </label>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="h-5 w-16  text-[#EC1577] absolute right-[84.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="right-container-box md:w-5/12 flex flex-col md:flex-row">
            <div className="input-container-box w-full md:space-y-5">
              <div className="input-container">
                <label htmlFor="ifInStock" className="essentialLabel">
                  Label if in stock
                </label>
                <input
                  type="text"
                  id="ifInStock"
                  className="essentialInput w-full p-3"
                  name="labelInStock"
                  value={formData.labelInStock}
                  onChange={handleFormData}
                />
              </div>
              <div className="input-container">
                <label htmlFor="ifOutStock" className="essentialLabel">
                  If out of stock (and pre-order authorized)
                </label>
                <input
                  type="text"
                  id="ifOutStock"
                  className="essentialInput w-full p-3"
                  name="labelOutOfStock"
                  value={formData.labelOutOfStock}
                  onChange={handleFormData}
                />
              </div>
              <div className="input-container">
                <label htmlFor="dataAvailability" className="essentialLabel">
                  Date of availability
                </label>
                <input
                  type="date"
                  id="dataAvailability"
                  className="essentialInput w-full p-3"
                  name="dataAvailability"
                  value={formData.dataAvailability}
                  onChange={handleFormData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductQuantity.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  handelFormDataChange: PropTypes.func.isRequired,
  selectedShop: PropTypes.number.isRequired,
  selectedShops: PropTypes.object.isRequired,
};
