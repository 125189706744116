import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';

async function getCountries() {
  try {
    const config = {
      method: 'get',
      url: `/countries`,
    };
    const response = await api.request(config);
    return response.data;
  } catch (error) {
    console.error('Error fetching countries:', error);
    throw error;
  }
}

export default function UserInfo({
  formData,
  setFormData,
  setCurrentTab,
  selectedCountry,
  setSelectedCountry,
}) {
  const [countries, setCountries] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [pwdShown, setPwdShown] = useState(false);
  const [pwdCShown, setPwdCShown] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const password = watch('password');

  const menuRef = useRef(null);

  const handleOpenCountries = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesData = await getCountries();
        setCountries(countriesData);
        setSelectedCountry(countriesData[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries(); // Call the fetchCountries function
  }, []);

  useEffect(() => {
    const hundler = document.addEventListener('mousedown', (event) => {
      if (menuRef.current !== null) {
        if (!menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      }

      document.addEventListener('mousedown', hundler);
      return () => document.removeEventListener('mousedown', hundler);
    });
  }, []);

  const hundleSubmitUserInfo = (data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      first_name: data.firstname,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      last_name: data.lastname,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      email: data.email,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      password: data.password,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      confirmed_password: data.passwordC,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      birthdate: data.birthDate,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: data.phone,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      country: selectedCountry,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      country:
        selectedCountry.name === '' ? 'Afghanistan' : selectedCountry.name,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneCode:
        selectedCountry.callingCode === ''
          ? '+93'
          : selectedCountry.callingCode,
    }));

    setCurrentTab('2');
  };

  if (loading === true || !countries.length) {
    return <Loader />;
  }
  return (
    <div className="flex flex-col justify-center items-center pr-32 pt-10 pb-10">
      <form
        onSubmit={handleSubmit(hundleSubmitUserInfo)}
        className="bg-[#E1DFEE]  flex flex-col justify-center items-center w-full pt-5 pb-5  space-y-6 drop-shadow-xl shadow-2xl rounded-2xl h-full"
      >
        <div className="title flex flex-col justify-center items-center space-y-2">
          <h1 className="text-[#181144] text-3xl font-bold">Sign Up</h1>
          <h2 className="text-[#5E6366] text-2xl font-semibold">
            Enter your personal information
          </h2>
        </div>
        <div className="inputs-box w-full flex flex-col space-y-6 justify-center items-center p-4">
          <div className="flex flex-col items-center  justify-center relative w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start  justify-end w-2/3"
              style={{ borderColor: errors.firstname ? 'red' : '#AAA6C3' }}
            >
              <label htmlFor="FirstName" className="text-xs font-semibold">
                First Name
              </label>
              <input
                type="text"
                id="FirstName"
                placeholder="first name"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.first_name}
                {...register('firstname', {
                  pattern: {
                    value: /^[A-Za-z'-]{1,50}$/,
                    message: 'Enter a valid name',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
            </div>
            {errors.firstname && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute text-xs -bottom-[1rem]"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.firstname?.message}
              </p>
            )}
          </div>

          <div className="flex flex-col items-center relative justify-center w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end w-2/3"
              style={{ borderColor: errors.lastname ? 'red' : '#AAA6C3' }}
            >
              <label htmlFor="LastName" className="text-xs font-semibold">
                Last Name
              </label>
              <input
                type="text"
                id="LastName"
                placeholder="last name"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.last_name}
                {...register('lastname', {
                  pattern: {
                    value: /^[A-Za-z'-]{1,50}$/,
                    message: 'Enter a valid name',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
            </div>
            {errors.lastname && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute -bottom-[1rem]  text-xs"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.lastname?.message}
              </p>
            )}
          </div>

          <div className="flex flex-col items-center relative justify-center w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end w-2/3 relative"
              style={{ borderColor: errors.password ? 'red' : '#AAA6C3' }}
            >
              <label htmlFor="password" className="text-xs font-semibold">
                Password
              </label>
              <input
                type={pwdShown ? 'text' : 'password'}
                id="password"
                placeholder="Password"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.password}
                {...register('password', {
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                  minLength: {
                    value: 8,
                    message: 'password needs to be more than 8 characters',
                  },
                  maxLength: {
                    value: 255,
                    message: 'max characters are 255 !',
                  },
                })}
              />
              <span className="z-20 absolute right-[2%]  bottom-[2%]">
                <button
                  type="button"
                  onClick={() =>
                    pwdShown === false ? setPwdShown(true) : setPwdShown(false)
                  }
                  className="text-[#AAA6C3] "
                >
                  {pwdShown === true ? (
                    <AiOutlineEyeInvisible size={30} />
                  ) : (
                    <AiOutlineEye size={30} />
                  )}
                </button>
              </span>
            </div>
            {errors.password && (
              <p
                className="text-[#EC1577] w-full self-center text-center  absolute -bottom-[1rem] text-xs"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.password?.message}
              </p>
            )}
          </div>

          <div className="flex flex-col relative items-center  justify-center w-full">
            <div
              style={{ borderColor: errors.passwordC ? 'red' : '#AAA6C3' }}
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 relative bg-white items-start justify-end w-2/3"
            >
              <label htmlFor="passwordC" className="text-xs font-semibold">
                Confirm password
              </label>
              <input
                id="passwordC"
                type={pwdCShown ? 'text' : 'password'}
                defaultValue={formData.confirmed_password}
                {...register('passwordC', {
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                  minLength: {
                    value: 8,
                    message: 'password needs to be more than 8 characters',
                  },
                  maxLength: {
                    value: 255,
                    message: 'max characters are 255 !',
                  },
                  validate: (value) =>
                    value === password || 'Passwords do not match',
                })}
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                placeholder="password confirmation"
              />
            </div>
            {errors.passwordC && (
              <p
                className="text-[#EC1577] w-full self-center text-center  absolute -bottom-[1rem] text-xs"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.passwordC?.message}
              </p>
            )}
          </div>

          <div className="flex  flex-col items-center relative justify-center w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end w-2/3"
              style={{ borderColor: errors.email ? 'red' : '#AAA6C3' }}
            >
              <label htmlFor="email" className="text-xs font-semibold">
                E-mail address
              </label>
              <input
                type="text"
                id="email"
                placeholder="Adresse email"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.email}
                {...register('email', {
                  required: 'this field is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Please enter a valid email addres',
                  },
                })}
              />
            </div>
            {errors.email && (
              <p
                className="text-[#EC1577] w-full self-center text-center  absolute -bottom-[1rem] text-xs"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.email?.message}
              </p>
            )}
          </div>

          <div className="flex flex-col items-center h-14 justify-center w-full">
            <div className=" flex flex-col border-[#AAA6C3] relative border-1 rounded-lg p-2 bg-white items-start justify-end w-2/3">
              <button
                className="w-full h-8 outline-none appearance-none border-0 bg-transparent z-10 hover:outline-none focus:"
                type="button"
                onClick={handleOpenCountries}
              >
                <div className="absolute top-[0.6rem] flex gap-1">
                  <ReactCountryFlag
                    className="emojiFlag"
                    countryCode={selectedCountry.countryCode}
                    style={{
                      fontSize: '2em',
                      lineHeight: '2em',
                    }}
                    svg
                  />

                  <div className="text-center text-[#AAA6C3] flex flex-col justify-center items-center ">
                    <div>{selectedCountry.name}</div>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className=" w-5 h-5 scale-100   bottom-3 right-[0.8rem]  bg-white rounded-full  absolute  text-[#181143] text-opacity-70 checked-1 transition "
                  />
                </div>
              </button>
              <div
                className="flex flex-col overflow-scroll z-10 absolute top-[2.8rem] w-full left-[0rem] bg-white "
                style={{ height: isOpen === true ? '200px' : '0px' }}
                ref={menuRef}
              >
                {countries.map((country, i) => (
                  <button
                    type="button"
                    className="flex w-full pl-2 hover:bg-[#E1DFEE]"
                    key={i}
                    onClick={() => {
                      setSelectedCountry(country);
                      setIsOpen(false);
                    }}
                  >
                    <ReactCountryFlag
                      className="emojiFlag"
                      countryCode={country.countryCode}
                      style={{
                        fontSize: '2em',
                        lineHeight: '2em',
                      }}
                      svg
                    />
                    <div className="text-center flex flex-col items-center h-full justify-center ml-2">
                      <div className="text-[#AAA6C3]">{country.name}</div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="flex   relative justify-center w-full gap-3">
            <div className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end relative w-[15%]">
              <div className="absolute top-[0.6rem] flex gap-1">
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode={selectedCountry.countryCode}
                  style={{
                    fontSize: '2em',
                    lineHeight: '2em',
                  }}
                  svg
                />
                <div className="text-center text-[#AAA6C3] flex flex-col justify-center items-center ">
                  <div>{selectedCountry.callingCode}</div>
                </div>
              </div>
            </div>
            <div
              style={{ borderColor: errors.phone ? 'red' : '#AAA6C3' }}
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end w-[50.2%]"
            >
              <label htmlFor="phone" className="text-xs font-semibold">
                Phone number
              </label>
              <input
                type="tel"
                id="phone"
                placeholder="phone number"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.phone}
                {...register('phone', {
                  required: 'this field is required',
                  pattern: {
                    value: /^\+?[0-9\s-()]{8,}$/,
                    message: 'Enter a valid phone number',
                  },
                })}
              />
            </div>
            {errors.phone && (
              <p
                className="text-[#EC1577] w-full self-center text-center  absolute -bottom-[1rem] text-xs"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.phone?.message}
              </p>
            )}
          </div>
          <div className="flex justify-center w-full relative">
            <div
              style={{ borderColor: errors.birthDate ? 'red' : '#AAA6C3' }}
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start justify-end w-2/3"
            >
              <label htmlFor="bd" className="text-xs font-semibold">
                Birth date
              </label>
              <input
                type="date"
                id="bd"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.birthdate}
                {...register('birthDate', {
                  required: 'this field is required',
                })}
              />
            </div>
            {errors.birthDate && (
              <p
                className="text-[#EC1577] w-full self-center text-center  absolute -bottom-[1rem] text-xs"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.birthDate?.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center space-y-5 justify-center w-full">
          <button
            type="submit"
            className="rounded-xl h-12 w-2/3  p-3 bg-[#EC1577] text-[#FFF] cursor-pointer hover:outline-0 hover:scale-105 transition duration-150"
          >
            Next
          </button>
          <button
            type="button"
            className="border-2 border-[#EC1577] rounded-xl text-[#EC1577] p-3 cursor-pointer h-12 w-2/3 text-center hover:outline-0 hover:scale-105 transition duration-150"
            onClick={() => navigate('/login')}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

UserInfo.propTypes = {
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
};
