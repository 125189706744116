import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';
import { store } from './app/store';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <Provider store={store}>
    <ContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
        <ToastContainer className="!top-28" />
      </BrowserRouter>
    </ContextProvider>
  </Provider>,
  document.getElementById('shifti')
);
