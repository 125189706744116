import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

function ProductOptions({ formData, setFormData }) {
  const [typeValue, setTypeValue] = useState('');
  const [inputLabel, setInputLabel] = useState([]);
  const [inputType, setInputType] = useState([]);
  const [inputRequired, setInputRequired] = useState([]);

  const numInputsRequired = inputRequired.length;

  useEffect(() => {
    const storedLabels = JSON.parse(localStorage.getItem('labels'));
    const storedTypes = JSON.parse(localStorage.getItem('types'));
    const storedTypesValue = JSON.parse(localStorage.getItem('typesValue'));
    const storedRequired = JSON.parse(localStorage.getItem('required'));
    if (storedLabels && storedTypes) {
      setInputLabel(storedLabels);
      setInputType(storedTypes);
      setInputRequired(storedRequired);
      setTypeValue(storedTypesValue);
    }
  }, []);

  const handleAddField = () => {
    const newInputLabel = {
      id: inputLabel.length,
      name: `label-${inputLabel.length}`,
      label: 'Label',
      value: '',
    };
    const newInputType = {
      id: inputType.length,
      name: `type-${inputType.length}`,
      label: 'Type',
      value: 'text',
    };
    const newInputRequired = {
      id: numInputsRequired,
      name: `required-${inputRequired.length}`,
      label: 'Required',
      value: false,
      isChecked: false,
    };
    setInputRequired([...inputRequired, newInputRequired]);
    setInputLabel([...inputLabel, newInputLabel]);
    setInputType([...inputType, newInputType]);
  };

  const handleRemoveField = (id) => {
    const newInputLabel = inputLabel.filter((input) => input.id !== id);
    const newInputType = inputType.filter((input) => input.id !== id);
    const newInputRequired = inputRequired.filter((input) => input.id !== id);
    setInputRequired(newInputRequired);
    setInputLabel(newInputLabel);
    setInputType(newInputType);
  };

  useEffect(() => {
    localStorage.setItem('labels', JSON.stringify(inputLabel));
    localStorage.setItem('types', JSON.stringify(inputType));
    localStorage.setItem('required', JSON.stringify(inputRequired));
  }, [inputLabel, inputType, inputRequired]);

  const handleFormData = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleToggle = (id) => {
    const newInputRequired = inputRequired.map((input) => {
      if (input.id === id) {
        return { ...input, isChecked: !input.isChecked };
      }
      return input;
    });
    setInputRequired(newInputRequired);
    setFormData({
      ...formData,
      productCustomizedRequired: newInputRequired,
    });
  };

  return (
    <div className="mt-12 rounded-3xl flex flex-col sm:flex-row">
      <div className="main-container flex flex-col w-full space-y-10">
        <div className="status-container w-full space-y-5">
          <h1 className="SubTitle">Status and references</h1>
          <div className="inputs-container flex md:flex-row flex-col w-full md:-space-x-6 ">
            <div className="input-container flex flex-col md:space-y-2 w-5/12">
              <label htmlFor="state" className="essentialLabel">
                State
              </label>
              <select
                className="essentialSelect"
                id="state"
                name="status"
                value={formData.status}
                onChange={handleFormData}
              >
                <option value="1">New</option>
                <option value="2">used</option>
                <option value="3">refurbished</option>
              </select>
            </div>
            <div className="input-container self-end flex flex-col w-5/12">
              <div className="input-container relative flex items-center space-x-3 md:w-[46rem] mt-5">
                <input
                  type="checkbox"
                  id="sendMail"
                  name="products"
                  className="optionCheckbox"
                />
                <label
                  htmlFor="products"
                  className="essentialLabel md:w-full w-[17rem]"
                >
                  Display the status on the product sheet
                </label>
                <FontAwesomeIcon
                  icon={faCheck}
                  className="h-4 w-16  text-[#EC1577] absolute right-[85.1%] md:right-[94%] md:bottom-[32.5%] pointer-events-none text-opacity-0 checked transition scale-75"
                />
              </div>
            </div>
          </div>
          <div className="inputs-container flex md:flex-row flex-col w-full gap-8">
            <div className="w-full">
              <label
                htmlFor="state"
                className="text-shifti-blue text-lg font-medium mr-2 mt-2 mb-2 block"
              >
                ISBN
              </label>
              <input
                className="rounded-xl p-5 h-11 w-full"
                type="text"
                placeholder=""
                id="ISBN"
                name="isbn"
                value={formData.isbn}
                onChange={handleFormData}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="state"
                className="text-shifti-blue text-lg font-medium mr-2 mt-2 mb-2 block"
              >
                EAN-13 or JAN barcode
              </label>
              <input
                className="rounded-xl p-5 h-11 w-full"
                type="text"
                placeholder=""
                id="EAN"
                name="ean"
                value={formData.ean}
                onChange={handleFormData}
              />
            </div>
          </div>
          <div className="inputs-container flex md:flex-row flex-col w-full gap-8">
            <div className="w-full">
              <label
                htmlFor="UPC"
                className="text-shifti-blue text-lg font-medium mr-2 mt-2 mb-2 block"
              >
                UPC barCode
              </label>
              <input
                className="rounded-xl p-5 h-11 w-full"
                type="text"
                placeholder=""
                id="UPC"
                name="upc"
                value={formData.upc}
                onChange={handleFormData}
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="MPN"
                className="text-shifti-blue text-lg font-medium mr-2 mt-2 mb-2 block"
              >
                MPN
              </label>
              <input
                className="rounded-xl p-5 h-11 w-full"
                type="text"
                placeholder=""
                id="MPN"
                name="mpn"
                value={formData.mpn}
                onChange={handleFormData}
              />
            </div>
          </div>
        </div>
        <div className="customization-container space-y-5">
          <h1 className="SubTitle">Customization</h1>
          <p className="text-[#AAA6C3] font-medium">
            Customers can personalize the product by entering text or providing
            an image of their choice
          </p>
          <div className="flex flex-col">
            {inputLabel.map((label, index) => (
              <div key={label.id} className="flex flex-row md:space-x-2 w-full">
                <div className="flex flex-col space-y-2 w-[60%]">
                  <label htmlFor="">{label.label}</label>
                  <input
                    className="essentialInput"
                    type="text"
                    name={label.name}
                    value={label.value}
                    onChange={(e) => {
                      const newInputs = [...inputLabel];
                      newInputs[index].value = e.target.value;
                      setInputLabel(newInputs);
                      setFormData({
                        ...formData,
                        productCustomizedLabel: newInputs,
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-2 w-[20%]">
                  <label htmlFor="">{inputType[index].label}</label>
                  <select
                    className="essentialSelect"
                    name={inputType[index].name}
                    id={inputType[index].name}
                    value={inputType[index].value}
                    onChange={(e) => {
                      const newInputs = [...inputType];
                      newInputs[index].value = e.target.value;
                      setInputType(newInputs);
                      setFormData({
                        ...formData,
                        productCustomizedType: newInputs,
                      });
                    }}
                  >
                    <option value="text">Text</option>
                    <option value="file">File</option>
                  </select>
                </div>
                <button
                  className="relative mt-8"
                  type="button"
                  onClick={() => handleRemoveField(label.id)}
                  style={{ marginLeft: '10px' }}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="h-[1.5rem] w-[1.5rem] scale-100 left-[88%] top-[19%] 2xl:left-[92%] absolute  text-[#AAA6C3] text-opacity-70 checked-1 transition "
                  />
                </button>
                <div className="required self-center space-x-10 mt-6 ">
                  <label className="flex items-center space-x-2 flex-row ml-10">
                    <input
                      type="checkbox"
                      className="form-checkbox opacity-0 w-0 h-0"
                      name={inputRequired[index].name}
                      id={inputRequired[index].name}
                      value={!!inputRequired[index].isChecked}
                      checked={inputRequired[index].isChecked}
                      onChange={() => handleToggle(inputRequired[index].id)}
                    />
                    <div
                      className={`w-12 h-6 rounded-full transition-colors duration-300 relative ${
                        inputRequired[index].isChecked
                          ? 'bg-[#EC1577] '
                          : 'bg-[#AAA6C3]'
                      }`}
                    >
                      <span
                        className={`h-5 w-5 rounded-full border-2 top-[10%] duration-300 absolute  ${
                          inputRequired[index].isChecked
                            ? 'bg-[#E1DFEE] translate-x-[27px] '
                            : 'bg-[#E1DFEE]'
                        } `}
                      />
                    </div>
                    <div>{inputRequired.label}</div>
                  </label>
                </div>
              </div>
            ))}
          </div>
          <div className="button-container flex flex-col md:w-[50%]">
            <button
              className="addButton w-full md:pr-[50%] pr-[20%]"
              type="button"
              onClick={handleAddField}
            >
              <span
                className="p-2"
                style={{
                  background: '#ffffff',
                  color: '#18114397',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  padding: '4px 9px',
                  fontSize: '1,20vw',
                  marginLeft: '-5%',
                  marginRight: '2%',
                }}
              >
                +
              </span>
              add a customization field
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductOptions.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};
export default ProductOptions;
