import React, { useState } from 'react';
import UserInfo from '../users/UserInfo';
import UserCompany from '../users/UserCompany';
import UserSummary from '../users/UserSummary';

const SignUp = () => {
  const [currentTab, setCurrentTab] = useState('1');
  const [selectedCountry, setSelectedCountry] = useState({});

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    confirmed_password: '',
    birthdate: '',
    country: '',
    flag: '',
    phoneCode: '',
    company_name: '',
    taxName: '',
    address: '',
    companyEmail: '',
    website: '',
    postcode: '',
    activitySector: '',
    description: '',
    ruleCkecked: false,
  });

  const tabs = [
    {
      id: 1,
      tabTitle: 'User info',
      title: 'User info',
      content: (
        <UserInfo
          tabNumber={1}
          formData={formData}
          setFormData={setFormData}
          setCurrentTab={setCurrentTab}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'User Company info',
      title: 'User Company info',
      content: (
        <UserCompany
          tabNumber={2}
          formData={formData}
          setFormData={setFormData}
          setCurrentTab={setCurrentTab}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'User Summary',
      title: 'User Summary',
      content: (
        <UserSummary
          tabNumber={3}
          formData={formData}
          setFormData={setFormData}
          setCurrentTab={setCurrentTab}
        />
      ),
    },
  ];

  return (
    <section className=" flex h-full">
      <div className="w-3/12 flex flex-col items-center justify-start p-10">
        <div className="image-div w-full bg-[#181144] rounded-3xl  flex justify-center h-full items-center pr-32 ">
          <img src="../register2.png" alt="" className="bg-no-repeat w-full" />
        </div>
      </div>
      {tabs.map(
        (tab, i) =>
          currentTab === `${tab.id}` && (
            <div key={i} className="w-9/12 h-full">
              {tab.content}
            </div>
          )
      )}
    </section>
  );
};

export default SignUp;
