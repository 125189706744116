import React from 'react';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import PropTypes from 'prop-types';

const DateRange = ({ className, id, startDate, endDate, onChange }) => {
  const presets = [
    {
      label: 'Today',
      start: new Date(),
      end: new Date(),
    },
    {
      label: 'This Month',
      start: new Date(new Date().setDate(1)),
      end: new Date(),
    },
    {
      label: 'Last Month',
      start: new Date(
        new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
      ),
      end: new Date(),
    },
    {
      label: 'Last Year',
      start: new Date(new Date().getFullYear() - 1, 0, 1),
      end: new Date(),
    },
  ];

  return (
    <div className={className}>
      <DateRangePickerComponent
        id={id}
        placeholder="Select a range"
        startDate={startDate}
        endDate={endDate}
        format="yyyy-MM-dd"
        onChange={onChange}
        presets={presets}
      />
    </div>
  );
};
DateRange.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};
export default DateRange;
