import React, { useState, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import Select from '../../components/Select';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export default function EditCustomer() {
  const token = useSelector(selectCurrentToken);
  let idComingFromUrl;
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    shop_id: 0,
    email: '',
    validation: {
      error: [true, true, false, true, false, true],
      errorMsg: [
        'Obligatoire',
        'Obligatoire',
        'Obligatoire',
        'Obligatoire',
        'Obligatoire',
        'Obligatoire',
      ],
    },
  });
  const [formDataError, setformDataError] = useState([
    true,
    true,
    false,
    true,
    true,
    true,
  ]);

  useEffect(() => {
    // Recuperation de l'id
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    idComingFromUrl = urlParams.get('id');
    // end of id recuperation
    getCustomerById();
  }, []);

  const handelFormDataChange = (value, key, index) => {
    const aux = { ...formData };
    aux[key] = value;
    if (key === 'first_name') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'last_name') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'email') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else if (!isEmail(value)) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Inavlid Email';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    setFormData(aux);
  };

  const getCustomerById = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/customers/${idComingFromUrl}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };

    await api
      .request(config)
      .then((response) => {
        const { customer } = response.data;
        const { shops } = response.data;
        setItems(shops);
        setFormData({
          first_name: customer.first_name,
          last_name: customer.last_name,
          shop_id: customer.shop_id,
          email: customer.email,
        });
      })
      .catch((error) => {});
  };

  const handelSubmit = async () => {};

  return (
    <div className="p-2 md:p-5 ml-3 rounded-3xl mb-3 md:mt-8 mt-20 flex justify-center">
      <div className="flex justify-center flex-col w-[90%]">
        <Header category="Page" title="Edit Customer" />

        <form
          action=""
          className="sft-form formStyle mt-2 md:mt-0 p-2 md:p-10 mr-3 bg-white rounded-3xl md:w-full"
        >
          <div className="sft-input-container grid grid-cols-2 gap-y-8 pt-5 mr-10 md:mr-0 md:w-[40%] lg:w-[81%]">
            <label htmlFor="name" className="formLabel">
              First Name :
            </label>
            <div className="input-container">
              <input
                id="name"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'first_name', 0)
                }
                type="text"
                defaultValue={formData.first_name}
                className="formInput"
              />
              <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
            </div>
            <label htmlFor="name" className="formLabel">
              Last Name :
            </label>
            <div className="input-container">
              <input
                type="text"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'last_name', 1)
                }
                defaultValue={formData.last_name}
                className="formInput w-[200px]"
              />
              <div style={{ color: '#FF9494' }}>{formDataError[1]}</div>
            </div>
          </div>
          <div className="sft-input-container grid grid-cols-2 gap-y-8 mt-4 mr-10 md:mr-0 md:w-[40%] lg:w-[81%]">
            <label htmlFor="name" className="formLabel mr-[2rem] md:mr-[3rem]">
              Shops :
            </label>
            <Select
              onChange={(e) =>
                handelFormDataChange(e.target.value, 'shop_id', 2)
              }
              items={
                items.length > 0 && Object.keys(items[0]).length !== 0
                  ? items
                  : []
              }
              selected={formData.shop_id}
            />
          </div>

          <div className="sft-input-container grid grid-cols-2 mt-4 mr-10 md:mr-0 md:w-[40%] lg:w-[81%]">
            <label htmlFor="name" className="formLabel mr-[2rem] md:mr-[3rem]">
              Email :
            </label>
            <div className="input-container">
              <input
                type="email"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'email', 3)
                }
                defaultValue={formData.email}
                className="formInput"
              />
              <div style={{ color: '#FF9494' }}>{formDataError[3]}</div>
              <div style={{ color: '#FF9494' }}>{formDataError[5]}</div>
            </div>
            <div /> {/* empty div for button position */}
            <div className="flex justify-end">
              <button
                type="button"
                className=" mt-[2rem] bg-[#181143] text-white h-9 w-20 rounded-md "
                onClick={handelSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
