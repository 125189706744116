import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

function EditCarrierSummary({
  currentTab,
  setValidatedTabs,
  validatedTabs,
  formData,
  setFormData,
  setCurrentTab,
  selectedTaxes,
  billingByPrice,
  selectedShopName,
  allZoneNames,
  isAllZones,
  zoneNames,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const currentTabId = parseInt(currentTab);
    setValidatedTabs([...validatedTabs, currentTabId]);
  }, []);

  const handleOnChangeEnabled = () => {
    if (formData.active) {
      setFormData({
        ...formData,
        active: 0,
      });
    } else {
      setFormData({
        ...formData,
        active: 1,
      });
    }
  };

  const onSubmit = () => {
    setCurrentTab('2');
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-5">
        <div className="input-box flex flex-col space-y-3 w-full bg-white p-5 rounded-xl">
          <p className="text-[1.1vw] font-semibold text-[#181143]">
            CARRIER NAME: {formData.name}
          </p>
          <div className="h-[2px] mt-2 mb-2 bg-[#E1DFEE]" />
          <p className="text-[1vw] text-[#181143]">
            This carrier is not free and the transit time is
            <span className="font-semibold"> {formData.delay}</span>
          </p>
          <p className="text-[1vw] text-[#181143]">
            The shipping cost is calculated according to the weight and the tax
            rule : <span className="font-semibold">{selectedTaxes}</span>
          </p>
          <p className="text-[1vw] text-[#181143]">
            This carrier can deliver orders from
            <span className="font-semibold">{formData.delimiter1}</span>
            {billingByPrice === 1 ? (
              <span className="font-semibold">TND</span>
            ) : (
              <span className="font-semibold">kg</span>
            )}
            to <span className="font-semibold">{formData.delimiter2}</span>
            {billingByPrice === 1 ? (
              <span className="font-semibold">TND</span>
            ) : (
              <span className="font-semibold">kg</span>
            )}
            .
          </p>
          <p className="text-[1vw] text-[#181143]">
            If the order is out of range, the behavior is to apply the cost of
            the highest defined range.
          </p>
          <p className="text-[1vw] text-[#181143]">
            This carrier will be proposed for those delivery zones:
          </p>
          <p>
            {!isAllZones &&
              (zoneNames.length ? (
                <>
                  {zoneNames.map((zone, i) => (
                    <div
                      className="text-[1vw] text-[#181143] font-semibold"
                      key={i}
                    >
                      {zone}
                    </div>
                  ))}
                </>
              ) : null)}

            {isAllZones && allZoneNames.length && (
              <>
                {allZoneNames.map((zone, i) => (
                  <p
                    className="text-[1vw] text-[#181143] font-semibold"
                    key={i}
                  >
                    {zone}
                  </p>
                ))}
              </>
            )}
          </p>
          <p className="text-[1vw] text-[#181143]">
            Finally, this carrier will be proposed in those shops:
            <div className="text-[1vw] font-semibold text-[#181143]">
              {selectedShopName}
            </div>
          </p>
        </div>
        <div className="input-box flex flex-col space-y-3 w-full bg-white p-5 rounded-xl">
          <div className="input-box flex flex-row space-y-3 w-[90%] justify-center">
            <div className="label-container mr-5 mt-4 mb-2">
              <label htmlFor="shipping_handling" className="form-label">
                Enabled
              </label>
            </div>
            <div className="input-container">
              <div className="radio-box relative">
                <input
                  type="radio"
                  name="active"
                  className="radio-input-left appearance-none bg-white shadow-xl rounded-xl w-12 h-8  z-10 checked:z-30 absolute checked:scale-125 checked:bg-[#EC1577] checked:outline-none hover:cursor-pointer hover:outline-none"
                  onChange={handleOnChangeEnabled}
                  checked={formData.active === 0 ? 1 : 0}
                />
                <p className="absolute z-30 text-[#AAA6C3] left-[0.5rem] top-1 pointer-events-none">
                  No
                </p>
              </div>
              <div className="radio-box relative ">
                <input
                  type="radio"
                  name="active"
                  className="radio-input-right appearance-none bg-white shadow-xl rounded-xl w-12 h-8 z-10 checked:z-30 absolute left-10 checked:scale-125 checked:bg-[#F99E1F] checked:outline-none hover:cursor-pointer hover:outline-none"
                  onChange={handleOnChangeEnabled}
                  checked={formData.active === 1 ? 1 : 0}
                />
                <p className="absolute z-30 left-[3.5rem] text-[#AAA6C3] top-1 pointer-events-none">
                  Yes
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-save-submit flex justify-end md:mr-10 mt-5 ">
        <button
          type="button"
          onClick={() => {
            setCurrentTab('3');
          }}
          className="pl-[0.2rem]  bg-[#AAA6C3] rounded-3xl w-[10rem] text-white h-[37px]"
        >
          previous
        </button>
      </div>
    </form>
  );
}

EditCarrierSummary.propTypes = {
  currentTab: PropTypes.number.isRequired,
  setValidatedTabs: PropTypes.func.isRequired,
  validatedTabs: PropTypes.number.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  selectedTaxes: PropTypes.string.isRequired,
  billingByPrice: PropTypes.number.isRequired,
  selectedShopName: PropTypes.string.isRequired,
  allZoneNames: PropTypes.object.isRequired,
  isAllZones: PropTypes.bool.isRequired,
  zoneNames: PropTypes.object.isRequired,
};

export default EditCarrierSummary;
