import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPencil,
  faEllipsisVertical,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { AiOutlineDrag } from 'react-icons/ai';
import PropTypes from 'prop-types';
import AttributeGroupContext from '../pages/groupAttributeContext/GroupAttributeContext';

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const Actions = ({ id }) => {
  const { value, setValue } = useContext(AttributeGroupContext) || {
    value: null,
    setValue: () => {},
  };
  const [toggle, setToggle] = useState(false);
  const handleActionClick = () => {
    setToggle((prevCheck) => !prevCheck);
  };
  useEffect(() => {}, []);
  return (
    <div>
      <button
        type="button"
        style={{ color: '#000' }}
        className="text-[#AAA6C3] py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => {
          window.location.href = `/edit-group-attribute/?id=${id}`;
        }}
      >
        <FontAwesomeIcon icon={faPencil} className="w-6 h-6 text-[#AAA6C3]" />
      </button>
      <button
        type="button"
        style={{ color: '#000' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => handleActionClick(id)}
      >
        <FontAwesomeIcon
          icon={faEllipsisVertical}
          className="w-6 h-6 text-[#AAA6C3]"
        />
      </button>
      {toggle ? (
        <div className="bg-white -mb-2 flex flex-col absolute w-4 text-left">
          <button
            type="button"
            className="text-black w-4"
            onClick={() =>
              setValue({
                title: 'Add a new attribute',
                component: 'AddGroupAttribute',
                id,
              })
            }
          >
            <FontAwesomeIcon icon={faEdit} className="w-6 h-6 text-[#AAA6C3]" />
            <span className="text-black">Edit</span>
          </button>
          <button type="button" className="text-black w-4">
            <FontAwesomeIcon
              icon={faTrash}
              className="w-6 h-6 text-[#AAA6C3]"
            />
            <span className="text-black">Delete</span>
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export const position = (props) => (
  <div className="flex items-center justify-center cursor-move rounded-full gap-2">
    <div className="bg-shifti-main-bg text-white py-2 px-4 rounded-xl">
      {props.position}
    </div>
    <div>
      <AiOutlineDrag className="text-shifti-main-text" />
    </div>
  </div>
);

export const attributeGroupsGrid = [
  {
    field: 'id',
    headerText: 'ID',
    width: '80',
    textAlign: 'Start',
  },
  {
    field: 'AttributeGroupLangs.0.name',
    headerText: 'Name',
    width: '120',
    textAlign: 'Start',
  },
  {
    field: 'position',
    headerText: 'Position',
    template: position,
    width: '200',
    textAlign: 'Center',
  },
  {
    field: 'action',
    headerText: 'Action',
    template: Actions,
    width: '120',
    textAlign: 'End',
  },
];
Actions.propTypes = {
  id: PropTypes.number.isRequired,
};
