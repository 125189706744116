import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import InputUpdatePassword from '../../components/InputUpdatePassword';
import { selectCurrentToken } from '../../features/auth/authSlice';
import Select from '../../components/Select';

const apiURL = process.env.REACT_APP_API_URL;

const Profile = () => {
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const [langs, setLangs] = useState([]);
  const [user, setUser] = useState(decoded.UserInfo);
  const [externalLangs, setExternalLangs] = useState([]);
  const navigate = useNavigate();
  // get lang
  const getLangs = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/langs`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setLangs(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  const updateUser = () => {
    const config = {
      url: `${apiURL}/user/updateUser/${user.id}`,
      method: 'patch',
      data: user,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .request(config)
      .then((res) => {
        navigate('/profile');
      })
      .catch((err) => navigate('/profile'));
  };

  const handleOnChange = (event) => {
    const temp = { ...user };
    temp[event.target.name] = event.target.value;
    setUser(temp);
  };
  useEffect(() => {
    getLangs();
  }, []);

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl">
      <div className="flex flex-col md:flex-row justify-between">
        <div>
          <Header
            category="Page"
            title={`Edit : ${user.first_name} ${user.last_name}`}
          />
        </div>
        <div>
          <br />
          <div
            className="add-button"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Link
              className="p-2"
              style={{
                background: '#EC1577',
                color: 'white',
                cursor: 'pointer',
                borderRadius: '25px',
                padding: '7.1px 30px 0px',
                fontWeight: 'normal',
                fontSize: '1,04vw',
                height: '37px',
              }}
              to="/help"
            >
              Help
            </Link>
          </div>
        </div>
      </div>
      {/* ----------------------------- main content ---------------------------- */}
      {/* -------------------------------- row 1 -------------------------------- */}
      <form onSubmit={updateUser}>
        <div className="w-2/3">
          <div className="space-y-4">
            <br />
            <label htmlFor="FirstName">First name</label>
            <input
              name="first_name"
              onChange={(e) => handleOnChange(e)}
              className="w-full bg-white border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block dark:placeholder-gray-400 h-[2.8rem] p-2"
              type="text"
              placeholder="First Name"
              value={user.first_name}
            />
            <br />
            <label htmlFor="LastName">Last name</label>
            <input
              name="last_name"
              onChange={(e) => handleOnChange(e)}
              className="w-full bg-white border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block dark:placeholder-gray-400 h-[2.8rem] p-2"
              type="text"
              placeholder="Last Name"
              value={user.last_name}
            />
          </div>
          {/* -------------------------------- row 2 -------------------------------- */}
          <br /> <br />
          <div className="space-y-4">
            <label htmlFor="Email">Email Address</label>
            <input
              name="email"
              onChange={(e) => handleOnChange(e)}
              className="w-full bg-white border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block dark:placeholder-gray-400 h-[2.8rem] p-2"
              type="text"
              placeholder="Email Address"
              value={user.email}
            />
          </div>
          <br />
          {/* -------------------------------- row 3 -------------------------------- */}
          <div className="space-y-4">
            <div className="w-2/5" />
            <div className="w-full">
              <div className="Flex">
                <span className="mt-4">Password</span>
                <InputUpdatePassword onChange={handleOnChange} />
              </div>
            </div>
          </div>
          {/* -------------------------------- row 4 -------------------------------- */}
          <br />
          <div className="space-y-4">
            <label htmlFor="LastName">Default page</label>
            <select
              name="name"
              className="w-full bg-white border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block dark:placeholder-gray-400 h-[2.8rem] p-2"
            >
              <option value="test">static 1</option>
              <option value="test">static 2</option>
              <option value="test">static 3</option>
            </select>
          </div>
          <br />
          <div className="space-y-4">
            <label htmlFor="LastName">Language</label>
            {externalLangs}
            <Select
              icon="icon"
              className="inline-block"
              items={langs}
              name="lang"
            />
          </div>
        </div>
        <div className="flex">
          <div className="ml-auto mr-32 mt-24">
            <button className="addButton mt-8 w-[14rem]" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    /// ////////////////////////// end main warp ////////////////////////////////////////
  );
};

export default Profile;
