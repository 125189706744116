import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

const UserCompany = ({ setFormData, setCurrentTab, formData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const hundleSubmitUserCompany = (data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      company_name: data.companyName,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      taxName: data.taxNumber,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      companyEmail: data.companyEmail,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      website: data.website,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      postcode: data.companyPostCode,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      address: data.companyAddress,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      activitySector: data.activitySector,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      description: data.companyDescription,
    }));

    setCurrentTab('3');
  };

  return (
    <div className="flex flex-col justify-center items-center pr-32 pt-10 pb-10">
      <form
        onSubmit={handleSubmit(hundleSubmitUserCompany)}
        className="bg-[#E1DFEE]  flex flex-col justify-center items-center w-full pt-5 pb-5  space-y-6 drop-shadow-xl shadow-2xl rounded-2xl h-full"
      >
        <div className="title flex flex-col justify-center items-center space-y-2">
          {' '}
          <h1 className="text-[#181144] text-3xl font-bold">Sign Up</h1>
          <h2 className="text-[#5E6366] text-2xl font-semibold">
            Enter your company information
          </h2>
        </div>
        <div className="grid grid-rows-6 grid-flow-col p-10 gap-8 h-full w-full">
          <div className="col-span-1 flex flex-col items-center  justify-center relative w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start  justify-end w-full"
              style={{ borderColor: errors.companyName ? 'red' : '#AAA6C3' }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-xs font-semibold"
              >
                Company Name
              </label>
              <input
                type="text"
                id="username"
                placeholder="company name"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.company_name}
                {...register('companyName', {
                  pattern: {
                    value: /^[A-Za-z'-]{1,50}$/,
                    message: 'Enter a valid name',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
            </div>
            {errors.companyName && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute text-xs -bottom-[1rem]"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.companyName?.message}
              </p>
            )}
          </div>
          <div className="col-span-1 flex flex-col items-center  justify-center relative w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start  justify-end w-full"
              style={{ borderColor: errors.taxNumber ? 'red' : '#AAA6C3' }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-xs font-semibold"
              >
                Tax number
              </label>
              <input
                type="text"
                id="username"
                placeholder="tax number"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.taxName}
                {...register('taxNumber', {
                  pattern: {
                    value: /^[A-Z0-9-]{5,20}$/,
                    message: 'Enter a valid tax number',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
            </div>
            {errors.taxNumber && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute text-xs -bottom-[1rem]"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.taxNumber?.message}
              </p>
            )}
          </div>
          <div className="col-span-1 flex flex-col items-center  justify-center relative w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start  justify-end w-full"
              style={{ borderColor: errors.companyEmail ? 'red' : '#AAA6C3' }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-xs font-semibold"
              >
                E-mail
              </label>
              <input
                type="email"
                id="username"
                placeholder="example@example.com"
                defaultValue={formData.companyEmail}
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                {...register('companyEmail', {
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Enter a valid email address',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
            </div>
            {errors.companyEmail && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute text-xs -bottom-[1rem]"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.companyEmail?.message}
              </p>
            )}
          </div>
          <div className="col-span-1 flex flex-col items-center  justify-center relative w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start  justify-end w-full"
              style={{ borderColor: errors.website ? 'red' : '#AAA6C3' }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-xs font-semibold"
              >
                Web site
              </label>
              <input
                type="text"
                id="username"
                placeholder="www.example.com"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.website}
                {...register('website', {
                  pattern: {
                    value:
                      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
                    message: 'Enter a valid website address',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
            </div>
            {errors.website && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute text-xs -bottom-[1rem]"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.website?.message}
              </p>
            )}
          </div>
          <div className="col-span-2 row-span-2 w-full flex flex-col items-center h-full  justify-center relative ">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 h-full rounded-lg p-2 bg-white items-start  justify-start w-full"
              style={{
                borderColor: errors.companyDescription ? 'red' : '#AAA6C3',
              }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-xs font-semibold"
              >
                Company discription
              </label>
              <textarea
                type="text"
                id="username"
                placeholder="your text"
                className="w-full bg-white border-none outline-none h-full text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.description}
                {...register('companyDescription')}
              />
            </div>
            {errors.companyDescription && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute text-xs -bottom-[1rem]"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.companyDescription?.message}
              </p>
            )}
          </div>
          <div className="col-span-1 row-span-2 flex flex-col items-center h-full justify-start relative w-full">
            <div
              className=" flex flex-col h-full border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start  justify-start w-full"
              style={{ borderColor: errors.companyAddress ? 'red' : '#AAA6C3' }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-xs font-semibold"
              >
                Address (street,number,city,state,country)
              </label>
              <textarea
                type="text"
                id="username"
                placeholder="Exemple (123 Main Street, Springfield,Virginia, United States)"
                className="w-full bg-white border-none h-full outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none  text-sm"
                autoComplete="off"
                defaultValue={formData.address}
                {...register('companyAddress')}
              />
            </div>
            {errors.companyAddress && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute text-xs -bottom-[1rem]"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.companyAddress?.message}
              </p>
            )}
          </div>
          <div className="col-span-1 flex flex-col items-center  justify-center relative w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start  justify-end w-full"
              style={{
                borderColor: errors.companyPostCode ? 'red' : '#AAA6C3',
              }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-xs font-semibold"
              >
                post code
              </label>
              <input
                type="text"
                id="username"
                placeholder="post code"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue={formData.postcode}
                {...register('companyPostCode', {
                  pattern: {
                    value:
                      /^\d{4}-?\d{4}?$|^([A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$|^\d{4}$/,
                    message: 'Enter a valid post code',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
            </div>
            {errors.companyPostCode && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute text-xs -bottom-[1rem]"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.companyPostCode?.message}
              </p>
            )}
          </div>
          <div className="col-span-1  flex flex-col items-center  justify-center relative w-full">
            <div
              className=" flex flex-col border-[#AAA6C3] border-1 rounded-lg p-2 bg-white items-start  justify-end w-full"
              style={{ borderColor: errors.activitySector ? 'red' : '#AAA6C3' }}
            >
              <label
                htmlFor="shipping_transaction"
                className="text-xs font-semibold"
              >
                Activity sector
              </label>
              <select
                type="text"
                id="username"
                className="w-full bg-white border-none outline-none text-[#AAA6C3] focus:outline-none focus:border-none hover:outline-none hover:border-none text-sm"
                autoComplete="off"
                defaultValue="Select an activity field"
                {...register('activitySector', {
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              >
                {formData.activitySector === '' ? (
                  <option value="">Select an activity field</option>
                ) : (
                  <option value={formData.activitySector}>
                    {formData.activitySector}
                  </option>
                )}

                <option className="Information Technology and Software">
                  Information Technology and Software
                </option>
                <option value="Finance and Banking">Finance and Banking</option>
                <option value="Healthcare and Pharmaceuticals">
                  Healthcare and Pharmaceuticals
                </option>
                <option value="Retail and Consumer Goods">
                  Retail and Consumer Goods
                </option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Energy and Utilities">
                  Energy and Utilities
                </option>
                <option value="Real Estate and Construction">
                  Real Estate and Construction
                </option>
                <option value="Consulting and Professional Services">
                  Consulting and Professional Services
                </option>
                <option value="Agriculture and Farming">
                  Agriculture and Farming
                </option>
                <option value="Travel and Tourism">Travel and Tourism</option>
                <option value="Environmental Services">
                  Environmental Services
                </option>
                <option value="Gaming and Entertainment">
                  Gaming and Entertainment
                </option>
                <option value="Government and Public Services:">
                  Government and Public Services:
                </option>
              </select>
            </div>
            {errors.activitySector && (
              <p
                className="text-[#EC1577] w-full self-center text-center absolute text-xs -bottom-[1rem]"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errors.activitySector?.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center space-y-5 pl-10 pr-10 justify-center w-full">
          <button
            type="submit"
            className="rounded-xl h-12 w-full  p-3 bg-[#EC1577] text-[#FFF] cursor-pointer hover:outline-0 hover:scale-105 transition duration-150"
          >
            Next
          </button>
          <button
            type="button"
            className="border-2 border-[#EC1577] rounded-xl text-[#EC1577] p-3 cursor-pointer h-12 w-full text-center hover:outline-0 hover:scale-105 transition duration-150"
            onClick={() => {
              setCurrentTab('1');
            }}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

UserCompany.propTypes = {
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
};

export default UserCompany;
