import React, { useEffect, useState } from 'react';

import 'react-quill/dist/quill.snow.css';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import PropTypes from 'prop-types';
import CategoryBox from '../../components/CategoryBox';
import ImageBox from '../../components/ImageBox';
import PriceBox from '../../components/PriceBox';
import RichTextEditor from '../../components/RichTextEditor';

export default function ProductEssential({
  formData,
  setFormData,
  setVariations,
  shopType,
  type,
  variations,
  handelFormDataChange,
}) {
  const [items, setItems] = useState([]);
  const [singleProductPack, setSingleProductPack] = useState([]);
  const [declinations, setDeclinations] = useState('');

  const handleFormData = (event, name = '') => {
    event.preventDefault();
    const temp = { ...formData };
    if (name === 'categories') {
      setFormData({
        ...formData,
        categories: event,
      });
    } else {
      temp[name] = event.target.value;
      setFormData(temp);
    }
  };

  const handleSearchProductData = (e) => {
    setFormData({
      ...formData,
      searchProducts: e.itemData.ProductLangs[0].name,
    });
  };

  const handleProductVariationType = (event) => {
    const newProductVariationType = event.target.value;
    setVariations(newProductVariationType);
  };

  // product packs
  const handleSingleProductPack = () => {
    setSingleProductPack([
      ...singleProductPack,
      {
        name: formData.searchProducts,
        quantity: formData.packSingleProductQuantity,
      },
    ]);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-8">
        <div className="w-full">
          {type === 'Standard product' ? (
            <div className="flex sm:flex-row flex-col mb-5 justify-between gap-8">
              <div className="mt-3 w-full">
                <label
                  htmlFor="Declinations"
                  className="block text-shifti-blue text-lg font-medium mr-2 mb-2"
                >
                  Product with variations :
                </label>
                <select
                  value={variations}
                  className="cursor-pointer bg-shifti-light-grey-bg border border-transparent text-gray-900 text-sm rounded-xl block h-10 p-2 shadow-lg w-full"
                  onChange={handleProductVariationType}
                  name="declinations"
                >
                  <option value="simple">Simple product</option>
                  <option value="Product with variations">
                    Product with variations
                  </option>
                </select>
              </div>

              <div className="mt-3 w-full">
                <label
                  htmlFor="Reference"
                  className="block text-shifti-blue text-lg font-medium mr-2 mb-2"
                >
                  Reference :
                </label>
                <input
                  className="bg-transparent border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-xl block h-10 p-2 shadow-lg w-full"
                  id="Reference"
                  name="reference"
                  type="text"
                  placeholder="Reference"
                  value={formData.reference}
                  onChange={(e) => handelFormDataChange(e, 'reference')}
                />
              </div>
              {declinations !== 'Variable product' && (
                <div className="mt-3 w-full">
                  <label
                    htmlFor="Quantity"
                    className="block text-shifti-blue text-lg font-medium mr-2 mb-2"
                  >
                    Quantity :
                  </label>
                  <input
                    className="bg-transparent border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-xl block h-10 p-2 shadow-lg w-full"
                    id="Quantity"
                    type="text"
                    placeholder="0"
                    name="quantity"
                    value={formData.quantity}
                    onChange={(e) => handelFormDataChange(e, 'quantity')}
                  />
                </div>
              )}
            </div>
          ) : (
            type === 'product package' && (
              <div className="flex flex-col">
                <div className="flex flex-row flex-wrap">
                  {singleProductPack.map((product) => (
                    <div className="border-2 border-[#EC1577] rounded-3xl w-[28%] flex flex-col p-2 m-2">
                      <p>{product.name}</p>
                      <p className="self-end">x{product.quantity}</p>
                    </div>
                  ))}
                </div>
                <div className="flex sm:flex-row flex-col space-x-5">
                  <div className="flex flex-col sm:w-[45.5%] mt-3 -space-y-1">
                    <label htmlFor="searchProduct" className="essentialLabel">
                      Add products to your pack :
                    </label>
                    <div className="p-2 bg-[#E1DFEE] border-[#AAA6C3] border-2 h-[2.9rem] text-gray-900 text-sm rounded-[0.8rem] flex flex-col">
                      <AutoCompleteComponent
                        dataSource={items}
                        fields={{
                          value: 'ProductLangs.0.name',
                        }}
                        name="searchProducts"
                        value={formData.searchProducts}
                        select={handleSearchProductData}
                        cssClass="self-center"
                        placeholder="Search Product"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col sm:w-[25.5%] self-end">
                    <div className="currency-input-container border-2 border-[#AAA6C3]   text-[1.2rem] rounded-3xl md:w-[60%] flex flex-row items-center justify-between pr-2 self-center">
                      <p className="w-[25%] text-center flex h-[2.7rem] bg-[#E1DFEE] rounded-l-3xl  ">
                        <p className="self-center w-[90%] text-[#AAA6C3] text-[1.5rem]">
                          x
                        </p>
                        <p className="text-[3.1rem] w-[10%] text-[#AAA6C3] self-center">
                          |
                        </p>
                      </p>
                      <input
                        type="text"
                        name="packSingleProductQuantity"
                        id="packSingleProductQuantity"
                        className="packProductInput text-[0.9rem]"
                        value={formData.packSingleProductQuantity}
                        onChange={handleFormData}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col sm:w-[32.5%] md:self-end md:mb-2 md:ml-5">
                    <button
                      className="pl-[0.7rem] addButton w-[10rem] flex flex-start items-center"
                      type="button"
                      onClick={handleSingleProductPack}
                    >
                      <span
                        className="p-2"
                        style={{
                          background: '#ffffff',
                          color: '#18114397',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          padding: '4px 9px',
                          fontSize: '1,20vw',
                          marginLeft: '-2%',
                          marginRight: '2%',
                        }}
                      >
                        +
                      </span>
                      Add product
                    </button>
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col">
                  <div className="flex flex-col sm:w-[32.5%] mt-3">
                    <label htmlFor="Reference" className="essentialLabel">
                      Reference :
                    </label>
                    <input
                      className=" essentialInput"
                      id="Reference"
                      name="reference"
                      type="text"
                      placeholder="Reference"
                      value={formData.reference}
                      onChange={handleFormData}
                    />
                  </div>
                  {declinations !== 'Variable product' && (
                    <div className="flex flex-col sm:w-[32.5%] mt-3">
                      <label htmlFor="Quantity" className="essentialLabel">
                        Quantity :
                      </label>
                      <input
                        className="essentialInput"
                        id="Quantity"
                        type="text"
                        placeholder="0"
                        name="quantity"
                        value={formData.quantity}
                        onChange={handleFormData}
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          )}

          <div className="flex flex-col sm:flex-col flex-grow  rounded-3xl flex flex-col mb-5 mt-2">
            <div className="flex flex-col">
              <label htmlFor="Price" className="essentialLabel">
                Price :
              </label>
              <PriceBox />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-items-start space-y-1  w-[30%]">
          <label
            htmlFor="images"
            className="flex flex-col justify-center  essentialLabel "
          >
            <div className="mb-2">Images :</div>
            <ImageBox id="images" />
          </label>
        </div>
      </div>
      <div className=" rounded-3xl flex flex-col mb-5 mt-2">
        <label htmlFor="Categories" className="shifti-blue font-medium mb-2">
          Categories
          <span className="text-shifti-pink font-medium ml-2">
            Please indicate the category of your product for each site you wish
            to use.
          </span>
        </label>
        <CategoryBox
          className="md:p-5 bg-white rounded-3xl w-full mb-2"
          onChange={(e) => handleFormData(e, 'categories')}
        />
      </div>

      <div className="rounded-3xl mb-5 mt-2 w-full">
        <RichTextEditor
          name="short_description"
          label="Short description"
          placeholder="Fill in a striking short description of the product (displayed on product page and product list as abstract for customers and search engines). For detailed informations use the 'description tab.'"
        />
      </div>
      <div className="rounded-3xl mb-5 mt-2 w-full">
        <RichTextEditor name="description" label="Description" />
      </div>
    </>
  );
}

ProductEssential.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  setVariations: PropTypes.func.isRequired,
  shopType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  variations: PropTypes.number.isRequired,
  handelFormDataChange: PropTypes.func.isRequired,
};
