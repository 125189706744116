import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import FeatureContext from '../featureContext/FeatureContext';
import Select from '../../components/Select';
import AssociatedStores from '../../components/AssociatedStores';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const FeatureValue = () => {
  const token = useSelector(selectCurrentToken);
  const { contextValue, setContextValue } = useContext(FeatureContext);
  const [stores, setStores] = useState([]);
  const [features, setFeatures] = useState([]);
  const [isColor, setIsColor] = useState(false);
  const [formData, setFormData] = useState({
    feature: null,
    value: null,
    shops: [],
    validation: {
      error: [true, true, true],
      errorMsg: ['required', 'required', 'required'],
    },
  });
  const [formDataError, setformDataError] = useState([true, true, true]);

  const getStores = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-group-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };

    await api
      .request(config)
      .then((response) => {
        setStores(response.data);
      })
      .catch((err) => {});
  };

  const getFeatures = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/features/get-all-features`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        const data = response.data.map((item) => ({
          id: item.id,
          name: item.FeatureLangs[0].name,
        }));
        setFeatures(data);
      })
      .catch((err) => {});
  };

  const handelFormDataChange = (value, key, index) => {
    const aux = { ...formData };
    if (key === 'feature') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
        aux[key] = value;
      } else {
        const arr = value.split('-');
        if (arr[1] === 'color') setIsColor(true);
        else setIsColor(false);
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
        const [firstItem] = arr;
        aux[key] = firstItem;
      }
    }
    if (key === 'value') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      aux[key] = value;
    }

    if (key === 'shops') {
      if (value.checked === false && aux.shops.length < 2) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      const itemIndex = aux[key].indexOf(value.value);
      if (value.checked === true && itemIndex === -1) {
        aux[key].push(value.value);
      } else if (itemIndex > -1) {
        aux[key].splice(itemIndex, 1);
      }
    }
    setFormData(aux);
  };

  const handelSubmit = async (e, redirect = false) => {
    e.preventDefault();
    const decoded = jwtDecode(token);
    const ERROR = [...formData.validation.error];
    const ERROR_MSG = [...formData.validation.errorMsg];
    setformDataError(ERROR_MSG);
    if (ERROR.includes(true)) return;
    const idToast = toast.loading('Please wait...');
    const data = JSON.stringify({
      value: formData.value,
      feature: formData.feature,
      shops: formData.shops,
    });
    const config = {
      method: 'post',
      url: `${apiURL}/features/add-feature-value`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'Feature value created succesfully !',
          type: 'success',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        });
        if (redirect) {
          setTimeout(() => {
            setContextValue({
              title: 'Features',
              component: 'Feature',
              id: null,
            });
          }, 1500);
        } else {
          const aux = { ...formData };
          aux.feature = null;
          aux.value = null;
          aux.shops = [];
          aux.validation = {
            error: [true, true, true],
            errorMsg: ['required', 'required', 'required'],
          };
          setFormData(aux);
          document.getElementById('featureForm').reset();
        }
      })
      .catch(() =>
        toast.update(idToast, {
          render: 'Failed to create feature value !',
          type: 'error',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        })
      );
  };

  useEffect(() => {
    getStores();
    getFeatures();
  }, []);

  return (
    <form id="featureForm">
      <div className="flex flex-col gap-8 mt-8">
        <div className="w-1/3">
          <label htmlFor="AttributeGroup" className="groupAttributeLabel">
            Feature
          </label>
          <Select
            icon="icon"
            items={features}
            name="type"
            onChange={(e) => handelFormDataChange(e.target.value, 'feature', 0)}
          />
          <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
        </div>
        <div className="flex flex-col gap-8 ml-32">
          <div>
            <label htmlFor="Value" className="groupAttributeLabel">
              Value
            </label>
            <input
              id="Value"
              name="value"
              placeholder="Value"
              type="text"
              className="groupAttributeInput w-1/3"
              onChange={(e) => handelFormDataChange(e.target.value, 'value', 1)}
            />
            <div style={{ color: '#FF9494' }}>{formDataError[1]}</div>
          </div>
          <div>
            <label
              htmlFor="associatedStores"
              className="groupAttributeLabel my-4"
            >
              Associated Stores
            </label>
            <AssociatedStores
              name="shops"
              stores={stores}
              disallowed="woocommerce"
              onChange={(e) => handelFormDataChange(e.target, 'shops', 2)}
            />
            <div style={{ color: '#FF9494' }}>{formDataError[2]}</div>
          </div>
        </div>
        <div
          className="help-button mt-3"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Link
            style={{
              background: '#EC1577',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '25px',
              padding: '7.5px 40px 0px',
              fontWeight: 'normal',
              fontSize: '1,04vw',
              height: '37px',
              marginRight: '16px',
            }}
            onClick={(e) => handelSubmit(e)}
          >
            Save and add a new value
          </Link>
          <Link
            style={{
              background: '#EC1577',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '25px',
              padding: '7.5px 40px 0px',
              fontWeight: 'normal',
              fontSize: '1,04vw',
              height: '37px',
            }}
            onClick={(e) => handelSubmit(e, true)}
          >
            Save
          </Link>
        </div>
      </div>
    </form>
  );
};

export default FeatureValue;
