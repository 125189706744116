import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import { selectCurrentToken } from '../../features/auth/authSlice';
import api from '../../utils/apiService';

const apiURL = process.env.REACT_APP_API_URL;

function RenewPlanPaymentPage() {
  const [loaded, setLoaded] = useState(false);
  const [isStatusSuccess, setIsStatusSuccess] = useState(null);
  const [errors, setErrors] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [pdfResponse, setPDFResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector(selectCurrentToken);

  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentToken = searchParams.get('payment_token');

  const getPaymentResponse = async (currentPlanIid) => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `https://sandbox.paymee.tn/api/v2/payments/${paymentToken}/check`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token 88d14b79b11188f130a06bea8c2003e9bdfd200a',
      },
    };

    await api.request(config).then(async (responseAPI) => {
      setIsStatusSuccess(responseAPI.data.data.payment_status);
      setPaymentData(responseAPI.data.data);
      if (responseAPI.status !== 200) {
        setIsStatusSuccess(false);
        setErrors(responseAPI.response);
      } else {
        const planData = {
          user_id: decoded.UserInfo.id,
          payment_status: responseAPI.status,
          transaction_id: responseAPI.data.data.transaction_id,
        };
        const config2 = {
          method: 'patch',
          url: `${apiURL}/plans/renewPlan/${currentPlanIid}`,
          headers: {
            'Content-Type': 'application/json',
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
          data: planData,
        };
        api
          .request(config2)
          .then((responsePlan) => {
            setPaymentId(responsePlan.data.payment_id);

            const invoiceConfig = {
              method: 'patch',
              url: `${apiURL}/payment/generateInvoicePdf/${responsePlan.data.payment_id}`,
              headers: {
                'Content-Type': 'application/json',
                'App-Tenant': decoded.UserInfo.tenantId,
                Authorization: `Bearer ${token}`,
              },
            };

            api
              .request(invoiceConfig)
              .then((responsePDF) => {
                setPDFResponse(responsePDF.status);
              })
              .catch((err) => {
                setIsStatusSuccess(false);
                setErrors(err.response);
              });
          })
          .catch((err) => {
            setIsStatusSuccess(false);
            setErrors(err.response);
          });
      }
    });
  };

  const getCurrentPlan = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/plans/currentPlan`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getCurrentPlan().then((currentPlanResponse) => {
      getPaymentResponse(currentPlanResponse.id).then(() => {
        setLoading(false);
      });
    });

    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  const downloadPdf = async () => {
    const decoded = jwtDecode(token);
    try {
      // Make a GET request to the API to fetch the PDF file
      const config = {
        method: 'get',
        responseType: 'blob',
        url: `${apiURL}/payment/invoice/${paymentId}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      await api.request(config).then(async (response) => {
        if (response.status === 200) {
          const pdfBlob = new Blob([response.data], {
            type: 'application/pdf',
          });

          // Create a blob URL for the PDF
          const pdfBlobUrl = URL.createObjectURL(pdfBlob);

          // Create a link element to trigger the download
          const downloadLink = document.createElement('a');
          downloadLink.href = pdfBlobUrl;
          downloadLink.download = 'invoice.pdf'; // Set the filename for the download
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Clean up the blob URL
          URL.revokeObjectURL(pdfBlobUrl);
        } else {
          console.error('Server response was not OK.');
        }
      });
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="w-full flex justify-center items-center desktop:mt-[10%] mt-[5%]">
        <div className="w-[80%] flex flex-col justify-center items-center space-y-12">
          <div className=" bg-white p-4 w-[30%]  space-y-10">
            <p className="text-[#181143] text-center text-xl">
              {isStatusSuccess === true ? (
                'Thank you for your purchase'
              ) : (
                <p className="text-[#181143] text-center text-xl">
                  error occured while upgrading your plan!
                </p>
              )}
            </p>
            <div className="flex justify-center">
              <div
                className={`relative w-[8rem] h-[8rem] bg-[#32C770] rounded-full
                ${loaded ? 'animate-grow' : 'opacity-0'}`}
                style={{
                  backgroundColor:
                    isStatusSuccess === true ? '#32C770' : '#EC1577',
                }}
              >
                {isStatusSuccess === true ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={`w-[7rem] h-[7rem] left-2 top-2 text-white absolute pointer-events-none transition-transform ${
                      loaded ? 'animate-grow' : 'hidden'
                    }`}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className={`w-[7rem] h-[7rem] left-2 top-2 text-white absolute pointer-events-none transition-transform ${
                      loaded ? 'animate-grow' : 'hidden'
                    }`}
                  />
                )}
              </div>
            </div>
            <div />
          </div>
          <div className="text-3xl font-semibold text-[#181143]">
            {isStatusSuccess === true ? (
              paymentData.note
            ) : (
              <p>
                <span>error : {errors?.data.message}</span>
              </p>
            )}

            {paymentData.payment_status === 200 && 'confirmed'}
          </div>
          {isStatusSuccess === true && (
            <div className=" flex justify-center relative w-[16rem]">
              <button
                type="button"
                className="bg-[#181143] p-2 text-white rounded-3xl w-[16rem] "
                onClick={() => downloadPdf()}
                disabled={!pdfResponse}
              >
                <span className=""> Download invoice PDF</span>
              </button>
              {pdfResponse === null && (
                <div className="absolute w-5 h-5 left-4 top-2  border-[#fff] ">
                  <div className="animate-spin h-5 w-5  border-4  text-white border-r-4 rounded-full border-white  border-r-[#EC1577] " />
                </div>
              )}
            </div>
          )}

          <div className="w-full flex justify-center">
            <button
              type="button"
              className="bg-[#EC1577] p-2 w-full text-white rounded-3xl"
              onClick={() => navigate('/')}
            >
              Home page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenewPlanPaymentPage;
