import React from 'react';
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  ColumnSeries,
  DateTime,
  Legend,
  Category,
  Tooltip,
  StackingColumnSeries,
  StackingBarSeries,
} from '@syncfusion/ej2-react-charts';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';

const ColumnChart = ({
  data,
  id,
  height,
  customTooltip = false,
  background,
  legendSettings,
  type,
}) => {
  let tooltip;
  // eslint-disable-next-line no-template-curly-in-string
  if (customTooltip) tooltip = { enable: true, format: '${point.tooltip}' };
  else tooltip = { enable: true };

  return (
    <div className="relative">
      {!data || !data.length ? (
        <Spinner className="absolute left-0 right-0 top-0 bottom-0 m-auto w-20 h-20" />
      ) : null}
      <ChartComponent
        id={id}
        height={height}
        primaryXAxis={{
          valueType: 'Category',
        }}
        tooltip={tooltip}
        background={background}
        legendSettings={legendSettings}
      >
        <Inject
          services={[
            ColumnSeries,
            StackingColumnSeries,
            StackingBarSeries,
            DateTime,
            Legend,
            Tooltip,
            Category,
          ]}
        />
        <SeriesCollectionDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {data.map((item, index) => (
            <SeriesDirective
              key={index}
              marker={{
                borderRadius: '10px',
                borderRadiusTopLeft: '10px',
                borderRadiusTopRight: '10px',
              }}
              {...item}
              type={type}
            />
          ))}
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
};

ColumnChart.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  customTooltip: PropTypes.bool,
  background: PropTypes.string.isRequired,
  legendSettings: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};
export default ColumnChart;
