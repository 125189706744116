import React from 'react';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import api from '../utils/apiService';
import { selectCurrentToken } from '../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const DeletRole = async (id) => {
  const idToast = toast.loading('Please wait...');
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const config = {
    method: 'delete',
    url: `${apiURL}/roles/deleteRole/${id}`,
    headers: {
      'App-Tenant': decoded.UserInfo.tenantId,
      Authorization: `Bearer ${token}`,
    },
  };

  await api
    .request(config)
    .then((response) => {
      toast.update(idToast, {
        render: 'Deleted !',
        type: response.data === 'success',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        isLoading: false,
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1500);
    })
    .catch((error) => {
      toast.update(id, {
        render: "Couldn't delete !",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        isLoading: false,
      });
    });
};

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const actions = (props) => (
  <div>
    <button
      type="button"
      style={{ color: '#000' }}
      className="text-[#AAA6C3] py-1 px-2 capitalize rounded-2xl text-md"
      onClick={() => {
        window.location.href = `/edit-role/?id=${props.id}`;
      }}
    >
      <FontAwesomeIcon icon={faPencil} className="w-6 h-6 text-[#AAA6C3]" />
    </button>
    <button
      type="button"
      style={{ color: '#000' }}
      className="text-white py-1 px-2 capitalize rounded-2xl text-md"
      onClick={() => DeletRole(props.id)}
    >
      <FontAwesomeIcon icon={faTrash} className="w-6 h-6 text-[#AAA6C3]" />
    </button>
  </div>
);

export const RolesGrid = [
  {
    field: 'id',
    headerText: 'ID',
    width: '80',
    textAlign: 'Start',
  },
  {
    field: 'name',
    headerText: 'Role',
    width: '120',
    textAlign: 'Start',
  },
  {
    field: '',
    headerText: 'Permissions',
    width: '120',
    textAlign: 'Start',
  },

  {
    field: 'action',
    headerText: 'Action',
    template: actions,
    width: '120',
    textAlign: 'End',
  },
];
