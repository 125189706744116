import { React, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../utils/apiService';
import { selectCurrentToken } from '../../features/auth/authSlice';
import Loader from '../../components/Loader';

const apiURL = process.env.REACT_APP_API_URL;

function CreateShopSummary({
  setCurrentTab,
  formData,
  setFormData,
  shops,
  employees,
  subscriptionTime,
  extraEmployees,
  extraShops,
}) {
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const [confirmPlan, setConfirmPlan] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const getPlan = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/plans/${formData.plan_id}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setPlan(response.data);
        setLoading(false);
      })
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getPlan();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const totalPrice = () => {
    let total = 0;
    decoded.UserInfo.country === 'Tunisia' &&
      (subscriptionTime === 'monthly'
        ? (total = plan.price_dt_monthly + extraEmployees * 2 + extraShops * 5)
        : (total = plan.price_dt_yearly + extraEmployees * 2 + extraShops * 5));

    decoded.UserInfo.country === 'France' &&
      (subscriptionTime === 'monthly'
        ? (total =
            plan.price_euro_monthly + extraEmployees * 2 + extraShops * 5)
        : (total =
            plan.price_euro_yearly + extraEmployees * 2 + extraShops * 5));

    decoded.UserInfo.country !== 'Tunisia' &&
      decoded.UserInfo.country !== 'France' &&
      (subscriptionTime === 'monthly'
        ? (total =
            plan.price_dollar_monthly + extraEmployees * 2 + extraShops * 5)
        : (total =
            plan.price_dollar_yearly + extraEmployees * 2 + extraShops * 5));

    return total;
  };

  const totalPriceTTC = () => {
    let total = 0;
    decoded.UserInfo.country === 'Tunisia' &&
      (subscriptionTime === 'monthly'
        ? (total =
            plan.price_dt_monthly + extraEmployees * 2 + extraShops * 5 * 1.19)
        : (total =
            plan.price_dt_yearly + extraEmployees * 2 + extraShops * 5 * 1.19));

    decoded.UserInfo.country === 'France' &&
      (subscriptionTime === 'monthly'
        ? (total =
            plan.price_euro_monthly +
            extraEmployees * 2 +
            extraShops * 5 * 1.19)
        : (total =
            plan.price_euro_yearly +
            extraEmployees * 2 +
            extraShops * 5 * 1.19));

    decoded.UserInfo.country !== 'Tunisia' &&
      decoded.UserInfo.country !== 'France' &&
      (subscriptionTime === 'monthly'
        ? (total =
            plan.price_dollar_monthly +
            extraEmployees * 2 +
            extraShops * 5 * 1.19)
        : (total =
            plan.price_dollar_yearly +
            extraEmployees * 2 +
            extraShops * 5 * 1.19));

    return total;
  };

  function generateUniqueId() {
    const now = new Date();
    const day = now.getDate();
    const year = now.getFullYear();
    const minute = now.getMinutes();
    const second = now.getSeconds();
    const millisecond = now.getMilliseconds();

    const generatedOrderId = `${year}${day}${minute}${second}${millisecond}`;

    return generatedOrderId;
  }

  const hundleNextStep = (props) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      plan_id: props,
    }));

    setCurrentTab('4');
  };

  const handleSubmit = async () => {
    try {
      setLoadingSubmit(true);
      const idToast = toast.loading('Please wait...');

      const uniqueId = generateUniqueId();

      const paymentData = {
        amount: totalPrice(),
        note: `Order n°#${uniqueId}`,
        first_name: decoded.UserInfo.first_name,
        last_name: decoded.UserInfo.last_name,
        email: decoded.UserInfo.email,
        phone: decoded.UserInfo.phone,
        order_id: uniqueId,
        webhook_url: 'https://workplace.shifti.co/create-shop',
        return_url: 'https://workplace.shifti.co/paymentResult',
        cancel_url: 'https://workplace.shifti.co/paymentResult',
      };

      const config = {
        method: 'post',
        url: 'https://sandbox.paymee.tn/api/v2/payments/create',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token 88d14b79b11188f130a06bea8c2003e9bdfd200a',
        },
        data: paymentData,
      };

      await api.request(config).then(async (responseAPI) => {
        const data = JSON.stringify({
          formData,
          shops,
          employees,
          token: responseAPI.data.data.token,
        });

        const config1 = {
          method: 'post',
          url: `${apiURL}/plans`,
          headers: {
            'Content-Type': 'application/json',
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
          data,
        };

        await api
          .request(config1)
          .then(async (response) => {
            if (response.status === 200) {
              toast.update(idToast, {
                render: 'shipping updated !',
                type: response.data === 'success',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                isLoading: false,
              });

              const config2 = {
                method: 'patch',
                url: `${apiURL}/plans/token/${response.data.id}`,
                headers: {
                  'Content-Type': 'application/json',
                  'App-Tenant': decoded.UserInfo.tenantId,
                  Authorization: `Bearer ${token}`,
                },
                data,
              };

              await api.request(config2).then(() => {
                window.location.href = responseAPI.data.data.payment_url;
              });
            }
          })
          .catch((error) => {
            toast.update(idToast, {
              render: 'order Status failed , select a new state !',
              type: 'failed',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              isLoading: false,
            });
          });
      });

      /// shop
    } catch (error) {
      setLoadingSubmit(false);
      alert('alert ERROR');
      console.error('An error occurred:', error);
      // Handle any unexpected errors
    }
  };

  return (
    <div className="flex flex-col justify-center items-center pr-32 pl-32  pt-10 pb-10">
      <form className="bg-[#E1DFEE]  flex flex-col justify-center items-center w-full pt-5 pb-5   space-y-6 drop-shadow-xl shadow-2xl rounded-2xl h-full">
        <div className="title flex flex-col justify-start items-start space-y-2  pl-32 w-full">
          <h1 className="text-[#181144] text-3xl font-bold w-full">
            Confirmer votre choix
          </h1>
          <h2 className="text-[#5E6366] text-2xl font-semibold w-full">
            Nom du plan : {plan.name} ({subscriptionTime})
          </h2>
          <h2 className="text-[#5E6366] text-2xl font-semibold w-full">
            Price :{' '}
            <span className="font-semibold text-2xl text-[#EC1577]">
              {totalPrice()} {decoded.UserInfo.country === 'Tunisia' && 'dt'}
              {decoded.UserInfo.country === 'France' && '€'}
              {decoded.UserInfo.country !== 'Tunisia' &&
                decoded.UserInfo.country !== 'France' &&
                '$'}
            </span>
          </h2>
        </div>
        <div className="flex flex-col w-full pr-10 pl-32 space-y-2">
          <h2 className="text-[#5E6366] text-2xl mb-2 space-y-5 font-semibold">
            Plan choisi et avantages:
          </h2>
          <div className="flex flex-col">
            <div className="flex flex-col w-[90%] space-y-1">
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Plan</p>{' '}
                  <div className=" text-sm font-semibold">:</div>{' '}
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{plan.name}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm">Prix de l’abonnement</p>{' '}
                  <div className=" text-sm font-semibold">:</div>{' '}
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">
                    {decoded.UserInfo.country === 'Tunisia' &&
                      (subscriptionTime === 'monthly'
                        ? plan.price_dt_monthly
                        : plan.price_dt_yearly)}
                    {decoded.UserInfo.country === 'France' &&
                      (subscriptionTime === 'monthly'
                        ? plan.price_euro_monthly
                        : plan.price_euro_yearly)}
                    {decoded.UserInfo.country !== 'Tunisia' &&
                      decoded.UserInfo.country !== 'France' &&
                      (subscriptionTime === 'monthly'
                        ? plan.price_dollar_monthly
                        : plan.price_dollar_yearly)}{' '}
                    {decoded.UserInfo.country === 'Tunisia' && 'dt'}
                    {decoded.UserInfo.country === 'France' && '€'}
                    {decoded.UserInfo.country !== 'Tunisia' &&
                      decoded.UserInfo.country !== 'France' &&
                      '$'}
                  </p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Shops</p>{' '}
                  <div className=" text-sm font-semibold">:</div>{' '}
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{plan.limit_shops}</p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Employees</p>{' '}
                  <div className=" text-sm font-semibold">:</div>{' '}
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{plan.limit_employees} </p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Products</p>{' '}
                  <div className=" text-sm font-semibold">:</div>{' '}
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{plan.limit_products} </p>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex justify-between w-[20%] ">
                  <p className="text-sm"> Storage</p>{' '}
                  <div className=" text-sm font-semibold">:</div>{' '}
                </div>
                <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                  <p className="w-full">{plan.storage} MB</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full pr-10 pl-32 space-y-2">
          <h2 className="text-[#5E6366] text-2xl mb-2 font-semibold">
            Employee(s)
          </h2>
          <div className="flex flex-col">
            <div className="flex flex-col w-[90%] space-y-1">
              {employees.map((employee, i) => (
                <div className="flex justify-between" key={i}>
                  <div className="flex justify-between w-[20%] ">
                    <p className="text-sm"> employee {i + 1} </p>{' '}
                    <div className=" text-sm font-semibold">:</div>{' '}
                  </div>
                  <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                    <p className="w-full">
                      {employee.first_name} {employee.last_name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full pr-10 pl-32 space-y-2">
          <h2 className="text-[#5E6366] text-2xl mb-2 font-semibold">
            Shop(s)
          </h2>
          <div className="flex flex-col">
            <div className="flex flex-col w-[90%] space-y-1">
              {shops.map((shop, i) => (
                <div className="flex justify-between ">
                  <div className="flex justify-between w-[20%] ">
                    <p className="text-sm"> shop {i + 1}</p>{' '}
                    <div className=" text-sm font-semibold">:</div>{' '}
                  </div>
                  <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                    <p className="w-full">{shop.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full pr-10 pl-32 space-y-2">
          <h2 className="text-[#5E6366] text-2xl mb-2 font-semibold">
            Extra options
          </h2>
          <div className="flex flex-col">
            <div className="flex flex-col w-[90%] space-y-1">
              {extraEmployees !== 0 && (
                <div className="flex justify-between ">
                  <div className="flex justify-between w-[20%] ">
                    <p className="text-sm"> Extra employees </p>{' '}
                    <div className=" text-sm font-semibold">:</div>{' '}
                  </div>
                  <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                    <p className="w-full">
                      {extraEmployees} for{' '}
                      <span className="font-semibold text-[#EC1577]">
                        {extraEmployees * 2}{' '}
                        {decoded.UserInfo.country === 'Tunisia' && 'dt'}
                        {decoded.UserInfo.country === 'France' && '€'}
                        {decoded.UserInfo.country !== 'Tunisia' &&
                          decoded.UserInfo.country !== 'France' &&
                          '$'}
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {extraShops !== 0 && (
                <div className="flex justify-between ">
                  <div className="flex justify-between w-[20%] ">
                    <p className="text-sm"> Extra shops </p>{' '}
                    <div className=" text-sm font-semibold">:</div>{' '}
                  </div>
                  <div className="text-start font-semibold flex justify-center items-center w-[78%]">
                    <p className="w-full">
                      {extraShops} for{' '}
                      <span className="font-semibold text-[#EC1577]">
                        {extraShops * 5}{' '}
                        {decoded.UserInfo.country === 'Tunisia' && 'dt'}
                        {decoded.UserInfo.country === 'France' && '€'}
                        {decoded.UserInfo.country !== 'Tunisia' &&
                          decoded.UserInfo.country !== 'France' &&
                          '$'}
                      </span>
                    </p>
                  </div>
                </div>
              )}
              {extraShops === 0 && extraEmployees === 0 && (
                <p className="font-semibold text-sm text-[#EC1577]">
                  {' '}
                  - no extra options are added
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="flex  items-center justify-start pr-10 pl-32 w-full">
          <div className="relative flex  items-start w-[40rem]">
            <input
              className="permission-checkbox h-8 w-8 rounded-full	outline-0	appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
              type="checkbox"
              id="display"
              onChange={() => {
                confirmPlan === false
                  ? setConfirmPlan(true)
                  : setConfirmPlan(false);

                setFormData((prevFormData) => ({
                  ...prevFormData,
                  total_price_httc: totalPrice(),
                }));

                setFormData((prevFormData) => ({
                  ...prevFormData,
                  total_price_ttc: totalPriceTTC(),
                }));
              }}
            />
            <FontAwesomeIcon
              icon={faCheck}
              className="h-5 w-16  text-[#EC1577] absolute -left-[1rem] top-[0.4rem] pointer-events-none text-opacity-0 checked-1 transition scale-75"
            />
            <label
              htmlFor="display"
              className="text-[#181143] text-lg  font-semibold mt-1 ml-3"
            >
              I confirm the plan and the information.
            </label>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-5 pl-32 pr-10 justify-center w-full">
          <button
            type="button"
            className="rounded-xl h-12 w-full  p-3 bg-[#EC1577] text-[#FFF] cursor-pointer relative disabled:bg-[#AAA6C3] disabled:pointer-events-none"
            disabled={confirmPlan === false || loadingSubmit}
            onClick={() => hundleNextStep(plan.id)}
          >
            {loadingSubmit && (
              <div className="absolute w-5 h-5 left-2  border-[#fff] ">
                <div className="animate-spin h-5 w-5  border-4  text-white border-r-4 rounded-full border-white  border-r-[#EC1577] " />
              </div>
            )}
            Switch to payment {totalPrice()}{' '}
            {decoded.UserInfo.country === 'Tunisia' && 'dt'}
            {decoded.UserInfo.country === 'France' && '€'}
            {decoded.UserInfo.country !== 'Tunisia' &&
              decoded.UserInfo.country !== 'France' &&
              '$'}
          </button>
          <button
            type="button"
            className="border-2 border-[#EC1577] rounded-xl text-[#EC1577] p-3 cursor-pointer h-12 w-full text-center"
            onClick={() => setCurrentTab('2')}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
}

CreateShopSummary.propTypes = {
  setCurrentTab: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,

  shops: PropTypes.array.isRequired,

  employees: PropTypes.array.isRequired,

  subscriptionTime: PropTypes.object.isRequired,

  extraEmployees: PropTypes.object.isRequired,

  extraShops: PropTypes.object.isRequired,
};

export default CreateShopSummary;
