import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../utils/apiService';
import { selectCurrentToken } from '../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

const DeleteAddress = async (id) => {
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const idToast = toast.loading('Please wait...');
  const config = {
    method: 'delete',
    url: `${apiURL}/addresses/${id}`,
    headers: {
      'App-Tenant': decoded.UserInfo.tenantId,
      Authorization: `Bearer ${token}`,
    },
  };

  await api
    .request(config)
    .then((response) => {
      toast.update(idToast, {
        render: 'Deleted !',
        type: response.data === 'success',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        isLoading: false,
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1500);
    })
    .catch((error) => {});
};

export const Actions = ({ id, Shop }) => {
  const navigate = useNavigate();

  return (
    <div>
      <button
        type="button"
        style={{ color: '#000' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => navigate(`/edit-address/${id}`)}
      >
        <FontAwesomeIcon icon={faPencil} className="w-4 h-4 text-[#AAA6C3]" />
      </button>
      {Shop.type !== 'woocommerce' && (
        <button
          type="button"
          style={{ color: '#000' }}
          className="text-white py-1 px-2 capitalize rounded-2xl text-md"
          // text="Delete"
          onClick={() => DeleteAddress(id)}
        >
          <FontAwesomeIcon icon={faTrash} className="w-4 h-4 text-[#AAA6C3]" />
        </button>
      )}
    </div>
  );
};
Actions.propTypes = {
  id: PropTypes.number.isRequired,
  Shop: PropTypes.object.isRequired,
};

const firstName = (props) => {
  if (props.first_name) {
    return <div>{props.first_name}</div>;
  }
  return <div>N/A</div>;
};

const lastName = (props) =>
  props.last_name ? <div>{props.last_name}</div> : <div>N/A</div>;

const address1 = (props) =>
  props.address1 ? <div>{props.address1}</div> : <div>N/A</div>;

const postcode = (props) =>
  props.postcode ? <div>{props.postcode}</div> : <div>N/A</div>;

const countries = (props) =>
  props.Country ? (
    <div>{props.Country.CountryLangs[0].name}</div>
  ) : (
    <div>N/A</div>
  );

const city = (props) => (props.city ? <div>{props.city}</div> : <div>N/A</div>);

const shop = (props) =>
  props.Shop ? <div>{props.Shop.name}</div> : <div>N/A</div>;

export const cartsGrid = [
  { field: 'id', headerText: 'Id', width: '60', textAlign: 'Center' },

  {
    template: firstName,
    headerText: 'First name',
    width: '130',
    textAlign: 'Start',
  },
  {
    template: lastName,
    headerText: 'Last name',
    width: '130',
    textAlign: 'Start',
  },
  {
    template: address1,
    headerText: 'Address',
    width: '130',
    textAlign: 'Center',
  },
  {
    template: postcode,
    headerText: 'Zip/postal code',
    width: '130',
    textAlign: 'Center',
  },

  {
    headerText: 'Country',
    template: countries,
    width: '130',
    textAlign: 'Center',
  },
  {
    headerText: 'City',
    template: city,
    width: '100',
    textAlign: 'Center',
  },
  {
    headerText: 'Shop',
    template: shop,
    width: '100',
    textAlign: 'Center',
  },
  {
    headerText: 'Actions',
    template: Actions,
    width: '130',
    textAlign: 'Center',
  },
];
