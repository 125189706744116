import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import api from '../utils/apiService';
import { selectCurrentToken } from '../features/auth/authSlice';

const InputUpdatePassword = () => {
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const [showInputs, setShowInputs] = useState(false);
  const [hidebutton, setShowbutton] = useState(true);
  const [user, setUser] = useState(decoded.UserInfo);
  const [pwd, setPwd] = useState({
    newpass: '',
    oldpass: '',
  });
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;

  const handleClick = () => {
    setShowInputs(true);
    setShowbutton(false);
  };
  const updatepwd = (event) => {
    event.preventDefault();
    const config = {
      url: `${apiURL}/user/updateUserPass/${user.id}`,
      method: 'patch',
      data: pwd,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .request(config)
      .then(() => {
        navigate('/profile');
      })
      .catch((err) => navigate('/profile'));
  };
  const handlePwdChange = (e) => {
    const temp = { ...pwd };
    if (e.target.name === 'oldpass') temp.oldpass = e.target.value;
    else temp.newpass = e.target.value;
    setPwd(temp);
  };

  return (
    <div className="mt-4">
      {hidebutton && (
        <button
          type="button"
          onClick={handleClick}
          className="p-2"
          style={{
            background: 'grey',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '25px',
            padding: '7.1px 30px 0px',
            fontWeight: 'normal',
            fontSize: '1,04vw',
            height: '37px',
          }}
        >
          Change Password
        </button>
      )}
      {showInputs && (
        <div className="flex">
          <form>
            <div className="w-1/3">
              <input
                name="oldpass"
                className="bg-white mr-4 border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block dark:placeholder-gray-400 h-[2.8rem] p-2"
                type="password"
                placeholder="Old password"
                onChange={(e) => handlePwdChange(e)}
              />
            </div>
            <div className="w-1/3">
              <input
                name="newpass"
                className="bg-white border-2 border-[#AAA6C3] text-gray-900 text-sm rounded-[0.8rem] block dark:placeholder-gray-400 h-[2.8rem] p-2"
                type="password"
                placeholder="New password"
                onChange={(e) => handlePwdChange(e)}
              />
            </div>
            <div className="w-1/3">
              <button
                style={{
                  background: '#EC1577',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: '25px',
                  padding: '7.1px 30px 0px',
                  fontWeight: 'normal',
                  fontSize: '1,04vw',
                  height: '37px',
                }}
                type="submit"
                onClick={(e) => updatepwd(e)}
              >
                submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default InputUpdatePassword;
