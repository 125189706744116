import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MdOutlineCancel, MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { links } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';

const Sidebar = () => {
  const [dropdown, setDropdown] = useState(null);
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };
  const handleDropdown = (index) => {
    if (dropdown == null || index !== dropdown) {
      setDropdown(index);
    } else {
      setDropdown(null);
    }
  };

  const activeLink =
    'flex items-center gap-5 pt-3 pl-2 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink =
    'flex items-center gap-5 pt-3 pb-2.5 rounded-lg text-md text-white dark:text-gray-200 dark:hover:text-black hover:underline  m-2';
  const normalSubLink =
    'flex items-center gap-5 pt-3 pb-2.5 rounded-lg text-md text-white dark:text-gray-200 dark:hover:text-black hover:underline';

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10 ">
            {links.map((item, index) => (
              <div key={index}>
                <p className="text-shifti-main-text font-semibold dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link, i) =>
                  link.submenus ? (
                    <div key={i}>
                      <NavLink
                        to={link.name ? link.name : window.location.pathname}
                        onClick={() => handleDropdown(i)}
                        className={normalLink}
                      >
                        {link.icon}
                        <span className="capitalize ">{link.title}</span>
                        <MdKeyboardArrowDown
                          className={`ml-auto w-5 h-5 ${
                            dropdown === i
                              ? 'rotate-180 duration-500'
                              : 'duration-500'
                          }`}
                        />
                      </NavLink>
                      <div className={dropdown !== i ? 'hidden' : 'ml-6 block'}>
                        {link.submenus.map((submenu, j) => (
                          <div key={j}>
                            <NavLink
                              to={`/${submenu.name}`}
                              key={submenu.name}
                              onClick={handleCloseSideBar}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : '',
                              })}
                              className={({ isActive }) =>
                                isActive ? activeLink : normalSubLink
                              }
                            >
                              {submenu.icon}
                              <span className="capitalize ">
                                {submenu.title}
                              </span>
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <NavLink
                      to={`/${link.name}`}
                      key={link.name}
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : '',
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      {link.icon}
                      <span className="capitalize ">{link.name}</span>
                    </NavLink>
                  )
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
