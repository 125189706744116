import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import AttributeBox from '../../components/AttributeBox';
import { productContext } from './CreateProduct';
import { ShopContext } from '../../contexts/ShopContext';
import placeholder from '../../data/product_placeholder.jpg';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const ProductVariations = () => {
  const token = useSelector(selectCurrentToken);
  const { formData, setFormData } = useContext(productContext);
  const [
    shops,
    setShops,
    allShopTypes,
    allShopIds,
    selectedShop,
    setSelectedShop,
  ] = useContext(ShopContext);

  const [open, setOpen] = useState(true);

  const getAttributes = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/attributes/get-all-attributes-group-by-shop/${selectedShop}/${true}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then(async (response) => {
        if (
          JSON.stringify(response.data) !==
          JSON.stringify(formData.variations.attributes)
        ) {
          const output = [];
          response.data.map((groupAttribute) => {
            groupAttribute.attributes.map((attribute) => {
              output.push({
                value: `${groupAttribute.id}-${groupAttribute.foreign_id}-${attribute.id}-${attribute.foreign_id}`,
                label: `${groupAttribute.name}: ${attribute.name}`,
                group: groupAttribute.id,
              });
              return null;
            });
            return null;
          });
          setFormData({
            ...formData,
            variations: {
              attributes: response.data,
              options: output,
              selected: [],
              selectDefaultValues: [],
              variations: [],
            },
          });
        }
      })
      .catch((error) => {});
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '2px solid #AAA6C3',
      borderRadius: '0.8rem',
      backgroundColor: 'transparent',
      boxShadow:
        '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    }),
  };

  const generate = () => {
    const temp = { ...formData };
    if (!formData.variations.selectDefaultValues.length) {
      temp.variations.variations = [];
      setFormData({
        ...formData,
        temp,
      });
      return;
    }
    const groupedData = formData.variations.selectDefaultValues.reduce(
      (acc, obj) => {
        if (!acc[obj.group]) {
          acc[obj.group] = [];
        }
        acc[obj.group].push(obj);
        return acc;
      },
      {}
    );

    const keys = Object.keys(groupedData);
    const output = [];

    for (let i = 0; i < groupedData[keys[0]].length; i += 1) {
      const groupValues = {};
      groupValues[keys[0]] = groupedData[keys[0]][i];

      if (keys.length === 1) {
        output.push(groupValues);
      } else {
        for (let j = 0; j < groupedData[keys[1]].length; j += 1) {
          const nextGroupValues = { ...groupValues };
          nextGroupValues[keys[1]] = groupedData[keys[1]][j];

          if (keys.length === 2) {
            output.push(nextGroupValues);
          } else {
            for (let k = 0; k < groupedData[keys[2]].length; k += 1) {
              nextGroupValues[keys[2]] = groupedData[keys[2]][k];
              if (keys.length === 3) {
                output.push(nextGroupValues);
              } else {
                for (let l = 0; l < groupedData[keys[3]].length; l += 1) {
                  nextGroupValues[keys[3]] = groupedData[keys[3]][l];
                  if (keys.length === 4) {
                    output.push(nextGroupValues);
                  } else {
                    for (let m = 0; m < groupedData[keys[4]].length; m += 1) {
                      nextGroupValues[keys[4]] = groupedData[keys[4]][m];
                      output.push(nextGroupValues);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    temp.variations.variations = output;
    setFormData({
      ...formData,
      temp,
    });
  };

  const handleVariationsChange = (e, index) => {
    const temp = { ...formData };
    temp.variations.variations[index].quantity = e.target.value;
    setFormData({
      ...formData,
      temp,
    });
  };

  const handleFormData = (event, index = '') => {
    const temp = { ...formData };
    if (index === 'attributeBox' || index === 'attributeSelect') {
      if (index === 'attributeBox') {
        const { value } = event.target;
        const state = event.target.checked;
        const optionIndex = formData.variations.options.findIndex(
          (item) => item.value === value
        );
        if (state === true) {
          temp.variations.selected.push(value);
          temp.variations.selectDefaultValues.push(
            temp.variations.options[optionIndex]
          );
        } else {
          const selectedIndex = temp.variations.selected.indexOf(value);
          if (selectedIndex > -1) {
            temp.variations.selected = formData.variations.selected.filter(
              (item) => item !== value
            );
          }
          if (optionIndex > -1) {
            temp.variations.selectDefaultValues =
              formData.variations.selectDefaultValues.filter(
                (item) =>
                  JSON.stringify(item) !==
                  JSON.stringify(formData.variations.options[optionIndex])
              );
          }
        }
      } else {
        const nonOccurrenceObjects = event.filter(
          (obj1) =>
            !formData.variations.selectDefaultValues.some(
              (obj2) => obj2.value === obj1.value
            )
        );
        const result = nonOccurrenceObjects.concat(
          formData.variations.selectDefaultValues.filter(
            (obj2) => !event.some((obj1) => obj1.value === obj2.value)
          )
        );
        if (event.length > formData.variations.selectDefaultValues.length) {
          temp.variations.selectDefaultValues.push(result[0]);
          temp.variations.selected.push(result[0].value);
        } else {
          temp.variations.selectDefaultValues =
            formData.variations.selectDefaultValues.filter(
              (item) => JSON.stringify(item) !== JSON.stringify(result[0])
            );
          temp.variations.selected = formData.variations.selected.filter(
            (item) => item !== result[0].value
          );
        }
      }
      setFormData({
        ...formData,
        temp,
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  useEffect(() => {
    getAttributes();
  });

  return (
    <div
      className="m-5 md:mb-2 mt-24 p-2 md:pl-10 md:pr-5 md:pb-[0.5rem] rounded-3xl flex flex-col sm:flex-row w-[85%] sm:w-[98%] sm:pl-[4rem]"
      style={{
        paddingTop: '0',
        marginTop: '0',
      }}
    >
      <div className="main-content flex flex-col space-y-5">
        <h1 className="SubTitle">Manage your variations</h1>
        <div className="alert-container h-[8rem] w-[85%] bg-[#EC1577] rounded-[1.2rem] relative flex items-center mt-5">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="h-7 w-18  text-[#181143] absolute right-[88.5%]  md:right-[95.5%] md:bottom-[28%] pointer-events-none  checked transition scale-100"
          />
          <p className="relative p-2 md:ml-[3rem] ml-[2rem] md:text-[1rem] text-[0.7rem]">
            To add combinations, you must first create the necessary attributes
            and values in Attributes & characteristics. Once created, you can
            enter the desired attributes ("color" or "size") and their
            respective values ("green", "XS", all", etc.) in the field below. Or
            simply select them on Then click on "Generate": the combinations are
            automatically created for you!
          </p>
        </div>
        <div>
          {formData.variations.attributes.map((attribute, index) => (
            <AttributeBox
              key={attribute.id}
              className={`w-1/2 inline-block ${
                index % 2 === 0 ? 'pr-2' : 'pl-2'
              }`}
              onChange={(e) => handleFormData(e, 'attributeBox')}
              attribute={attribute}
              selected={formData.variations.selected}
            />
          ))}
        </div>
        <div className="stock-location">
          <div className="input-container flex flex-row justify-between w-[85%] gap-4">
            <div className="input w-full">
              <Select
                value={formData.variations.selectDefaultValues}
                options={formData.variations.options}
                placeholder="Combine several attributes"
                styles={customStyles}
                isMulti
                onChange={(e) => handleFormData(e, 'attributeSelect')}
              />
            </div>
            <div className="container-generate flex self-center">
              <button
                type="button"
                className="pl-[0.7rem] addButton w-[10rem]"
                onClick={() => generate()}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col	gap-4">
          <Link onClick={() => setOpen((prevState) => !prevState)}>
            <div className="variations">
              <span>
                Grouped actions (0/{formData.variations.variations.length})
              </span>
              <span>
                <MdKeyboardArrowDown
                  className={`text-gray-400 text-4xl mr-8 duration-500 ${
                    open ? '' : 'rotate-180'
                  }`}
                />
              </span>
            </div>
          </Link>
          {formData.variations.variations.length && open ? (
            <div className="bg-white px-4 sm:w-11/12 rounded-[0.8rem] duration-500">
              <div className="flex justify-between">
                <div className="w-2/12">
                  <input type="checkbox" name="" id="" />
                </div>
                <div className="w-4/12 text-left">
                  <span>Variations</span>
                </div>
                <div className="w-3/12 text-center">
                  <span>Quantity</span>
                </div>
                <div className="w-3/12 text-right">
                  <span>Actions</span>
                </div>
              </div>
              <hr className="w-full " />
              <div className="flex flex-col gap-2">
                {formData.variations.variations.map((attribute, index) => (
                  <>
                    <div className="flex justify-between items-center py-4">
                      <div className="w-2/12">
                        <input type="checkbox" name="" id="" />
                      </div>
                      <div className="w-4/12 text-left">
                        <span className="flex gap-2 items-center">
                          <img
                            className="inline"
                            src={placeholder}
                            width="40"
                            height="40"
                            alt=""
                          />
                          <span>
                            {Object.entries(attribute).map((item, i) =>
                              i === 0 ? item[1].label : ` - ${item[1].label}`
                            )}
                          </span>
                        </span>
                      </div>

                      <div className="w-3/12 text-center">
                        <input
                          type="number"
                          defaultValue={0}
                          onChange={(e) => handleVariationsChange(e, index)}
                          className="text-center w-16"
                          name=""
                          id=""
                        />
                      </div>
                      <div className="w-3/12 text-right">
                        <span>Actions</span>
                      </div>
                    </div>
                    <hr className="w-full " />
                  </>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="attribute-list-container" />
        <div className="availability-container flex md:flex-row flex-col md:space-x-10 space-y-3">
          <div className="left-container md:w-5/12 flex flex-col space-y-5">
            <h1 className="SubTitle">Availability preferences</h1>
            <h4 className="text-[1rem] font-bold text-[#181143]">
              Behavior in case of stock shortage
            </h4>
            <div className="input-container-box">
              <div className="input-container">
                <div className="input-container relative flex items-center space-x-3 md:w-[34rem] w-[19rem]">
                  <input
                    type="radio"
                    id="refuseOrders"
                    name="preferences"
                    className="deliveryCheckbox"
                  />
                  <label htmlFor="refuseOrders" className="essentialLabel">
                    Refuse orders
                  </label>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="h-5 w-16  text-[#EC1577] absolute right-[84.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
                  />
                </div>
              </div>
              <div className="input-container">
                <div className="input-container relative flex items-center space-x-3 md:w-[34rem] w-[19rem]">
                  <input
                    type="radio"
                    id="acceptingOrders"
                    name="preferences"
                    className="deliveryCheckbox"
                  />
                  <label htmlFor="acceptingOrders" className="essentialLabel">
                    Accepting orders
                  </label>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="h-5 w-16  text-[#EC1577] absolute right-[84.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
                  />
                </div>
              </div>
              <div className="input-container">
                <div className="input-container relative flex items-center space-x-3 md:w-[34rem] w-[19rem]">
                  <input
                    type="radio"
                    id="refuseCommands"
                    name="preferences"
                    className="deliveryCheckbox"
                  />
                  <label
                    htmlFor="refuseCommands"
                    className="essentialLabel w-[15rem] md:w-full"
                  >
                    Use default behavior (refuse commands)
                  </label>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="h-5 w-16  text-[#EC1577] absolute right-[84.5%] md:right-[91.5%] md:bottom-[28%] pointer-events-none text-opacity-0 checked transition scale-75"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="right-container-box md:w-5/12 flex flex-col md:flex-row">
            <div className="input-container-box w-full md:space-y-5">
              <div className="input-container">
                <label htmlFor="ifInStock" className="essentialLabel">
                  Label if in stock
                </label>
                <input
                  type="text"
                  id="ifInStock"
                  className="essentialInput w-full p-3"
                  name="labelInStock"
                  value={formData.labelInStock}
                  onChange={handleFormData}
                />
              </div>
              <div className="input-container">
                <label htmlFor="ifOutStock" className="essentialLabel">
                  If out of stock (and pre-order authorized)
                </label>
                <input
                  type="text"
                  id="ifOutStock"
                  className="essentialInput w-full p-3"
                  name="labelOutOfStock"
                  value={formData.labelOutOfStock}
                  onChange={handleFormData}
                />
              </div>
              <div className="input-container">
                <label htmlFor="dataAvailability" className="essentialLabel">
                  Date of availability
                </label>
                <input
                  type="date"
                  id="dataAvailability"
                  className="essentialInput w-full p-3"
                  name="dataAvailability"
                  value={formData.dataAvailability}
                  onChange={handleFormData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductVariations;
