import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export default function EditManufacturer() {
  let idComingFromUrl;
  const token = useSelector(selectCurrentToken);
  const [items, setItems] = useState([]);
  // const [passwordShown, setPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
  });
  const [formDataError, setformDataError] = useState([true]);

  useEffect(() => {
    // Recuperation de l'id
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    idComingFromUrl = urlParams.get('id');
    // end of id recuperation
    // getUserRoles();
    getManufacturerById();
  }, []);

  const handelFormDataChange = (value, key) => {
    const aux = { ...formData };
    aux[key] = value;
    setFormData(aux);
  };

  const getManufacturerById = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/manufacturers/${idComingFromUrl}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };

    await api
      .request(config)
      .then((response) => {
        const manufacturer = response.data;
        setItems(manufacturer);
        setFormData({
          name: manufacturer.name,
        });
      })
      .catch((error) => {});
  };

  const handelSubmit = async () => {};

  return (
    <div className="mr-3 p-2 md:p-5 ml-3 rounded-3xl mb-3 md:mt-8 mt-20 flex justify-center">
      <div className="flex justify-center flex-col w-[90%]">
        <Header category="Page" title="Edit Manufacturer" />
        <form
          action=""
          className="sft-form formStyle mt-2 md:mt-0 p-2 md:p-10 mr-4 bg-white rounded-3xl md:w-full"
        >
          <div className="sft-input-container grid grid-cols-2 gap-y-8 mt-6 mr-10 md:mr-0 md:w-[40%] lg:w-[81%]">
            <label htmlFor="name" className="formLabel mt-2">
              Name :
            </label>
            <div>
              <input
                id="name"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'name', 0)
                }
                type="text"
                defaultValue={formData.name}
                className="formInput"
              />
              <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
            </div>
            <div /> {/* empty div for button position */}
            <div className="flex justify-end">
              <button
                type="button"
                className="formButton"
                onClick={handelSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
