/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import { productContext } from '../pages/products/CreateProduct';
import upload from '../data/upload.png';

const ImageBox = () => {
  const [
    shops,
    setShops,
    allShopTypes,
    allShopIds,
    selectedShop,
    setSelectedShop,
    selectedShops,
    setSelectedShops,
    langs,
    setLangs,
    selectedLangs,
    setSelectedLangs,
    formData,
    setFormData,
  ] = useContext(productContext);
  const [message, setMessage] = useState();
  const handleFile = (e) => {
    setMessage('');
    const { files } = e.target;
    const temp = { ...formData };
    for (let i = 0; i < files.length; i += 1) {
      const fileType = files[i].type;
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      if (validImageTypes.includes(fileType)) {
        temp.images.push(files[i]);
      } else {
        setMessage('only images are accepted');
      }
    }
    setFormData(temp);
  };

  const removeImage = (e, i) => {
    e.preventDefault();
    const temp = { ...formData };
    temp.images = formData.images.filter((image) => image.file.name !== i);
    setFormData(temp);
  };
  return (
    <div className="flex justify-center items-center">
      <div className="cursor-pointer border-2 border-[#AAA6C3] rounded-2xl w-full">
        <div className="m-4 py-4">
          <span className="flex justify-center items-center text-[12px] mb-1 text-red-500">
            {message}
          </span>
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="image"
              className="flex cursor-pointer flex-col w-full border-2 rounded-md border-dashed hover:bg-gray-100 hover:border-gray-300"
            >
              <div className="flex flex-col items-center justify-center pt-7">
                <img src={upload} alt="" />
                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                  Upload your Image here
                </p>
              </div>
              <input
                type="file"
                id="image"
                onChange={handleFile}
                className="opacity-0"
                multiple="multiple"
                name="files[]"
              />
            </label>
          </div>
          <div className="flex gap-6 mt-2 justify-evenly flex-wrap">
            {formData.images.map((image, key) => (
              <div key={key} className="overflow-hidden relative">
                <span
                  onClick={(e) => {
                    removeImage(e, image.name);
                  }}
                  className="absolute font-thin	right-1 top-1 text-xl text-black-800 hover:text-red cursor-pointer"
                >
                  X
                </span>
                <img
                  className="h-32 w-32 rounded-md"
                  src={URL.createObjectURL(image)}
                  alt=""
                />
                <div className="absolute p-2 flex items-center w-full h-8 bg-white bottom-0 right-0 left-0 m-auto border-t-2 border-gray-400">
                  <label
                    htmlFor={`cover-${key}`}
                    className="text-xs cursor-pointer"
                  >
                    <input
                      type="radio"
                      className="align-middle mr-1"
                      name="cover"
                      id={`cover-${key}`}
                    />
                    <span>Cover image</span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageBox;
