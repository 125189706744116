import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../utils/apiService';

const apiURL = process.env.REACT_APP_API_URL;

const ErrorBoundary = () => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);

  const sendErrorMail = (error, info) => {
    const formdata = JSON.stringify({
      error,
      info,
    });
    const config = {
      method: 'post',
      url: `${apiURL}/mail/send-error-mail`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: formdata,
    };
    api
      .request(config)
      .then(() => {
        toast.error(
          'An error occured. Our dev team notified for immediate resolution',
          {
            toastId: 'error',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
      })
      .catch(() => {
        toast.error('An error occured.', {
          toastId: 'error',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };

  useEffect(() => {
    const handleComponentError = async (error, info) => {
      setHasError(true);
      setError(error);
      setErrorInfo(info);
      sendErrorMail(error, info);
    };

    window.addEventListener('error', handleComponentError);
    return () => {
      window.removeEventListener('error', handleComponentError);
    };
  }, []);

  if (hasError) {
    toast.error(
      'Error occured. Our dev team notified for immediate resolution',
      {
        toastId: 'planWarning',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      }
    );
    return (
      <div>
        <h1>Something went wrong</h1>
        <p>{error && error.toString()}</p>
        <p>Component Stack Trace:</p>
        <pre>{errorInfo && errorInfo.componentStack}</pre>
      </div>
    );
  }

  return <Outlet />;
};
export default ErrorBoundary;
