import React, { useState, useEffect, useContext } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header, Toggle } from '../../components';
import { selectCurrentToken } from '../../features/auth/authSlice';
import { ShopContext } from '../../contexts/ShopContext';

const apiURL = process.env.REACT_APP_API_URL;
const DropDown = ({
  className,
  id,
  text,
  value,
  data,
  onChange,
  defaultValue,
}) => (
  <div className={className}>
    <DropDownListComponent
      id={id}
      fields={{ text, value }}
      style={{ border: 'none' }}
      value={defaultValue}
      dataSource={data}
      popupHeight="220px"
      popupWidth="120px"
      onChange={(e) => onChange(e.value)}
    />
  </div>
);

const CategoryPage = () => {
  const token = useSelector(selectCurrentToken);
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };
  const [
    shops,
    setShops,
    allShopTypes,
    allShopIds,
    selectedShop,
    setSelectedShop,
    selectedShops,
    setSelectedShops,
    langs,
    setLangs,
  ] = useContext(ShopContext);
  const [currentShopId, setCurrentShopId] = useState(
    selectedShop || selectedShops[0].id
  );
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (selectedShop && currentShopId !== selectedShop) {
      setCurrentShopId(selectedShop);
    }
    getCategories();
  }, []);

  const contextMenuItems = [
    'AutoFit',
    'AutoFitAll',
    'SortAscending',
    'SortDescending',
    'Copy',
    'Edit',
    'Delete',
    'Save',
    'Cancel',
    'PdfExport',
    'ExcelExport',
    'CsvExport',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];

  const description = (props) => {
    const sanitizedDescription = DOMPurify.sanitize(
      // eslint-disable-next-line react/prop-types
      props.CategoryLangs[0].description
    );

    return <div>{sanitizedDescription}</div>;
  };

  const status = ({ id, active }) => {
    const handleClick = () => {
      const decoded = jwtDecode(token);
      const data = JSON.stringify({
        active: !active,
        shopId: 1,
      });
      const config = {
        url: `${apiURL}/categories/${id}`,
        method: 'patch',
        data,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then(async () => {
          await getCategories();
          toast.success('Status updated successfully!');
        })
        .catch((error) => {
          toast.error('Failed to update status.');
        });
    };

    return (
      <center>
        <Toggle onClick={handleClick} toggle={active} text={false} />
      </center>
    );
  };

  const categoriesGrid = [
    { field: 'id', headerText: 'Id', width: '50' },
    {
      field: 'CategoryLangs.0.name',
      headerText: 'Name',
      width: '120',
      textAlign: 'Start',
    },
    {
      field: 'CategoryLangs.0.description',
      headerText: 'Description',
      template: description,
      width: '170',
      textAlign: 'Start',
    },
    {
      template: status,
      headerText: 'Active',
      width: '170',
      textAlign: 'Center',
    },
    {
      field: 'actions',
      headerText: 'Actions',
      template: Actions,
      width: '120',
      textAlign: 'Center',
    },
  ];

  const getCategories = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/categories`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl">
      <Header category="Page" title="Categories" />
      <div
        className="add-button"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Link
          className="p-2"
          style={{
            background: '#EC1577',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '25px',
            padding: '7.1px 30px 0px',
            fontWeight: 'normal',
            fontSize: '1,04vw',
            height: '37px',
          }}
          to="/create-category"
        >
          <Link
            className="p-2"
            style={{
              background: '#ffffff',
              color: '#181143',
              cursor: 'pointer',
              borderRadius: '50%',
              padding: '4px 10px',
              marginRight: '32%',
              marginLeft: '-35%',
              fontSize: '1,20vw',
            }}
            to="/create-category"
          >
            +
          </Link>
          Add
        </Link>
      </div>
      <div className="m-2 mt-10 p-2 md:p-10 bg-white rounded-3xl">
        <GridComponent
          dataSource={items}
          width="auto"
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          pageSettings={{ pageCount: 5, pageSize: 10 }}
          editSettings={editing}
          toolbar={toolbarOptions}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {categoriesGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Search, Page, Toolbar]} />
        </GridComponent>
        <div id="myModal" className="modal items-center justify-center">
          <div
            className="modal-content bg-main-bg m-auto py-20 px-20 w-2/5
          "
          >
            <span className="close">&times;</span>
            <div id="modal-content" className="flex justify-center gap-5">
              <button
                type="button"
                className="bg-shifti-main-bg text-white p-5 rounded-full"
              >
                Amazonia
              </button>
              <button
                type="button"
                className="bg-shifti-main-bg text-white p-5 rounded-full"
              >
                Masmoude
              </button>
              <button
                type="button"
                className="bg-shifti-main-bg text-white p-5 rounded-full"
              >
                shopify
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Actions = ({ id }) => {
  const navigate = useNavigate();
  return (
    <div>
      <button
        type="button"
        style={{ color: '#000' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => navigate(`/edit-category/${id}`)}
      >
        <FontAwesomeIcon icon={faPencil} className="w-6 h-6 text-[#AAA6C3]" />
      </button>
      <button
        type="button"
        style={{ color: '#000' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
      >
        <FontAwesomeIcon icon={faTrash} className="w-6 h-6 text-[#AAA6C3]" />
      </button>
    </div>
  );
};
Actions.propTypes = {
  id: PropTypes.number.isRequired,
};
DropDown.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.number.isRequired,
};
export default CategoryPage;
