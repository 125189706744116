import React from 'react';

export default function Loader() {
  return (
    <div className="flex flex-col justify-center ml-[35%] mt-[10%]">
      <h2 className="w-[15rem] ">
        <img src="/logo192.png" alt="" />
      </h2>
    </div>
  );
}
