import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../utils/apiService';
import searchlogo from './search-file.png';
import { selectCurrentToken } from '../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

const DeleteCart = async (id, shopId) => {
  const idToast = toast.loading('Please wait...');
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const data = {
    shops: [shopId],
  };
  const config = {
    method: 'delete',
    url: `${apiURL}/carts/${id}`,
    headers: {
      'App-Tenant': decoded.UserInfo.tenantId,
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  await api
    .request(config)
    .then((response) => {
      toast.update(idToast, {
        render: 'Deleted !',
        type: response.data === 'success',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        isLoading: false,
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1500);
    })
    .catch((error) => {});
};

export const Actions = ({ id, Order, Shop }) => {
  const navigate = useNavigate();
  return (
    <div>
      {Order === null ? (
        <button
          type="button"
          style={{ color: '#000' }}
          className="text-white py-1 px-2 capitalize rounded-2xl text-md absolute right-[60%] bottom-[20%]"
          onClick={() => DeleteCart(id, Shop.id)}
        >
          <FontAwesomeIcon icon={faTrash} className="w-4 h-4 text-[#AAA6C3]" />
        </button>
      ) : null}
      <div className="hidden_preview_cart  opacity-0 h-full">
        <button
          className="flex justify-center h-full  ml-[10%]"
          type="button"
          onClick={() => {
            navigate(`/cart/${id}`);
          }}
        >
          <img src={searchlogo} className="h-9 w-9 mt-2 " alt="" />
        </button>
      </div>
    </div>
  );
};
Actions.propTypes = {
  id: PropTypes.number.isRequired,
  Order: PropTypes.object.isRequired,
  Shop: PropTypes.object.isRequired,
};

const carrier = (props) => {
  if (props.Carrier) {
    return <div>{props.Carrier.name}</div>;
  }
  return <div>NaN</div>;
};

const customer = (props) => (
  <div className="">
    {props.Customer ? (
      <div>
        {props.Customer.first_name} {props.Customer.last_name}
      </div>
    ) : (
      <div>--</div>
    )}
  </div>
);

const order = (props) => (
  <div className="flex justify-center">
    {props.Order !== null ? (
      <div>{props.Order.id}</div>
    ) : (
      <div className="bg-[#ec1577] w-[100%] rounded-lg p-1 ">
        Abandoned cart
      </div>
    )}
  </div>
);

const date = (props) => {
  const originalString = props.date_add;
  const croppedString = originalString.replace('.000Z', '').replace('T', ' ');
  return <div>{croppedString}</div>;
};

const totalProductsTTC = (props) => {
  let price = 0;
  props.CartProducts.map((product) => {
    price += parseFloat(product.Product.price) * parseFloat(product.quantity);
    return null;
  });
  return (
    <div className="flex justify-center">
      {props.Order ? (
        <div className="p-2 bg-[#72c279] rounded-xl crud-data">
          {price.toFixed(3)} TND
        </div>
      ) : (
        <div>{price.toFixed(3)} TND</div>
      )}
    </div>
  );
};

const shop = (props) => <div>{props.Shop.name}</div>;

const Id = ({ id }) => (
  <div className="flex justify-center  crud-data">{id}</div>
);
Id.propTypes = {
  id: PropTypes.number.isRequired,
};

export const cartsGrid = [
  { template: Id, headerText: 'Id', width: '60', textAlign: 'Center' },

  {
    headerText: 'Order id',
    width: '130',
    template: order,
    textAlign: 'Center',
  },
  {
    headerText: 'Customer',
    template: customer,
    width: '130',
    textAlign: 'Center',
  },

  {
    headerText: 'Total Products',
    template: totalProductsTTC,
    width: '170',
    textAlign: 'Center',
  },
  {
    headerText: 'Transporter',
    template: carrier,
    width: '130',
    textAlign: 'Center',
  },
  {
    headerText: 'Date',
    template: date,
    width: '130',
    textAlign: 'Center',
  },
  {
    headerText: 'Shop',
    template: shop,
    width: '130',
    textAlign: 'Center',
  },
  {
    headerText: 'Actions',
    template: Actions,
    width: '100',
    textAlign: 'Center',
    allowSelection: false,
  },
];
