import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSlash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;
const EmployeesPermissions = ({ rolePermissions, roleId }) => {
  const token = useSelector(selectCurrentToken);
  const [permissions, setPermission] = useState([]);
  const [isCheck, setIsCheck] = useState(rolePermissions);
  const [roleIdState, setRoleIdState] = useState(roleId);
  const getPermissions = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/permissions`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setPermission(response.data);
      })
      .catch((error) => {});
  };

  const setRolePermissions = async (e) => {
    const decoded = jwtDecode(token);
    const idToast = toast.loading('Please wait...');
    const data = JSON.stringify({
      permissions: isCheck,
    });
    const config = {
      method: 'put',
      url: `${apiURL}/roles/assign-permissions/${roleIdState}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    await api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: response.data,
          type: response.data === 'success',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      })
      .catch((error) => {});
  };

  const handleCheck = async (value) => {
    const id = Number(value);
    if (isCheck.includes(id)) {
      setIsCheck(isCheck.filter((item) => item !== id));
    } else {
      setIsCheck([...isCheck, Number(value)]);
    }
  };

  const toggleAll = (value, isChecked) => {
    const values = value.split(',');
    const toAdd = [];
    if (isChecked === true) {
      values.map((id) => {
        if (!isCheck.includes(Number(id))) {
          toAdd.push(Number(id));
        }
        return null;
      });
      setIsCheck([...isCheck, ...toAdd]);
    } else {
      const toRemove = [];
      values.map((id) => {
        if (isCheck.includes(Number(id))) {
          toRemove.push(Number(id));
        }
        return null;
      });
      setIsCheck(isCheck.filter((el) => !toRemove.includes(el)));
    }
  };

  useEffect(() => {
    getPermissions();
    setIsCheck(rolePermissions);
    setRoleIdState(roleId);
  }, [rolePermissions, roleId]);
  useEffect(() => {}, [isCheck]);

  const allPermissions = Object.entries(permissions);

  return (
    <div className="p-2 md:p-5 bg-white rounded-3xl mb-3 mt-8 overflow-y-auto">
      <div className="p-2 md:p-5 rounded-3xl mb-3 overflow-y-auto">
        <div className="flex justify-between mb-2 w-screen sm:w-full">
          <span className="w-32 text-[#EC1577]">Menu</span>
          <span className="">Add</span>
          <span className="">Delete</span>
          <span className="">Show</span>
          <span className="">Update</span>
          <span className="">All</span>
        </div>
        <hr className="border-3" />
        {allPermissions && Object.keys(allPermissions).length > 0
          ? allPermissions.map((item, index) => {
              const name = item[0];
              const itemPermissions = item[1];
              let row = [];
              return (
                <div className="flex justify-between w-full items-center mb-3 mt-3 w-screen sm:w-full">
                  <label className="w-32">{name}</label>
                  {itemPermissions.map((permission, i) => {
                    if (i === 0 && permission.id !== 0) row = [permission.id];
                    else if (permission.id !== 0) row.push(permission.id);
                    return (
                      <div className="relative flex items-center mr-3">
                        <input
                          key={permission.id}
                          name={`${permission.group}[]`}
                          className="permission-checkbox h-8 w-8 rounded-full	outline-0	appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
                          onChange={(e) => handleCheck(e.target.value)}
                          checked={isCheck.includes(permission.id)}
                          type="checkbox"
                          disabled={permission.id === 0}
                          id={permission.id}
                          value={permission.id}
                        />
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="h-5 w-16  text-[#EC1577] absolute -right-4 pointer-events-none text-opacity-0 checked-1 transition scale-75"
                        />
                        <FontAwesomeIcon
                          icon={faSlash}
                          className="h-5 w-16  text-[#E1DFEE] absolute -right-4 pointer-events-none text-opacity-0 disabled-1 transition scale-110 rotate-90"
                        />
                      </div>
                    );
                  })}
                  <div className="relative flex items-center">
                    <input
                      className="all-permissions-checkbox h-8 w-8 rounded-full	outline-0	appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
                      onChange={(e) =>
                        toggleAll(e.target.value, e.target.checked)
                      }
                      type="checkbox"
                      value={row}
                    />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="h-5 w-16  text-[#EC1577] absolute -right-4 pointer-events-none text-opacity-0 checked-2 transition scale-75"
                    />
                    <FontAwesomeIcon
                      icon={faSlash}
                      className="h-5 w-16  text-[#E1DFEE] absolute -right-4 pointer-events-none text-opacity-0 disabled-2 transition scale-110 rotate-90"
                    />
                  </div>
                </div>
              );
            })
          : ''}
      </div>
      <button
        type="button"
        style={{
          marginTop: '2%',
          float: 'right',
          background: '#EC1577',
          color: 'white',
          cursor: 'pointer',
          borderRadius: '25px',
          padding: '0px 40px 0px',
          fontWeight: 'normal',
          fontSize: '1,04vw',
          height: '37px',
        }}
        onClick={() => {
          setRolePermissions();
        }}
      >
        Save
      </button>
    </div>
  );
};

EmployeesPermissions.propTypes = {
  rolePermissions: PropTypes.object.isRequired,
  roleId: PropTypes.number.isRequired,
};
export default EmployeesPermissions;
