import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import EmployeesMain from './EmployeesMain';
import EmployeesPermission from './EmployeesPermissions';
import EmployeesRole from './EmployeesRole';
import { Header } from '../../components';
import Select from '../../components/Select';
import SearchBar from '../../components/SearchBar';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const Employees = () => {
  const token = useSelector(selectCurrentToken);
  const [currentTab, setCurrentTab] = useState('1');
  const [rolePermissions, setRolePermissions] = useState();
  const [items, setItems] = useState([]);
  const [roleId, setRoleId] = useState();
  const [users, setUsers] = useState([]);

  const tabs = [
    {
      id: 1,
      tabTitle: 'Employees',
      title: 'Employees',
      content: <EmployeesMain users={users} />,
    },
    {
      id: 2,
      tabTitle: 'Roles',
      title: 'Roles',
      content: <EmployeesRole items={items} />,
    },
    {
      id: 3,
      tabTitle: 'Permissions',
      title: 'Permissions',
      content: (
        <EmployeesPermission
          rolePermissions={rolePermissions}
          roleId={roleId}
        />
      ),
    },
  ];

  const getUsers = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/user/getAllUsers`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  const handelFormDataChange = async (id) => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/permissions/byRole/${id}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        // fiha les permissions mta3 role id bien determine
        setRolePermissions(response.data);
        // id mta3 role
        setRoleId(id);
      })
      .catch((err) => {});
  };

  const getRoles = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/user/getRoles`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setItems(response.data);
        const idRole = response.data[0].id;
        handelFormDataChange(idRole);
      })
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getRoles();
    getUsers();
  }, []);

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl">
      <Header category="Page" title="Employees" />
      <div className="flex justify-between flex-wrap gap-2">
        <div className="p-2 md:p-2 bg-white rounded-3xl flex sm:w-46 h-11 mt-[8px]">
          <div className="tabs">
            <div className="divide-x">
              {tabs.map((tab, i) => (
                <button
                  type="button"
                  key={i}
                  id={tab.id}
                  disabled={currentTab === `${tab.id}`}
                  onClick={handleTabClick}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tab.tabTitle} &nbsp;
                  &nbsp;
                </button>
              ))}
            </div>
          </div>
        </div>

        {currentTab === '1' || currentTab === '2' ? (
          <div
            className="add-button"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 18,
            }}
          >
            <Link
              className="p-2"
              style={{
                background: '#EC1577',
                color: 'white',
                cursor: 'pointer',
                borderRadius: '25px',
                padding: '7.1px 30px 0px',
                fontWeight: 'normal',
                fontSize: '1,04vw',
                height: '37px',
              }}
              to={currentTab === '2' ? '/create-role' : '/create-user'}
            >
              <Link
                className="p-2"
                style={{
                  background: '#ffffff',
                  color: '#18114397',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  padding: '4px 9px',
                  marginRight: '32%',
                  marginLeft: '-35%',
                  fontSize: '1,20vw',
                }}
                to={currentTab === '2' ? '/create-role' : '/create-user'}
              >
                +
              </Link>
              ADD
            </Link>
          </div>
        ) : (
          <>
            <div className="w-96">
              <Select
                onChange={(e) => handelFormDataChange(e.target.value)}
                items={
                  items.length > 0 && Object.keys(items[0]).length !== 0
                    ? items
                    : []
                }
              />
            </div>
            <SearchBar />
            <div
              className="help-button mt-3"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <a
                style={{
                  background: '#EC1577',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: '25px',
                  padding: '7.5px 40px 0px',
                  fontWeight: 'normal',
                  fontSize: '1,04vw',
                  height: '37px',
                }}
              >
                Help
              </a>
            </div>
          </>
        )}
      </div>

      <div className="content">
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && <div> {tab.content} </div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Employees;
