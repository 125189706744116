import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import Select from '../../components/Select';
import Loader from '../../components/Loader';
import prestashopLogo from '../../img/prestaShop.png';
import shopifyLogo from '../../img/shopify.png';
import woocommerceLogo from '../../img/woocommerce.png';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const EditShop = () => {
  const token = useSelector(selectCurrentToken);
  const [groupShop, setGroupShop] = useState([]);
  const [langs, setLangs] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  // const [stepOnedata, setStepOneData] = useState({
  //   name: '',
  //   group_shop: 0,
  //   type: '',
  //   validation: {
  //     error: [true],
  //     errorMsg: ['Obligatoire'],
  //   },
  // });
  // const [stepOneDataErrorMsg, setStepOneDataErrorMsg] = useState(['']);
  const [types, setTypes] = useState([
    {
      id: 1,
      name: 'Shopify',
      type: 'shopify',
      logo: shopifyLogo,
      active: false,
    },
    {
      id: 2,
      name: 'Woocommerce',
      type: 'woocommerce',
      logo: woocommerceLogo,
      active: false,
    },
    // { id: 3, name: 'Bigcommerce',type: 'bigcommerce', logo:"", active:false },
    // { id: 4, name: 'Magento',type: 'magento', logo:"", active:false },
    {
      id: 5,
      name: 'Prestashop',
      type: 'prestashop',
      logo: prestashopLogo,
      active: false,
    },
  ]);

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    group_shop_id: '',
    type: '',
    url: '',
    pres_key: '',
    woo_consumer_key: '',
    woo_consumer_secret: '',
    woo_version: '',
    active: true,
  });

  const navigate = useNavigate();

  const getLangs = async () => {
    const decoded = jwtDecode(token);

    const config = {
      method: 'get',
      url: `${apiURL}/langs`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setLangs(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  const { id } = useParams();
  const getShop = (shopId) =>
    new Promise((resolve, reject) => {
      const decoded = jwtDecode(token);

      const config = {
        method: 'get',
        url: `${apiURL}/shop/${shopId}`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err));
    });

  const handleShopType = (e) => {
    const temp = [...types];
    const test = types.map((item, key) => {
      if (item.id === e || item.type === e) {
        temp[key].active = true;
      }
      if (item.id !== e && item.type !== e && item.active) {
        temp[key].active = false;
      }
      return null;
    });
    setTypes(temp);
  };

  const formGenerator = () => {
    let temp;
    types.map((item) => {
      if (item.active) {
        temp = item;
      }
      return null;
    });
    if (temp) {
      if (temp.type === 'prestashop') {
        return (
          <>
            <div className="label-container">
              <label htmlFor="name" className="flex form-Label ">
                Key : <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container ">
              <input
                type="text"
                id="pres_key"
                className="formInput"
                defaultValue={formData.pres_key}
                {...register('pres_key', {
                  pattern: {
                    value: /^[a-zA-Z0-9]{32}$/,
                    message: 'Please enter a valid key',
                  },
                  required: {
                    value: true,
                    message: 'this field is required !',
                  },
                  minLength: {
                    value: 32,
                    message: 'The key must contain 32 characters !',
                  },
                  maxLength: {
                    value: 32,
                    message: 'The key must contain 32 characters !',
                  },
                })}
                // onChange={handleChange}
              />
              {errors.pres_key && (
                <div style={{ color: '#FF9494' }} className="">
                  {errors.pres_key?.message}
                </div>
              )}
            </div>
          </>
        );
      }
      if (temp.type === 'woocommerce') {
        return (
          <>
            <div className="label-container">
              <label htmlFor="name" className="flex form-Label ">
                Secret Key : <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container ">
              <input
                type="text"
                id="woo_consumer_secret"
                className="formInput"
                defaultValue={formData.woo_consumer_secret}
                {...register('woo_consumer_secret', {
                  pattern: {
                    value: /^cs_[a-zA-Z0-9]+$/,
                    message: 'Please enter a valid Secret Key',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                  minLength: {
                    value: 43,
                    message: 'The Secret Key must contain 43 characters !',
                  },
                  maxLength: {
                    value: 43,
                    message: 'The Secret Key must contain 43 characters !',
                  },
                })}
                // onChange={handleChange}
              />
              {errors.woo_consumer_secret && (
                <div style={{ color: '#FF9494' }} className="">
                  {errors.woo_consumer_secret?.message}
                </div>
              )}
            </div>

            <div className="label-container">
              <label htmlFor="name" className="flex form-Label ">
                Consumer Key : <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container ">
              <input
                type="text"
                id="woo_consumer_key"
                className="formInput"
                defaultValue={formData.woo_consumer_key}
                {...register('woo_consumer_key', {
                  pattern: {
                    value: /^ck_[a-zA-Z0-9]+$/,
                    message: 'Please enter a valid Consumer Key',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                  minLength: {
                    value: 43,
                    message: 'The Consumer Key must contain 43 characters !',
                  },
                  maxLength: {
                    value: 43,
                    message: 'The Consumer Key must contain 43 characters !',
                  },
                })}
                // onChange={handleChange}
              />
              {errors.woo_consumer_key && (
                <div style={{ color: '#FF9494' }} className="">
                  {errors.woo_consumer_key?.message}
                </div>
              )}
            </div>

            <div className="label-container">
              <label htmlFor="name" className="flex form-Label ">
                Version : <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container ">
              <input
                type="text"
                id="woo_version"
                className="formInput"
                defaultValue={formData.woo_version}
                {...register('woo_version', {
                  pattern: {
                    value: /^wc\/v\d+$/,
                    message: 'Please enter a valid Version',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                  minLength: {
                    value: 5,
                    message: 'The Version must contain 5 characters !',
                  },
                  maxLength: {
                    value: 5,
                    message: 'The Version must contain 5 characters !',
                  },
                })}
                // onChange={handleChange}
              />
              {errors.woo_version && (
                <div style={{ color: '#FF9494' }} className="">
                  {errors.woo_version?.message}
                </div>
              )}
            </div>
          </>
        );
      }
      if (temp.type === 'shopify') {
        return <p />;
      }
      if (temp.type === 'bigcommerce') {
        return <p />;
      }
      if (temp.type === 'magento') {
        return <p />;
      }
    }
    return <p>Choose shop</p>;
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getShopCategories();
    getLangs();
    getShop(id)
      .then((response) => {
        const data = { ...formData };
        data.id = response.id;
        data.name = response.name;
        data.url = response.url;
        data.group_shop_id = response.group_shop_id;
        data.type = response.type;
        data.pres_key = response.pres_key;
        data.woo_consumer_key = response.woo_consumer_key;
        data.woo_consumer_secret = response.woo_consumer_secret;
        data.woo_version = response.woo_version;
        data.is_imported = response.is_imported;
        setFormData(data);
        handleShopType(response.type);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  const getShopCategories = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/group-shop/groups`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setGroupShop(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  if (loading) {
    return <Loader />;
  }

  const handleGroupTypeChange = (event) => {
    const temp = { ...formData };
    temp.group_shop_id = event.target.value;
    setFormData(temp);
  };

  const onSubmit = (data) => {
    const decoded = jwtDecode(token);
    const temp = { ...formData };
    temp.name = data.name;
    temp.url = data.url;
    temp.woo_consumer_secret = data.woo_consumer_secret;
    temp.woo_consumer_key = data.woo_consumer_key;
    temp.woo_version = data.woo_version;
    temp.pres_key = data.pres_key;
    temp.type = types.find((item) => item.active === true)?.type;
    const config = {
      method: 'patch',
      url: `${apiURL}/shop/edit/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: temp,
    };
    api.request(config).then(() => navigate(`/shops`));
  };

  return (
    <div className="mt-20 md:mt-8 p-2 md:p-10 ml-1 rounded-3xl flex justify-center">
      <div className="flex justify-center flex-col w-[90%]">
        <div className="flex justify-between flex-row">
          <div className="flex justify-center">
            <Header category="Page" title={`Edit Shop `} />
            <div className="m-11 flex justify-center">
              {formData.is_imported && (
                <FontAwesomeIcon
                  style={{
                    '--fa-primary-color': '#ec1577',
                    '--fa-secondary-color': '#181143',
                    '--fa-secondary-opacity': '1',
                  }}
                  icon={faCircleCheck}
                  className=" text-[#EC1577] pointer-events-none transition scale-75 mr-4 mt-1"
                />
              )}
              <p className="text-[#81828b]">
                {formData.is_imported ? 'Imported Shop' : ''}
              </p>
            </div>
          </div>
          <div className="h-4 p-6 ">
            <button
              type="submit"
              className="text-white font-semibold bg-shifti-pink-bg rounded-full px-12 py-2 float-right mt-4 box-xl shadow-xl"
            >
              Help
            </button>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="input-container flex flex-col space-y-5"
        >
          <div>
            <div className="input-box flex flex-col space-y-3 w-4/5">
              <div className="label-container">
                <label htmlFor="name" className="form-Label">
                  Shop Name :
                </label>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  id="name"
                  className="formInput"
                  defaultValue={formData.name}
                  {...register('name', {
                    pattern: {
                      value: /^[a-zA-Z0-9\s]+$/,
                      message: 'Please enter a valid Shop Name',
                    },
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                    minLength: {
                      value: 3,
                      message: 'minimum characters are 3 !',
                    },
                    maxLength: {
                      value: 150,
                      message: 'max characters are 150 !',
                    },
                  })}
                  // onChange={handleChange}
                />
                {errors.name && (
                  <div style={{ color: '#FF9494' }} className="">
                    {errors.name?.message}
                  </div>
                )}
              </div>
              <div className="label-container">
                <label htmlFor="group_shop" className="form-Label ">
                  Shop Group :
                </label>
              </div>
              <div className="input-container ">
                <Select
                  items={
                    groupShop.length > 0 &&
                    Object.keys(groupShop[0]).length !== 0
                      ? groupShop
                      : []
                  }
                  defaultValue={formData.group_shop_id}
                  className="formselect"
                  onChange={(e) => handleGroupTypeChange(e)}
                />
              </div>
              <div className="label-container">
                <label htmlFor="type" className="form-Label">
                  Type :
                </label>
              </div>
              <div
                className="flex justify-between gap-8 ml-10"
                style={{ color: '#FF9494' }}
              >
                {types.map((item) => (
                  <Link
                    className={`flex items-center cursor-pointer w-1/5 p-3 rounded-2xl shadow-lg ${
                      item.active ? 'bg-[#F99E1FDE]' : 'bg-white'
                    }`}
                    onClick={() => handleShopType(item.id)}
                  >
                    <img src={item.logo} alt="" />
                  </Link>
                ))}
              </div>
              <div className="label-container">
                <label htmlFor="name" className="flex form-Label ">
                  Url : <p className="text-[#EC1577] ml-2">*</p>
                </label>
              </div>
              <div className="input-container ">
                <input
                  type="text"
                  id="url"
                  className="formInput"
                  defaultValue={formData.url}
                  {...register('url', {
                    pattern: {
                      value: /^(ftp|http|https):\/\/[^ "]+$/,
                      message: 'Please enter a valid URL',
                    },
                    required: {
                      value: true,
                      message: 'This field is required !',
                    },
                    minLength: {
                      value: 3,
                      message: 'minimum characters are 3 !',
                    },
                    maxLength: {
                      value: 254,
                      message: 'max characters are 255 !',
                    },
                  })}
                  // onChange={handleChange}
                />
                {errors.url && (
                  <div style={{ color: '#FF9494' }} className="">
                    {errors.url?.message}
                  </div>
                )}
              </div>
              {formGenerator()}
            </div>

            <button
              className="text-white font-semibold bg-shifti-pink-bg rounded-full px-12 py-2 mr-16 mt-7 m-auto box-xl shadow-xl block"
              type="submit"
              // onClick={() => }
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditShop;
