import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      margin: 'auto',
      background: 'none',
      display: 'block',
      shapeRendering: 'auto',
      zIndex: '9999',
    }}
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className={className}
  >
    <path
      d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
      fill="#181143"
      stroke="none"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="1s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 51;360 50 51"
      />
    </path>
  </svg>
);
Spinner.propTypes = {
  className: PropTypes.string.isRequired,
};
export default Spinner;
