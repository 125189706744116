import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';

import { selectCurrentToken } from '../../features/auth/authSlice';

function CreateShopOffre({
  setCurrentTab,
  currentTab,
  formData,
  setFormData,
  subscriptionTime,
  setSubscriptionTime,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);

  const getPlans = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/plans/`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getPlans().then((countriesResponse) => {
      setPlans(countriesResponse);
      setLoading(false);
    });
  }, []);

  const hundleNextStep = (props) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      plan_id: props,
    }));

    setCurrentTab('2');
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="space-y-10">
      <div className="title flex flex-row  ml-16 mt-12">
        <h1 className="text-[3rem] font-medium text-[#231D4F]">
          Plans & Pricing
        </h1>
      </div>
      <div className="flex flex-row w-[70%] h-[2.5rem] justify-between items-center  ml-16 mt-24">
        <div className="text-[#848199] text-sm">
          Whether your time-saving automation needs are large or small, we’re
          here to help you scale.
        </div>
        <div className="border-1 border-[#EC1577] h-full  rounded-3xl relative w-1/6">
          <div
            id="btn"
            className="btn bg-[#EC1577] rounded-3xl absolute left-[0.1rem] right-[0.1rem] top-[0.1rem] w-1/2 h-[95%] transition "
            style={{
              transition: 'transform 0.5s ease',
              transform:
                subscriptionTime === 'monthly'
                  ? 'translateX(0%)'
                  : 'translateX(97%)',
            }}
          />
          <button
            type="button"
            className="w-1/2 relative z-10 h-full text-sm"
            onClick={() => {
              setSubscriptionTime('monthly');
              setFormData((prevFormData) => ({
                ...prevFormData,
                is_monthly: true,
              }));
              setFormData((prevFormData) => ({
                ...prevFormData,
                is_yearly: false,
              }));
            }}
            style={{
              color: subscriptionTime === 'monthly' ? 'white' : '#231D4F',
              transition: `color 0.2s ${
                subscriptionTime === 'monthly' ? '0.2s' : '0s'
              } ease`,
            }}
          >
            Monthly
          </button>
          <button
            type="button"
            className="w-1/2 relative z-10 h-full text-sm"
            onClick={() => {
              setSubscriptionTime('yearly');
              setFormData((prevFormData) => ({
                ...prevFormData,
                is_yearly: true,
              }));
              setFormData((prevFormData) => ({
                ...prevFormData,
                is_monthly: false,
              }));
            }}
            style={{
              color: subscriptionTime === 'yearly' ? 'white' : '#231D4F',
              transition: `color 0.2s ${
                subscriptionTime === 'yearly' ? '0.2s' : '0s'
              } ease`,
            }}
          >
            Yearly
          </button>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex justify-center gap-4 w-full">
          {plans
            .filter((plan) => plan.name !== 'Entreprise')
            .map((plan, i) => (
              <div className="flex justify-center w-[17vw]" key={i}>
                <div
                  className="bg-white border-[#181144] border-[0.5px] self-center rounded-3xl p-8 space-y-5 w-full drop-shadow-2xl"
                  style={{
                    backgroundColor: plan.is_best_plan ? '#181144' : '#ffffff',
                    scale: plan.is_best_plan ? '1.10' : '1',
                    zIndex: plan.is_best_plan ? '2' : '1',
                  }}
                >
                  {plan.is_best_plan ? (
                    <div className="flex justify-end">
                      <div className="desktop:p-2 p-1 bg-white rounded-3xl text-[#EC1577] desktop:text-sm  text-xs font-semibold w-3/6 text-center">
                        First 50 subcribers
                      </div>
                    </div>
                  ) : (
                    <div className="p-2 text-transparent pointer-events-none">
                      Best Plan
                    </div>
                  )}

                  {plan.name !== 'Entreprise' ? (
                    <h2
                      className="text-[#231D4F] desktop:pl-9 pl-3"
                      style={{
                        color: plan.is_best_plan ? '#ffffff' : '#181144',
                      }}
                    >
                      <span className="font-bold text-[2.5rem]">
                        {decoded.UserInfo.country === 'Tunisia' &&
                          (subscriptionTime === 'monthly'
                            ? plan.price_dt_monthly
                            : plan.price_dt_yearly)}
                        {decoded.UserInfo.country === 'France' &&
                          (subscriptionTime === 'monthly'
                            ? plan.price_euro_monthly
                            : plan.price_euro_yearly)}
                        {decoded.UserInfo.country !== 'Tunisia' &&
                          decoded.UserInfo.country !== 'France' &&
                          (subscriptionTime === 'monthly'
                            ? plan.price_dollar_monthly
                            : plan.price_dollar_yearly)}
                      </span>
                      <span className="font-bold text-md">
                        {decoded.UserInfo.country === 'Tunisia' && 'dt'}
                        {decoded.UserInfo.country === 'France' && '€'}
                        {decoded.UserInfo.country !== 'Tunisia' &&
                          decoded.UserInfo.country !== 'France' &&
                          '$'}
                      </span>
                      <span className="font-semibold text-l">/</span>
                      <span className="font-semibold text-lg">
                        {subscriptionTime === 'monthly' ? 'month' : 'year'}
                      </span>
                    </h2>
                  ) : (
                    <h2
                      className="text-transparent ml-4 bg-transparent pointer-events-none "
                      style={{
                        color: plan.is_best_plan ? '#ffffff' : '#ffffff',
                      }}
                    >
                      <span className="font-bold text-[2.5rem]">
                        {subscriptionTime === 'monthly'
                          ? plan.price_month
                          : plan.price_year}
                      </span>
                      <span className="font-bold text-sm">DT</span>
                      <span className="font-semibold text-l">/</span>
                      <span className="font-semibold text-lg">
                        {subscriptionTime === 'monthly' ? 'month' : 'year'}
                      </span>
                    </h2>
                  )}
                  <div className="flex flex-col space-y-5 desktop:pl-5">
                    <h2
                      className="desktop:text-[2.5rem] text-[2rem] font-bold text-[#231D4F]"
                      style={{
                        color: plan.is_best_plan ? '#ffffff' : '#181144',
                      }}
                    >
                      {plan.name}
                    </h2>
                    {/* <p
                      className="desktop:text-lg text-base font-medium text-[#231D4F] h-12"
                      style={{
                        color: plan.is_best_plan ? '#ffffff' : '#181144',
                      }}
                    >
                      {plan.description}
                    </p> */}
                    <ul className="space-y-2 h-[10rem]">
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}
                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {plan.name === 'Entreprise'
                            ? 'Unlimited shops'
                            : `number of shops : ${plan.limit_shops}`}
                        </span>
                      </li>
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}
                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {plan.name === 'Entreprise'
                            ? 'Unlimited users'
                            : `number of users : ${plan.limit_employees}`}
                        </span>{' '}
                      </li>
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}
                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {plan.name === 'Entreprise'
                            ? 'Unlimited products'
                            : `number of products : ${plan.limit_products}`}
                        </span>
                      </li>
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}
                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {plan.name === 'Entreprise'
                            ? 'Unlimited support hours'
                            : `technical support : ${plan.limit_support} h`}
                        </span>
                      </li>
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}

                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {' '}
                          {plan.name === 'Entreprise'
                            ? 'Unlimited storage'
                            : `storage : ${plan.storage} GB`}
                        </span>
                      </li>
                    </ul>
                    <div className="flex justify-center items-center">
                      {plan.name === 'Entreprise' ? (
                        <button
                          type="button"
                          className="bg-[#231D4F] p-4 text-white self-center w-full rounded-3xl hover:scale-110 transition"
                          style={{
                            backgroundColor: plan.is_best_plan
                              ? '#EC1577'
                              : '#231D4F',
                          }}
                        >
                          Contact us
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="bg-[#231D4F] p-4 text-white self-center w-full rounded-3xl hover:scale-110 transition"
                          style={{
                            backgroundColor: plan.is_best_plan
                              ? '#EC1577'
                              : '#231D4F',
                          }}
                          onClick={() => hundleNextStep(plan.id)}
                        >
                          Choose plan
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex justify-center w-full">
          {plans
            .filter((plan) => plan.name === 'Entreprise')
            .map((plan, i) => (
              <div className="flex justify-center w-[80%]" key={i}>
                <div
                  className="bg-white border-[#181144] border-[0.5px] self-center rounded-3xl p-8 space-y-5 w-full drop-shadow-2xl"
                  style={{
                    backgroundColor: plan.is_best_plan ? '#181144' : '#ffffff',
                    scale: plan.is_best_plan ? '1.10' : '1',
                    zIndex: plan.is_best_plan ? '2' : '1',
                  }}
                >
                  <div className="flex flex-col space-y-5 desktop:pl-5">
                    <h2
                      className="desktop:text-[2.5rem] text-[2rem] font-bold text-[#231D4F]"
                      style={{
                        color: plan.is_best_plan ? '#ffffff' : '#181144',
                      }}
                    >
                      {plan.name}
                    </h2>
                    <p
                      className="desktop:text-lg text-base font-medium text-[#231D4F]"
                      style={{
                        color: plan.is_best_plan ? '#ffffff' : '#181144',
                      }}
                    >
                      {plan.description}
                    </p>
                    <ul className="space-y-2 h-[10rem]">
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}
                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {plan.name === 'Entreprise'
                            ? 'Unlimited shops'
                            : `number of shops : ${plan.limit_shops}`}
                        </span>
                      </li>
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}
                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {plan.name === 'Entreprise'
                            ? 'Unlimited users'
                            : `number of users : ${plan.limit_employees}`}
                        </span>{' '}
                      </li>
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}
                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {plan.name === 'Entreprise'
                            ? 'Unlimited products'
                            : `number of products : ${plan.limit_products}`}
                        </span>
                      </li>
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}
                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {plan.name === 'Entreprise'
                            ? 'Unlimited support hours'
                            : `technical support : ${plan.limit_support} h`}
                        </span>
                      </li>
                      <li className="flex space-x-2 items-center">
                        {plan.name === 'Entreprise' ? (
                          <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        ) : (
                          <div className="w-6 h-6  relative">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                            />
                          </div>
                        )}

                        <span
                          className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                          style={{
                            color: plan.is_best_plan ? '#ffffff' : '#181144',
                          }}
                        >
                          {' '}
                          {plan.name === 'Entreprise'
                            ? 'Unlimited storage'
                            : `storage : ${plan.storage} GB`}
                        </span>
                      </li>
                    </ul>
                    <div className="flex justify-center items-center">
                      {plan.name === 'Entreprise' ? (
                        <button
                          type="button"
                          className="bg-[#231D4F] p-4 text-white self-center w-full rounded-3xl hover:scale-110 transition"
                          style={{
                            backgroundColor: plan.is_best_plan
                              ? '#EC1577'
                              : '#231D4F',
                          }}
                        >
                          Contact us
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="bg-[#231D4F] p-4 text-white self-center w-full rounded-3xl hover:scale-110 transition"
                          style={{
                            backgroundColor: plan.is_best_plan
                              ? '#EC1577'
                              : '#231D4F',
                          }}
                          onClick={() => hundleNextStep(plan.id)}
                        >
                          Choose plan
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

CreateShopOffre.propTypes = {
  currentTab: PropTypes.object.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  subscriptionTime: PropTypes.object.isRequired,
  setSubscriptionTime: PropTypes.func.isRequired,
};

export default CreateShopOffre;
