/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BsShopWindow } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { productContext } from '../pages/products/CreateProduct';
import { ShopContext } from '../contexts/ShopContext';

const MultiShopsSelection = ({
  className = '',
  defaultShop = true,
  language = true,
  context = 'shop',
}) => {
  const [
    shops,
    setShops,
    allShopTypes,
    allShopIds,
    selectedShop,
    setSelectedShop,
    selectedShops,
    setSelectedShops,
    langs,
    setLangs,
    selectedLangs,
    setSelectedLangs,
  ] = useContext(context === 'product' ? productContext : ShopContext);

  const [shopOpen, setShopOpen] = useState(false);

  const handleShops = (e) => {
    const temp = [...selectedShops];
    const tempLangs = [];
    let itemIndex = -1;
    selectedShops.map((row, i) => {
      if (JSON.stringify(shops[e.target.value]) === JSON.stringify(row))
        itemIndex = i;
      return null;
    });
    if (e.target.checked === true && itemIndex === -1) {
      temp.push(shops[e.target.value]);
    } else if (itemIndex > -1) {
      temp.splice(itemIndex, 1);
    }
    temp.map((shop) =>
      shop.Langs.map((lang) => {
        tempLangs.push({ iso_code: lang.iso_code, checked: true });
        return null;
      })
    );
    const uniqueLangs = Array.from(
      new Set(tempLangs.map(JSON.stringify)),
      JSON.parse
    );
    setLangs([...uniqueLangs]);
    setSelectedShops(temp);
  };

  const handleLang = (e, i) => {
    const temp = [...langs];
    temp[i].checked = !temp[i].checked;
    setLangs(temp);
  };

  const getShopNames = () => {
    let words;
    selectedShops.map((shop, i) => {
      if (!i) words = shop.name;
      else words += ` + ${shop.name}`;
      return null;
    });
    return words;
  };

  return (
    <div className={`${className} pointer-events-none`}>
      <div className="flex gap-4 pointer-events-auto">
        <div
          className="flex justify-center gap-4 shadow-lg rounded-full relative p-2 bg-shifti-light-grey-bg cursor-pointer w-full z-50 text-center"
          onClick={() => setShopOpen((prevState) => !prevState)}
        >
          <BsShopWindow className="h-[1.5rem] w-[1.5rem] my-auto inline-block text-shifti-blue text-opacity-70 checked-1 transition" />
          <span className="text-shifti-pink font-semibold my-auto inline-block">
            {getShopNames()}
          </span>
          <MdKeyboardArrowDown
            className={`text-gray-400 text-4xl duration-500 m-auto absolute right-4 top-0 bottom-0 ${
              shopOpen ? '' : 'rotate-180'
            }`}
          />
        </div>
        <div
          className={`shadow-lg rounded-full p-2 bg-shifti-light-grey-bg cursor-pointer w-1/12 ${
            language ? '' : 'hidden'
          }`}
          onClick={() => setShopOpen((prevState) => !prevState)}
        >
          <MdKeyboardArrowDown
            className={`text-gray-400 text-4xl duration-500 ml-auto ${
              shopOpen ? '' : 'rotate-180'
            }`}
          />
        </div>
      </div>
      <div className={`flex gap-4 ${shopOpen ? 'pointer-events-auto' : ''}`}>
        <div
          className={`z-50 shadow-lg rounded-2xl p-2 bg-shifti-light-grey-bg mt-6 duration-500 w-full ${
            shopOpen ? '' : 'invisible opacity-0'
          }`}
        >
          <center>
            <div className="flex justify-center gap-2">
              <BsShopWindow className="h-[1.5rem] w-[1.5rem] text-shifti-blue text-opacity-70 checked-1 transition" />
              <span className="text-shifti-pink font-semibold">Shops</span>
            </div>
            <hr className="border-[#181143] border-1 mx-6 my-2" />
            <div className="flex flex-col text-left w-1/6 m-auto">
              {shops.map((shop, i) => (
                <label
                  htmlFor={shop.id}
                  className="flex items-center cursor-pointer"
                  key={i}
                >
                  <div className="relative inline-block">
                    <input
                      id={shop.id}
                      type="checkbox"
                      value={i}
                      className="permission-checkbox align-middle	h-8 w-8 rounded-full outline-0 appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
                      onChange={(e) => handleShops(e)}
                      key={i}
                      checked={selectedShops.some((obj) => obj.id === shop.id)}
                    />
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="h-3 w-3 text-[#EC1577] right-0 left-0 top-1 bottom-0 absolute pointer-events-none text-opacity-0 checked-1 m-auto"
                    />
                  </div>
                  <span className="ml-3">{shop.name}</span>
                </label>
              ))}
            </div>
          </center>
        </div>
        <div
          className={`shadow-lg rounded-2xl p-2 bg-shifti-light-grey-bg mt-6 duration-500 w-1/12 ${
            language ? '' : 'hidden'
          } ${shopOpen ? '' : 'invisible opacity-0'}`}
        >
          {langs.map((lang, i) => (
            <label
              htmlFor={i}
              key={i}
              className="flex items-center cursor-pointer"
            >
              <div className="relative inline-block">
                <input
                  id={i}
                  type="checkbox"
                  value={lang.iso_code}
                  className="permission-checkbox align-middle	h-8 w-8 rounded-full outline-0 appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
                  onChange={(e) => handleLang(e, i)}
                  key={i}
                  checked={lang.checked}
                />
                <FontAwesomeIcon
                  icon={faCheck}
                  className="h-3 w-3 text-[#EC1577] right-0 left-0 top-1 bottom-0 absolute pointer-events-none text-opacity-0 checked-1 m-auto"
                />
              </div>
              <span className="ml-3">{lang.iso_code}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};
MultiShopsSelection.propTypes = {
  className: PropTypes.string.isRequired,
  defaultShop: PropTypes.bool.isRequired,
  language: PropTypes.bool.isRequired,
  context: PropTypes.string.isRequired,
};
export default MultiShopsSelection;
