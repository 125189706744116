import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from '@syncfusion/ej2-react-grids';

import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import api from '../../utils/apiService';
import { Header } from '../../components';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const PaymentsPage = () => {
  const token = useSelector(selectCurrentToken);
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };
  const [items, setItems] = useState([]);
  const settings = { wrapMode: 'Content' };
  const [isGenerated, setIsGenerated] = useState(false);
  const contextMenuItems = [
    'AutoFit',
    'AutoFitAll',
    'SortAscending',
    'SortDescending',
    'Copy',
    'Edit',
    'Delete',
    'Save',
    'Cancel',
    'PdfExport',
    'ExcelExport',
    'CsvExport',
    'FirstPage',
    'PrevPage',
    'LastPage',
    'NextPage',
  ];

  const getPayments = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/payment`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getPayments();
  }, [isGenerated]);

  const user = ({ User }) => (
    <div>
      {User.first_name} {User.last_name}
    </div>
  );

  const downloadPdf = async (id) => {
    const decoded = jwtDecode(token);

    try {
      const config = {
        method: 'get',
        responseType: 'blob',
        url: `${apiURL}/payment/invoice/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      await api.request(config).then(async (response) => {
        if (response.status === 200) {
          const pdfBlob = new Blob([response.data], {
            type: 'application/pdf',
          });

          const pdfBlobUrl = URL.createObjectURL(pdfBlob);

          const downloadLink = document.createElement('a');
          downloadLink.href = pdfBlobUrl;
          downloadLink.download = 'invoice.pdf';
          document.body.appendChild(downloadLink);
          downloadLink.click();

          URL.revokeObjectURL(pdfBlobUrl);
        } else {
          console.error('Server response was not OK.');
        }
      });
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const generatePDF = async (id) => {
    setIsGenerated(false);
    const decoded = jwtDecode(token);
    const idToast = toast.loading('Please wait...');
    try {
      const config = {
        method: 'patch',
        responseType: 'blob',
        url: `${apiURL}/payment/generateInvoicePdf/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      await api.request(config).then(async (response) => {
        toast.update(idToast, {
          render: 'invoice pdf is generated !',
          type: response.status === 200,
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });

        setIsGenerated(true);
      });
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const invoiceButton = ({ invoice, id }) => (
    <div className="flex items-center justify-center">
      {invoice !== null ? (
        <button
          className="bg-[#EC1577] rounded-3xl text-white p-2 text-xs w-[7rem]"
          type="button"
          onClick={() => downloadPdf(id)}
        >
          Download invoice
        </button>
      ) : (
        <button
          className=" bg-white border-2 border-[#EC1577] rounded-3xl text-[#EC1577] p-2 text-xs w-[7rem]"
          type="button"
          onClick={() => generatePDF(id)}
        >
          Generate pdf
        </button>
      )}
    </div>
  );

  const date = ({ createdAt }) => (
    <div>{createdAt.replace('.000Z', '').replace('T', ' ')}</div>
  );

  user.propTypes = {
    User: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      // Add other expected properties of User here if any
    }),
  };

  const paymentsGrid = [
    { field: 'id', headerText: 'Id', width: '50', textAlign: 'Start' },
    {
      headerText: 'User',
      template: user,
      width: '100',
      textAlign: 'Center',
    },
    { template: date, headerText: 'Due Date', width: '80' },
    { field: 'description', headerText: 'Description', width: '80' },
    {
      field: 'is_paid',
      headerText: 'Paid',
      width: '60',
      textAlign: 'Center',
    },
    {
      field: 'total_price_ttc',
      headerText: 'Amount Paid',
      width: '80',
      textAlign: 'Center',
    },
    {
      field: 'payment_method',
      headerText: 'Payment method',
      width: '80',
      textAlign: 'Center',
    },
    {
      headerText: 'Invoice',
      template: invoiceButton,
      width: '100',
      textAlign: 'Center',
    },
  ];

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl">
      <Header title="Payments" />

      <div className="m-2 mt-10 p-2 md:p-10 bg-white rounded-3xl">
        <GridComponent
          id="gridcomp"
          dataSource={items}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
          pageSettings={{ pageSize: 10 }}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {paymentsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Sort,
              ContextMenu,
              Filter,
              Page,
              ExcelExport,
              Edit,
              PdfExport,
            ]}
          />
        </GridComponent>
      </div>
    </div>
  );
};

export default PaymentsPage;
