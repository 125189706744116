import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faExclamationTriangle,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export default function CartPreview() {
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector(selectCurrentToken);
  const { id } = useParams();

  const getCart = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/carts/${id}`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setCart(response.data);
        setLoading(false);
      })
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getCart();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  const handleTotals = () => {
    let totalProducts = 0;

    cart.CartProducts.map((product) => {
      totalProducts +=
        parseFloat(product.Product.price) * parseFloat(product.quantity);
      return null;
    });
    return (
      <div className="space-y-2">
        <div className="totalProducts flex flex-row justify-between">
          <div>Total products</div>
          <div>{totalProducts.toFixed(3)} TND</div>
        </div>
        <div className="totalPort flex flex-row justify-between">
          <div>Total shipping costs</div>
          <div>-- TND</div>
        </div>
        <div className="total flex flex-row justify-between bg-[#cce9d7] rounded-lg p-1">
          <div className="font-bold">Total</div>
          <div>price is not available in ttc</div>
        </div>
      </div>
    );
  };

  const cartProducts = () => {
    function productPriceTotal(quantity, price) {
      let totalPrice = 0;
      totalPrice = price * quantity;
      return totalPrice.toFixed(3);
    }

    return (
      <div className="product-table">
        <table className="w-full">
          <thead>
            <tr className="">
              <th className="text-[#AAA6C3] font-medium">Image</th>
              <th className="text-[#AAA6C3] font-medium">Product</th>
              <th className="text-[#AAA6C3] font-medium">Base price</th>
              <th className="text-[#AAA6C3] font-medium">Quantity</th>
              <th className="text-[#AAA6C3] font-medium">Stock</th>
              <th className="text-[#AAA6C3] font-medium">Total</th>
            </tr>
          </thead>
          <tbody>
            {cart.CartProducts.map((product) => (
              <tr key={product.id} className=" border-b-2 border-b-[#E1DFEE]">
                <td>
                  <div>
                    <img
                      alt=""
                      src={`${apiURL}/images/${cart.shop_id}/${product.Product.ProductShops[0].foreign_id}/${product.Product.default_image_id}`}
                      className="w-36 h-32"
                    />
                  </div>
                </td>
                <td className="text-[#181143]  text-md  font-semibold text-center ">
                  {product.Product.ProductLangs[0].name}
                </td>
                <td className="text-[#181143]  text-md  font-semibold text-center">
                  {product.Product.price} TND
                </td>
                <td className="text-[#181143]  text-md  font-semibold text-center">
                  {product.quantity}
                </td>
                <td className="text-[#181143]  text-md  font-semibold text-center">
                  --
                </td>
                <td className="text-[#181143]  text-md  font-semibold text-center">
                  {productPriceTotal(product.quantity, product.Product.price)}{' '}
                  TND
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl flex flex-col space-y-5 ">
      <div className=" rounded-3xl grid grid-cols-2 space-x-5">
        <div className="md:mt-2 p-2 bg-white md:p-5 rounded-3xl flex flex-col justify-between customer-info shadow-2xl ">
          <div className="title-container">
            <h2 className="font-bold text-[24px] text-[#EC1577]">
              customer information
            </h2>
            <div className="h-[2px] mt-2 mb-2 bg-[#E1DFEE]" />
          </div>
          {cart.Customer ? (
            <div className="md:pl-10 space-y-3">
              <div className="info grid grid-cols-4 justify-items-evenly col-span-1">
                <div className="bg-[#EC1577] rounded-full w-16 h-16 relative shadow-xl self-center justify-self-start">
                  <FontAwesomeIcon
                    icon={faUser}
                    className=" left-[10%] top-[5%] scale-100  h-[3rem] w-[3rem]  text-[#fff] pointer-events-none absolute "
                  />
                </div>
                <div className="flex flex-col col-span-2">
                  <p className="font-medium text-lg text-[#181143]">
                    {cart.Customer.first_name} {cart.Customer.last_name}
                  </p>
                  <p>{cart.Customer.email}</p>
                  <button
                    type="button"
                    className="font-semibold text-sm text-yellow-400 self-start"
                  >
                    View more details
                  </button>
                </div>
                <div className="col-span-1 text-[#AAA6C3]">
                  #{cart.Customer.id}
                </div>
              </div>
              <div>
                <p className="font-semibold text-lg text-[#181143]">
                  Account registered:
                </p>
                <p>
                  {cart.Customer.createdAt
                    .replace('.000Z', '')
                    .replace('T', ' ')}
                </p>
              </div>
              <div>
                <p className="font-semibold text-lg text-[#181143]">
                  Validated orders placed:
                </p>
                <p>0</p>
              </div>
              <div>
                <p className="font-semibold text-lg text-[#181143]">
                  Total spent since registration:
                </p>
              </div>
            </div>
          ) : (
            <div className="relative h-full w-[50rem] self-center text-center flex flex-col justify-center">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className=" left-[45%]  bottom-[60%] scale-100  h-[3rem] w-[3rem]  text-[#181143] pointer-events-none absolute "
              />
              <p className="font-semibold text-lg text-[#181143]">
                Unregistered visitor
              </p>
            </div>
          )}
        </div>
        {/* ------------------------------box--------------------------------------- 2 */}
        <div className=" rounded-3xl flex flex-col space-y-5 ">
          <div className="md:mt-2 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5  shadow-2xl">
            <div className="flex flex-col items-center space-y-3">
              <h2 className="font-bold text-[24px] text-[#EC1577] ">
                Cart total
              </h2>
              <p className="font-medium text-[16px] text-[#AAA6C3] ">
                Panier N°{cart.id}
              </p>
              <p className="font-semibold text-[20px] text-[#181143] ">
                {cart.Order
                  ? cart.CartProducts.map((product) => {
                      let totalProducts = 0;
                      return (totalProducts +=
                        parseFloat(product.Product.price) *
                        parseFloat(product.quantity)).toFixed(3);
                    })
                  : 'price is not available in ttc'}
              </p>
            </div>
          </div>
          <div className="md:mt-2 p-2 bg-white md:p-5 rounded-3xl flex flex-col   shadow-2xl">
            <div className="title-container">
              <h2 className="font-bold text-[24px] text-[#EC1577]">
                Order information
              </h2>
              <div className="h-[2px] mt-2 mb-2 bg-[#E1DFEE]" />
            </div>

            {cart.Order ? (
              <div className="flex flex-col space-y-2">
                <p className="font-semibold text-[18px] text-[#181143] ">
                  Cammande N°{cart.Order.id}
                </p>
                <p className="font-medium text-[16px] text-[#AAA6C3] ">
                  Created at :{' '}
                  {cart.Order.createdAt.replace('.000Z', '').replace('T', ' ')}
                </p>
              </div>
            ) : (
              <div className="relative w-[40rem]">
                <p className="font-semibold text-[18px] text-[#181143] ml-14">
                  No orders created from this cart.
                </p>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className=" left-[3%]  bottom-[17%] scale-100  h-[1.8rem] w-[1.8rem]  text-[#181143] pointer-events-none absolute "
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="md:mt-2 p-2 bg-white md:p-5 rounded-3xl flex flex-col space-y-5  shadow-2xl">
        <div className="title-container">
          <h2 className="font-bold text-[24px] text-[#EC1577]">
            Cart contents
          </h2>
          <div className="h-[2px] mt-2 mb-2 bg-[#E1DFEE]" />
        </div>

        {cartProducts()}
        {handleTotals()}
        <div className="alert-container h-[3rem] w-full bg-[#EC1577] rounded-[1.2rem] relative flex items-center mt-5">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="h-7 w-18  text-[#181143] absolute right-[88.5%]  md:right-[96.5%] md:bottom-[28%] pointer-events-none  checked transition scale-100"
          />
          <p className="relative p-2 md:ml-[3rem] ml-[2rem] md:text-[1rem] text-[0.7rem]">
            {' '}
            For this group of customers, the prices are displayed: tax included!
          </p>
        </div>
      </div>
    </div>
  );
}
