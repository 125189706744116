import React, { useState, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import Select from '../../components/Select';
import styles from '../../css/login.module.css';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export default function CreateUser() {
  const token = useSelector(selectCurrentToken);
  const [items, setItems] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    role_id: 0,
    email: '',
    phone: '',
    password: '',
    validation: {
      error: [true, true, false, true, true, true],
      errorMsg: [
        'Obligatoire',
        'Obligatoire',
        'Obligatoire',
        'Obligatoire',
        'Obligatoire',
        'Obligatoire',
      ],
    },
  });
  const [formDataError, setformDataError] = useState([
    true,
    true,
    false,
    true,
    true,
    true,
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getUserRoles();
  }, []);

  const getUserRoles = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/user/getRoles`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    const decoded = jwtDecode(token);
    const idToast = toast.loading('Please wait...');
    const ERROR_MSG = [...formData.validation.errorMsg];
    setformDataError(ERROR_MSG);
    if (!formData.validation.error.includes(true)) {
      const data = JSON.stringify({
        first_name: formData.first_name,
        last_name: formData.last_name,
        role_id: formData.role_id,
        email: formData.email,
        phone: formData.phone,
        password: formData.password,
      });
      const config = {
        method: 'post',
        url: `${apiURL}/user/addUser`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      api
        .request(config)
        .then((response) => {
          toast.update(idToast, {
            render: 'New User Is Created !',
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 1500);
        })
        .catch(() => {});
    }
  };

  const handelFormDataChange = (value, key, index) => {
    const aux = { ...formData };
    aux[key] = value;
    if (key === 'first_name') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'last_name') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'email') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else if (!isEmail(value)) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Inavlid Email';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'phone') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    if (key === 'password') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    setFormData(aux);
  };

  return (
    <div className="mr-3 p-2 md:p-5 ml-3 rounded-3xl mb-3 md:mt-8 mt-20 flex justify-center">
      <div className="flex justify-center flex-col w-[90%]">
        <Header category="Page" title="Create User" />
        <form
          action=""
          className="sft-form formStyle mt-2 md:mt-0 p-2 md:p-10 bg-white rounded-3xl md:w-full"
        >
          <div className="sft-input-container grid grid-cols-2 gap-y-8 mt-6 md:w-[40%] lg:w-[81%]">
            <label htmlFor="name" className="formLabel">
              First Name :
            </label>
            <div className="input-container">
              <input
                id="name"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'first_name', 0)
                }
                type="text"
                className="formInput"
              />
              <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
            </div>
            <label htmlFor="name" className="formLabel">
              Last Name :
            </label>
            <div className="input-container">
              <input
                type="text"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'last_name', 1)
                }
                className="formInput"
              />
              <div style={{ color: '#FF9494' }}>{formDataError[1]}</div>
            </div>
          </div>
          <div className="sft-input-container grid grid-cols-2 gap-y-4 mt-6 md:w-[40%] lg:w-[81%]">
            <label htmlFor="name" className="formLabel mr-[3rem] md:mr-[4rem]">
              Role :
            </label>
            <Select
              onChange={(e) =>
                handelFormDataChange(e.target.value, 'role_id', 2)
              }
              items={
                items.length > 0 && Object.keys(items[0]).length !== 0
                  ? items
                  : []
              }
            />
          </div>

          <div className="sft-input-container grid grid-cols-2 gap-y-8 mt-6 md:w-[40%] lg:w-[81%]">
            <label
              htmlFor="name"
              className="formLabel mr-[2rem] md:mr-[3.7rem]"
            >
              Email :
            </label>
            <div className="input-container">
              <input
                type="email"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'email', 3)
                }
                className="formInput"
              />
              <div style={{ color: '#FF9494' }}>{formDataError[3]}</div>
            </div>
            <label htmlFor="name" className="formLabel mr-[2%]">
              Password :
            </label>
            <div className="input-container">
              <div className={styles.passwordContainer}>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  onChange={(e) =>
                    handelFormDataChange(e.target.value, 'password', 5)
                  }
                  className="formInput"
                />
                <Link
                  className={styles.eye}
                  onClick={() => setPasswordShown(!passwordShown)}
                >
                  {passwordShown === true ? (
                    <AiOutlineEyeInvisible size={30} />
                  ) : (
                    <AiOutlineEye size={30} />
                  )}
                </Link>
              </div>
              <div style={{ color: '#FF9494' }}>{formDataError[5]}</div>
            </div>
          </div>
          <div className="sft-input-container grid grid-cols-2 gap-y-4 mt-6 md:w-[40%] lg:w-[81%]">
            <label htmlFor="name" className="formLabel mr-[2rem] md:mr-[3rem]">
              Phone :
            </label>
            <div className="input-container">
              <input
                type="tel"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'phone', 4)
                }
                className="formInput"
              />
              <div style={{ color: '#FF9494' }}>{formDataError[4]}</div>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="formButton"
                onClick={handelSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
