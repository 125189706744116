import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import GroupAttributeList from './GroupAttributeList';
import GroupAttribute from './GroupAttribute';
import AttributeValue from './AttributeValue';
import FeatureList from '../features/FeatureList';
import Feature from '../features/Feature';
import FeatureValue from '../features/FeatureValue';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const AttributeFeaturePage = () => {
  const token = useSelector(selectCurrentToken);
  const [value, setValue] = useState({ component: 'GroupAttribute', id: null });
  const [currentTab, setCurrentTab] = useState('1');
  const [features, setFeatures] = useState([]);
  const [groupAttributes, setGroupAttributes] = useState([]);
  const tabs = [
    {
      id: 1,
      tabTitle: 'Attributes',
      title: 'Attributes',
      content: (() => {
        if (
          value.component === 'AddGroupAttribute' ||
          value.component === 'AddAttributeValue'
        ) {
          if (value.component === 'AddGroupAttribute') {
            return <GroupAttribute />;
          }
          return <AttributeValue />;
        }
        return <GroupAttributeList groupAttributes={groupAttributes} />;
      })(),
    },
    {
      id: 2,
      tabTitle: 'Features',
      title: 'Features',
      content: (() => {
        if (
          value.component === 'AddFeature' ||
          value.component === 'AddFeatureValue'
        ) {
          if (value.component === 'AddFeature') {
            return <Feature />;
          }
          return <FeatureValue />;
        }
        return <FeatureList features={features} />;
      })(),
    },
  ];

  const getFeatures = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/features/get-all-features`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setFeatures(response.data);
      })
      .catch((error) => {
        JSON.stringify(error);
      });
  };

  const getGroupAttributes = async () => {
    const decoded = jwtDecode(token);

    const config = {
      method: 'get',
      url: `${apiURL}/attributes/get-all-attributes-group`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setGroupAttributes(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  const handleTabClick = (e) => {
    setValue({
      title: 'Attributes',
      component: 'GroupAttribute',
      id: null,
    });
    setCurrentTab(e.target.id);
  };

  useEffect(() => {
    getGroupAttributes();
    getFeatures();
  }, []);

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl">
      <div className="flex justify-between">
        {currentTab === '1' ? (
          <Header category="Page" title={value.title} />
        ) : (
          <Header category="Page" title="Feature" />
        )}
        <div className="flex justify-between gap-2 items-center">
          <Link
            className="cursor-pointer bg-shifti-grey-btn-bg text-white rounded-full w-64"
            onClick={() => {
              if (currentTab === '1') {
                setValue({
                  title: 'Add a new attribute',
                  component: 'AddGroupAttribute',
                  id: null,
                });
              } else {
                setValue({
                  title: 'Add a new feature',
                  component: 'AddFeature',
                  id: null,
                });
              }
            }}
          >
            <span className="px-2 py-2 flex items-center gap-8 text-xl">
              <span className="px-2 bg-white text-shifti-grey-text rounded-full">
                +
              </span>
              Create {currentTab === '1' ? 'Attribute' : 'Feature'}
            </span>
          </Link>
          <Link
            className="cursor-pointer bg-shifti-grey-btn-bg text-white rounded-full w-64"
            onClick={() => {
              if (currentTab === '1') {
                setValue({
                  title: 'Add a new value',
                  component: 'AddAttributeValue',
                  id: null,
                });
              } else {
                setValue({
                  title: 'Add a new value',
                  component: 'AddFeatureValue',
                  id: null,
                });
              }
            }}
          >
            <span className="px-2 py-2 flex items-center gap-8 text-xl">
              <Link
                to="/create-user"
                className="px-2 bg-white text-shifti-grey-text rounded-full"
              >
                +
              </Link>
              Add a value
            </span>
          </Link>
          <input
            type="search"
            className="bg-white text-grey rounded-full p-2 w-80"
            placeholder="Search: (ex: Reference, product...)"
          />
        </div>
      </div>
      <div className="flex justify-between flex-wrap gap-2">
        <div className="p-2 md:p-2 bg-white rounded-3xl flex sm:w-46 h-11 mt-[8px]">
          <div className="tabs">
            <div className="divide-x">
              {tabs.map((tab, i) => (
                <button
                  key={i}
                  type="button"
                  id={tab.id}
                  disabled={currentTab === `${tab.id}`}
                  onClick={handleTabClick}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tab.tabTitle} &nbsp;
                  &nbsp;
                </button>
              ))}
            </div>
          </div>
        </div>

        <div
          className="help-button mt-3"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <a
            style={{
              background: '#EC1577',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '25px',
              padding: '7.5px 40px 0px',
              fontWeight: 'normal',
              fontSize: '1,04vw',
              height: '37px',
            }}
          >
            Help
          </a>
        </div>
      </div>

      <div className="content">
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && <div> {tab.content} </div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AttributeFeaturePage;
