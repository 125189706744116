import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';

const apiURL = process.env.REACT_APP_API_URL;

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const actions = (props) => (
  <div>
    <button
      type="button"
      style={{ color: '#000' }}
      className="text-white py-1 px-2 capitalize rounded-2xl text-md"
      onClick={() => {
        window.location.href = `/edit-product/${props.id}`;
      }}
    >
      <FontAwesomeIcon icon={faPencil} className="w-6 h-6 text-[#AAA6C3]" />
    </button>
    <button
      type="button"
      style={{ color: '#000' }}
      className="text-white py-1 px-2 capitalize rounded-2xl text-md"
      onClick={() => {
        window.location.href = `/pop-up/?id=${props.id}&foregin_id=${props.ProductShops[0].foreign_id}`;
      }}
    >
      <FontAwesomeIcon icon={faTrash} className="w-6 h-6 text-[#AAA6C3]" />
    </button>
  </div>
);

const image = (props) => (
  <div>
    <img
      src={`${apiURL}/images/${props.ProductShops[0].shop_id}/${props.ProductShops[0].foreign_id}/${props.default_image_id}`}
      className="w-36 h-32"
      alt=""
    />
  </div>
);

export const productsGrid = [
  { field: 'id', headerText: 'Id', width: '50' },
  {
    field: 'image',
    headerText: 'Image',
    template: image,
    width: '120',
    textAlign: 'Center',
  },
  {
    field: 'ProductLangs.0.name',
    headerText: 'Name',
    width: '170',
    textAlign: 'Center',
  },
  {
    field: 'reference',
    headerText: 'Reference',
    width: '170',
    textAlign: 'Center',
  },
  { field: 'price', headerText: 'Price', width: '120', textAlign: 'Center' },
  { field: 'status', headerText: 'Status', width: '120', textAlign: 'Center' },
  {
    field: 'actions',
    headerText: 'Actions',
    template: actions,
    width: '120',
    textAlign: 'Center',
  },
];
