/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { MdKeyboardArrowRight } from 'react-icons/md';
import PropTypes from 'prop-types';
import { productContext } from '../pages/products/CreateProduct';
import Spinner from './Spinner';

const CategoryBox = ({ className = '', onChange }) => {
  const [
    shops,
    setShops,
    allShopTypes,
    allShopIds,
    selectedShop,
    setSelectedShop,
    selectedShops,
    setSelectedShops,
    langs,
    setLangs,
    selectedLangs,
    setSelectedLangs,
    formData,
    setFormData,
  ] = useContext(productContext);
  const [currentShopId, setCurrentShopId] = useState(
    selectedShop || selectedShops[0].id
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '2px solid #AAA6C3',
      borderRadius: '0.8rem',
      backgroundColor: '#E1DFEE',
      boxShadow:
        '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    }),
  };

  const handleCategoryChange = (e, name) => {
    const temp = { ...formData };
    if (name === 'toggle') {
      if (formData.sites[currentShopId].categories.toggledItems.includes(e)) {
        temp.sites[currentShopId].categories.toggledItems = formData.sites[
          currentShopId
        ].categories.toggledItems.filter((item) => item !== e);
      } else {
        temp.sites[currentShopId].categories.toggledItems.push(e);
      }
    } else if (
      name === 'categoryCheckbox' ||
      name === 'categorySelect' ||
      name === 'removeCategory'
    ) {
      const index = formData.sites[currentShopId].categories.selected.findIndex(
        (obj) => obj.value === e.value
      );
      if (index !== -1) {
        temp.sites[currentShopId].categories.selected = formData.sites[
          currentShopId
        ].categories.selected.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(e)
        );
      } else {
        temp.sites[currentShopId].categories.selected.push(e);
      }
    }
    setFormData(temp);
  };
  const generateCheckboxes = (e) =>
    e.map((category) => (
      <div key={category.value} className="relative">
        <span className="text-[#EC1577]">
          <MdKeyboardArrowRight
            onClick={() => handleCategoryChange(category.value, 'toggle')}
            className={`inline text-2xl	text-[#181143] cursor-pointer ${
              formData.sites[currentShopId].categories.toggledItems.includes(
                category.value
              )
                ? 'rotate-90'
                : ''
            }`}
          />
          <label>
            <input
              className="mr-2"
              type="checkbox"
              onChange={() =>
                handleCategoryChange(category, 'categoryCheckbox')
              }
              name={category.name}
              value={category.value}
              checked={formData.sites[
                currentShopId
              ].categories.selected.includes(category)}
            />
            {category.label}
          </label>
        </span>
        {category.children && category.children.length > 0 && (
          <div
            className={`ml-3 ${
              !formData.sites[currentShopId].categories.toggledItems.includes(
                category.value
              )
                ? 'absolute invisible '
                : ''
            }`}
          >
            {generateCheckboxes(category.children)}
          </div>
        )}
      </div>
    ));

  useEffect(() => {
    if (selectedShop && currentShopId !== selectedShop) {
      setCurrentShopId(selectedShop);
    }
  });

  if (formData.sites[currentShopId] && formData.sites[currentShopId].categories)
    return (
      <div>
        {selectedShop ? (
          ''
        ) : (
          <div className="bg-white h-12 rounded-t-xl divide-x -mb-4 inline-block px-4 pt-1 font-medium text-shifti-blue">
            {selectedShops.map((shop) => (
              <div
                className={`px-4 inline-block cursor-pointer divide-x ${
                  currentShopId === shop.id
                    ? 'text-shifti-pink font-semibold'
                    : ''
                }`}
                onClick={() => setCurrentShopId(shop.id)}
              >
                {shop.name}
              </div>
            ))}
          </div>
        )}
        {currentShopId ? (
          <div className={className}>
            <div className="px-1 py-2 w-full">
              <Select
                options={formData.sites[currentShopId].categories.categories}
                onChange={(e) => handleCategoryChange(e, 'categorySelect')}
                styles={customStyles}
                placeholder="search for categories"
              />
              <div className="flex justify-between gap-4 mt-4 w-full">
                <div className="w-1/2">
                  <label htmlFor="category" className="block">
                    Related categories
                  </label>
                  <div className="p-2 flex flex-wrap gap-2 rounded-2xl border-[#534B8580] border-solid border-2 w-full">
                    {formData.sites[currentShopId].categories.selected.map(
                      (category) => (
                        <span className="p-2 rounded-2xl bg-[#EC157780] text-white font-medium">
                          <span>
                            {category.label}
                            <span
                              id="category"
                              className="cursor-pointer text-xs pl-3"
                              onClick={() =>
                                handleCategoryChange(category, 'removeCategory')
                              }
                            >
                              X
                            </span>
                          </span>
                        </span>
                      )
                    )}
                  </div>
                </div>
                <div className="w-1/2">
                  <label htmlFor="categoryCheckbox">Main category</label>
                  <div id="categoryCheckbox">
                    {generateCheckboxes(
                      formData.sites[currentShopId].categories.categoryHierarchy
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  return (
    <div className="relative">
      <Spinner className="absolute left-0 right-0 top-0 bottom-0 m-auto w-20 h-20" />
      ;
    </div>
  );
};
CategoryBox.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default CategoryBox;
