import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartShopping,
  faChartColumn,
  faWallet,
  faSlash,
  faRotate,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import { ordersGrid, contextMenuItems } from '../../data/ordersTableDefinition';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const Orders = () => {
  const token = useSelector(selectCurrentToken);
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };
  const [items, setItems] = useState([]);
  const settings = { wrapMode: 'Content' };
  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/orders`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };
  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl">
      <Header category="Page" title="Orders" />
      <div
        className="add-button"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Link
          className="p-2"
          style={{
            background: '#EC1577',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '25px',
            padding: '7.1px 30px 0px',
            fontWeight: 'normal',
            fontSize: '1,04vw',
            height: '37px',
          }}
          to="/create-order"
        >
          <Link
            className="p-2"
            style={{
              background: '#ffffff',
              color: '#181143',
              cursor: 'pointer',
              borderRadius: '50%',
              padding: '4px 10px',
              marginRight: '32%',
              marginLeft: '-35%',
              fontSize: '1,20vw',
            }}
            to="/create-order"
          >
            +
          </Link>
          Add
        </Link>
      </div>
      {/* <div className="m-2 mt-10 p-2 md:p-10 bg-white rounded-3xl flex sm:flex-row flex-col sm:justify-evenly relative">
        <div className="orderInfo flex flex-col sm:justify-center self-center">
          <div className="orderInfoTop flex flex-row justify-between sm:w-[9rem] w-[12rem]">
            <div className="OrderInfoIcon relative rounded-full bg-[#EC1577] h-[3.5rem] w-[3.5rem] self-center">
              <FontAwesomeIcon
                icon={faChartColumn}
                className=" left-[16%] top-[17%] scale-100  h-[2.3rem] w-[2.3rem]  text-[#fff] pointer-events-none absolute "
              />
            </div>
            <div>
              <div className=" text-[#181143] text-[25px]  font-bold">0%</div>
              <div className=" text-[#181143] text-[14px]   font-medium">
                30 days
              </div>
            </div>
          </div>
          <div className="orderInfoBottom  text-[#AAA6C3] text-[16px] font-semibold ">
            Transformation rate
          </div>
        </div>
        <div className="orderInfo flex flex-col justify-center self-center">
          <div className="orderInfoTop flex flex-row justify-between  sm:w-[8rem] w-[12rem]">
            <div className="OrderInfoIcon relative rounded-full bg-[#EC1577] h-[3.5rem] w-[3.5rem] self-center">
              <FontAwesomeIcon
                icon={faCartShopping}
                className=" left-[20%] top-[25%] scale-100  h-[2rem] w-[2rem]  text-[#fff] pointer-events-none absolute "
              />
              <FontAwesomeIcon
                icon={faSlash}
                className="h-[2.4rem] w-[2.4rem] left-[12%] top-[17%]  text-[#fff] absolute  pointer-events-none  rotate-90"
              />
            </div>
            <div>
              <div className=" text-[#181143] text-[25px]  font-bold">0%</div>
              <div className=" text-[#181143] text-[14px]   font-medium">
                Today
              </div>
            </div>
          </div>
          <div className="orderInfoBottom  text-[#AAA6C3] text-[16px] font-semibold">
            Abandoned carts
          </div>
        </div>
        <div className="orderInfo flex flex-col justify-center self-center">
          <div className="orderInfoTop flex flex-row justify-between  w-[12rem]">
            <div className="OrderInfoIcon relative rounded-full bg-[#EC1577] h-[3.5rem] w-[3.5rem] self-center">
              <FontAwesomeIcon
                icon={faWallet}
                className=" left-[16%]  top-[17%] scale-100  h-[2.3rem] w-[2.3rem]  text-[#fff] pointer-events-none absolute "
              />
            </div>
            <div>
              <div className=" text-[#181143] text-[25px]  font-bold">
                0,000 DT
              </div>
              <div className=" text-[#181143] text-[14px]   font-medium">
                30 days
              </div>
            </div>
          </div>
          <div className="orderInfoBottom  text-[#AAA6C3] text-[16px] font-semibold ">
            Average cart
          </div>
        </div>
        <div className="orderInfo flex flex-col justify-center self-center">
          <div className="orderInfoTop flex flex-row justify-between  w-[12rem]">
            <div className="OrderInfoIcon relative rounded-full bg-[#EC1577] h-[3.5rem] w-[3.5rem] self-center">
              <FontAwesomeIcon
                icon={faUser}
                className=" left-[16%] top-[17%] scale-100  h-[2.3rem] w-[2.3rem]  text-[#fff] pointer-events-none absolute "
              />
            </div>
            <div>
              <div className=" text-[#181143] text-[25px]  font-bold">
                0,000 DT
              </div>
              <div className=" text-[#181143] text-[14px]   font-medium">
                30 days
              </div>
            </div>
          </div>
          <div className="orderInfoBottom  text-[#AAA6C3] text-[16px] font-semibold ">
            Net margin per visitor
          </div>
        </div>
        <FontAwesomeIcon
          icon={faRotate}
          className=" sm:left-[95%] left-[85%] top-[5%] scale-75  h-[2.3rem] w-[2.3rem]  text-[#EC1577] pointer-events-none absolute "
        />
      </div> */}
      <div className="m-2 mt-10 p-2 md:p-10 bg-white rounded-3xl">
        <GridComponent
          dataSource={items}
          width="auto"
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          pageSettings={{ pageCount: 5, pageSize: 10 }}
          editSettings={editing}
          toolbar={toolbarOptions}
          allowTextWrap
          textWrapSettings={settings}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {ordersGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Search, Page, Toolbar]} />
        </GridComponent>
        <div id="myModal" className="modal items-center justify-center">
          <div
            className="modal-content bg-main-bg m-auto py-20 px-20 w-2/5
          "
          >
            <span className="close">&times;</span>
            <div id="modal-content" className="flex justify-center gap-5">
              <button
                type="button"
                className="bg-shifti-main-bg text-white p-5 rounded-full"
              >
                Amazonia
              </button>
              <button
                type="button"
                className="bg-shifti-main-bg text-white p-5 rounded-full"
              >
                Masmoude
              </button>
              <button
                type="button"
                className="bg-shifti-main-bg text-white p-5 rounded-full"
              >
                shopify
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
