/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from 'react';
import { BsDownload, BsThreeDotsVertical } from 'react-icons/bs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { MdOutlineExpandMore, MdOutlineExpandLess } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import api from '../utils/apiService';
import { selectCurrentToken } from '../features/auth/authSlice';
import { ShopContext } from '../contexts/ShopContext';
import {
  productsGrid,
  contextMenuItems,
} from '../data/productsTableDefinitionKpi';
import {
  Pie,
  LineChart,
  Header,
  ColumnChart,
  DateRange,
  ShopKpis,
} from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const FLASK_API_URL = process.env.REACT_APP_FLASK_API_URL;

const DropDown = ({
  currentMode,
  className,
  id,
  text,
  value,
  data,
  onChange,
  defaultValue,
}) => (
  <div className={className}>
    <DropDownListComponent
      id={id}
      fields={{ text, value }}
      style={{ border: 'none', color: currentMode === 'Dark' && 'white' }}
      value={defaultValue}
      dataSource={data}
      popupHeight="220px"
      popupWidth="120px"
      onChange={(e) => onChange(e.value)}
    />
  </div>
);

DropDown.propTypes = {
  currentMode: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.number.isRequired,
};
const Ecommerce = () => {
  const { currentColor, currentMode } = useStateContext();
  const [
    shops,
    setShops,
    allShopTypes,
    allShopIds,
    selectedShop,
    setSelectedShop,
    selectedShops,
    setSelectedShops,
    langs,
    setLangs,
    shopError,
  ] = useContext(ShopContext);
  const [kpis, setKpis] = useState({});
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);

  const [weeklyStats, setWeeklyStats] = useState({});
  const [salesOverTime, setSalesOverTime] = useState([]);
  const [bestCustomers, setBestCustomers] = useState([]);
  const [bestProducts, setBestProducts] = useState([]);
  const [forecast, setForecast] = useState([]);
  const [forecastArima, setForecastArima] = useState([]);
  const [salesDateRange, setSalesDateRange] = useState({
    end_date: new Date(),
    start_date: new Date(0),
  });

  const [forecastShopId, setForecastShopId] = useState(selectedShops[0].id);
  const [currentEntriesNum, setCurrentEntriesNum] = useState(10);
  const [currentPayMtdShopId, setCurrentPayMtdShopId] = useState(
    selectedShops[0].id
  );
  const [currentOrderStatusShopId, setCurrentOrderStatusShopId] = useState(
    selectedShops[0].id
  );
  const [currentBestProductShopId, setCurrentBestProductShopId] = useState(
    selectedShops[0].id
  );
  const LinePrimaryYAxis = {
    labelFormat: '{value}k',
    rangePadding: 'None',
    interval: 100,
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
  };

  const LinePrimaryXAxis = {
    valueType: 'DateTime',
    labelFormat: 'MMM yy',
    intervalType: 'Months',
    edgeLabelPlacement: 'Shift',
    majorGridLines: { width: 0 },
    background: 'white',
  };

  const filteredShops = selectedShops.map((shop) => ({
    Id: shop.id,
    name: shop.name,
  }));

  const entriesNum = [
    {
      Id: 1,
    },
    {
      Id: 2,
    },
    {
      Id: 3,
    },
    {
      Id: 4,
    },
    {
      Id: 5,
    },
    {
      Id: 6,
    },
    {
      Id: 7,
    },
    {
      Id: 8,
    },
    {
      Id: 9,
    },
    {
      Id: 10,
    },
  ];

  const getSalesOverTime = async () =>
    new Promise((resolve, reject) => {
      let output = [];
      const promises = selectedShops.map(async (shop) => {
        const config = {
          method: 'get',
          url: `${FLASK_API_URL}/api/${shop.id}/sales_over_time`,
          headers: {
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
        };
        await api
          .request(config)
          .then((response) => {
            output = {
              dataSource: response.data,
              xName: 'invoice_date',
              yName: 'total_amount',
              name: shop.name,
              width: '2',
              marker: { visible: false, width: 5, height: 5 },
              type: 'Line',
            };
          })
          .catch((err) => {
            Promise.reject(err);
          });
        return output;
      });
      Promise.all(promises)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const getKpis = async () =>
    new Promise((resolve, reject) => {
      let output = [];
      const promise = selectedShops.map(async (shop) => {
        const config = {
          method: 'get',
          url: `${FLASK_API_URL}/api/${shop.id}/kpis`,
          headers: {
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
        };
        await api
          .request(config)
          .then(async (response) => {
            const tempRes = {
              orders_per_product: response.data.orders_per_product
                ? response.data.orders_per_product
                : 0,
              average_sales_price: response.data.average_sales_price
                ? response.data.average_sales_price
                : 0,
              total_orders: response.data.total_orders
                ? response.data.total_orders
                : 0,
              number_of_unique_products: response.data.number_of_unique_products
                ? response.data.number_of_unique_products
                : 0,
              total_sales_revenue: response.data.total_sales_revenue
                ? response.data.total_sales_revenue
                : 0,
              payment_method: response.data.payment_method
                ? Object.entries(response.data.payment_method).map(
                    ([x, y]) => ({ y, x })
                  )
                : [],
              valid_orders: response.data.valid_orders
                ? Object.entries(response.data.valid_orders).map(([x, y]) => ({
                    y,
                    x,
                  }))
                : [],
            };
            output = tempRes;
          })
          .catch(async (err) => {
            await Promise.reject(err);
          });
        return output;
      });
      Promise.all(promise)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  const getWeeklyStats = async () =>
    new Promise((resolve, reject) => {
      let output = [];
      const promise = selectedShops.map(async (shop) => {
        const config = {
          method: 'get',
          url: `${FLASK_API_URL}/api/${shop.id}/kpis`,
          headers: {
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
        };
        await api
          .request(config)
          .then(async (response) => {
            const tempRes = {
              orders_per_product: response.data.orders_per_product
                ? response.data.orders_per_product
                : 0,
              average_sales_price: response.data.average_sales_price
                ? response.data.average_sales_price
                : 0,
              total_orders: response.data.total_orders
                ? response.data.total_orders
                : 0,
              number_of_unique_products: response.data.number_of_unique_products
                ? response.data.number_of_unique_products
                : 0,
              total_sales_revenue: response.data.total_sales_revenue
                ? response.data.total_sales_revenue
                : 0,
              payment_method: response.data.payment_method
                ? Object.entries(response.data.payment_method).map(
                    ([x, y]) => ({ y, x })
                  )
                : [],
              valid_orders: response.data.valid_orders
                ? response.data.valid_orders
                : [],
            };
            output = tempRes;
          })
          .catch(async (err) => {
            await Promise.reject(err);
          });
        return output;
      });
      Promise.all(promise)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  const getBestCustomers = async () =>
    new Promise((resolve, reject) => {
      let output = [];
      const promises = selectedShops.map(async (shop) => {
        const config = {
          method: 'get',
          url: `${FLASK_API_URL}/api/${shop.id}/best_clients`,
          headers: {
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
        };
        await api
          .request(config)
          .then((response) => {
            output = {
              dataSource: response.data,
              xName: 'customer_id',
              yName: 'total_paid',
              name: shop.name,
            };
          })
          .catch((err) => {
            Promise.reject(err);
          });
        return output;
      });
      Promise.all(promises)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const getBestProducts = async () =>
    new Promise((resolve, reject) => {
      let output = [];
      const promises = selectedShops.map(async (shop) => {
        const config = {
          method: 'get',
          url: `${FLASK_API_URL}/api/${shop.id}/top_selling_products`,
          headers: {
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
        };
        await api
          .request(config)
          .then((response) => {
            output = {
              dataSource: response.data,
              xName: 'position',
              yName: 'total_quantity',
              name: shop.name,
              tooltipMappingName: 'product_name',
            };
          })
          .catch((err) => {
            Promise.reject(err);
          });
        return output;
      });
      Promise.all(promises)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const getForecast = async () =>
    new Promise((resolve, reject) => {
      let output = [];
      const promises = selectedShops.map(async (shop) => {
        const config = {
          method: 'get',
          url: `${FLASK_API_URL}/api/${shop.id}/forecast`,
          headers: {
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
        };
        await api
          .request(config)
          .then((response) => {
            function sampleData(data, sampleSize) {
              const { length } = data;
              const interval = Math.ceil(length / sampleSize);
              return data.filter((_, index) => index % interval === 0);
            }
            const sampledData = sampleData(response.data, 100);
            output = [
              {
                dataSource: sampledData.map((item) => ({
                  yhat: item.yhat,
                  ds: item.ds,
                })),
                xName: 'ds',
                yName: 'yhat',
                type: 'Line',
                name: 'Yhat',
                width: 2,
              },
              {
                dataSource: sampledData.map((item) => ({
                  yhat_upper: item.yhat_upper,
                  ds: item.ds,
                })),
                xName: 'ds',
                yName: 'yhat_upper',
                type: 'Line',
                name: 'Yhat Upper',
                width: 1,
                opacity: 0.5,
              },
              {
                dataSource: sampledData.map((item) => ({
                  yhat_lower: item.yhat_lower,
                  ds: item.ds,
                })),
                xName: 'ds',
                yName: 'yhat_lower',
                type: 'Line',
                name: 'Yhat Lower',
                width: 1,
                opacity: 0.5,
              },
            ];
          })
          .catch((err) => {
            Promise.reject(err);
          });
        return output;
      });
      Promise.all(promises)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const getForecastArima = async () =>
    new Promise((resolve, reject) => {
      let output = [];
      const promises = selectedShops.map(async (shop) => {
        const config = {
          method: 'get',
          url: `${FLASK_API_URL}/api/${shop.id}/holt_winters`,
          headers: {
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
        };
        await api
          .request(config)
          .then((response) => {
            output = {
              dataSource: response.data,
              xName: 'product_id',
              yName: 'inventory_amount',
              name: shop.name,
            };
          })
          .catch((err) => {
            Promise.reject(err);
          });
        return output;
      });
      Promise.all(promises)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const handleDateRangeChange = (e) => {
    const dateRange = {
      end_date: new Date(e.value[1]),
      start_date: new Date(e.value[0]),
    };
    setSalesDateRange({ ...dateRange });
  };

  const filterSalesByDate = () =>
    salesOverTime.map((chartData) => {
      const filteredDataSource = chartData.dataSource.filter((data) => {
        const invoiceDate = Date.parse(data.invoice_date);
        return (
          invoiceDate >= salesDateRange.start_date &&
          invoiceDate <= salesDateRange.end_date
        );
      });
      return { ...chartData, dataSource: filteredDataSource };
    });

  const filterByNum = (data) =>
    data.map((item) => {
      if (item.dataSource.length > currentEntriesNum) {
        const dataSource = item.dataSource.slice(
          0,
          currentEntriesNum - item.dataSource.length
        );
        return {
          ...item,
          dataSource,
        };
      }
      return item;
    });

  useEffect(() => {
    const functions = {
      kpis: getKpis,
      weeklyStats: getWeeklyStats,
      sales: getSalesOverTime,
      customers: getBestCustomers,
      products: getBestProducts,
      forecast: getForecast,
      forecast_arima: getForecastArima,
    };

    Promise.all(Object.values(functions).map((func) => func()))
      .then((data) => {
        const keys = Object.keys(functions);
        const output = keys.reduce((obj, key, index) => {
          obj[key] = data[index];
          return obj;
        }, {});

        if (JSON.stringify(output.kpis) !== JSON.stringify(kpis))
          setKpis({ ...output.kpis });
        if (JSON.stringify(output.weeklyStats) !== JSON.stringify(weeklyStats))
          setWeeklyStats({ ...output.weeklyStats });
        if (JSON.stringify(output.sales) !== JSON.stringify(salesOverTime))
          setSalesOverTime([...output.sales]);
        if (JSON.stringify(output.customers) !== JSON.stringify(bestCustomers))
          setBestCustomers([...output.customers]);
        if (JSON.stringify(output.products) !== JSON.stringify(bestProducts))
          setBestProducts([...output.products]);
        if (JSON.stringify(output.forecast) !== JSON.stringify(forecast))
          setForecast([...output.forecast]);
        if (
          JSON.stringify(output.forecast_arima) !==
          JSON.stringify(forecastArima)
        )
          setForecastArima([...output.forecast_arima]);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="ml-4">
      <div
        className="m-2 md:ml-7 p-2 md:p-5 rounded-3xl relative"
        style={{ paddingbottom: '0', marginBottom: '0' }}
      >
        <div className="mt-14">
          <Header category="Page" title="Dashboard" />
        </div>
      </div>
      <div className="flex m-3 flex-wrap justify-center items-center">
        <ShopKpis kpis={kpis} shops={selectedShops} />
      </div>
      <div className="p-8 m-3 relative">
        <div className="flex justify-between w-full mb-6">
          <div>
            <h2 className="font-semibold text-2xl text-shifti-blue">
              Sales Overview
            </h2>
            <span className="text-shifti-blue font-semibold opacity-50">
              Customers
            </span>
          </div>
          <div>
            <div className="flex justify-between items-center gap-4">
              <span>Filter by:</span>
              <DateRange
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl pt-1 px-4 shadow-xl"
                id="salesDateRange"
                startDate={salesDateRange.start_date}
                endDate={salesDateRange.end_date}
                onChange={handleDateRangeChange}
              />
              <BsDownload
                className="bg-white text-shifti-grey dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl p-2 cursor-pointer"
                style={{ height: '2.25rem', width: '4rem' }}
              />
            </div>
          </div>
        </div>
        <LineChart
          id="salesLineChart"
          className="bg-white rounded-3xl shadow-xly"
          data={filterSalesByDate()}
          PrimaryXAxis={LinePrimaryXAxis}
          PrimaryYAxis={LinePrimaryYAxis}
          background="transparent"
          legendSettings={{ background: 'transparent' }}
        />
      </div>
      <div className="flex justify-between gap-8 p-8 m-3">
        <div className="md:w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 shadow-xl">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Best customers</p>
          </div>
          <div className="mt-10">
            <ColumnChart
              id="customersChart"
              data={bestCustomers && bestCustomers.length ? bestCustomers : []}
              height="420px"
              type="StackingColumn"
              name="Best customers"
              background="white"
              legendSettings={{ background: 'white', visible: true }}
            />
          </div>
        </div>
        <div className="dark:text-gray-200 dark:bg-secondary-dark-bg w-96 md:w-760  mr-5 md:mr-0">
          <div className="flex justify-between w-full mb-6">
            <div>
              <h2 className="font-semibold text-2xl text-shifti-blue">
                Best Products
              </h2>
            </div>
            <div>
              <div className="flex justify-between items-center gap-4">
                <span>Filter by:</span>
                <DropDown
                  className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl pt-1 px-4"
                  id="productNum"
                  currentMode={currentMode}
                  data={entriesNum}
                  defaultValue={currentEntriesNum}
                  value="Id"
                  text="Id"
                  onChange={(e) => setCurrentEntriesNum(e)}
                />
                <BsDownload
                  className="bg-white text-shifti-grey dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl p-2 cursor-pointer"
                  style={{ height: '2.25rem', width: '4rem' }}
                />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-xl md:w-full overflow-auto p-6">
            <ColumnChart
              id="productsChart"
              data={filterByNum(bestProducts)}
              type="StackingColumn"
              height="450px"
              customTooltip
              name="Best products"
              background="white"
              legendSettings={{ background: 'white', visible: true }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-4 p-8 m-3">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 shadow-xl w-full">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Payment methods</p>
            <span className="text-shifti-blue font-semibold opacity-50">
              <DropDown
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl pt-1 px-4"
                id="paymentDrop"
                currentMode={currentMode}
                data={filteredShops}
                defaultValue={currentPayMtdShopId}
                value="Id"
                text="name"
                onChange={(e) => setCurrentPayMtdShopId(e)}
              />
            </span>
          </div>
          <div className="mt-10">
            <Pie
              id="paymentChart"
              xName="x"
              yName="y"
              data={
                kpis[currentPayMtdShopId - 1] &&
                kpis[currentPayMtdShopId - 1].payment_method
                  ? kpis[currentPayMtdShopId - 1].payment_method
                  : []
              }
              name="payment"
              legendVisiblity
              height="400px"
              width="100%"
            />
          </div>
        </div>
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 shadow-xl w-full">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Orders status</p>
            <span className="text-shifti-blue font-semibold opacity-50">
              <DropDown
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl pt-1 px-4"
                id="orderStatusDrop"
                currentMode={currentMode}
                data={filteredShops}
                defaultValue={currentOrderStatusShopId}
                value="Id"
                text="name"
                onChange={(e) => setCurrentOrderStatusShopId(e)}
              />
            </span>
          </div>
          <div className="mt-10">
            <Pie
              id="productCatChart2"
              xName="x"
              yName="y"
              data={
                kpis[currentOrderStatusShopId - 1] &&
                kpis[currentOrderStatusShopId - 1].valid_orders
                  ? kpis[currentOrderStatusShopId - 1].valid_orders
                  : []
              }
              name="payment"
              legendVisiblity
              height="400px"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between p-8 m-3 gap-8">
        <div className="w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 shadow-xl mr-5 md:mr-0">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Your Transactions</p>
            <BsThreeDotsVertical className="text-shifti-blue w-8 h-auto" />
          </div>
          <div className="mt-10">
            <div className="card h-100 mb-4">
              <div className="card-body pt-4 p-3">
                <h6 className="text-uppercase text-body text-xs font-weight-bolder mb-3">
                  Newest
                </h6>
                <ul className="list-group">
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-2 rounded-lg">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm flex items-center justify-center"
                      >
                        <i className="text-lg">
                          <MdOutlineExpandMore className="m-auto text-red-500 rounded border border-red-500" />
                        </i>
                      </button>
                      <div className="flex flex-col">
                        <h6 className="mb-1 text-dark text-sm">
                          ROGE CAVAILLES
                        </h6>
                        <span className="text-xs">
                          27 March 2020, at 12:30 PM
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center text-danger text-gradient text-sm font-weight-bold">
                      - $ 2,500
                    </div>
                  </li>
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-2 rounded-lg">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm flex items-center justify-center"
                      >
                        <i className="text-lg">
                          <MdOutlineExpandLess className="m-auto text-green-500 rounded border border-green-500" />
                        </i>
                      </button>
                      <div className="flex flex-col">
                        <h6 className="mb-1 text-dark text-sm">Kinder Maxi</h6>
                        <span className="text-xs">
                          27 March 2020, at 04:30 AM
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center text-success text-gradient text-sm font-weight-bold">
                      + $ 2,000
                    </div>
                  </li>
                </ul>
                <h6 className="text-uppercase text-body text-xs font-weight-bolder my-3">
                  Yesterday
                </h6>
                <ul className="list-group">
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-2 rounded-lg">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm flex items-center justify-center"
                      >
                        <i className="text-lg">
                          <MdOutlineExpandLess className="m-auto text-green-500 rounded border border-green-500" />
                        </i>
                      </button>
                      <div className="flex flex-col">
                        <h6 className="mb-1 text-dark text-sm">
                          Ombre à paupière
                        </h6>
                        <span className="text-xs">
                          26 March 2020, at 13:45 PM
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center text-success text-gradient text-sm font-weight-bold">
                      + $ 750
                    </div>
                  </li>
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-2 rounded-lg">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm flex items-center justify-center"
                      >
                        <i className="text-lg">
                          <MdOutlineExpandLess className="m-auto text-green-500 rounded border border-green-500" />
                        </i>
                      </button>
                      <div className="flex flex-col">
                        <h6 className="mb-1 text-dark text-sm">Kinder T8</h6>
                        <span className="text-xs">
                          26 March 2020, at 12:30 PM
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center text-success text-gradient text-sm font-weight-bold">
                      + $ 1,000
                    </div>
                  </li>
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-2 rounded-lg">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm flex items-center justify-center"
                      >
                        <i className="text-lg">
                          <MdOutlineExpandLess className="m-auto text-green-500 rounded border border-green-500" />
                        </i>
                      </button>
                      <div className="flex flex-col">
                        <h6 className="mb-1 text-dark text-sm">Espresso</h6>
                        <span className="text-xs">
                          26 March 2020, at 08:30 AM
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center text-success text-gradient text-sm font-weight-bold">
                      + $ 2,500
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg  p-4 rounded-2xl md:w-780  shadow-xl mr-4 md:mr-0">
          <div className="flex justify-between">
            <p className="font-semibold text-xl">Best selling product</p>
            <div className="flex items-center gap-4">
              <span>Number of days:</span>
              <DropDown
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl pt-1 px-4"
                id="paymentDrop"
                currentMode={currentMode}
                data={filteredShops}
                defaultValue={currentBestProductShopId}
                value="Id"
                text="name"
                onChange={(e) => setCurrentBestProductShopId(e)}
              />
            </div>
          </div>
          <div className="mt-10 flex gap-10 flex-wrap justify-center">
            <div className="relative">
              <GridComponent
                dataSource={
                  bestProducts[currentBestProductShopId - 1] &&
                  bestProducts[currentBestProductShopId - 1].dataSource
                    ? bestProducts[currentBestProductShopId - 1].dataSource
                    : []
                }
                width="auto"
                allowPaging
                allowSorting
                allowExcelExport
                allowPdfExport
                contextMenuItems={contextMenuItems}
                pageSettings={{ pageCount: 5, pageSize: 5 }}
              >
                <ColumnsDirective>
                  {productsGrid.map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
                </ColumnsDirective>
                <Inject services={[Search, Page, Toolbar]} />
              </GridComponent>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-8 m-3 gap-8">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg  p-4 rounded-2xl md:w-780  shadow-xl mr-4 md:mr-0">
          <div className="flex justify-between">
            <p className="font-semibold text-xl">Stock prevision</p>
            <div className="flex items-center gap-4">
              <span>Number of days:</span>
              <DropDown
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl pt-1 px-4"
                id="daysNum"
                currentMode={currentMode}
                data={entriesNum}
                defaultValue={currentEntriesNum}
                value="Id"
                text="Id"
                onChange={(e) => setCurrentEntriesNum(e)}
              />
            </div>
          </div>
          <div className="mt-10 flex gap-10 flex-wrap justify-center">
            <div className="relative">
              <ColumnChart
                id="product"
                data={forecastArima}
                height="420px"
                type="StackingBar"
                name="Forecast Arima"
                background="white"
                legendSettings={{ background: 'white', visible: true }}
              />
            </div>
          </div>
        </div>
        <div className="w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 shadow-xl mr-5 md:mr-0">
          <div className="flex justify-between">
            <p className="text-xl font-semibold">Invoices</p>
            <BsThreeDotsVertical className="text-shifti-blue w-8 h-auto" />
          </div>
          <div className="mt-10">
            <div className="card">
              <div className="card-body p-3 pb-0">
                <ul className="list-group">
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-6 rounded-lg">
                    <div className="flex flex-col">
                      <h6 className="mb-1 text-dark font-weight-bold text-sm">
                        March, 01, 2020
                      </h6>
                      <span className="text-xs">#MS-415646</span>
                    </div>
                    <div className="flex items-center text-sm">
                      $180
                      <button
                        type="button"
                        className="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                      >
                        <i className="text-lg relative me-1">
                          <AiOutlineFilePdf className="m-auto" />
                        </i>
                        PDF
                      </button>
                    </div>
                  </li>
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-6 rounded-lg">
                    <div className="flex flex-col">
                      <h6 className="text-dark mb-1 font-weight-bold text-sm">
                        February, 10, 2021
                      </h6>
                      <span className="text-xs">#RV-126749</span>
                    </div>
                    <div className="flex items-center text-sm">
                      $250
                      <button
                        type="button"
                        className="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                      >
                        <i className="text-lg relative me-1">
                          <AiOutlineFilePdf className="m-auto" />
                        </i>
                        PDF
                      </button>
                    </div>
                  </li>
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-6 rounded-lg">
                    <div className="flex flex-col">
                      <h6 className="text-dark mb-1 font-weight-bold text-sm">
                        April, 05, 2020
                      </h6>
                      <span className="text-xs">#FB-212562</span>
                    </div>
                    <div className="flex items-center text-sm">
                      $560
                      <button
                        type="button"
                        className="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                      >
                        <i className="text-lg relative me-1">
                          <AiOutlineFilePdf className="m-auto" />
                        </i>
                        PDF
                      </button>
                    </div>
                  </li>
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-6 rounded-lg">
                    <div className="flex flex-col">
                      <h6 className="text-dark mb-1 font-weight-bold text-sm">
                        June, 25, 2019
                      </h6>
                      <span className="text-xs">#QW-103578</span>
                    </div>
                    <div className="flex items-center text-sm">
                      $120
                      <button
                        type="button"
                        className="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                      >
                        <i className="text-lg relative me-1">
                          <AiOutlineFilePdf className="m-auto" />
                        </i>
                        PDF
                      </button>
                    </div>
                  </li>
                  <li className="list-group-item border-0 flex justify-between ps-0 mb-6 rounded-lg">
                    <div className="flex flex-col">
                      <h6 className="text-dark mb-1 font-weight-bold text-sm">
                        March, 01, 2019
                      </h6>
                      <span className="text-xs">#AR-803481</span>
                    </div>
                    <div className="flex items-center text-sm">
                      $300
                      <button
                        type="button"
                        className="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                      >
                        <i className="text-lg relative me-1">
                          <AiOutlineFilePdf className="m-auto" />
                        </i>
                        PDF
                      </button>
                    </div>
                  </li>
                  <li className="list-group-item border-0 flex justify-between ps-0 rounded-lg">
                    <div className="flex flex-col">
                      <h6 className="text-dark mb-1 font-weight-bold text-sm">
                        March, 17, 2023
                      </h6>
                      <span className="text-xs">#AR-953581</span>
                    </div>
                    <div className="flex items-center text-sm">
                      $58
                      <button
                        type="button"
                        className="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                      >
                        <i className="text-lg relative me-1">
                          <AiOutlineFilePdf className="m-auto" />
                        </i>
                        PDF
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-8 m-3 gap-8">
        <div className="w-full bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 shadow-xl mr-5 md:mr-0">
          <p className="text-xl font-semibold">Sales forecast</p>
          <div className="mt-10">
            <div className="bg-white h-12 rounded-t-xl divide-x -mb-4 inline-block px-4 font-medium text-shifti-blue">
              {selectedShops.map((shop, i) => (
                <Link key={i} onClick={() => setForecastShopId(shop.id)}>
                  <div
                    className={`px-4 inline-block cursor-pointer divide-x ${
                      forecastShopId === shop.id
                        ? 'text-shifti-pink font-semibold'
                        : ''
                    }`}
                  >
                    {shop.name}
                  </div>
                </Link>
              ))}
            </div>
            <LineChart
              id="forcastChart"
              data={
                forecast[forecastShopId - 1] ? forecast[forecastShopId - 1] : []
              }
              background="white"
              PrimaryXAxis={{
                valueType: 'DateTime',
                labelFormat: 'MMM yyyy',
                title: 'Date',
                intervalType: 'Months',
              }}
              PrimaryYAxis={{ title: 'Yhat' }}
              legendSettings={{ background: 'transparent' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
