import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSlash } from '@fortawesome/free-solid-svg-icons';
import api from '../../utils/apiService';
import { Header } from '../../components';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export default function CreateRole() {
  const token = useSelector(selectCurrentToken);
  const [isCheck, setIsCheck] = useState([]);
  const [permissions, setPermission] = useState([]);
  const [newRoleId, setNewRoleId] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    validation: {
      error: [true],
      errorMsg: ['Obligatoire'],
    },
  });
  const [formDataError, setformDataError] = useState([true]);

  const getPermissions = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/permissions`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setPermission(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getPermissions();
    if (newRoleId !== null) {
      handlePermissions();
    }
  }, [newRoleId]);

  const handleRole = async () => {
    const decoded = jwtDecode(token);
    const idToast = toast.loading('Please wait...');
    const ERROR = [...formData.validation.error];
    const ERROR_MSG = [...formData.validation.errorMsg];
    setformDataError(ERROR_MSG);
    if (!formData.validation.error.includes(true)) {
      const data = JSON.stringify({
        name: formData.name,
      });
      const config = {
        method: 'post',
        url: `${apiURL}/roles/addRole`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      await api
        .request(config)
        .then((response) => {
          const newId = response.data.id;
          setNewRoleId(newId);
          toast.update(idToast, {
            render: `New Role Is Created with ID: ${newId}`,
            type: response.data === 'success',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 6500);
        })
        .catch((error) => {
          toast.update(idToast, {
            render: "Couldn't create role!!",
            type: 'error',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            isLoading: false,
          });
        });
    }
  };
  const handlePermissions = async () => {
    const decoded = jwtDecode(token);
    const idToast = toast.loading('Please wait...');
    const data = JSON.stringify({
      permissions: isCheck,
    });
    const config = {
      method: 'put',
      url: `${apiURL}/roles/assign-permissions/${newRoleId}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    await api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: response.data,
          type: response.data === 'success',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          isLoading: false,
        });
      })
      .catch((error) => {});
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    await handleRole();
  };
  const handelFormDataChange = (value, key, index) => {
    const aux = { ...formData };
    aux[key] = value;
    if (key === 'name') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Obligatoire';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
    }
    setFormData(aux);
  };

  /* EmployeesPermisssions */

  const handleCheck = async (value) => {
    const id = Number(value);
    if (isCheck.includes(id)) {
      setIsCheck(isCheck.filter((item) => item !== id));
    } else {
      setIsCheck([...isCheck, Number(value)]);
    }
  };

  /* EmployeesPermisssions */
  const toggleAll = (value, isChecked) => {
    const values = value.split(',');
    const toAdd = [];
    if (isChecked === true) {
      values.map((id) => {
        if (!isCheck.includes(Number(id))) {
          toAdd.push(Number(id));
        }
        return null;
      });
      setIsCheck([...isCheck, ...toAdd]);
    } else {
      const toRemove = [];
      values.map((id) => {
        if (isCheck.includes(Number(id))) {
          toRemove.push(Number(id));
        }
        return null;
      });
      setIsCheck(isCheck.filter((el) => !toRemove.includes(el)));
    }
  };

  const allPermissions = Object.entries(permissions);
  return (
    <div className="mr-3 p-2 md:p-5 ml-3 rounded-3xl mb-3 md:mt-8 mt-20 flex justify-center">
      <div className="flex justify-center flex-col w-[90%]">
        <Header category="Page" title="Create Role" />
        <form
          action=""
          className="sft-form  mt-2 md:mt-0 p-2 md:p-10 bg-white rounded-3xl md:w-full"
        >
          <div className="sft-input-container grid grid-cols-3 gap-x-2 gap-y-8 mt-6 md:w-[40%] lg:w-[81%]">
            <label htmlFor="name" className="formLabel">
              Role Name :
            </label>
            <div className="input-container">
              <input
                id="name"
                onChange={(e) =>
                  handelFormDataChange(e.target.value, 'name', 0)
                }
                type="text"
                className="formInput"
              />
            </div>
          </div>

          <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>

          {/* stopped here */}
          <div className="p-2 md:p-5 bg-white rounded-3xl mb-3 mt-8 overflow-y-auto">
            <div className="p-2 md:p-5 rounded-3xl mb-3 overflow-y-auto">
              <div className="flex justify-between mb-2 w-screen sm:w-full">
                <span className="w-32 text-[#EC1577]">Menu</span>
                <span className="">Add</span>
                <span className="">Delete</span>
                <span className="">Show</span>
                <span className="">Update</span>
                <span className="">All</span>
              </div>
              <hr className="border-3" />
              {allPermissions && Object.keys(allPermissions).length > 0
                ? allPermissions.map((item, index) => {
                    const name = item[0];
                    const itemPermissions = item[1];
                    let row = [];
                    return (
                      <div className="flex justify-between w-full items-center mb-3 mt-3 w-screen sm:w-full">
                        <label className="w-32">{name}</label>

                        {itemPermissions.map((permission, i) => {
                          if (i === 0 && permission.id !== 0)
                            row = [permission.id];
                          else if (permission.id !== 0) row.push(permission.id);
                          return (
                            <div className="relative flex items-center mr-3">
                              <input
                                key={permission.id}
                                name={`${permission.group}[]`}
                                className="permission-checkbox h-8 w-8 rounded-full	outline-0	appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
                                onChange={(e) => handleCheck(e.target.value)}
                                type="checkbox"
                                disabled={permission.id === 0}
                                id={permission.id}
                                value={permission.id}
                              />
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="h-5 w-16  text-[#EC1577] absolute -right-4 pointer-events-none text-opacity-0 checked-1 transition scale-75"
                              />
                              <FontAwesomeIcon
                                icon={faSlash}
                                className="h-5 w-16  text-[#E1DFEE] absolute -right-4 pointer-events-none text-opacity-0 disabled-1 transition scale-110 rotate-90"
                              />
                            </div>
                          );
                        })}
                        <div className="relative flex items-center">
                          <input
                            className="all-permissions-checkbox h-8 w-8 rounded-full	outline-0	appearance-none checked:bg-[#181143] scale-75 border-1 border-[#181143]"
                            onChange={(e) =>
                              toggleAll(e.target.value, e.target.checked)
                            }
                            type="checkbox"
                            value={row}
                          />
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="h-5 w-16  text-[#EC1577] absolute -right-4 pointer-events-none text-opacity-0 checked-2 transition scale-75"
                          />
                          <FontAwesomeIcon
                            icon={faSlash}
                            className="h-5 w-16  text-[#E1DFEE] absolute -right-4 pointer-events-none text-opacity-0 disabled-2 transition scale-110 rotate-90"
                          />
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
            <button type="button" className="formButton" onClick={handelSubmit}>
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
