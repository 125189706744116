import React from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
  RowDD,
  Selection,
} from '@syncfusion/ej2-react-grids';
import PropTypes from 'prop-types';
import {
  attributeGroupsGrid,
  contextMenuItems,
} from '../../data/attributeGroupTableDefinition';

const GroupAttributeList = ({ groupAttributes }) => {
  let grid;
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };
  const selectOptions = { type: 'Multiple' };
  const rowDropOptions = { targetID: 'Grid' };
  const rowDrop = (args) => {
    args.cancel = true;
    const value = [];
    for (let r = 0; r < args.rows.length; r += 1) {
      value.push(args.fromIndex + r);
    }
    grid.reorderRows(value, args.dropIndex);
  };
  return (
    <div className="m-2 mt-3 p-2 md:p-5 bg-white rounded-3xl">
      <GridComponent
        dataSource={groupAttributes}
        id="Grid"
        ref={(g) => (grid = g)}
        width="auto"
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        pageSettings={{ pageCount: 5, pageSize: 10 }}
        editSettings={editing}
        toolbar={toolbarOptions}
        allowRowDragAndDrop
        rowDrop={rowDrop}
        selectionSettings={selectOptions}
        rowDropSettings={rowDropOptions}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {attributeGroupsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[RowDD, Selection, Search, Page, Toolbar]} />
      </GridComponent>
    </div>
  );
};

GroupAttributeList.propTypes = {
  groupAttributes: PropTypes.object.isRequired,
};
export default GroupAttributeList;
