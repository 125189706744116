import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import TaxList from './TaxList';
import Tax from './Tax';
import TaxRuleGroup from './TaxRuleGroup';
import TaxRuleGroupList from './TaxRuleGroupList';
import TaxContext from '../taxContext/TaxContext';
import { selectCurrentToken } from '../../features/auth/authSlice';
import TaxRuleContext from '../taxRuleContext/TaxRuleContext';

const apiURL = process.env.REACT_APP_API_URL;

const TaxPage = () => {
  const token = useSelector(selectCurrentToken);
  const [value, setValue] = useState({
    component: 'Tax',
    id: null,
    title: 'Tax',
  });
  const [currentTab, setCurrentTab] = useState('1');
  const [taxRuleGroups, setTaxRuleGroups] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const tabs = [
    {
      id: 1,
      tabTitle: 'Tax',
      title: 'Tax',
      content: (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <TaxContext.Provider value={{ value, setValue }}>
          {value.component === 'AddTax' ? <Tax /> : <TaxList taxes={taxes} />}
        </TaxContext.Provider>
      ),
    },
    {
      id: 2,
      tabTitle: 'Tax Rules',
      title: 'Tax Rules',
      content: (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <TaxRuleContext.Provider value={{ value, setValue }}>
          {value.component === 'AddTaxRuleGroup' ? (
            <TaxRuleGroup />
          ) : (
            <TaxRuleGroupList taxRuleGroups={taxRuleGroups} />
          )}
        </TaxRuleContext.Provider>
      ),
    },
  ];

  const getTaxRuleGroups = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/taxes/get-all-tax-rule-groups`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setTaxRuleGroups(response.data);
      })
      .catch((error) => {
        JSON.stringify(error);
      });
  };

  const getTaxes = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/taxes/get-all-taxes`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setTaxes(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  const handleTabClick = (e) => {
    setValue({
      title: 'Tax',
      component: 'Tax',
      id: null,
    });
    setCurrentTab(e.target.id);
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    // getRoles();
    getTaxes();
    getTaxRuleGroups();
    // handelFormDataChange();
  }, []);

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl">
      <div className="flex justify-between">
        {currentTab === '1' ? (
          <Header category="Page" title={value.title} />
        ) : (
          <Header category="Page" title="Tax rules" />
        )}
        <div className="flex justify-between gap-2 items-center">
          <Link
            className="cursor-pointer bg-shifti-grey-btn-bg text-white rounded-full w-64"
            onClick={() => {
              if (currentTab === '1') {
                setValue({
                  title: 'Tax',
                  component: 'AddTax',
                  id: null,
                });
              } else {
                setValue({
                  title: 'Tax rules',
                  component: 'AddTaxRuleGroup',
                  id: null,
                });
              }
              // navigate("/create-user");
            }}
          >
            <span className="px-2 py-2 flex items-center gap-8 text-xl">
              <span className="px-2 bg-white text-shifti-grey-text rounded-full">
                +
              </span>
              Create {currentTab === '1' ? 'Tax' : 'Tax rules'}
            </span>
          </Link>
          <input
            type="search"
            className="bg-white text-grey rounded-full p-2 w-80"
            placeholder="Search: (ex: Reference, product...)"
          />
        </div>
      </div>
      <div className="flex justify-between flex-wrap gap-2">
        <div className="p-2 md:p-2 bg-white rounded-3xl flex sm:w-46 h-11 mt-[8px]">
          <div className="tabs">
            <div className="divide-x">
              {tabs.map((tab, i) => (
                <button
                  type="button"
                  key={i}
                  id={tab.id}
                  disabled={currentTab === `${tab.id}`}
                  onClick={handleTabClick}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tab.tabTitle} &nbsp;
                  &nbsp;
                </button>
              ))}
            </div>
          </div>
        </div>

        <div
          className="help-button mt-3"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <a
            style={{
              background: '#EC1577',
              color: 'white',
              cursor: 'pointer',
              borderRadius: '25px',
              padding: '7.5px 40px 0px',
              fontWeight: 'normal',
              fontSize: '1,04vw',
              height: '37px',
            }}
            // onClick={() => {
            //   navigate('/create-user')
            // }}
          >
            Help
          </a>
        </div>
      </div>

      <div className="content">
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && <div> {tab.content} </div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaxPage;
