import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

function EditCarrierDimensions({
  setErrors,
  setFormData,
  formData,
  currentTab,
  validatedTabs,
  setValidatedTabs,
  setCurrentTab,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (Object.entries(errors).length === 0) {
      setErrors(0);
    } else {
      setErrors(1);
    }
  }, [errors]);

  const handleOnChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const onSubmit = () => {
    const currentTabId = parseInt(currentTab);
    setValidatedTabs([...validatedTabs, currentTabId]);
    setCurrentTab('4');
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-5">
        <div className="input-box flex flex-col space-y-3 w-[90%]">
          <div className="label-container">
            <label htmlFor="name" className="form-label">
              Maximum package width (cm)
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="text"
                id="name"
                className="form-Input-Shifti-white"
                {...register('max_width', {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter a valid width',
                  },
                })}
                onChange={handleOnChange}
                value={formData.max_width}
              />
              {errors.max_width && (
                <div className="error-box relative">
                  <div className="error-message-box">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.max_width?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="input-box flex flex-col space-y-3 w-[90%]">
          <div className="label-container">
            <label htmlFor="name" className="form-label">
              Maximum package height (cm)
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="text"
                id="name"
                className="form-Input-Shifti-white"
                // value={formData.name}
                {...register('max_height', {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter a valid height',
                  },
                })}
                onChange={handleOnChange}
                value={formData.max_height}
              />
              {errors.max_height && (
                <div className="error-box relative">
                  <div className="error-message-box">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.max_height?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="input-box flex flex-col space-y-3 w-[90%]">
          <div className="label-container">
            <label htmlFor="name" className="form-label">
              Maximum package depth (cm)
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="text"
                id="name"
                className="form-Input-Shifti-white"
                {...register('max_depth', {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter a valid depth',
                  },
                })}
                onChange={handleOnChange}
                value={formData.max_depth}
              />
              {errors.max_depth && (
                <div className="error-box relative">
                  <div className="error-message-box">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.max_depth?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="input-box flex flex-col space-y-3 w-[90%]">
          <div className="label-container">
            <label htmlFor="name" className="form-label">
              Maximum package weight (kg)
            </label>
          </div>
          <div className="input-container">
            <div className="text_input_box">
              <input
                type="text"
                id="name"
                className="form-Input-Shifti-white"
                {...register('max_weight', {
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: 'Please enter a valid weight',
                  },
                })}
                onChange={handleOnChange}
                value={formData.max_weight}
              />
              {errors.max_weight && (
                <div className="error-box relative">
                  <div className="error-message-box">
                    <p
                      className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                      ref={(el) =>
                        el &&
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        })
                      }
                    >
                      {errors.max_weight?.message}
                    </p>
                    <div className="cone" />
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-save-submit flex justify-end md:mr-10 mt-5 space-x-5">
        <button
          type="button"
          onClick={() => {
            setCurrentTab('2');
          }}
          className="pl-[0.2rem]  bg-[#AAA6C3] rounded-3xl w-[10rem] text-white h-[37px]"
        >
          previous
        </button>
        <button
          type="submit"
          className="pl-[0.7rem] bg-[#F99E1F] rounded-3xl w-[10rem] text-white h-[37px]"
        >
          Next
        </button>
      </div>
    </form>
  );
}

EditCarrierDimensions.propTypes = {
  setErrors: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  currentTab: PropTypes.number.isRequired,
  validatedTabs: PropTypes.number.isRequired,
  setValidatedTabs: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
};
export default EditCarrierDimensions;
