import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faShop } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';
import { Header } from '../../components';
import ShopNotImported from '../../components/ShopNotImported';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

export default function CreateAddress() {
  const token = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const [firstCountryId, setFirstCountryId] = useState(null); // Initialize state variable to null
  const [countries, setCountries] = useState([]);
  const [shops, setShops] = useState([]);
  const [shopType, setShopType] = useState('');
  const [selectedShop, setSelectedShop] = useState('');
  const [isShopImported, setIsShopImported] = useState();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerExists, setCustomerExists] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const [phonePrefix, setPhonePrefix] = useState('93');

  const getShops = async () =>
    new Promise((resolve, reject) => {
      const decoded = jwtDecode(token);
      const config = {
        method: 'get',
        url: `${apiURL}/shop/get-all-shops`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  const getCustomers = async () =>
    new Promise((resolve, reject) => {
      const decoded = jwtDecode(token);
      const config = {
        method: 'get',
        url: `${apiURL}/customers`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

  const getCountries = async (id) =>
    new Promise((resolve, reject) => {
      const decoded = jwtDecode(token);
      const config = {
        method: 'get',
        url: `${apiURL}/countries/shop/${id}`,
        headers: {
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .request(config)
        .then((response) => {
          setCountries(response.data);
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });

  useEffect(() => {
    Promise.all([getCustomers(), getShops()]).then(
      async ([customerResponse, shopsResponse]) => {
        setShops(shopsResponse);
        setCustomers(customerResponse);
        if (shopType === '') {
          setShopType(shopsResponse[0].type);
          setSelectedShop(shopsResponse[0].id);
          setIsShopImported(shopsResponse[0].is_imported);
          getCountries(shopsResponse[0].id).then(() => {
            if (countries.length > 0) {
              setFirstCountryId(countries[0].id);
            }

            setLoading(false);
          });
        } else {
          getCountries(selectedShop).then(() => {
            if (countries.length > 0) {
              setFirstCountryId(countries[0].id);
            }

            setLoading(false);
          });
        }
      }
    );
  }, [selectedShop]);

  const handleStates = (event) => {
    countries.forEach((country) => {
      if (JSON.stringify(country.id) === event.target.value) {
        setPhonePrefix(country.call_prefix);
        if (country.States.length > 0) {
          const stateNames = country.States.map((state) => ({
            id: state.id,
            name: state.name,
          }));
          setStates(stateNames);
        } else {
          setStates([]);
          setValue('state', '');
        }
      }
    });
  };

  const showStates = () => {
    if (countries.length > 0) {
      if (states.length > 0) {
        return (
          <div className="input-box flex flex-col space-y-3 w-[60%]">
            <div className="label-container">
              <label htmlFor="State" className="form-label">
                State <p className="text-[#EC1577] ml-2">*</p>
              </label>
            </div>
            <div className="input-container">
              <select
                id="State"
                className="essentialSelect"
                {...register('state_id', {
                  required: {
                    value: true,
                    message: 'this field is required',
                  },
                })}
              >
                <option value="">choose a state</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            {errors.state_id && (
              <span className="text-[#EC1577]">{errors.state_id?.message}</span>
            )}
          </div>
        );
      }
    }
  };

  const handleShop = (e) => {
    setSelectedShop(e.target.value);
    setShopType(e.target.selectedOptions[0].getAttribute('type'));
    setIsShopImported(e.target.selectedOptions[0].getAttribute('isImported'));
  };

  const onSubmit = (data) => {
    const customer = customers.find(
      (item) => item.email === data.customer_email
    );
    if (!customer) {
      // Email not found, show error message
      setError('customer_email', {
        type: 'manual',
        message: 'Please select a customer email!',
      });
    } else if (customer) {
      if (shopType === 'prestashop') {
        const addressData = JSON.stringify({
          shops: [parseInt(selectedShop)],
          customer_id: customer.id,
          manufacturer_id: 1, // static
          supplier_id: 0, // static
          warehouse_id: 0, // static
          country_id: parseInt(data.country_id),
          state_id: data.state_id !== '' ? parseInt(data.state_id) : null,
          country: data.country,
          state: data.state,
          alias: data.alias,
          company: data.company,
          last_name: data.last_name,
          first_name: data.first_name,
          vat_number: data.vat_number,
          address1: data.address1,
          address2: data.address2,
          postcode: data.postcode,
          city: data.city,
          other: data.other,
          phone: data.phone,
          phone_mobile: data.phone_mobile,
          dni: data.dni,
          deleted: 0,
        });

        const idToast = toast.loading('Please wait...');
        const decoded = jwtDecode(token);
        const config = {
          method: 'post',
          url: `${apiURL}/addresses`,
          headers: {
            'Content-Type': 'application/json',
            'App-Tenant': decoded.UserInfo.tenantId,
            Authorization: `Bearer ${token}`,
          },
          data: addressData,
        };
        api
          .request(config)
          .then((response) => {
            toast.update(idToast, {
              render: 'New Address Is Created !',
              type: response.data === 'success',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              isLoading: false,
            });
            setTimeout(() => {
              window.location.assign('/addresses');
            }, 1500);
          })
          .catch((error) => {});
      }
    }
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="w-[95%] sticky top-[5.5rem] md:ml-[3%] ml-[3.5%] z-40">
        <div className="filter-by-shop mb-5 relative md:w-full w-[22rem]">
          <select className="shopSelect text-center" onChange={handleShop}>
            {shops.map((shop) => (
              <option
                type={shop.type}
                // isImported={`${shop.is_imported}`}
                value={shop.id}
                className="text-[#EC1577] text-center"
                key={shop.id}
              >
                {shop.name}
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            icon={faShop}
            className="h-[1.5rem] w-[1.5rem] scale-100 md:left-[42%] md:top-[19%] top-[19%] left-[30%]  absolute  text-[#AAA6C3] text-opacity-70 checked-1 transition "
          />
        </div>
      </div>
      {isShopImported === 'false' ? (
        <ShopNotImported />
      ) : (
        <div className="md:mt-2 mt-24 md:ml-16 p-2 md:p-5 rounded-3xl flex flex-col">
          <Header category="Page" title="Create Address" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-container flex flex-col space-y-5">
              {shopType === 'prestashop' ? (
                <>
                  <div className="input-box flex flex-col space-y-3 w-[60%]">
                    <div className="label-container">
                      <label htmlFor="Customer-email" className="form-label">
                        Customer email <p className="text-[#EC1577] ml-2">*</p>
                      </label>
                    </div>
                    <div className="input-container">
                      <select
                        id="customer_email"
                        className="essentialSelect"
                        {...register('customer_email')}
                      >
                        <option value="0">Select a customer e-mail</option>
                        {customers.map((customer) => (
                          <option value={customer.email} key={customer.id}>
                            {customer.email}
                          </option>
                        ))}
                      </select>
                      {errors.customer_email && (
                        <span
                          className="text-[#EC1577]"
                          ref={(el) =>
                            el &&
                            el.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            })
                          }
                        >
                          {errors.customer_email?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="id-number" className="form-label">
                        Identification number
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id="id-number"
                          className="form-Input-Shifti-white"
                          {...register('dni', {
                            pattern: {
                              value: /^[a-zA-Z0-9]+$/,
                              message:
                                'Please enter a valid identification number',
                            },
                            maxLength: {
                              value: 16,
                              message: 'max characters are 16 !',
                            },
                          })}
                        />
                        {errors.dni && (
                          <div className="error-box relative">
                            <div className="error-message-box  ">
                              <p
                                className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.dni?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
                      The national ID card number of this person, or a unique
                      tax identification number.
                    </p>
                  </div>
                  {/* prestashop input */}
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="alias" className="form-label">
                        Address alias <p className="text-[#EC1577] ml-2">*</p>
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id="alias"
                          className="form-Input-Shifti-white"
                          {...register('alias', {
                            pattern: {
                              value: /^[^<>;=#{}]*$/,
                              message: 'Please avoid these characters <>;=#{}',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                            minLength: {
                              value: 3,
                              message: 'minimum characters are 3 !',
                            },
                            maxLength: {
                              value: 32,
                              message: 'max characters are 32 !',
                            },
                          })}
                        />
                        {errors.alias && (
                          <div className="error-box relative">
                            <div className="error-message-box">
                              <p
                                className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.alias?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
                      {'Invalid characters: <>;=#{}'}
                    </p>
                  </div>
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="First-name" className="form-label">
                        First name <p className="text-[#EC1577] ml-2">*</p>
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id="First-name"
                          className="form-Input-Shifti-white"
                          {...register('first_name', {
                            pattern: {
                              value: /^[^0-9!<>,;?=+()@#"�{}_ $%:]*$/,
                              message:
                                'Please avoid these characters ^0-9!<>,;?=+()@#"�{}_ $%:',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                            minLength: {
                              value: 3,
                              message: 'minimum characters are 3 !',
                            },
                            maxLength: {
                              value: 255,
                              message: 'maximum characters are 255 !',
                            },
                          })}
                        />
                        {errors.first_name && (
                          <div className="error-box relative">
                            <div className="error-message-box">
                              <p
                                className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.first_name?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
                      {'Invalid characters: 0-9!<>,;?=+()@#"�{}_ $%:'}
                    </p>
                  </div>
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="Last-name" className="form-label">
                        Last name <p className="text-[#EC1577] ml-2">*</p>
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id="Last-name"
                          className="form-Input-Shifti-white"
                          {...register('last_name', {
                            pattern: {
                              value: /^[^0-9!<>,;?=+()@#"�{}_ $%:]*$/,
                              message:
                                'Please avoid these characters ^0-9!<>,;?=+()@#"�{}_ $%:',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                            minLength: {
                              value: 3,
                              message: 'minimum characters are 3 !',
                            },
                            maxLength: {
                              value: 255,
                              message: 'maximum characters are 255 !',
                            },
                          })}
                        />
                        {errors.last_name && (
                          <div className="error-box relative">
                            <div className="error-message-box">
                              <p
                                className="text-white h-full  self-center text-center text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.last_name?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[69%]">
                      {'Invalid characters: 0-9!<>,;?=+()@#"�{}_ $%:'}
                    </p>
                  </div>
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="Company" className="form-label">
                        Company
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id="Company"
                          className="form-Input-Shifti-white"
                          {...register('company', {
                            pattern: {
                              value: /^[^<>;=#{}]*$/,
                              message: 'Please avoid these characters <>;=#{}',
                            },
                            maxLength: {
                              value: 255,
                              message: 'maximum characters are 255 !',
                            },
                          })}
                        />
                        {errors.company && (
                          <div className="error-box relative">
                            <div className="error-message-box">
                              <p
                                className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.company?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
                      {'Invalid characters: <>;=#{}'}
                    </p>
                  </div>
                  {/* prestashop input */}
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="VAT-number" className="form-label">
                        VAT number
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id="VAT-number"
                          className="form-Input-Shifti-white"
                          {...register('vat_number', {
                            maxLength: {
                              value: 255,
                              message: 'maximum characters are 255 !',
                            },
                          })}
                        />
                        {errors.vat_number && (
                          <div className="error-box relative">
                            <div className="error-message-box">
                              <p
                                className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.vat_number?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor=" Address" className="form-label">
                        Address <p className="text-[#EC1577] ml-2">*</p>
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id=" Address"
                          className="priceInput"
                          {...register('address1', {
                            minLength: {
                              value: 3,
                              message: 'minimum characters are 3 !',
                            },
                            maxLength: {
                              value: 128,
                              message: 'maximum characters are 128 !',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                          })}
                        />
                        {errors.address1 && (
                          <div className="error-box relative">
                            <div className="error-message-box">
                              <p
                                className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.address1?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="Address2" className="form-label">
                        Address (2)
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id="Address2"
                          className="form-Input-Shifti-white"
                          {...register('address2', {
                            maxLength: {
                              value: 128,
                              message: 'maximum characters are 128 !',
                            },
                          })}
                        />
                        {errors.address2 && (
                          <div className="error-box relative">
                            <div className="error-message-box">
                              <p
                                className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.address2?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="postal-code" className="form-label">
                        Zip/postal code <p className="text-[#EC1577] ml-2">*</p>
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id="postal-code"
                          className="form-Input-Shifti-white"
                          {...register('postcode', {
                            pattern: {
                              value: /^[0-9]/,
                              message: 'Please enter a valid postal code',
                            },
                            minLength: {
                              value: 3,
                              message: 'minimum characters are 3 !',
                            },
                            maxLength: {
                              value: 12,
                              message: 'maximum characters are 12 !',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                          })}
                        />
                        {errors.postcode && (
                          <div className="error-box relative">
                            <div className="error-message-box">
                              <p
                                className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.postcode?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="City" className="form-label">
                        City <p className="text-[#EC1577] ml-2">*</p>
                      </label>
                    </div>
                    <div className="input-container">
                      <div className="text_input_box">
                        <input
                          type="text"
                          id="City"
                          className="form-Input-Shifti-white"
                          {...register('city', {
                            minLength: {
                              value: 3,
                              message: 'minimum characters are 3 !',
                            },
                            maxLength: {
                              value: 64,
                              message: 'maximum characters are 64 !',
                            },
                            required: {
                              value: true,
                              message: 'This field is required !',
                            },
                          })}
                        />
                        {errors.city && (
                          <div className="error-box relative">
                            <div className="error-message-box">
                              <p
                                className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                                ref={(el) =>
                                  el &&
                                  el.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  })
                                }
                              >
                                {errors.city?.message}
                              </p>
                              <div className="cone" />
                            </div>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-box flex flex-col space-y-3 w-[60%]">
                    <div className="label-container">
                      <label htmlFor="Country" className="form-label">
                        Country
                      </label>
                    </div>
                    <div className="input-container">
                      <select
                        id="Country"
                        className="essentialSelect"
                        {...register('country_id')}
                        defaultValue={firstCountryId} // Set the default value to the ID of the first country
                        onChange={handleStates}
                      >
                        {/* <option value="null">choose a Country</option> */}
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.CountryLangs[0].name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.country_id && (
                      <span
                        className="text-[#EC1577]"
                        ref={(el) =>
                          el &&
                          el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                          })
                        }
                      >
                        {errors.country_id?.message}
                      </span>
                    )}
                  </div>
                  {/* prestashop input */}
                  {showStates()}
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="Phone" className="form-label">
                        Phone
                      </label>
                    </div>
                    <div className="input-container phone_box ">
                      <div className=" phone_call_prefix_box">
                        <p className="phone_call_prefix">+{phonePrefix}</p>
                        <p className="text-[#E1DFEE] bg-[#E1DFEE] w-[3px] h-[2rem] font-light" />
                      </div>
                      <input
                        id="Phone"
                        type="phone"
                        className="phone_input"
                        {...register('phone', {
                          maxLength: {
                            value: 14,
                            message: 'maximum characters are 14 !',
                          },

                          pattern: {
                            value: /^\d+$/,
                            message: 'Please enter a valid phone number',
                          },
                        })}
                      />
                      {errors.phone && (
                        <div className="error-box-phone relative">
                          <div className="error-message-box-phone">
                            <p
                              className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                              ref={(el) =>
                                el &&
                                el.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'center',
                                })
                              }
                            >
                              {errors.phone?.message}
                            </p>
                            <div className="cone" />
                          </div>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* prestashop input */}
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="Mobile-phone" className="form-label">
                        Mobile phone
                      </label>
                    </div>
                    <div className="input-container phone_box ">
                      <div className=" phone_call_prefix_box">
                        <p className="phone_call_prefix">+{phonePrefix}</p>
                        <p className="text-[#E1DFEE] bg-[#E1DFEE] w-[3px] h-[2rem] font-light" />
                      </div>
                      <input
                        id="Mobile-phone"
                        type="phone"
                        className="phone_input"
                        {...register('phone_mobile', {
                          maxLength: {
                            value: 32,
                            message: 'maximum characters are 32 !',
                          },

                          pattern: {
                            value: /^\d+$/,
                            message: 'Please enter a valid phone number',
                          },
                        })}
                      />
                      {errors.phone_mobile && (
                        <div className="error-box-phone relative">
                          <div className="error-message-box">
                            <p
                              className="text-white h-full w-full self-center text-center p-1 text-[16px]"
                              ref={(el) =>
                                el &&
                                el.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'center',
                                })
                              }
                            >
                              {errors.phone_mobile?.message}
                            </p>
                            <div className="cone" />
                          </div>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            className="h-[1.5rem] w-[1.5rem]  scale-100 bg-[#EC1577]  text-white pointer-events-none absolute top-[10px] left-4"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* prestashop input */}
                  <div className="input-box flex flex-col space-y-3 w-[69%]">
                    <div className="label-container">
                      <label htmlFor="quantity" className="form-label">
                        Other
                      </label>
                    </div>
                    <div className="input-container space-y-2 ">
                      <div className="input-box">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          className="form-Textarea-shifti-white"
                          {...register('other', {
                            pattern: {
                              value: /^[^<>;=#{}]*$/,
                              message: 'Please avoid these characters <>;=#{}',
                            },
                            maxLength: {
                              value: 300,
                              message: 'maximum characters are 32 !',
                            },
                          })}
                        />
                      </div>
                      {errors.other && (
                        <span
                          className="text-[#EC1577]"
                          ref={(el) =>
                            el &&
                            el.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                            })
                          }
                        >
                          {errors.other?.message}
                        </span>
                      )}
                      <p className="font-medium text-sm text-[#AAA6C3] self-Start w-[70%]">
                        {'Invalid characters: <>;=#{}'}
                      </p>
                    </div>
                  </div>
                  <div className="self-center">
                    <button className="addButton w-[14rem]" type="submit">
                      Save
                    </button>
                  </div>
                </>
              ) : null}

              {shopType === 'woocommerce' ? (
                <div className="flex flex-col space-y-10">
                  <p className="text-[#EC1577]">
                    in WooCommerce , Addresses are bound to customers , each
                    customer have it own billing and shipping addresses , you
                    can only Edit existing WooCommerce addresses.
                  </p>
                  <button
                    className="addButtonGrey self-center w-[13rem]"
                    type="button"
                    onClick={() => navigate('/addresses')}
                  >
                    Return
                  </button>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
