import React, { useEffect, useState } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
  Filter,
} from '@syncfusion/ej2-react-grids';
import { RowDirective } from '@syncfusion/ej2-react-charts';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Header } from '../../components';
import { cartsGrid, contextMenuItems } from '../../data/cartsTableDefinition';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const Carts = () => {
  const token = useSelector(selectCurrentToken);
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };
  const [items, setItems] = useState([]);

  useEffect(() => {
    getCarts();
  }, []);

  const getCarts = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/carts`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl">
      <Header category="Page" title="Carts" />

      <div className="m-2 mt-10 p-2 md:p-10 bg-white rounded-3xl">
        <GridComponent
          dataSource={items}
          width="auto"
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          pageSettings={{ pageCount: 5, pageSize: 10 }}
          editSettings={editing}
          toolbar={toolbarOptions}
          allowSelection
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {cartsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <a href="CartPreview/:id">
            <RowDirective />
          </a>
          <Inject services={[Search, Page, Toolbar, Filter]} />
        </GridComponent>
        <div id="myModal" className="modal items-center justify-center">
          <div
            className="modal-content bg-main-bg m-auto py-20 px-20 w-2/5
          "
          >
            <span className="close">&times;</span>
            <div id="modal-content" className="flex justify-center gap-5">
              <button
                type="button"
                className="bg-shifti-main-bg text-white p-5 rounded-full"
              >
                Amazonia
              </button>
              <button
                type="button"
                className="bg-shifti-main-bg text-white p-5 rounded-full"
              >
                Masmoude
              </button>
              <button
                type="button"
                className="bg-shifti-main-bg text-white p-5 rounded-full"
              >
                shopify
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carts;
