/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faAngleRight,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import api from '../../utils/apiService';
import Loader from '../../components/Loader';
import { selectCurrentToken } from '../../features/auth/authSlice';
import woocommerceLogo from '../../img/woocommerce.png';
import shopifyLogo from '../../img/shopify.png';
import prestashopLogo from '../../img/prestaShop.png';

const apiURL = process.env.REACT_APP_API_URL;

function CurrentPlan() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userPlan, setUserPlan] = useState([]);
  const [plan, setPlan] = useState([]);
  const [shops, setShops] = useState([]);
  const [employees, setEmployees] = useState([]);
  const token = useSelector(selectCurrentToken);
  const decoded = jwtDecode(token);
  const [selectedType, setSelectedType] = useState(null);
  const [shopCategories, setShopCategories] = useState([]);
  const [showAddShopModal, setShowAddShopModal] = useState(false);
  const [shopPrice, setShopPrice] = useState([]);
  const [employeePrice, setEmployeePrice] = useState([]);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [showAddProductsModal, setShowAddProductsModal] = useState(false);
  const [pwdShown, setPwdShown] = useState(false);
  const [selectedProductSpace, setSelectedProductSpace] = useState('10');
  const [errors, setErrors] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [showCancelPlanModal, setCancelPlanModal] = useState(false);
  const [plans, setPlans] = useState(null);
  const [subscriptionTime, setSubscriptionTime] = useState('monthly');
  const [upgradeExtra, setUpgradeExtra] = useState(0);
  const [timeExtra, setTimeExtra] = useState(0);
  const [subscriptionDaysLeft, setSubscriptionDaysLeft] = useState(null);

  const getUserCountry = () => decoded.UserInfo.country;

  function getCurrencySymbol() {
    let currency = '';

    if (getUserCountry() === 'Tunisia') {
      currency = 'dt';
    } else if (getUserCountry() === 'France') {
      currency = '€';
    } else {
      currency = '$';
    }

    return currency;
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentToken = searchParams.get('payment_token');
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  const {
    register: registerAddShop,
    formState: { errors: errorsAddShop },
    handleSubmit: handleSubmitAddShop,
    reset: resetAddShop,
  } = useForm();

  const {
    register: registerAddEmployee,
    formState: { errors: errorsAddEmployee },
    handleSubmit: handleSubmitAddEmployee,
    reset: resetAddEmployee,
  } = useForm();

  const {
    register: registerAddProducts,
    formState: { errors: errorsAddProducts },
    handleSubmit: handleSubmitAddProducts,
    reset: resetAddProducts,
  } = useForm();

  const [types, setTypes] = useState([
    {
      id: 1,
      name: 'Shopify',
      type: 'shopify',
      logo: shopifyLogo,
      active: false,
      disabled: true,
    },
    {
      id: 2,
      name: 'Woocommerce',
      type: 'woocommerce',
      logo: woocommerceLogo,
      disabled: false,
    },
    {
      id: 5,
      name: 'Prestashop',
      type: 'prestashop',
      logo: prestashopLogo,
      disabled: false,
    },
  ]);

  const getPaymentResponse = async () => {
    if (paymentToken !== null) {
      const config = {
        method: 'get',
        url: `https://sandbox.paymee.tn/api/v2/payments/${paymentToken}/check`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token 88d14b79b11188f130a06bea8c2003e9bdfd200a',
        },
      };

      await api.request(config).then(async (responseAPI) => {
        if (responseAPI.data.data.payment_status === true) {
          const match = responseAPI.data.data.note.match(/x\d+\s+(\w+)/);
          const paymentType = match[1];

          const configAddExtrashop = {
            method: 'post',
            url: `${apiURL}/payment/${paymentType}`,
            headers: {
              'Content-Type': 'application/json',
              'App-Tenant': decoded.UserInfo.tenantId,
              Authorization: `Bearer ${token}`,
            },
            data: responseAPI,
          };

          api.request(configAddExtrashop).then(async (response) => {
            url.searchParams.delete('payment_token');
            url.searchParams.delete('transaction');
            window.history.pushState({}, document.title, url.toString());

            const invoiceData = {
              fname: responseAPI.data.data.firstname,
              lname: responseAPI.data.data.lastname,
              country: 'tunisia',
              email: responseAPI.data.data.email,
              companyName: 'shifti',
              postCode: '2037',
              address: 'Godaddy, LLC.12345 Sunny Road',

              payment_method: 'paymee',
              payment_id: response.data.payment_id,
              total_price_httc: parseFloat(responseAPI.data.data.amount * 0.89),
              total_price_ttc: responseAPI.data.data.amount,
              description: responseAPI.data.data.note,
              transaction_id: responseAPI.data.data.transaction_id,
              createdAt: '18/09/223',
              currencySybmol: getCurrencySymbol(),
            };

            const invoiceConfig = {
              method: 'post',
              url: `${apiURL}/payment/pdf`,
              headers: {
                'Content-Type': 'application/json',
                'App-Tenant': decoded.UserInfo.tenantId,
                Authorization: `Bearer ${token}`,
              },
              data: invoiceData,
            };
            await api.request(invoiceConfig).then(() => {
              window.location.reload();
            });
          });
        } else {
          url.searchParams.delete('payment_token');
          url.searchParams.delete('transaction');
          window.location.reload();
          window.history.pushState({}, document.title, url.toString());
        }
      });
    }
  };

  const getCurrenUserPlan = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/plans/currentPlan`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => {
        JSON.stringify(error);
        setErrors(error);
      });
  };

  const getAllPlans = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/plans`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => setPlans(response.data))
      .catch((error) => JSON.stringify(error));
  };

  const getPlan = async (id) => {
    const config = {
      method: 'get',
      url: `${apiURL}/plans/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  const getShopPrice = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/prices/shop`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setShopPrice(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  const getEmployeePrice = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/prices/employee`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setEmployeePrice(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  const getEmployees = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/user/getAllUsers`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setEmployees(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  const getShopCategories = async () => {
    const config = {
      method: 'get',
      url: `${apiURL}/group-shop/groups`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setShopCategories(response.data);
        setLoading(false);
        //  setFirstGroupId(response.data[0].id);
      })
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getCurrenUserPlan().then((userPlanResponse) => {
      setUserPlan(userPlanResponse);
      getPlan(userPlanResponse?.plan_id).then((planResponse) => {
        setPlan(planResponse);
        setSubscriptionTime(
          userPlanResponse?.is_monthly === 1 ? 'monthly' : 'yearly'
        );
        getAllPlans();
        getShopCategories();
        getShopPrice();
        getEmployeePrice();
        getPaymentResponse();
        getEmployees();
        setLoading(false);
      });
    });
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handleShopType = (e, type) => {
    if (e.target.checked) {
      setSelectedType(type);
    } else {
      setSelectedType(null);
    }
  };

  const hundleAddExtraShopsModal = () => {
    if (
      userPlan.Shops.length > plan.limit_shops + userPlan.extra_shops ||
      userPlan.Shops.length === plan.limit_shops + userPlan.extra_shops
    ) {
      return (
        <div className="mt-0 fixed left-0 top-0 right-0 bottom-5  z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
          <form
            onSubmit={handleSubmitAddShop(hundleAddShop)}
            className="bg-white flex flex-col items-center justify-center p-5  absolute top-[30%]  w-2/6 left-[35%] space-y-4 pt-6"
          >
            <div className="flex justify-end w-full">
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={() => {
                  setShowAddShopModal(false);
                }}
              >
                X
              </button>
            </div>
            <p className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
              Oops! You've reached the maximum limit for shops on your current
              plan. If you'd like to add more, you can purchase an additional
              slot.
            </p>
            <div className="w-3/6">
              <button
                type="submit"
                className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold disabled:bg-[#AAA6C3]"
              >
                proceed to payment 5 {getCurrencySymbol()}
              </button>
            </div>
            <div className="w-3/6">
              <button
                type="button"
                className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
                onClick={() => {
                  setShowAddShopModal(false);
                }}
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      );
    }

    if (userPlan.Shops.length < plan.limit_shops + userPlan.extra_shops) {
      return (
        <div className="mt-0 fixed left-0 top-0 right-0 bottom-5  z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
          <form
            onSubmit={handleSubmitAddShop(hundleAddShop)}
            className="bg-white flex flex-col items-center justify-center p-5  absolute top-[5%]  w-2/6 left-[35%] space-y-4 pt-6"
          >
            <div className="flex justify-end w-full">
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={() => {
                  setShowAddShopModal(false);
                }}
              >
                X
              </button>
            </div>
            <div
              className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
              style={{ borderColor: errorsAddShop.name ? 'red' : '#AAA6C3' }}
            >
              <label
                htmlFor="shop-name"
                className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
              >
                Shop Name
              </label>
              <input
                id="shop-name"
                type="text"
                placeholder="Shop Name"
                className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
                {...registerAddShop('name', {
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
              {errorsAddShop.name && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsAddShop.name?.message}
                </p>
              )}
            </div>

            <div
              className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#5E6366] relative  p-2"
              style={{
                borderColor: errorsAddShop.shop_group_id ? 'red' : '#AAA6C3',
              }}
            >
              <label
                htmlFor="Shop-Group"
                className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
              >
                Shop Group
              </label>
              <select
                id="Shop-Group"
                className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
                {...registerAddShop('shop_group_id', {
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
                defaultValue={shopCategories[0].id}
              >
                {shopCategories.map((cat, i) => (
                  <option value={cat.id} key={i}>
                    {cat.name}
                  </option>
                ))}
              </select>
              {errorsAddShop.shop_group_id && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsAddShop.shop_group_id?.message}
                </p>
              )}
            </div>
            <div className="text-sm font-semibold text-[#181143] w-2/3">
              Shop Type
            </div>
            <div className="flex flex-col w-5/6 p-2 justify-evenly space-y-3">
              <div className="flex flex-row w-full justify-evenly">
                {types.map((type, i) => (
                  <label
                    key={i}
                    htmlFor={`type-${type.id}`}
                    className={`flex items-center cursor-pointer w-1/5 p-3 rounded-2xl shadow-lg ${
                      // eslint-disable-next-line no-nested-ternary
                      type.disabled === false
                        ? selectedType === type.type
                          ? 'bg-[#F99E1FDE]'
                          : 'bg-white'
                        : 'pointer-events-none'
                    } `}
                  >
                    <img
                      src={type.logo}
                      alt={type.name}
                      style={{
                        filter:
                          type.disabled === true
                            ? 'grayscale(100%)'
                            : 'grayscale(0%)',
                      }}
                    />
                    <input
                      id={`type-${type.id}`}
                      type="radio"
                      checked={selectedType === type.type}
                      disabled={type.disabled}
                      value={type.type}
                      onClick={(e) => handleShopType(e, type.type)}
                      className="appearance-none hover:outline-0 focus:outline-0"
                      {...registerAddShop('type')}
                    />
                  </label>
                ))}
              </div>
            </div>
            {selectedType === 'prestashop' && prestashopForm()}
            {selectedType === 'woocommerce' && woocommerceForm()}
            <div className="w-3/6">
              <button
                type="submit"
                className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold disabled:bg-[#AAA6C3]"
                disabled={selectedType === null}
              >
                add shop
              </button>
            </div>
            <div className="w-3/6">
              <button
                type="button"
                className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
                onClick={() => {
                  setShowAddShopModal(false);
                  setSelectedType(null);
                }}
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      );
    }
  };

  const hundleAddEmployee = async (employeeData) => {
    const checkdata = {
      username: `${employeeData.name}@${employeeData.lastname}`,
      email: employeeData.email,
    };

    if (employees.length < plan.limit_employees + userPlan.extra_employees) {
      const config = {
        method: 'post',
        url: `${apiURL}/user/checkDuplicate/`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: checkdata,
      };

      await api
        .request(config)
        .then(async () => {
          const newEmployeeData = {
            first_name: employeeData.name,
            last_name: employeeData.lastname,
            password: employeeData.password,
            phone: employeeData.phone,
            email: employeeData.email,
          };

          const config3 = {
            method: 'post',
            url: `${apiURL}/user/addEmployee/`,
            headers: {
              'Content-Type': 'application/json',
              'App-Tenant': decoded.UserInfo.tenantId,
              Authorization: `Bearer ${token}`,
            },
            data: newEmployeeData,
          };

          await api.request(config3).then((responsoneAddEmployee) => {
            if (responsoneAddEmployee.status === 201) {
              window.location.reload();
            }
          });
        })
        .catch((error) => {
          setErrors(error.response.data.message);
        });
    }

    if (
      employees.length > plan.limit_employees + userPlan.extra_employees ||
      employees.length === plan.limit_employees + userPlan.extra_employees
    ) {
      const userData = JSON.stringify({
        first_name: decoded.UserInfo.first_name,
        last_name: decoded.UserInfo.last_name,
        email: decoded.UserInfo.email,
        phone: decoded.UserInfo.phone,
        priceType: 'employee',
        value: 1,
      });

      const config2 = {
        method: 'post',
        url: `${apiURL}/payment/`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: userData,
      };

      api.request(config2).then((responsePayment) => {
        if (responsePayment.status === 200) {
          window.location.href = responsePayment.data.data.payment_url;
        }
      });
    }
  };

  const hundleAddExtraEmployeeModal = () => {
    if (
      employees.length > plan.limit_employees + userPlan.extra_employees ||
      employees.length === plan.limit_employees + userPlan.extra_employees
    ) {
      return (
        <div className="mt-0 fixed left-0 top-0 right-0 bottom-5  z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
          <form
            onSubmit={handleSubmitAddEmployee(hundleAddEmployee)}
            className="bg-white flex flex-col items-center justify-center p-5  absolute top-[30%]  w-2/6 left-[35%] space-y-4 pt-6"
          >
            <div className="flex justify-end w-full">
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={() => {
                  setShowAddEmployeeModal(false);
                }}
              >
                X
              </button>
            </div>
            <p className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
              Oops! You've reached the maximum limit for employees on your
              current plan. If you'd like to add more, you can purchase an
              additional slot.
            </p>
            <div className="w-3/6">
              <button
                type="submit"
                className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold  disabled:bg-[#AAA6C3]"
              >
                proceed to payment 3$
              </button>
            </div>
            <div className="w-3/6">
              <button
                type="button"
                className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
                onClick={() => {
                  setShowAddEmployeeModal(false);
                }}
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      );
    }

    if (employees.length < plan.limit_employees + userPlan.extra_employees) {
      return (
        <div className="mt-0 fixed left-0 top-0 right-0 bottom-0 z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
          <form
            onSubmit={handleSubmitAddEmployee(hundleAddEmployee)}
            className="bg-white flex flex-col items-center justify-center p-5 h-[40rem] absolute top-[10%]  w-2/6 left-[35%] space-y-4 pt-6"
          >
            <div className="flex justify-end w-full">
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={() => {
                  setShowAddShopModal(false);
                }}
              >
                X
              </button>
            </div>
            <div className="text-lg text-red-600 ">{errors}</div>
            <div
              className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
              style={{
                borderColor: errorsAddEmployee.name ? 'red' : '#AAA6C3',
              }}
            >
              <label
                htmlFor="name"
                className="text-xs font-semibold text-[#181143] desktop:h-full  desktop:text-sm"
              >
                First Name
              </label>
              <input
                id="name"
                type="text"
                placeholder="First Name"
                className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
                {...registerAddEmployee('name', {
                  pattern: {
                    value: /^[A-Za-z'-]{1,50}$/,
                    message: 'Enter a valid name',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
            </div>
            {errorsAddEmployee.name && (
              <p
                className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                ref={(el) =>
                  el &&
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              >
                {errorsAddEmployee.name?.message}
              </p>
            )}
            <div
              className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
              style={{
                borderColor: errorsAddEmployee.lastname ? 'red' : '#AAA6C3',
              }}
            >
              <label
                htmlFor="last_name"
                className="text-xs font-semibold text-[#181143] desktop:h-full desktop:sm"
              >
                Last Name
              </label>
              <input
                id="last_name"
                type="text"
                placeholder="Last Name"
                className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
                {...registerAddEmployee('lastname', {
                  pattern: {
                    value: /^[A-Za-z'-]{1,50}$/,
                    message: 'Enter a valid name',
                  },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                })}
              />
              {errorsAddEmployee.lastname && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsAddEmployee.lastname?.message}
                </p>
              )}
            </div>
            <div
              className="flex flex-col w-2/3 border-1 rounded-lg border-[#5E6366] relative  p-2"
              style={{
                borderColor: errorsAddEmployee.password ? 'red' : '#AAA6C3',
              }}
            >
              <label
                htmlFor="Password"
                className="desktop:text-sm font-semibold text-[#181143] text-xs"
              >
                Password
              </label>
              <input
                id="Password"
                type={pwdShown ? 'text' : 'password'}
                placeholder="Password"
                className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
                {...registerAddEmployee('password', {
                  // pattern: {
                  //   value:
                  //     /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~])(?=.*[A-Z]).{8,}$/ <
                  //     />/,
                  //   message: 'Enter a valid name',
                  // },
                  required: {
                    value: true,
                    message: 'This field is required !',
                  },
                  minLength: {
                    value: 8,
                    message: 'password needs to be more than 8 characters',
                  },
                  maxLength: {
                    value: 255,
                    message: 'max characters are 255 !',
                  },
                })}
              />
              <span className="z-20 absolute right-[2%]  bottom-[2%]">
                <button
                  type="button"
                  onClick={() =>
                    pwdShown === false ? setPwdShown(true) : setPwdShown(false)
                  }
                  className="text-[#AAA6C3] "
                >
                  {pwdShown === true ? (
                    <AiOutlineEyeInvisible size={30} />
                  ) : (
                    <AiOutlineEye size={30} />
                  )}
                </button>
              </span>
              {errorsAddEmployee.password && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsAddEmployee.password?.message}
                </p>
              )}
            </div>
            <div
              className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
              style={{
                borderColor: errorsAddEmployee.email ? 'red' : '#AAA6C3',
              }}
            >
              <label
                htmlFor="E-mail"
                className="text-xs font-semibold text-[#181143] desktop:text-sm"
              >
                E-mail
              </label>
              <input
                id="E-mail"
                type="text"
                placeholder="E-mail"
                className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
                {...registerAddEmployee('email', {
                  required: 'this field is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Please enter a valid email addres',
                  },
                })}
              />
              {errorsAddEmployee.email && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsAddEmployee.email?.message}
                </p>
              )}
            </div>
            <div
              className="flex flex-col w-2/3  relative border-1 rounded-lg border-[#5E6366]  p-2"
              style={{
                borderColor: errorsAddEmployee.phone ? 'red' : '#AAA6C3',
              }}
            >
              <label
                htmlFor="phone"
                className="text-xs font-semibold text-[#181143] desktop:text-sm"
              >
                Phone
              </label>
              <input
                id="phone"
                type="text"
                placeholder="Phone"
                className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
                {...registerAddEmployee('phone', {
                  required: 'this field is required',
                  pattern: {
                    value: /^\+?[0-9\s-()]{8,}$/,
                    message: 'Enter a valid phone number',
                  },
                })}
              />
              {errorsAddEmployee.phone && (
                <p
                  className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
                  ref={(el) =>
                    el &&
                    el.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                  }
                >
                  {errorsAddEmployee.phone?.message}
                </p>
              )}
            </div>
            <div className="w-3/6">
              <button
                type="submit"
                className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold"
              >
                Add
              </button>
            </div>
            <div className="w-3/6">
              <button
                type="button"
                className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
                onClick={() => {
                  setShowAddEmployeeModal(false);
                  setErrors('');
                }}
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      );
    }
  };

  const prestashopForm = () => (
    <>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.url ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Url
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Shop url"
          {...registerAddShop('url', {
            pattern: {
              value:
                /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
              message: 'Enter a valid website address',
            },
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.url && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.url?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.key ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Webservice key"
          {...registerAddShop('key', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.key && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.key?.message}
          </p>
        )}
      </div>
    </>
  );

  const woocommerceForm = () => (
    <>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.url ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Url
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Shop url"
          {...registerAddShop('url', {
            pattern: {
              value:
                /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/.*)?$/,
              message: 'Enter a valid website address',
            },
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.url && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.url?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.woo_sk ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Secret Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Secret Key"
          {...registerAddShop('woo_sk', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.woo_sk && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.woo_sk?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.woo_ck ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Consumer Key
        </label>
        <input
          type="text"
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          placeholder="Consumer Key"
          {...registerAddShop('woo_ck', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        />
        {errorsAddShop.woo_ck && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.woo_ck?.message}
          </p>
        )}
      </div>
      <div
        className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
        style={{ borderColor: errorsAddShop.version ? 'red' : '#AAA6C3' }}
      >
        <label
          htmlFor="name"
          className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
        >
          Version
        </label>
        <select
          name=""
          id=""
          className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
          {...registerAddShop('version', {
            required: {
              value: true,
              message: 'This field is required !',
            },
          })}
        >
          <option value="wc/v3">wc/v3</option>
        </select>
        {errorsAddShop.version && (
          <p
            className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
            ref={(el) =>
              el &&
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            {errorsAddShop.version?.message}
          </p>
        )}
      </div>
    </>
  );

  const hundleAddShop = async (shopData) => {
    const id = toast.loading('Please wait...');

    if (
      shops.length > plan.limit_shops + userPlan.extra_shops ||
      shops.length === plan.limit_shops + userPlan.extra_shops
    ) {
      const userData = JSON.stringify({
        first_name: decoded.UserInfo.first_name,
        last_name: decoded.UserInfo.last_name,
        email: decoded.UserInfo.email,
        phone: decoded.UserInfo.phone,
        priceType: 'shop',
        value: 1,
      });

      const config = {
        method: 'post',
        url: `${apiURL}/payment/`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data: userData,
      };

      api.request(config).then((responsePayment) => {
        if (responsePayment.status === 200) {
          window.location.href = responsePayment.data.data.payment_url;
        }
      });
    }

    if (shops.length < plan.limit_shops + userPlan.extra_shops) {
      const data = JSON.stringify({
        name: shopData.name,
        type: shopData.type,
        group_shop_id: shopData.shop_group_id,
        url: shopData.url,
        pres_key: shopData.key,
        woo_consumer_key: shopData.woo_ck,
        woo_consumer_secret: shopData.woo_sk,
        woo_version: shopData.version,
      });

      const config = {
        method: 'post',
        url: `${apiURL}/shop/${shopData.type}`,
        headers: {
          'Content-Type': 'application/json',
          'App-Tenant': decoded.UserInfo.tenantId,
          Authorization: `Bearer ${token}`,
        },
        data,
      };

      api
        .request(config)
        .then(async (response) => {
          if (response.status === 200) {
            const configAddShop = {
              method: 'post',
              url: `${apiURL}/plans/createShop`,
              headers: {
                'Content-Type': 'application/json',
                'App-Tenant': decoded.UserInfo.tenantId,
                Authorization: `Bearer ${token}`,
              },
              data,
            };

            await api
              .request(configAddShop)
              .then((responseAddShop) => {
                if (responseAddShop.status === 200) {
                  toast.update(id, {
                    render: 'Connected',
                    type: 'success',
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    isLoading: false,
                  });
                  setShowAddShopModal(false);
                  window.location.reload();
                }
              })
              .catch((error) => {
                toast.update(id, {
                  render: 'Connection failed !',
                  type: 'error',
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  isLoading: false,
                });
              });
          }
        })
        .catch((err) => setShowAddShopModal(false));
    }
  };

  const hundleAddExtraProductsModal = () => (
    <div className="mt-0 fixed left-0 top-0 right-0 bottom-5  z-40 bg-opacity-50 bg-black w-full h-full flex justify-center">
      <form
        onSubmit={handleSubmitAddProducts(hundleExtraProducts)}
        className="bg-white flex flex-col items-center justify-center p-5  absolute top-[30%]  w-2/6 left-[35%] space-y-4 pt-6"
      >
        <div className="flex justify-end w-full">
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            onClick={() => {
              setShowAddProductsModal(false);
            }}
          >
            X
          </button>
        </div>
        <p className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
          Oops! You're about to add new extra products space to your current
          plan
        </p>
        <div
          className="flex flex-col w-2/3 border-1 desktop:h-full h-[3rem] rounded-lg border-[#AAA6C3] relative  p-2"
          style={{ borderColor: errorsAddProducts.space ? 'red' : '#AAA6C3' }}
        >
          <label
            htmlFor="shop-name"
            className="text-xs font-semibold text-[#181143] desktop:h-full h-[2rem] desktop:text-sm"
          >
            space
          </label>
          <select
            id="shop-name"
            type="text"
            placeholder="Shop Name"
            className="text-sm w-full focus:outline-0 font-medium text-[#181143] hover:outline-0 desktop:h-full desktop:text-lg h-[2rem]"
            {...registerAddProducts('space', {
              required: {
                value: true,
                message: 'This field is required !',
              },
            })}
            value={selectedProductSpace}
            onChange={(e) => hundleProductSpace(e)}
          >
            <option selected value={10}>
              10 products
            </option>
            <option value={30}>30 products</option>
            <option value={50}>50 products</option>
            <option value={100}>100 products</option>
          </select>
          {errorsAddProducts.space && (
            <p
              className="text-red-500 h-full w-full self-center text-center top-[95%] p-1 text-xs absolute"
              ref={(el) =>
                el &&
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
            >
              {errorsAddProducts.space?.message}
            </p>
          )}
        </div>
        <div className="w-3/6">
          <button
            type="submit"
            className="bg-[#EC1577] rounded-2xl w-full p-1 text-white font-semibold disabled:bg-[#AAA6C3]"
          >
            proceed to payment{' '}
            {selectedProductSpace === '10' && `2${getCurrencySymbol()}`}{' '}
            {selectedProductSpace === '30' && `4${getCurrencySymbol()}`}{' '}
            {selectedProductSpace === '50' && `5${getCurrencySymbol()}`}{' '}
            {selectedProductSpace === '100' && `8${getCurrencySymbol()}`}
          </button>
        </div>
        <div className="w-3/6">
          <button
            type="button"
            className=" border-[#EC1577] border-2 rounded-2xl w-full p-1 text-[#EC1577] font-semibold"
            onClick={() => {
              setShowAddProductsModal(false);
            }}
          >
            cancel
          </button>
        </div>
      </form>
    </div>
  );

  const hundleExtraProducts = () => {
    const userData = JSON.stringify({
      first_name: decoded.UserInfo.first_name,
      last_name: decoded.UserInfo.last_name,
      email: decoded.UserInfo.email,
      phone: decoded.UserInfo.phone,
      priceType: `products`,
      value: selectedProductSpace,
    });

    const config = {
      method: 'post',
      url: `${apiURL}/payment/`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data: userData,
    };

    api.request(config).then((responsePayment) => {
      if (responsePayment.status === 200) {
        window.location.href = responsePayment.data.data.payment_url;
      }
    });
  };

  const hundleProductSpace = (e) => {
    setSelectedProductSpace(e.target.value);
  };

  async function handleRenewPlan(currPlanId, withExtra) {
    const data = {
      with_extra: withExtra,
      user_id: decoded.UserInfo.id,
      first_name: decoded.UserInfo.first_name,
      last_name: decoded.UserInfo.last_name,
      email: decoded.UserInfo.email,
      phone: decoded.UserInfo.phone,
      country: decoded.UserInfo.country,
      currency: getCurrencySymbol(),
    };
    const config = {
      method: 'post',
      url: `${apiURL}/payment/paymentRenewPlan/${currPlanId}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    try {
      const response = await api.request(config);
      if (response.data.status === true) {
        window.location.href = response.data.data.payment_url;
      }
    } catch (error) {
      // setErrors(error);
    }
  }

  async function handleUpgradePlan(planToUpgradeTo, withExtra) {
    const data = {
      with_extra: withExtra,
      user_id: decoded.UserInfo.id,
      first_name: decoded.UserInfo.first_name,
      last_name: decoded.UserInfo.last_name,
      email: decoded.UserInfo.email,
      phone: decoded.UserInfo.phone,
      currency: getCurrencySymbol(),
      country: decoded.UserInfo.country,
    };
    const config = {
      method: 'post',
      url: `${apiURL}/payment/paymentUpgradePlan/${planToUpgradeTo}`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    try {
      const response = await api.request(config);
      if (response.data.status === true) {
        window.location.href = response.data.data.payment_url;
      }
    } catch (error) {
      return JSON.stringify(error);
    }
  }

  // async function handleCancelPlan(currPlanId) {
  //   const data = {
  //     user_id: decoded.UserInfo.id,
  //     first_name: decoded.UserInfo.first_name,
  //     last_name: decoded.UserInfo.last_name,
  //     email: decoded.UserInfo.email,
  //     phone: decoded.UserInfo.phone,
  //     country: decoded.UserInfo.country,
  //     currency: getCurrencySymbol(),
  //   };
  //   const config = {
  //     method: 'patch',
  //     url: `${apiURL}/plans/cancelPlan/${currPlanId}`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'App-Tenant': decoded.UserInfo.tenantId,
  //       Authorization: `Bearer ${token}`,
  //     },
  //     data,
  //   };
  //   try {
  //     const response = await api.request(config);
  //     if (response.status === 200) {
  //       navigate('/');
  //     }
  //   } catch (error) {
  //     return JSON.stringify(error);
  //   }
  // }

  async function handleChangeSubscriptionTime(Type, withExtra) {
    const data = {
      with_extra: withExtra,
      user_id: decoded.UserInfo.id,
      first_name: decoded.UserInfo.first_name,
      last_name: decoded.UserInfo.last_name,
      email: decoded.UserInfo.email,
      phone: decoded.UserInfo.phone,
      country: decoded.UserInfo.country,
      currency: getCurrencySymbol(),
    };
    const config = {
      method: 'post',
      url: `${apiURL}/payment/paymentPlanTo${Type}/`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    try {
      const response = await api.request(config);
      if (response.data.status === true) {
        window.location.href = response.data.data.payment_url;
      }
    } catch (error) {
      // setErrors(error);
    }
  }

  const handleUpgradeModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="absolute w-[75vw] left-[20%] bg-white flex-col items-center p-4">
        <div className="flex justify-end w-full">
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            onClick={() => {
              setShowUpgradeModal(false);
              setUpgradeExtra(0);
            }}
          >
            X
          </button>
        </div>
        <div className="w-[58%] flex justify-between pl-8 ml-8">
          <div className="flex self-start w-[50%]">
            <label htmlFor="extra" className="text-xs font-semibold">
              With extra purchases
            </label>
            <select
              className="w-full bg-white border-1 border-[#AAA6C3] rounded-3xl outline-none text-[#AAA6C3] focus:outline-none  hover:outline-none  text-sm"
              id="extra"
              onChange={(e) => {
                setUpgradeExtra(e.target.value);
              }}
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
          <h2 className="text-[1.5rem] text-[#EC1577] font-semibold self-center">
            Upgrade your plan!
          </h2>
        </div>
        <div className="mt-2 flex flex-col justify-center mb-4">
          <div className="flex justify-between gap-2 w-[90%] self-center">
            {plans
              .filter(
                (toUpgradeToplan) => toUpgradeToplan.id > userPlan.plan_id
              )
              .map((toUpgradeToplan, i) => (
                <div className="flex justify-center w-[18rem]" key={i}>
                  <div
                    className="bg-white border-[#181144] border-[0.5px] self-center rounded-3xl p-8 space-y-5 w-full drop-shadow-2xl"
                    style={{
                      backgroundColor: toUpgradeToplan.is_best_plan
                        ? '#181144'
                        : '#ffffff',
                      scale: toUpgradeToplan.is_best_plan ? '1.035' : '1',
                      zIndex: toUpgradeToplan.is_best_plan ? '2' : '1',
                    }}
                  >
                    {toUpgradeToplan.is_best_plan ? (
                      <div className="flex justify-end">
                        <div className="desktop:p-2 p-1 bg-white rounded-3xl text-[#EC1577] desktop:text-sm  text-xs font-semibold w-3/6 text-center">
                          Best Plan
                        </div>
                      </div>
                    ) : (
                      <div className="p-2 text-transparent pointer-events-none">
                        Best Plan
                      </div>
                    )}

                    {toUpgradeToplan.name !== 'Entreprise' ? (
                      <h2
                        className="text-[#231D4F] desktop:pl-9 pl-3"
                        style={{
                          color: toUpgradeToplan.is_best_plan
                            ? '#ffffff'
                            : '#181144',
                        }}
                      >
                        <span className="font-bold text-[2.5rem]">
                          {subscriptionTime === 'monthly'
                            ? toUpgradeToplan.price_month
                            : toUpgradeToplan.price_year}
                        </span>
                        <span className="font-bold text-sm">DT</span>
                        <span className="font-semibold text-l">/</span>
                        <span className="font-semibold text-lg">
                          {subscriptionTime === 'monthly' ? 'month' : 'year'}
                        </span>
                      </h2>
                    ) : (
                      <h2
                        className="text-transparent ml-4 bg-transparent pointer-events-none "
                        style={{
                          color: plan.is_best_plan ? '#ffffff' : '#ffffff',
                        }}
                      >
                        <span className="font-bold text-[2.5rem]">
                          {subscriptionTime === 'monthly'
                            ? toUpgradeToplan.price_month
                            : toUpgradeToplan.price_year}
                        </span>
                        <span className="font-bold text-sm">DT</span>
                        <span className="font-semibold text-l">/</span>
                        <span className="font-semibold text-lg">
                          {subscriptionTime === 'monthly' ? 'month' : 'year'}
                        </span>
                      </h2>
                    )}
                    <div className="flex flex-col space-y-5 desktop:pl-5">
                      <h2
                        className="desktop:text-[2.5rem] text-[2rem] font-bold text-[#231D4F]"
                        style={{
                          color: toUpgradeToplan.is_best_plan
                            ? '#ffffff'
                            : '#181144',
                        }}
                      >
                        {toUpgradeToplan.name}
                      </h2>

                      <ul className="space-y-2 h-[10rem]">
                        <li className="flex space-x-2 items-center">
                          {toUpgradeToplan.name === 'Entreprise' ? (
                            <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          ) : (
                            <div className="w-6 h-6  relative">
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          )}
                          <span
                            className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                            style={{
                              color: toUpgradeToplan.is_best_plan
                                ? '#ffffff'
                                : '#181144',
                            }}
                          >
                            {toUpgradeToplan.name === 'Entreprise'
                              ? 'Unlimited shops'
                              : `number of shops : ${plan.limit_shops}`}
                          </span>
                        </li>
                        <li className="flex space-x-2 items-center">
                          {toUpgradeToplan.name === 'Entreprise' ? (
                            <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          ) : (
                            <div className="w-6 h-6  relative">
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          )}
                          <span
                            className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                            style={{
                              color: toUpgradeToplan.is_best_plan
                                ? '#ffffff'
                                : '#181144',
                            }}
                          >
                            {toUpgradeToplan.name === 'Entreprise'
                              ? 'Unlimited users'
                              : `number of users : ${plan.limit_employees}`}
                          </span>{' '}
                        </li>
                        <li className="flex space-x-2 items-center">
                          {toUpgradeToplan.name === 'Entreprise' ? (
                            <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          ) : (
                            <div className="w-6 h-6  relative">
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          )}
                          <span
                            className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                            style={{
                              color: toUpgradeToplan.is_best_plan
                                ? '#ffffff'
                                : '#181144',
                            }}
                          >
                            {toUpgradeToplan.name === 'Entreprise'
                              ? 'Unlimited products'
                              : `number of products : ${plan.limit_products}`}
                          </span>
                        </li>
                        <li className="flex space-x-2 items-center">
                          {toUpgradeToplan.name === 'Entreprise' ? (
                            <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          ) : (
                            <div className="w-6 h-6  relative">
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          )}
                          <span
                            className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                            style={{
                              color: toUpgradeToplan.is_best_plan
                                ? '#ffffff'
                                : '#181144',
                            }}
                          >
                            {toUpgradeToplan.name === 'Entreprise'
                              ? 'Unlimited support hours'
                              : `technical support : ${plan.limit_support} h`}
                          </span>
                        </li>
                        <li className="flex space-x-2 items-center">
                          {toUpgradeToplan.name === 'Entreprise' ? (
                            <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          ) : (
                            <div className="w-6 h-6  relative">
                              <FontAwesomeIcon
                                icon={faAngleRight}
                                className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                              />
                            </div>
                          )}

                          <span
                            className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                            style={{
                              color: toUpgradeToplan.is_best_plan
                                ? '#ffffff'
                                : '#181144',
                            }}
                          >
                            {' '}
                            {toUpgradeToplan.name === 'Entreprise'
                              ? 'Unlimited storage'
                              : `storage : ${plan.storage} GB`}
                          </span>
                        </li>
                      </ul>
                      <div className="flex justify-center items-center">
                        {toUpgradeToplan.name === 'Entreprise' ? (
                          <button
                            type="button"
                            className="bg-[#231D4F] p-4 text-white self-center w-full rounded-3xl hover:scale-110 transition"
                            style={{
                              backgroundColor: toUpgradeToplan.is_best_plan
                                ? '#EC1577'
                                : '#231D4F',
                            }}
                          >
                            Contact us
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="bg-[#231D4F] p-4 text-white self-center w-full rounded-3xl hover:scale-110 transition"
                            style={{
                              backgroundColor: toUpgradeToplan.is_best_plan
                                ? '#EC1577'
                                : '#231D4F',
                            }}
                            onClick={() => {
                              handleUpgradePlan(
                                toUpgradeToplan.id,
                                upgradeExtra
                              );
                            }}
                          >
                            Choose plan
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const handleChangeSubscriptionTypeModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="bg-white flex flex-col items-center justify-center p-5  absolute  w-2/6space-y-4 pt-8">
        <div className="w-full flex justify-end">
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            onClick={() => setShowTimeModal(false)}
          >
            X
          </button>
        </div>
        <p className="text-xl font-semibold">
          You are switching to a{' '}
          {userPlan.is_monthly === 1 ? 'annual' : 'monthly'} subscription
        </p>
        <p className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
          Once the current billing period ends you will be billed{' '}
          {userPlan.is_monthly === 1 ? 'annualy' : 'monthly'} By choosing an
          annual subscription you save 10% on subscription fee.
        </p>
        <div className="flex flex-col items-start justify-start w-full">
          <div className="input-container w-full">
            <div className="input-container relative flex items-center space-x-3  w-[16rem]">
              <input
                type="radio"
                id="refuseOrders"
                name="preferences"
                className="deliveryCheckbox"
                onClick={() => setTimeExtra(0)}
                defaultChecked
              />
              <label htmlFor="refuseOrders" className="text-sm">
                without Extra purchases
              </label>
              <FontAwesomeIcon
                icon={faCheck}
                className="h-5 w-16  text-[#EC1577] absolute right-[82%]  md:bottom-[15%] pointer-events-none text-opacity-0 checked transition scale-75"
              />
            </div>
          </div>
          <div className="input-container">
            <div className="input-container relative flex items-center space-x-3 w-[16rem]">
              <input
                type="radio"
                id="refuseOrders"
                name="preferences"
                className="deliveryCheckbox"
                onClick={() => setTimeExtra(1)}
              />
              <label htmlFor="refuseOrders" className="text-sm">
                with Extra purchases
              </label>
              <FontAwesomeIcon
                icon={faCheck}
                className="h-5 w-16  text-[#EC1577] absolute right-[82%]  md:bottom-[15%] pointer-events-none text-opacity-0 checked transition scale-75"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <button
            type="button"
            className=" border-2 border-[#EC1577] text-[#EC1577] w-[12rem] rounded-3xl p-2 text-sm"
            onClick={() => setShowTimeModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-[#EC1577] w-[12rem] text-white rounded-3xl p-2 text-sm"
            onClick={() => {
              const confirmed = window.confirm(
                `Do you confirm switching  your current plan to ${
                  userPlan.is_monthly === true ? 'annual' : 'monthly'
                } subscription?`
              );

              if (confirmed) {
                handleChangeSubscriptionTime(
                  userPlan.is_monthly === true ? 'Yearly' : 'Monthly',
                  timeExtra
                );
              }
            }}
          >
            Confirm
            {timeExtra === 1
              ? `(${extraPurchasesTimeCalculation()})`
              : userPlan.is_monthly === false
              ? `(${getPlanPriceMonthly()})`
              : `(${getPlanPriceAnnual()})`}
          </button>
        </div>
      </div>
    </div>
  );

  const extraPurchasesCalculation = () => {
    const extraEmployeesPrice =
      userPlan.extra_employees * employeePrice[0].price;

    const extraShopsPrice = userPlan.extra_shops * shopPrice[0].price;

    const extraProductsPrice =
      userPlan.extra_products === 10
        ? userPlan.extra_products * 1
        : userPlan.extra_products === 20
        ? userPlan.extra_products * 2
        : userPlan.extra_products === 40
        ? userPlan.extra_products * 4
        : userPlan.extra_products === 100
        ? userPlan.extra_products * 5
        : userPlan.extra_products * 5;

    let planPrice;

    if (userPlan.is_monthly === true) {
      if (getUserCountry() === 'Tunisia') {
        planPrice = plan.price_dt_monthly;
      } else if (getUserCountry() === 'France') {
        planPrice = plan.price_euro_monthly;
      } else {
        planPrice = plan.price_dollar_monthly;
      }
    } else if (getUserCountry() === 'Tunisia') {
      planPrice = plan.price_dt_yearly;
    } else if (getUserCountry() === 'France') {
      planPrice = plan.price_euro_yearly;
    } else {
      planPrice = plan.price_dollar_yearly;
    }

    const totalPrice =
      planPrice + extraShopsPrice + extraEmployeesPrice + extraProductsPrice;

    return totalPrice;
  };

  const extraPurchasesTimeCalculation = () => {
    const extraEmployeesPrice =
      userPlan.extra_employees * employeePrice[0].price;

    const extraShopsPrice = userPlan.extra_shops * shopPrice[0].price;

    const extraProductsPrice =
      userPlan.extra_products === 10
        ? userPlan.extra_products * 1
        : userPlan.extra_products === 20
        ? userPlan.extra_products * 2
        : userPlan.extra_products === 40
        ? userPlan.extra_products * 4
        : userPlan.extra_products === 100
        ? userPlan.extra_products * 5
        : userPlan.extra_products * 5;

    // const extraSupportTime = userPlan.extra_support_time * .price;

    let planPrice;

    if (userPlan.is_monthly === false) {
      if (getUserCountry() === 'Tunisia') {
        planPrice = plan.price_dt_monthly;
      } else if (getUserCountry() === 'France') {
        planPrice = plan.price_euro_monthly;
      } else {
        planPrice = plan.price_dollar_monthly;
      }
    } else if (getUserCountry() === 'Tunisia') {
      planPrice = plan.price_dt_yearly;
    } else if (getUserCountry() === 'France') {
      planPrice = plan.price_euro_yearly;
    } else {
      planPrice = plan.price_dollar_yearly;
    }

    const totalPrice =
      planPrice + extraShopsPrice + extraEmployeesPrice + extraProductsPrice;

    return totalPrice;
  };

  function getPlanPrice() {
    let planPrice;

    if (userPlan.is_monthly === true) {
      if (getUserCountry() === 'Tunisia') {
        planPrice = plan.price_dt_monthly;
      } else if (getUserCountry() === 'France') {
        planPrice = plan.price_euro_monthly;
      } else {
        planPrice = plan.price_dollar_monthly;
      }
    } else if (getUserCountry() === 'Tunisia') {
      planPrice = plan.price_dt_yearly;
    } else if (getUserCountry() === 'France') {
      planPrice = plan.price_euro_yearly;
    } else {
      planPrice = plan.price_dollar_yearly;
    }

    return planPrice;
  }

  function getPlanPriceAnnual() {
    let planPrice;

    if (getUserCountry() === 'Tunisia') {
      planPrice = plan.price_dt_yearly;
    } else if (getUserCountry() === 'France') {
      planPrice = plan.price_euro_yearly;
    } else {
      planPrice = plan.price_dollar_yearly;
    }

    return planPrice;
  }

  function getPlanPriceMonthly() {
    let planPrice;

    if (getUserCountry() === 'Tunisia') {
      planPrice = plan.price_dt_monthly;
    } else if (getUserCountry() === 'France') {
      planPrice = plan.price_euro_monthly;
    } else {
      planPrice = plan.price_dollar_monthly;
    }

    return planPrice;
  }

  const handleRenewPlanModal = () => (
    <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center transition delay-150 duration-300 ease-in-out">
      <div className="bg-white flex flex-col items-center justify-center p-5  absolute  w-3/6  space-y-4 pt-8">
        <div className="w-full flex justify-end">
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            onClick={() => setShowRenewModal(false)}
          >
            X
          </button>
        </div>
        <div className=" p-2 w-full ">
          <div className="flex w-full justify-start gap-12 mb-2">
            <div className="flex flex-col space-y-3">
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p>purchase date : </p>
                <span className="text-sm text-[#FEC90F]">
                  {userPlan?.purchase_date
                    .replace('.000Z', '')
                    .replace('T', ' ')}
                </span>
              </p>
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p>expiration date : </p>
                <span className="text-sm text-[#FEC90F]">
                  {userPlan?.expiration_date
                    .replace('.000Z', '')
                    .replace('T', ' ')}
                </span>
              </p>
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p>payment method :</p>
                <span className="text-sm text-[#FEC90F]">
                  {userPlan?.payment_method}
                </span>
              </p>
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p>total price httc :</p>
                <span className="text-sm text-[#FEC90F]">
                  {userPlan?.total_price_httc}
                </span>
              </p>
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p>total price ttc :</p>
                <span className="text-sm text-[#FEC90F]">
                  {userPlan?.total_price_ttc}
                </span>
              </p>
            </div>
            <div className="flex flex-col space-y-3">
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p> extra employees :</p>
                <span className="text-sm text-[#FEC90F]">
                  {userPlan?.extra_employees}
                </span>
              </p>
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p> extra shops :</p>
                <span className="text-sm text-[#FEC90F] ">
                  {userPlan?.extra_shops}
                </span>
              </p>
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p>extra products :</p>
                <span className="text-sm text-[#FEC90F]">
                  {userPlan?.extra_products}
                </span>
              </p>
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p>extra support time :</p>
                <span className="text-sm text-[#FEC90F]">
                  {userPlan?.extra_support_time}
                </span>
              </p>
              <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                <p> extra storage :</p>
                <span className="text-sm text-[#FEC90F]">
                  {userPlan?.extra_storages}
                </span>
              </p>
            </div>
          </div>
        </div>
        <p className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
          {userPlan.is_monthly === 1 ? 'Your monthly ' : 'Your yearly '}
          subscription is paid until{' '}
          {userPlan.expiration_date.replace('.000Z', '').replace('T', ' ')}. if
          you would like to proceed with canceling your subscription, please
          select "Cancel Subscription" below
        </p>
        <div className="flex justify-end gap-4">
          <button
            type="button"
            className=" border-2 border-[#EC1577] text-[#EC1577] w-[12rem] rounded-3xl p-2 text-sm"
            onClick={() => setShowRenewModal(false)}
          >
            cancel
          </button>

          <button
            type="button"
            className="bg-[#EC1577] w-[12rem] text-white rounded-3xl p-2 text-sm"
            onClick={() => {
              const confirmed = window.confirm(
                'Do you confirm renewing your current plan ?'
              );

              if (confirmed) {
                handleRenewPlan(userPlan.id, 0);
              }
            }}
          >
            Renew Plan (
            {userPlan.is_monthly === true
              ? getPlanPriceMonthly()
              : getPlanPriceAnnual()}
            )
          </button>
          <button
            type="button"
            className="bg-[#EC1577] w-[14rem] text-white rounded-3xl p-2 text-sm"
            onClick={() => {
              const confirmed = window.confirm(
                'Do you confirm renewing your current plan with extra purchases ?'
              );

              if (confirmed) {
                handleRenewPlan(userPlan.id, 1);
              }
            }}
          >
            Renew Plan with extra purcheses ({extraPurchasesCalculation()})
          </button>
        </div>
      </div>
    </div>
  );

  // const handleCancelPlanModal = () => (
  //   <div className="z-40 mt-0 fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center transition delay-150 duration-300 ease-in-out">
  //     <div className="bg-white flex flex-col items-center justify-center p-5  absolute  w-2/6  space-y-4 pt-8">
  //       <div className="w-full flex justify-end">
  //         <button
  //           type="button"
  //           className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
  //           onClick={() => setCancelPlanModal(false)}
  //         >
  //           X
  //         </button>
  //       </div>
  //       <p className="text-3xl">We're sorry to see you go!</p>
  //       <p className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300">
  //         {userPlan.is_expired === true ? (
  //           <>
  //             You're about to cancel this plan, you can allways reactivate it
  //             later if you want it as your current plan!
  //           </>
  //         ) : (
  //           <>
  //             {userPlan.is_monthly === 1 ? 'Your monthly ' : 'Your annual '}
  //             subscription is paid until{' '}
  //             {userPlan.expiration_date.replace('.000Z', '').replace('T', ' ')}.
  //             If you would like to proceed with canceling your subscription,
  //             please select "Cancel Subscription" below.
  //           </>
  //         )}
  //       </p>
  //       <div className="flex justify-end gap-4">
  //         <button
  //           type="button"
  //           className=" border-2 border-[#EC1577] text-[#EC1577] w-[12rem] rounded-3xl p-2 text-sm"
  //           onClick={() => setCancelPlanModal(false)}
  //         >
  //           exit
  //         </button>
  //         <button
  //           type="button"
  //           className="bg-[#EC1577] w-[12rem] text-white rounded-3xl p-2 text-sm"
  //           onClick={() => {
  //             const confirmed = window.confirm(
  //               'Do you confirm canceling your current plan ?'
  //             );

  //             if (confirmed) {
  //               handleCancelPlan(userPlan.id);
  //             }
  //           }}
  //         >
  //           Cancel Subscription
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );

  function isExpirationWithinThreeDays() {
    if (!userPlan.is_expired) {
      const currentDate = new Date();
      const expirationDate = new Date(userPlan.expiration_date);

      const differenceInMilliseconds = expirationDate - currentDate;
      const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
      if (differenceInDays <= 4) {
        return true;
      }
      return false;
    }
    return null;
  }

  const numberOfSubscriptionDaysLeft = () => {
    const currentDate = new Date();
    const expirationDate = new Date(userPlan.expiration_date);

    const differenceInMilliseconds = expirationDate - currentDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
    const daysLeft = parseInt(differenceInDays);
    if (isExpirationWithinThreeDays()) {
      return (
        <div className="w-full flex justify-center items-center">
          <div
            className="p-4 w-[90%] mb-2 mt-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
            role="alert"
          >
            <span className="font-medium">Warning: </span> Your subscription
            expires soon . your plan expires in {daysLeft} day
            {daysLeft < 2 ? '' : 's'}
          </div>
        </div>
      );
    }
    return null;
  };

  if (!errors) {
    return (
      <div className="w-full flex flex-col justify-center items-center">
        {showAddShopModal === true && hundleAddExtraShopsModal()}
        {showAddEmployeeModal === true && hundleAddExtraEmployeeModal()}
        {showAddProductsModal === true && hundleAddExtraProductsModal()}
        {showUpgradeModal === true && handleUpgradeModal()}
        {/* {showCancelPlanModal === true && handleCancelPlanModal()} */}
        {showRenewModal === true && handleRenewPlanModal()}
        {showTimeModal === true && handleChangeSubscriptionTypeModal()}

        {userPlan.is_expired === true ? (
          <div className="w-full flex justify-center items-center">
            <div
              className="p-4 w-[90%] mb-2 mt-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert"
            >
              <span className="font-medium">Alert: </span> Your subscription has
              expired. to continue managing your shops{' '}
              <button
                type="button"
                className="underline decoration-2"
                onClick={() => setShowRenewModal(true)}
              >
                renew
              </button>{' '}
              your plan or{' '}
              <button
                type="button"
                className="underline decoration-2"
                onClick={() => setCancelPlanModal(true)}
              >
                cancel
              </button>{' '}
              your plan to purchase another.
            </div>
          </div>
        ) : null}

        {numberOfSubscriptionDaysLeft()}

        {userPlan.is_expired && userPlan.expira === false ? (
          <div className="w-full flex justify-center items-center">
            <div
              className="p-4 w-[90%] mb-2 mt-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert"
            >
              <span className="font-medium">Alert: </span> Your subscription has
              expired. to continue managing your shops{' '}
              <button
                type="button"
                className="underline decoration-2"
                onClick={() => setShowRenewModal(true)}
              >
                renew
              </button>{' '}
              your plan or{' '}
              <button
                type="button"
                className="underline decoration-2"
                onClick={() => setCancelPlanModal(true)}
              >
                cancel
              </button>{' '}
              your plan to purchase another.
            </div>
          </div>
        ) : null}

        <div className="w-[90%] flex justify-between space-x-5">
          <div className="flex justify-center w-1/4 mt-4">
            <div className="bg-white  self-center rounded-3xl p-8 space-y-5 w-full drop-shadow-2xl">
              {userPlan.is_monthly ? (
                <div className="flex justify-end">
                  <div className="desktop:p-2 p-1 bg-white rounded-3xl text-[#EC1577] desktop:text-sm  text-xs font-semibold w-3/6 text-center">
                    Monthly subscription
                  </div>
                </div>
              ) : (
                <div className="flex justify-end">
                  <div className="desktop:p-2 p-1 bg-white rounded-3xl text-[#EC1577] desktop:text-sm  text-xs font-semibold w-3/6 text-center">
                    Annual subscription
                  </div>
                </div>
              )}

              {plan.name !== 'Entreprise' ? (
                <h2
                  className="text-[#231D4F] desktop:pl-9 pl-3"
                  style={{
                    color: plan.is_best_plan ? '#181144' : '#181144',
                  }}
                >
                  <span className="font-bold text-[2.5rem]">
                    {getPlanPrice()}
                  </span>
                  <span className="font-bold text-sm">DT</span>
                  <span className="font-semibold text-l">/</span>
                  <span className="font-semibold text-lg">
                    {userPlan.is_monthly ? 'month' : 'year'}
                  </span>
                </h2>
              ) : (
                <h2
                  className="text-transparent ml-4 bg-transparent pointer-events-none "
                  style={{
                    color: plan.is_best_plan ? '#181144' : '#181144',
                  }}
                >
                  <span className="font-bold text-[2.5rem]">
                    {userPlan.is_monthly ? plan.price_month : plan.price_year}
                  </span>
                  <span className="font-bold text-sm">DT</span>
                  <span className="font-semibold text-l">/</span>
                  <span className="font-semibold text-lg">
                    {userPlan.is_monthly ? 'month' : 'year'}
                  </span>
                </h2>
              )}
              <div className="flex flex-col space-y-5 desktop:pl-5">
                <h2
                  className="desktop:text-[2.5rem] text-[2rem] font-bold text-[#231D4F]"
                  style={{
                    color: plan.is_best_plan ? '#181144' : '#181144',
                  }}
                >
                  {plan.name}
                </h2>
                <p
                  className="desktop:text-lg text-base font-medium text-[#231D4F] "
                  style={{
                    color: plan.is_best_plan ? '#181144' : '#181144',
                  }}
                >
                  {plan.description}
                </p>
                <ul className="space-y-2 h-[10rem]">
                  <li className="flex space-x-2 items-center">
                    {plan.name === 'Entreprise' ? (
                      <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    ) : (
                      <div className="w-6 h-6  relative">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    )}
                    <span
                      className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                      style={{
                        color: plan.is_best_plan ? '#181144' : '#181144',
                      }}
                    >
                      {plan.name === 'Entreprise'
                        ? 'Unlimited shops'
                        : `number of shops : ${plan.limit_shops}`}
                    </span>
                  </li>
                  <li className="flex space-x-2 items-center">
                    {plan.name === 'Entreprise' ? (
                      <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    ) : (
                      <div className="w-6 h-6  relative">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    )}
                    <span
                      className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                      style={{
                        color: plan.is_best_plan ? '#181144' : '#181144',
                      }}
                    >
                      {plan.name === 'Entreprise'
                        ? 'Unlimited users'
                        : `number of users : ${plan.limit_employees}`}
                    </span>{' '}
                  </li>
                  <li className="flex space-x-2 items-center">
                    {plan.name === 'Entreprise' ? (
                      <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    ) : (
                      <div className="w-6 h-6  relative">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    )}
                    <span
                      className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                      style={{
                        color: plan.is_best_plan ? '#181144' : '#181144',
                      }}
                    >
                      {plan.name === 'Entreprise'
                        ? 'Unlimited products'
                        : `number of products : ${plan.limit_products}`}
                    </span>
                  </li>
                  <li className="flex space-x-2 items-center">
                    {plan.name === 'Entreprise' ? (
                      <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    ) : (
                      <div className="w-6 h-6  relative">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    )}
                    <span
                      className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                      style={{
                        color: plan.is_best_plan ? '#181144' : '#181144',
                      }}
                    >
                      {plan.name === 'Entreprise'
                        ? 'Unlimited support hours'
                        : `technical support : ${plan.limit_support} h`}
                    </span>
                  </li>
                  <li className="flex space-x-2 items-center">
                    {plan.name === 'Entreprise' ? (
                      <div className="w-6 h-6 bg-[#ffd3e8] rounded-full relative">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="h-6 w-6  text-[#589B23] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    ) : (
                      <div className="w-6 h-6  relative">
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="h-6 w-6  text-[#EC1577] absolute left-[2.5%] pointer-events-none transition scale-75"
                        />
                      </div>
                    )}

                    <span
                      className="desktop:text-sm text-xs font-medium text-[#231D4F]"
                      style={{
                        color: plan.is_best_plan ? '#181144' : '#181144',
                      }}
                    >
                      {' '}
                      {plan.name === 'Entreprise'
                        ? 'Unlimited storage'
                        : `storage : ${plan.storage} GB`}
                    </span>
                  </li>
                </ul>
                <div className="p-2 h-14" />
                {/* <div className="flex justify-center items-center">
                  {plan.name === 'Entreprise' ? (
                    <button
                      type="button"
                      className="bg-[#231D4F] p-4 text-white self-center w-full rounded-3xl hover:scale-110 transition"
                      style={{
                        backgroundColor: plan.is_best_plan
                          ? '#EC1577'
                          : '#231D4F',
                      }}
                    >
                      Contact us
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="bg-[#231D4F] p-4 text-white self-center w-full rounded-3xl hover:scale-110 transition"
                      style={{
                        backgroundColor: plan.is_best_plan
                          ? '#EC1577'
                          : '#231D4F',
                      }}
                      // onClick={() => hundleNextStep(plan.id)}
                    >
                      Change plan
                    </button>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-3/4 mt-4 items-start justify-center">
            <div className="topo w-full flex justify-between gap-4 mb-2">
              <button
                type="button"
                className="bg-[#EC1577] w-[14rem] text-white rounded-3xl p-2 text-sm disabled:bg-[#AAA6C3]"
                onClick={() => setShowUpgradeModal(true)}
                disabled={userPlan.is_expired}
              >
                Upgrade your plan
              </button>
              <button
                type="button"
                className="bg-[#EC1577] w-[14rem] text-white rounded-3xl p-2 text-sm disabled:bg-[#AAA6C3]"
                onClick={() => setShowRenewModal(true)}
                disabled={!userPlan.is_expired}
              >
                Renew your plan
              </button>
              {/* <button
                type="button"
                className="bg-[#EC1577] w-[14rem] text-white rounded-3xl p-2 text-sm disabled:bg-[#AAA6C3]"
                onClick={() => setCancelPlanModal(true)}
              >
                Cancel Plan
              </button> */}
              <button
                type="button"
                className="bg-[#EC1577] w-[14rem] text-white rounded-3xl p-2 text-sm disabled:bg-[#AAA6C3]"
                onClick={() => setShowTimeModal(true)}
                disabled={userPlan.is_expired}
              >
                {userPlan.is_monthly
                  ? 'Change to annual subscription'
                  : 'Upgrade to monthly subscription'}
              </button>
            </div>
            <div className="bg-white shadow-2xl rounded-3xl w-full p-4 ">
              <p className="text-3xl font-semibold  text-[#EC1577] mb-2">
                Current plan data{' '}
              </p>

              <div className=" p-2 ">
                <div className="flex w-full justify-between mb-2">
                  <div className="flex flex-col space-y-3">
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p>purchase date : </p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan.purchase_date
                          ?.replace('.000Z', '')
                          .replace('T', ' ')}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      {' '}
                      <p>expiration date : </p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan.expiration_date
                          ?.replace('.000Z', '')
                          .replace('T', ' ')}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      {' '}
                      <p>payment method :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan.payment_method}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      {' '}
                      <p>total price httc :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan.total_price_httc}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      {' '}
                      <p>total price ttc :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan.total_price_ttc}
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-col space-y-3">
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      <p> extra employees :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan.extra_employees}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      {' '}
                      <p> extra shops :</p>
                      <span className="text-sm text-[#FEC90F] ">
                        {userPlan.extra_shops}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      {' '}
                      <p>extra products :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan.extra_products}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      {' '}
                      <p>extra support time :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan.extra_support_time}
                      </span>
                    </p>
                    <p className="text-sm font-medium text-[#181144] flex justify-start items-center space-x-4">
                      {' '}
                      <p> extra storage :</p>
                      <span className="text-sm text-[#FEC90F]">
                        {userPlan.extra_storages}
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-around">
                    <button
                      type="button"
                      className="bg-[#231D4F] rounded-3xl p-2 flex items-center space-x-2 w-52   disabled:bg-[#AAA6C3]"
                      onClick={() => setShowAddShopModal(true)}
                      disabled={userPlan.is_expired}
                    >
                      <div className="rounded-full bg-white w-6 h-6 text-center flex items-center justify-center">
                        <span> +</span>
                      </div>
                      <p className="text-white text-xs">
                        {userPlan.Shops.length >
                          plan.limit_shops + userPlan.extra_shops ||
                        userPlan.Shops.length ===
                          plan.limit_shops + userPlan.extra_shops
                          ? 'Add extra shop'
                          : 'add shop'}{' '}
                      </p>
                    </button>
                    <button
                      type="button"
                      className="bg-[#231D4F] rounded-3xl p-2 flex items-center space-x-2  w-52  disabled:bg-[#AAA6C3]"
                      onClick={() => setShowAddEmployeeModal(true)}
                      disabled={userPlan.is_expired}
                    >
                      <div className="rounded-full bg-white w-6 h-6 text-center flex items-center justify-center">
                        <span> +</span>
                      </div>
                      <p className="text-white text-xs">
                        {' '}
                        {employees.length >
                          plan.limit_employees + userPlan.extra_employees ||
                        employees.length ===
                          plan.limit_employees + userPlan.extra_employees
                          ? 'Add extra employee'
                          : 'add employee'}{' '}
                      </p>
                    </button>
                    <button
                      type="button"
                      className="bg-[#231D4F] rounded-3xl p-2 flex items-center space-x-2  w-52  disabled:bg-[#AAA6C3]"
                      onClick={() => setShowAddProductsModal(true)}
                      disabled={userPlan.is_expired}
                    >
                      <div className="rounded-full bg-white w-6 h-6 text-center flex items-center justify-center">
                        <span> +</span>
                      </div>
                      <p className="text-white text-xs">
                        Add extra products space
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between space-x-5 bg-white shadow-2xl rounded-3xl p-4 mt-5 h-full w-full">
              <div className="w-3/6 ">
                <h2 className="text-xl font-semibold text-[#EC1577] mb-2">
                  Shops ({userPlan.Shops.length}
                  <span className="text-lg">/</span>
                  {userPlan.extra_shops + plan.limit_shops})
                </h2>

                <div className="flex justify-between gap-2 border-b-1 border-[#EF325E] mb-1 pb-1">
                  <div className="w-1/4 text-start text-sm font-semibold">
                    Name
                  </div>
                  <div className="w-1/4 text-center text-sm font-semibold">
                    Type
                  </div>
                  <div className="w-2/4  text-center text-sm font-semibold">
                    Url
                  </div>
                </div>
                <div className="max-h-[230px] overflow-scroll">
                  {userPlan.Shops.map((shop, i) => (
                    <div key={i} className="flex justify-between">
                      <div className="w-1/4 flex justify-start items-center text-start text-xs h-10">
                        <span>{shop.name}</span>
                      </div>
                      <div className="w-1/4 text-center text-xs flex justify-center items-center  h-10">
                        <span className="">
                          {shop.type === 'prestashop' && (
                            <img
                              src={prestashopLogo}
                              alt="prestashop"
                              className="w-9 h-9"
                            />
                          )}
                          {shop.type === 'woocommerce' && (
                            <img
                              src={woocommerceLogo}
                              alt="woocommerce"
                              className="w-9 h-9"
                            />
                          )}
                        </span>
                      </div>
                      <div className="w-2/4 text-center text-xs flex justify-center items-center h-10">
                        <span className="hover:text-[#FEC90F]">
                          {' '}
                          <a
                            href={shop.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {shop.url}
                          </a>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-3/6">
                <h2 className="text-xl font-semibold text-[#EC1577] mb-2">
                  Employees ({employees.length}
                  <span className="text-lg">/</span>
                  {userPlan.extra_employees + plan.limit_employees})
                </h2>

                <div className="flex justify-between gap-2 border-b-1 border-[#EF325E] mb-1 pb-1">
                  <div className="w-5/12 text-start text-sm font-semibold">
                    First & last name
                  </div>
                  <div className="w-3/12 text-center text-sm font-semibold">
                    Phone
                  </div>
                  <div className="w-4/12  text-center text-sm font-semibold">
                    E-mail
                  </div>
                </div>
                <div className="max-h-[230px] overflow-scroll">
                  {employees.map((employee, i) => (
                    <div key={i} className="flex justify-between">
                      <div className="w-5/12 flex justify-start items-center text-start text-xs h-10 space-x-2">
                        <span>{employee.first_name}</span>{' '}
                        <span>{employee.last_name}</span>
                      </div>
                      <div className="w-3/12 text-center text-xs flex justify-start items-center  h-10">
                        <span className="w-full">{employee.phone}</span>
                      </div>
                      <div className="w-4/12 text-center text-xs flex justify-start items-center h-10">
                        <span className="w-full">{employee.email}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mt-[30vh] flex justify-center">
      <div className="w-[90%] flex justify-center">
        <div className="flex flex-col justify-between items-center">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="h-16 w-16  text-[#EC1577]  pointer-events-none transition scale-75"
          />{' '}
          <span className="text-[#EC1577] text-3xl font-semibold ">
            No plan is active!
          </span>
        </div>
      </div>
    </div>
  );
}

export default CurrentPlan;
