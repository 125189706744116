import React from 'react';
import {
  MdOutlineSupervisorAccount,
  MdOutlineAttachMoney,
} from 'react-icons/md';
import { CgMathDivide } from 'react-icons/cg';
import { ImSortNumbericDesc } from 'react-icons/im';
import { FaProductHunt } from 'react-icons/fa';
import { BsThreeDotsVertical } from 'react-icons/bs';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';

const color = ['#181143', '#FD6700', '#EC1577', '#87839D', '#12B400'];
const Loader = () => (
  <Spinner className="absolute left-0 right-0 top-0 bottom-0 m-auto w-12 h-12s" />
);

const ShopKpis = ({ kpis, shops }) => (
  <div className="flex gap-4 flex-col w-full px-8">
    {shops.map((shop, i) => (
      <div key={i}>
        <h3 style={{ color: color[i] }} className="font-semibold text-2xl">
          {shop.name}
        </h3>
        <div className="flex justify-between items-center">
          <div className="w-1/5 pr-5 border-r-1 border-gray-700 relative h-36">
            {!kpis[i] ? (
              <Loader />
            ) : (
              <>
                <div className="flex justify-between">
                  <MdOutlineSupervisorAccount
                    style={{ backgroundColor: color[i] }}
                    className="text-white opacity-50 rounded-full w-12 h-12 p-2 mb-2"
                  />
                  <BsThreeDotsVertical className="text-shifti-blue w-8 h-auto" />
                </div>
                <h3 className="font-semibold text-shifti-blue mb-1">
                  {kpis[i].average_sales_price.toFixed(2)}
                </h3>
                <h5 className="text-shifti-blue opacity-50 font-semibold">
                  avg. order value
                </h5>
              </>
            )}
          </div>
          <div className="w-1/5 pr-5 pl-5 border-r-1 border-gray-700 relative h-36">
            {!kpis[i] ? (
              <Loader />
            ) : (
              <>
                <div className="flex justify-between">
                  <ImSortNumbericDesc
                    style={{ backgroundColor: color[i] }}
                    className="text-white opacity-50 rounded-full w-12 h-12 p-2 mb-2"
                  />
                  <BsThreeDotsVertical className="text-shifti-blue w-8 h-auto" />
                </div>
                <h3 className="font-semibold text-shifti-blue mb-1">
                  {kpis[i].total_orders}
                </h3>
                <h5 className="text-shifti-blue opacity-50 font-semibold">
                  Orders number
                </h5>
              </>
            )}
          </div>
          <div className="w-1/5 pr-5 pl-5 border-r-1 border-gray-700 relative h-36">
            {!kpis[i] ? (
              <Loader />
            ) : (
              <>
                <div className="flex justify-between">
                  <CgMathDivide
                    style={{ backgroundColor: color[i] }}
                    className="text-white opacity-50 rounded-full w-12 h-12 p-2 mb-2"
                  />
                  <BsThreeDotsVertical className="text-shifti-blue w-8 h-auto" />
                </div>
                <h3 className="font-semibold text-shifti-blue mb-1">
                  {kpis[i].orders_per_product.toFixed(2)}
                </h3>
                <h5 className="text-shifti-blue opacity-50 font-semibold">
                  avg. number of products per order
                </h5>
              </>
            )}
          </div>
          <div className="w-1/5 pr-5 pl-5 border-r-1 border-gray-700 relative h-36">
            {!kpis[i] ? (
              <Loader />
            ) : (
              <>
                <div className="flex justify-between">
                  <FaProductHunt
                    style={{ backgroundColor: color[i] }}
                    className="text-white opacity-50 rounded-full w-12 h-12 p-2 mb-2"
                  />
                  <BsThreeDotsVertical className="text-shifti-blue w-8 h-auto" />
                </div>
                <h3 className="font-semibold text-shifti-blue mb-1">
                  {kpis[i].number_of_unique_products}
                </h3>
                <h5 className="text-shifti-blue opacity-50 font-semibold">
                  Number of unique products
                </h5>
              </>
            )}
          </div>
          <div className="w-1/5 pl-5 text-center relative h-36">
            {!kpis[i] ? (
              <Loader />
            ) : (
              <>
                <div className="flex justify-between items-center gap-10">
                  <h5 className="text-shifti-blue opacity-50 font-semibold">
                    Total sales
                  </h5>
                  <MdOutlineAttachMoney
                    style={{ backgroundColor: color[i] }}
                    className="text-white rounded-full w-12 h-12 p-2 mb-2"
                  />
                </div>
                <h3 className="font-semibold text-shifti-blue mb-1">
                  {kpis[i].total_sales_revenue.toFixed(2)}
                </h3>
                <span className="bg-shifti-main-bg text-white font-semibold p-2 rounded-full inline-block">
                  Download
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
);
ShopKpis.propTypes = {
  kpis: PropTypes.object.isRequired,
  shops: PropTypes.array.isRequired,
};
export default ShopKpis;
