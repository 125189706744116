import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import ShopNotImported from '../../components/ShopNotImported';
import { Header } from '../../components';
import Loader from '../../components/Loader';
import { selectCurrentToken } from '../../features/auth/authSlice';

export default function CreateManufacturer() {
  const token = useSelector(selectCurrentToken);
  const [shops, setShops] = useState([]);
  const [shopType, setShopType] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedShop, setSelectedShop] = useState('');
  const [isShopImported, setIsShopImported] = useState();
  const [formData, setFormData] = useState({
    name: '',
  });
  const [formDataError, setformDataError] = useState([true]);

  const apiURL = process.env.REACT_APP_API_URL;

  const getShops = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-all-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    return api
      .request(config)
      .then((response) => response.data)
      .catch((error) => JSON.stringify(error));
  };

  useEffect(() => {
    getShops().then((response) => {
      setShops(response);
      if (shopType === '') {
        setShopType(response[0].type);
        setSelectedShop(response[0].id);
        setIsShopImported(response[0].is_imported);
        setLoading(false);
      }
    });
  }, [selectedShop]);

  const handelFormDataChange = (value, key, index) => {
    const aux = { ...formData };
    aux[key] = value;
    setFormData(aux);
  };

  const handelSubmit = async () => {};

  const handleShop = (e) => {
    setSelectedShop(e.target.value);
    setShopType(e.target.selectedOptions[0].getAttribute('type'));
    e.target.selectedOptions[0].getAttribute('isImported') === 'true'
      ? setIsShopImported(true)
      : setIsShopImported(false);
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="w-[95%] sticky top-[5.5rem] md:ml-[3%] ml-[3.5%] z-40">
        <div className="filter-by-shop mb-5 relative md:w-full w-[22rem]">
          <select className="shopSelect text-center" onChange={handleShop}>
            {shops.map((shop) => (
              <option
                type={shop.type}
                value={shop.id}
                className="text-[#EC1577] text-center"
                key={shop.id}
              >
                {shop.name}
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            icon={faShop}
            className="h-[1.5rem] w-[1.5rem] scale-100 md:left-[42%] md:top-[19%] top-[19%] left-[30%]  absolute  text-[#AAA6C3] text-opacity-70 checked-1 transition "
          />
        </div>
      </div>
      {isShopImported === true ? (
        <div className="mr-3 p-2 md:p-5 ml-3 rounded-3xl mb-3 md:mt-8 mt-20 flex justify-center">
          <div className="flex justify-center flex-col">
            <Header category="Page" title="Create Manufacturer" />
            <form
              action=""
              className="sft-form formStyle md:m-10 mt-2 md:mt-0 p-2 md:p-10 mr-4 bg-white rounded-3xl md:w-[1000px]"
            >
              <div className="sft-input-container grid grid-cols-2 gap-y-8 mt-6 mr-10 md:mr-0 md:w-[400px] lg:w-780">
                <label htmlFor="name" className="formLabel mt-2">
                  Name :
                </label>
                <div>
                  <input
                    id="name"
                    onChange={(e) =>
                      handelFormDataChange(e.target.value, 'name', 0)
                    }
                    type="text"
                    className="formInput"
                  />
                  <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
                </div>
                <div /> {/* empty div for button position */}
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="formButton"
                    onClick={handelSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <ShopNotImported />
      )}
    </div>
  );
}
