import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Creatable from 'react-select/creatable';
import PropTypes from 'prop-types';
import DropDown from '../../components/DropDown';

const ProductSeoReferencing = ({
  handelFormDataChange,
  selectedShop,
  selectedShops,
  formData,
  setFormData,
  shops,
}) => {
  const [currentShopId, setCurrentShopId] = useState(
    selectedShop || selectedShops[0].id
  );
  const [langsByShop, setLangsByShop] = useState(
    shops.find((shop) => shop.id === currentShopId).Langs
  );
  const [url, setUrl] = useState(
    shops.find((shop) => shop.id === currentShopId).url
  );
  const [currentLang, setCurrentLang] = useState(langsByShop[0].iso_code);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '1.5rem',
      height: '2vw',
      outline: 'none',
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };
  const handleLangs = (shopId) => {
    setCurrentShopId(shopId);
    const temp = shops.find((shop) => shop.id === shopId).Langs;
    setLangsByShop(temp);
    setCurrentLang(temp[0].iso_code);
  };
  const handleShopToggle = (id) => {
    setCurrentShopId(id);
    handleLangs(id);
    setUrl(shops.find((shop) => shop.id === id).url);
  };
  const handelMetaData = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    const temp = { ...formData };
    if (name === 'metaTitle' || name === 'metaDescription') {
      temp.sites[currentShopId].languages[currentLang][name] = value;
      setFormData(temp);
    }
  };

  useEffect(() => {
    if (selectedShop && currentShopId !== selectedShop) {
      setCurrentShopId(selectedShop);
      handleLangs(selectedShop);
    }
  });

  return (
    <div
      className="mt-12 rounded-3xl flex flex-col sm:flex-row"
      style={{
        paddingTop: '0',
        marginTop: '0',
      }}
    >
      <div className="sm:w-[250%]">
        <div>
          <span
            htmlFor="Declinations"
            className="text-[#181143] text-[10px] font-black sm:text-lg font-medium mt-2 mb-2 block"
          >
            Referencing
          </span>
          <DropDown
            className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl shadow-xl pt-2 px-2 w-14 float-right -mt-6"
            id="name"
            currentMode="white"
            data={langsByShop}
            defaultValue={currentLang}
            value="iso_code"
            text="iso_code"
            onChange={(e) => setCurrentLang(e)}
          />
          {selectedShop ? null : (
            <div className="bg-white h-12 rounded-t-xl divide-x -mb-4 inline-block px-4 pt-1 font-medium text-shifti-blue">
              {selectedShops.map((shop) => (
                <Link
                  className={`px-4 inline-block cursor-pointer divide-x ${
                    currentShopId === shop.id
                      ? 'text-shifti-pink font-semibold'
                      : ''
                  }`}
                  onClick={() => handleShopToggle(shop.id)}
                >
                  {shop.name}
                </Link>
              ))}
            </div>
          )}
          <div className="flex flex-col gap-8 md:p-5 bg-white rounded-3xl px-1 py-2 w-full">
            <div>
              <p className="text-gray-400 block">
                Improve your positioning and how your product page appears in
                search engine results
              </p>
              <p className="text-shifti-blue font-medium mt-6">
                Here is a preview of your search result, it's up to you!
              </p>
              <div
                className="rounded-2xl w-full p-7"
                style={{
                  boxShadow:
                    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                }}
              >
                <p className="text-[#1A0DAB] text-lg cursor-pointer">
                  {formData.sites[currentShopId] &&
                  formData.sites[currentShopId].languages[currentLang]
                    .metaTitle === ''
                    ? formData.languages[currentLang].name
                    : formData.sites[currentShopId].languages[currentLang]
                        .metaTitle}
                </p>
                <p className="text-[#006621] text-sm">{`${url}${
                  langsByShop.length > 1 ? `${currentLang}/` : ''
                }example-product-url.html`}</p>
                <p className="text-[#545454] text-sm">
                  {formData.sites[currentShopId] &&
                  formData.sites[currentShopId].languages[currentLang]
                    .metaDescription
                    ? formData.sites[currentShopId].languages[currentLang]
                        .metaDescription
                    : ''}
                </p>
              </div>
            </div>
            <div className="flex gap-4 justify-between">
              <div className="w-1/2">
                <label
                  htmlFor="metaKeywords"
                  className="text-center text-[#181143] sm:text-2xl"
                >
                  Title tag
                </label>
                <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11 border border-[#534B85]">
                  <Creatable
                    isMulti
                    value={formData.metaKeywords}
                    placeholder="Enter keywords..."
                    id="metaKeywords"
                    className="w-full hover:outline-0  focus:outline-transparent focus:shadow-transparent"
                    onCreateOption={(e) =>
                      handelFormDataChange(e, 'metaKeywords', 0)
                    }
                    name="keywords"
                    styles={customStyles}
                    isSearchable={false}
                  />
                </div>
                <p className="text-gray-400	mt-4 float-right">
                  {
                    'To add tags, click in the field, write something, and then press the "Enter" key. Invalid characters: <>;=#{}'
                  }
                </p>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="metaTitle"
                  className="text-center text-[#181143] sm:text-2xl"
                >
                  Meta title
                </label>
                <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between pr-2 h-11 border border-[#534B85]">
                  <input
                    name="metaTitle"
                    id="metaTitle"
                    type="text"
                    className="priceInput"
                    onChange={(e) => handelMetaData(e)}
                    value={
                      formData.sites[currentShopId].languages[currentLang]
                        .metaTitle
                    }
                  />
                </div>
                <p className="text-gray-400	mt-4 float-right">
                  0 of 160 characters used (recommended)
                </p>
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="metaDescription"
                className="text-center text-[#181143] sm:text-2xl"
              >
                Meta description
              </label>
              <div className="currency-input-container bg-white text-[#AAA6C3] text-[1.2rem] rounded-2xl md:w-full flex flex-row items-center justify-between p-1 border border-[#534B85]">
                <textarea
                  name="metaDescription"
                  id="metaDescription"
                  type="text"
                  cols="30"
                  rows="4"
                  className="rounded-[1vw] w-full p-[1vw] text-[1vw] hover:outline-0 focus:outline-transparent focus:shadow-transparent bg-transparent"
                  onChange={(e) => handelMetaData(e)}
                  value={
                    formData.sites[currentShopId].languages[currentLang]
                      .metaDescription
                  }
                />
              </div>
              <p className="text-gray-400	mt-4 float-right">
                0 of 160 characters used (recommended)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductSeoReferencing.propTypes = {
  handelFormDataChange: PropTypes.func.isRequired,
  selectedShop: PropTypes.number.isRequired,
  selectedShops: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  shops: PropTypes.object.isRequired,
};

export default ProductSeoReferencing;
