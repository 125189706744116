import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { Toggle } from '../../components';
import AssociatedStores from '../../components/AssociatedStores';
import { selectCurrentToken } from '../../features/auth/authSlice';

const apiURL = process.env.REACT_APP_API_URL;

const TaxRuleGroup = () => {
  const token = useSelector(selectCurrentToken);
  const [stores, setStores] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    active: false,
    shops: [],
    validation: {
      error: [true, false, true],
      errorMsg: ['required', '', 'required'],
    },
  });
  const [formDataError, setformDataError] = useState([true, false, true]);

  const getStores = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/shop/get-group-shops`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        setStores(response.data);
      })
      .catch((err) => {});
  };

  const handelFormDataChange = (value, key, index) => {
    const aux = { ...formData };
    if (key === 'name') {
      if (value.trim() === '') {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      aux[key] = value;
    }
    if (key === 'active') {
      setToggle((prevCheck) => !prevCheck);
      if (value) aux[key] = '1';
      else aux[key] = '0';
    }
    if (key === 'shops') {
      if (value.checked === false && aux.shops.length < 2) {
        aux.validation.error[index] = true;
        aux.validation.errorMsg[index] = 'Required';
      } else {
        aux.validation.error[index] = false;
        aux.validation.errorMsg[index] = '';
      }
      const itemIndex = aux[key].indexOf(value.value);
      if (value.checked === true && itemIndex === -1) {
        aux[key].push(value.value);
      } else if (itemIndex > -1) {
        aux[key].splice(itemIndex, 1);
      }
    }
    setFormData(aux);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    const decoded = jwtDecode(token);
    const ERROR = [...formData.validation.error];
    const ERROR_MSG = [...formData.validation.errorMsg];
    setformDataError(ERROR_MSG);
    if (ERROR.includes(true)) return;
    const idToast = toast.loading('Please wait...');
    const data = JSON.stringify({
      name: formData.name,
      active: formData.active,
      shops: formData.shops,
    });
    const config = {
      method: 'post',
      url: `${apiURL}/taxes/add-tax-rule-group`,
      headers: {
        'Content-Type': 'application/json',
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    api
      .request(config)
      .then((response) => {
        toast.update(idToast, {
          render: 'Feature created succesfully !',
          type: 'success',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        });
      })
      .catch(() =>
        toast.update(idToast, {
          render: 'Failed to create feature !',
          type: 'error',
          hideProgressBar: false,
          autoClose: 1500,
          closeOnClick: true,
          isLoading: false,
        })
      );
  };

  useEffect(() => {
    getStores();
  }, []);

  return (
    <div className="flex flex-col gap-8 mt-8">
      <div className="flex justify-between">
        <div className="w-3/4">
          <label htmlFor="Name" className="groupAttributeLabel">
            Name
          </label>
          <input
            id="Name"
            name="name"
            placeholder="Name"
            type="text"
            className="groupAttributeInput w-full mt-3"
            onChange={(e) => handelFormDataChange(e.target.value, 'name', 0)}
          />
          <div style={{ color: '#FF9494' }}>{formDataError[0]}</div>
        </div>
      </div>
      <div className="w-3/4">
        <div>
          <label htmlFor="test" className="groupAttributeLabel">
            Activate
          </label>
          <Toggle
            className="mt-3"
            toggle={toggle}
            text={false}
            onClick={() => handelFormDataChange(!toggle, 'active', 1)}
          />
        </div>
      </div>
      <div className="w-3/4">
        <label htmlFor="associatedStores" className="groupAttributeLabel my-4">
          Associated Stores
        </label>
        <AssociatedStores
          name="shops"
          className="w-full mt-3"
          stores={stores}
          // disallowed="woocommerce"
          onChange={(e) => handelFormDataChange(e.target, 'shops', 2)}
        />
        <div style={{ color: '#FF9494' }}>{formDataError[2]}</div>
      </div>
      <div
        className="help-button mt-3"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Link
          style={{
            background: '#EC1577',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '25px',
            padding: '7.5px 40px 0px',
            fontWeight: 'normal',
            fontSize: '1,04vw',
            height: '37px',
          }}
          onClick={(e) => handelSubmit(e)}
        >
          Save
        </Link>
      </div>
    </div>
  );
};

export default TaxRuleGroup;
