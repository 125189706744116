/* eslint-disable quotes */
import React, { useState, useEffect } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
} from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import api from '../../utils/apiService';
import { selectCurrentToken } from '../../features/auth/authSlice';

import {
  customersGrid,
  contextMenuItems,
} from '../../data/customerTableDefinition';
import { Header } from '../../components';

const apiURL = process.env.REACT_APP_API_URL;

const Customers = () => {
  const token = useSelector(selectCurrentToken);
  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true, allowEditing: true };

  const [items, setItems] = useState([]);

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = async () => {
    const decoded = jwtDecode(token);
    const config = {
      method: 'get',
      url: `${apiURL}/customers`,
      headers: {
        'App-Tenant': decoded.UserInfo.tenantId,
        Authorization: `Bearer ${token}`,
      },
    };
    await api
      .request(config)
      .then((response) => {
        console.log(response.data, 'response.data');
        setItems(response.data);
      })
      .catch((error) => JSON.stringify(error));
  };

  return (
    <div className="md:mt-2 mt-24 md:ml-5 p-2 md:p-5 rounded-3xl">
      <Header category="Page" title="Customers" />
      <div
        className="add-button"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Link
          className="p-2"
          style={{
            background: '#EC1577',
            color: 'white',
            cursor: 'pointer',
            borderRadius: '25px',
            padding: '7.1px 30px 0px',
            fontWeight: 'normal',
            fontSize: '1,04vw',
            height: '37px',
          }}
          to="/create-customer"
        >
          <Link
            className="p-2"
            style={{
              background: '#ffffff',
              color: '#181143',
              cursor: 'pointer',
              borderRadius: '50%',
              padding: '4px 10px',
              marginRight: '32%',
              marginLeft: '-35%',
              fontSize: '1,20vw',
            }}
            to="/create-customer"
          >
            +
          </Link>
          Add
        </Link>
      </div>
      <div className="m-2 mt-10 p-2 md:p-10 bg-white rounded-3xl">
        <GridComponent
          dataSource={items}
          enableHover={false}
          allowPaging
          pageSettings={{ pageCount: 5, pageSize: 10 }}
          selectionSettings={selectionsettings}
          editSettings={editing}
          contextMenuItems={contextMenuItems}
          allowSorting
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {customersGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default Customers;
