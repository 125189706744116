import axios from 'axios';
import { toast } from 'react-toastify';

const apiURL = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: apiURL,
});

const planErrors = [
  'Your active plan has expired',
  'No active plan is found',
  'Plan is not paid',
];

const sendErrorMail = (error, info) => {
  const formdata = JSON.stringify({
    error,
    info,
  });
  const config = {
    method: 'post',
    url: `${apiURL}/mail/send-error-mail`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: formdata,
  };
  api
    .request(config)
    .then(() => {
      toast.error(
        'An error occured. Our dev team notified for immediate resolution',
        {
          toastId: 'error',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        }
      );
      return false;
    })
    .catch(() => {
      toast.error('An error occured.', {
        toastId: 'error',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    });
};

// Add a response interceptor to catch 401 and 403 errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error, 'Error logged by developer !');
    const errorMessage =
      error?.response?.data?.message || 'Something went wrong.';
    if (error?.response?.status === 401) {
      // Handle the 401 error here (e.g., redirect to login)
      localStorage.removeItem('accessToken');
      window.location.href = '/login';
    }
    if (error?.response?.status === 403) {
      if (planErrors.includes(errorMessage)) {
        toast.warn(errorMessage, {
          toastId: 'planWarning',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } else if (error?.response?.status > 499 && error?.response?.status < 600) {
      sendErrorMail(error, errorMessage);
    } else {
      toast.warn(errorMessage, {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    return Promise.reject(error);
  }
);

export default api;
