import React from 'react';
import PropTypes from 'prop-types';

const Select = ({
  icon,
  onChange,
  selected,
  bgColor,
  color,
  bgHoverColor,
  size,
  borderRadius,
  width,
  items,
  className = '',
}) => (
  <select
    onChange={onChange}
    style={{ backgroundColor: bgColor, color, borderRadius }}
    className={`formselect ${className}`}
    defaultValue={selected}
  >
    {items.map((item) => (
      <option
        key={item.id}
        value={`${item.id}${item.type ? `-${item.type}` : ''}`}
      >
        {item.name}
      </option>
    ))}
    {icon}
  </select>
);
Select.propTypes = {
  icon: PropTypes.element.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  bgHoverColor: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};
export default Select;
