import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import searchlogo from './search-file.png';

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const Actions = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-center h-10  border-2 border-[#EC1577] text-[#EC1577] rounded-xl w-14 text-center">
        <button
          className=""
          type="button"
          onClick={() => {
            // eslint-disable-next-line react/destructuring-assignment, react/prop-types
            navigate(`/order/${props.id}/${props.shop_id}`);
          }}
        >
          View Order
        </button>
      </div>
    </div>
  );
};

export const customer = (props) =>
  props.Customer ? (
    <div className="">
      {props.Customer.first_name} {props.Customer.last_name}
    </div>
  ) : (
    <div>Nan</div>
  );

export const Reference = ({ reference }) => (
  <center>
    <div>{reference}</div>
  </center>
);

Reference.propTypes = {
  reference: PropTypes.string.isRequired,
};

const delivery = (props) => {
  if (props.delivery_address && props.delivery_address.Country) {
    return <div>{props.delivery_address.Country.CountryLangs[0].name}</div>;
  }
  return <div>NaN</div>;
};

const shop = ({ Shop }) => <div>{Shop.name}</div>;

const status = (props) => {
  if (props.OrderHistories[0]) {
    const { color } = props.OrderHistories[0].OrderState;
    const divStyle = {
      backgroundColor: color,
    };
    return (
      <div style={divStyle} className="p-1 rounded-xl text-white">
        {props.OrderHistories[0].OrderState.name}
      </div>
    );
  }

  return <div className="p-1 rounded-x">{props.woo_status}</div>;
};

export const ordersGrid = [
  { field: 'foreign_id', headerText: 'Id', width: '80' },

  {
    template: Reference,
    headerText: 'Reference',
    font_size: '20',
    width: '100',
    textAlign: 'Start',
  },
  {
    headerText: 'Carrier',
    template: delivery,
    width: '80',
    textAlign: 'Start',
  },
  {
    headerText: 'Customer',
    template: customer,
    width: '100',
    textAlign: 'Start',
  },
  {
    field: 'total_paid',
    headerText: 'Total',
    width: '100',
    textAlign: 'Start',
  },
  {
    field: 'payment',
    headerText: 'Payment',
    width: '100',
    textAlign: 'Start',
    wrap: 'true',
  },
  {
    headerText: 'Status',
    template: status,
    width: '130',
    textAlign: 'Center',
  },
  {
    field: 'delivery_date',
    headerText: 'Date',
    width: '130',
    textAlign: 'Center',
  },
  {
    template: shop,
    headerText: 'Shop',
    width: '130',
    textAlign: 'Center',
  },
  {
    headerText: 'Actions',
    template: Actions,
    width: '90',
    textAlign: 'Center',
    allowSelection: false,
  },
];
