/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPencil,
  faEllipsisVertical,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const Actions = ({ id }) => {
  const [toggle, setToggle] = useState(false);
  const handleActionClick = () => {
    setToggle((prevCheck) => !prevCheck);
  };

  return (
    <div>
      <button
        type="button"
        style={{ color: '#000' }}
        className="text-[#AAA6C3] py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => {
          window.location.href = `/edit-group-attribute/?id=${id}`;
        }}
      >
        <FontAwesomeIcon icon={faPencil} className="w-6 h-6 text-[#AAA6C3]" />
      </button>
      <button
        type="button"
        style={{ color: '#000' }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
        onClick={() => handleActionClick(id)}
      >
        <FontAwesomeIcon
          icon={faEllipsisVertical}
          className="w-6 h-6 text-[#AAA6C3]"
        />
      </button>
      {toggle ? (
        <div className="bg-white -mb-2 flex flex-col absolute w-4 text-left">
          <button className="text-black w-4" type="button">
            <FontAwesomeIcon icon={faEdit} className="w-6 h-6 text-[#AAA6C3]" />
            <span className="text-black">Edit</span>
          </button>
          <button type="button" className="text-black w-4">
            <FontAwesomeIcon
              icon={faTrash}
              className="w-6 h-6 text-[#AAA6C3]"
            />
            <span className="text-black">Delete</span>
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
Actions.propTypes = {
  id: PropTypes.number.isRequired,
};
export const Toggle = ({ active }) => {
  const toggleClass = ' transform translate-x-6';
  return (
    <div className="flex justify-center">
      <div
        className={`md:w-14 md:h-7 w-12 h-6 flex items-center ${
          active ? 'bg-shifti-pink-bg' : 'bg-gray-400'
        } rounded-full p-1 cursor-pointer`}
      >
        <div
          className={`bg-white md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out${
            active ? toggleClass : ''
          }`}
        />
      </div>
    </div>
  );
};

Toggle.propTypes = {
  active: PropTypes.bool.isRequired,
};

export const taxRuleGroupGrid = [
  {
    field: 'id',
    headerText: 'ID',
    width: '80',
    textAlign: 'Start',
  },
  {
    field: 'name',
    headerText: 'Name',
    textAlign: 'Start',
  },
  {
    field: 'active',
    headerText: 'Activated',
    template: Toggle,
    textAlign: 'Center',
  },
  {
    field: 'action',
    headerText: 'Action',
    template: Actions,
    textAlign: 'End',
  },
];
